import {MobxManager, ModelValidator, ValidatableModel} from "../../../framework/mobx-integration";
import {ApplicationState} from "../../../framework/applicationState";
import {CostBudgetItem, CostBudgetLink} from "../models/costBudgetItem";
import {flatTree} from "../../../tools/tree";
import {CostsApi} from "../api";
import {costTranslates} from "../translations";
import {DataNode} from "antd/lib/tree";
import {BudgetTableStore} from "./components/BudgetTableStore";
import {BudgetYearValues, CostType} from "./budgetTypes";
import {makeAutoObservable, makeObservable, observable} from "mobx";
import {LinkCostProfileRowWindowProps} from './linkCostProfileRowWindow'
import {CostBudget} from "../models/costBudget";
import {translator} from "core/localization/localization";
import {LabeledValue} from "antd/lib/tree-select";
import {AntTreeSelectValue} from "../../../controls/react/ant/antTreeSelect";
import {Key, LegacyDataNode } from "rc-tree-select/lib/interface";
import {DefaultOptionType} from "rc-tree-select/lib/TreeSelect";
import {sortBy} from "lodash";
import {getCurrencyConversionErrorString} from "../costHelper";
import {apiFetch} from "framework/api";
import {getCostLinkInfo} from "areas/cost/api";

const i = translator({
	"The input value should be between {0} and {1}.": {
		"no": "Sett inn en verdi mellom {0} og {1}."
	},
	"{0} is already linked in this Cost Model": {
		"no": "{0} er allerede lenket i denne Kost Modellen"
	},
	"Could not fetch cost data. There might be an issue with your cost database.": {
		"no": "Klarte ikke å hente kost data. Det kan være et problem med kost databasen."
	}
}, costTranslates);

type CostModelDetails = {
	name: string,
	id: string,
	items: CostModelDetails[],
	cost: BudgetYearValues,
	costType: CostType,
	listingPrice: BudgetYearValues,
	currentEstimate: number,
	periodEstimate: number,
	reportModelId: string
}

interface TreeNode<TData> extends DataNode {
	checked: boolean;
	children?: TreeNode<TData>[];
	value: string;
	label: string;
	parent: TreeNode<TData>;
	childrenLoaded: boolean;
	data: TData;
}

export enum CheckStrategy {
	Free = 'FREE',
	Dependent = 'DEPENDENT',
	DependOnChild = 'DEPEND_ON_CHILD',
	DependOnLeaf = 'DEPEND_ON_LEAF'
}

export class TreeCache {
	initialData: CostModelDetails[];
	initialMap: Map<string, CostModelDetails> = new Map();
	treeData: TreeNode<CostModelDetails>[] = [];
	treeMap: Map<string | number, TreeNode<CostModelDetails>> = new Map();
	checkStrategy: CheckStrategy;

	constructor(data: CostModelDetails[], checkStrategy: CheckStrategy = CheckStrategy.Dependent) {
		this.checkStrategy = checkStrategy;
		makeObservable(this, {
			treeData: observable
		});
		this.initialData = data;
		flatTree(this.initialData, (x) => x.items).forEach((x) => {
			this.initialMap.set(x.id, x);
		});
		this.treeData = this.mapLevel(this.initialData);
		this.addToTreeMap(this.treeData);
	}

	mapLevel(nodes: CostModelDetails[], parent: TreeNode<CostModelDetails> = null, defaults: Partial<{checked: boolean, disabled: boolean}> = {}) : TreeNode<CostModelDetails>[] {
		return sortBy(nodes, x => x.name).map(node => ({
			key: node.id,
			value: node.id,
			label: node.name,
			isLeaf: !(node.items || []).length,
			disabled: false,
			checked: false,
			children: null,
			childrenLoaded: false,
			parent,
			...defaults,
			data: node
		}));
	}

	loadChildren(node: LegacyDataNode | TreeNode<CostModelDetails>, defaults: Partial<{checked: boolean, disabled: boolean}> = null) {
		if(node.childrenLoaded) {
			return;
		}

		const data = this.initialMap.get(node.key as string);
		const treeNode = this.treeMap.get(node.key);

		treeNode.children = this.mapLevel(data.items, treeNode,defaults || {checked: treeNode.checked, disabled: treeNode.disabled});
		treeNode.childrenLoaded = true;

		this.addToTreeMap(treeNode.children);
		this.treeData = [...this.treeData]; // force reload
	}

	loadForSearch(value: string) {
		const ids = this.idsToLoadForSearch(this.initialData[0], value);
		this.loadNodes(this.treeData[0], ids);
	}

	idsToLoadForSearch(node: CostModelDetails, search: string) : string[] {
		let ids : string[] = [];
		let added = false;
		for(let child of (node.items || [])) {
			const childIds = this.idsToLoadForSearch(child, search);
			const needToAdd = childIds.length > 0 || child.name.toLowerCase().includes(search.toLowerCase());
			if(!added && needToAdd) {
				ids.push(node.id);
				added = true;
			}
			//should be after we add node, to be sure that parent is loaded when we load child
			ids = ids.concat(childIds);
		}
		return ids;
	}

	allNodeChildIds(node: TreeNode<CostModelDetails>) {
		const id = node.value;
		const initialNode = this.initialMap.get(id);
		return flatTree(initialNode.items || [], x => x.items).map(x => x.id);
	}

	addToTreeMap(nodes: TreeNode<CostModelDetails>[]){
		nodes.forEach((x => {
			this.treeMap.set(x.key, x);
		}));
	}

	topCheckedNode(parentNode: TreeNode<CostModelDetails> = null) : TreeNode<CostModelDetails> {
		parentNode ||= this.treeData[0];
		if (parentNode.checked) {
			return parentNode;
		}
		for(let child of parentNode.children || []) {
			const childTopCheckedNode = this.topCheckedNode(child);
			if(childTopCheckedNode) {
				return childTopCheckedNode;
			}
		}
		return null;
	}

	setChecked(values: string[]) {
		const checked: TreeNode<CostModelDetails>[] = [];
		const unchecked: TreeNode<CostModelDetails>[] = [];
		this.treeMap.forEach((x) => {
			if (values.includes(x.value)) {
				if (!x.checked) checked.push(x);
			} else {
				if(x.checked) unchecked.push(x);
			}
		});
		// we must do it after first iteration, to prevent overriding checked state of descendants
		checked.forEach(this.checkNode);
		unchecked.forEach(this.uncheckNode);
		this.treeData = [...this.treeData]; // force reload
	}

	get checkedNodes() {
		const result: TreeNode<CostModelDetails>[] = [];
		this.treeMap.forEach((x) => {
			if (x.checked) result.push(x);
		});
		return result;
	}

	checkNode = (node: TreeNode<CostModelDetails>) => {
		if (node.checked || node.disabled) {
			return;
		}

		if (this.checkStrategy == CheckStrategy.Dependent) {
			const topCheckedAncestor = this.topCheckedAncestor(node);
			if(topCheckedAncestor) {
				this.changeToAncestor(node, topCheckedAncestor);
			} else {
				this.changeToAncestor(node, this.treeData[0]);
			}

			// must be after checkToAncestor
			if (node.parent && !node.parent.checked) {
				//disable siblings
				this.setDisabled(node.parent.children.filter(x => x.value != node.value), true);
				this.disableSiblingsOfAncestors(node);
			}
		} else if (this.checkStrategy == CheckStrategy.DependOnChild && !node.parent?.checked && node.parent?.parent) {
			this.changeToAncestor(node, node.parent);
		} else if (this.checkStrategy == CheckStrategy.DependOnLeaf && !node.parent?.checked && node.isLeaf) {
			this.changeToAncestor(node, node.parent);
		}

		node.checked = true;
		if (this.checkStrategy == CheckStrategy.Dependent) {
			this.setDisabled(node.children || [], false);
			if (!this.anyDescendantChecked(node)) {
				this.setDescendantsChecked(node, true);
			}
		}
	}

	changeToAncestor(node: TreeNode<CostModelDetails>, ancestor: TreeNode<CostModelDetails>, value = true) {
		let parent = node;
		while(parent && parent != ancestor.parent) {
			parent.checked = value;
			parent = parent.parent;
		}
	}

	uncheckNode = (node: TreeNode<CostModelDetails>) => {
		if(!node.checked) {
			return;
		}

		node.checked = false;

		if (this.checkStrategy == CheckStrategy.Dependent) {
			if (node.parent && !node.parent.checked && node.parent.children.every(x => !x.checked)) {
				this.setDisabled(node.parent.children, false);
			}
		}

		if (this.checkStrategy == CheckStrategy.DependOnChild) {
			this.setDescendantsChecked(node, false);
		}

		if (node.isLeaf || !node.childrenLoaded || this.checkStrategy == CheckStrategy.DependOnLeaf) {
			this.setDescendantsChecked(node, false);
			return;
		}

		if (node.isLeaf || !node.childrenLoaded || this.checkStrategy != CheckStrategy.Dependent) {
			return;
		}

		if (!this.topCheckedAncestor(node) && this.oneChildChecked(node)) {
			this.setDisabled(node.children.filter(x => !x.checked), true);
		} else {
			this.setDescendantsChecked(node, false);
		}
	}

	oneChildChecked = (node: TreeNode<CostModelDetails>) => {
		return node.children?.filter(x => x.checked).length == 1;
	}

	anySiblingChecked = (node: TreeNode<CostModelDetails>) => {
		if (!node.parent) {
			return false;
		}
		return node.parent.children.some(x => x.checked);
	}

	anyDescendantChecked = (node: TreeNode<CostModelDetails>) => {
		for(let child of (node.children || [])) {
			if (child.checked) return true;
			if (this.anyDescendantChecked(child)) return true;
		}
		return false;
	}

	allDescendantsChecked = (node: TreeNode<CostModelDetails>) => {
		for(let child of (node.children || [])) {
			if (!child.checked) return false;
			if (!this.allDescendantsChecked(child)) return false;
		}
		return true;
	}

	setDescendantsChecked(node: TreeNode<CostModelDetails>, value: boolean) {
		(node.children || []).forEach(x => {
			x.checked = value;
			this.setDescendantsChecked(x, value);
		});
	}

	setDisabled(nodes: TreeNode<CostModelDetails>[], value: boolean) {
		nodes.forEach(x => {
			x.disabled = value;
			if (value) {
				x.checked = false;
			}
			this.setDisabled(x.children || [], value);
		});
	}

	disableSiblingsOfAncestors(node: TreeNode<CostModelDetails>) {
		let parent = node.parent;
		while (parent.parent != null) {
			this.setDisabled(parent.parent.children.filter(x => x.value != parent.value), true);
			parent = parent.parent;
		}
	}

	topCheckedAncestor(node: TreeNode<CostModelDetails>) {
		let parent = node.parent;
		let top: TreeNode<CostModelDetails> = null;
		while(parent) {
			if(parent.checked) {
				top = parent;
			}
			parent = parent.parent;
		}
		return top;
	}

	nodeCheckedDescendants(node: TreeNode<CostModelDetails>) {
		let result: string[] = [];
		if(node.isLeaf) {
			return [];
		}
		//if node children are not loaded & node.checked we decide that all children are checked too
		if(!node.childrenLoaded) {
			if(node.checked) {
				const initialNode = this.initialMap.get(node.value);
				const children = flatTree(initialNode.items, x => x.items);
				return children.map(x => x.id);
			} else {
				return [];
			}
		}

		node.children.forEach(x => {
			if(x.checked) {
				result.push(x.value);
			}
			result = result.concat(this.nodeCheckedDescendants(x));
		});
		return result;
	}

	getInitialNode(targetId: string) {
		return this.initialMap.get(targetId);
	}

	loadAndCheckNecessaryNodes(checkedIds: string[]) {
		const loadIds = this.nodesToLoadIds(this.initialData[0], checkedIds);
		this.loadAndCheckNode(this.treeData[0], loadIds, checkedIds);
	}

	get loadedNodesIds() {
		const result: string[] = [];
		this.treeMap.forEach(x => {
			if (x.childrenLoaded) {
				result.push(x.value);
			}
		})
		return result;
	}

	loadNodes(node: TreeNode<CostModelDetails>, loadIds: string[]) : boolean {
		if (node.isLeaf) {
			return ;
		}

		if(loadIds.includes(node.value)) {
			this.loadChildren(node);
			node.children.forEach(child => this.loadNodes(child, loadIds));
		}
	}

	loadAndCheckNode(node: TreeNode<CostModelDetails>, loadIds: string[], checkedIds: string[]) {
		if(checkedIds.includes(node.value)) {
			node.checked = true;
		}

		if (node.isLeaf) {
			return;
		}

		if(loadIds.includes(node.value)) {
			this.loadChildren(node, {checked: false, disabled: false});
			node.children.forEach(child => this.loadAndCheckNode(child, loadIds, checkedIds));
		}
	}

	// we load node if it has meaningful checked state
	// if it has children with state that not the same as parent's state
	// if any descendant should be loaded
	nodesToLoadIds(node: CostModelDetails, checkedIds: string[]) : string[] {
		if (!(node.items || []).length) {
			return [];
		}
		const isChecked = (x: CostModelDetails) => checkedIds.includes(x.id);
		const currentNodeIsChecked = isChecked(node);
		let result: string[] = [];
		let nodeHasChildWithDifferentChecked = false;
		// we don't need to load node if no any child checked
		// because we think that all this nodes selected and will selected when node loaded
		let anyChildChecked = false;
		for (let child of node.items) {
			result = result.concat(this.nodesToLoadIds(child, checkedIds));

			const childChecked = isChecked(child);
			anyChildChecked ||= childChecked;
			nodeHasChildWithDifferentChecked ||= currentNodeIsChecked != childChecked;
		}

		if((nodeHasChildWithDifferentChecked && anyChildChecked) || result.length > 0) {
			result.push(node.id);
		}

		return result;
	}
}

// temporary for debug purpose
const printTree = (tree: TreeNode<CostModelDetails>[], level = 0) =>  {
	if(!tree || tree.length < 1) {
		return;
	}
	const tabs = Array.from(Array(level).keys()).map(x => "\t").join('');
	tree.forEach(x => {
		console.log(
			`${tabs}${x.label}, checked: %c${x.checked}%c, disabled: %c${x.disabled}`,
			`color:${x.checked ? 'green' : 'black'}`,
			'color: inherit',
			`color:${x.disabled ? 'red' : 'green'}`
		);
		printTree(x.children, level+1);
	});
}

export class LinkCostProfileRowWindowStore implements ValidatableModel<LinkCostProfileRowWindowStore>, LinkCostProfileRowWindowProps {
	accounts: any[];
	costProfiles: any[];
	costModels: any[];
	targetId?: string = null;
	costModelId?: string = null;
	accountId?: string = ApplicationState.accountId;
	costProfileId?: string = null;
	linkName: string;
	error?: string;
	errorSeverity?: 'error' | 'warning-error' | null;
	mode: 'create' | 'update';
	hasHierarchy: boolean;
	hierarchyIds: string[] = [];
	percentage: number = 100;
	searchValue: string;

	onSave: (link: CostBudgetLink) => void;
	onCancel: () => void;
	initLink?: CostBudgetItem;
	tableStore: BudgetTableStore;
	budget: CostBudget;
	parent: CostBudgetItem;

	value: AntTreeSelectValue;

	validator = new ModelValidator<LinkCostProfileRowWindowStore>(this);

	treeCache: TreeCache;
	expandedKeys: string[] = [];

	mobx = new MobxManager()

	constructor(props: LinkCostProfileRowWindowProps) {
		Object.assign(this, props);
		makeAutoObservable(this);

		if(this.initLink) {
			this.fillDataFromInitLink(() => {
				this.setupReactions();
			});
		} else {
			this.setupReactions();
			this.loadProfiles();
			this.loadAccounts();
		}

		this.validator
			.required('linkName')
			.required('accountId')
			.required('costProfileId')
			.required('costModelId')
			.required('targetId')
			.required('percentage')
			.between('percentage', 0, 100, true)
			.add('value', { callback: () => this.duplicatedIds.length == 0 });
	}

	private setupReactions() {
		this.mobx.reaction(() => this.accountId, (value) => {
			this.costProfileId = null;
			value && this.loadProfiles();
		});

		this.mobx.reaction(() => this.costProfileId, (value) => {
			this.costModelId = null;
			value && this.loadModels();
		});

		this.mobx.reaction(() => this.costModelId, (value) => {
			this.targetId = null;
			this.value = this.hasHierarchy ? [] : null;
			if (value) {
				this.loadTree();
			} else {
				this.treeCache = null;
			}
		});

		this.mobx.reaction(() => this.targetId, (value, prevValue) => {
			if (value) {
				this.linkName = this.treeCache?.getInitialNode(value)?.name;
			} else {
				const prevLabel = this.treeCache?.getInitialNode(prevValue)?.name;
				if (prevLabel == this.linkName) {
					this.linkName = null;
				}
			}
		});

		this.mobx.reaction(() => this.hasHierarchy, (value, prevValue) => {
			if (!value) {
				this.value = this.targetId;
			} else {
				this.value = [];
			}
		})
	}

	get treeData() {
		return this.treeCache?.treeData;
	}

	get currency() {
		return this.budget.currency;
	}

	get startDate() {
		return this.budget.startDate;
	}

	get parentCostModelId() {
		return this.budget.parent?.id;
	}

	get parentProfileId() {
		return this.budget.costBudget.profileId;
	}

	get valid() {
		return this.validator.valid;
	}

	get showHierarchyCheckbox() {
		return true
	}

	get topCheckedNode() {
		return this.treeCache.topCheckedNode();
	}

	get budgetLinkedIds() {
		let { linkedIds } = this.budget.costBudget;
		if(this.mode != 'update') {
			return linkedIds;
		}
		// remove initialLink ids from all linkedIds
		const initialLinkLinkedIds = this.initLink.linkedIds;
		return linkedIds.filter(x => !initialLinkLinkedIds.includes(x));
	}

	get duplicatedIds() {
		return this.allCheckedIds.filter(x => this.budgetLinkedIds.includes(x));
	}

	setValue = (value: AntTreeSelectValue, valueArray: string[]) => {
		let valueName;
		if (value instanceof Array) {
			const labeledValue = value as LabeledValue[];
			// set value must be after setting checked
			this.treeCache.setChecked(labeledValue.map(x => x.value as string));
			this.setValueFromTreeCache();
			this.targetId = this.treeCache?.topCheckedNode()?.value;
			valueName = labeledValue[0]?.label;
		} else {
			this.value = value;
			this.targetId = value as string;
			valueName = valueArray[0]
		}
		if (this.duplicatedIds.length) {
			this.error = i('{0} is already linked in this Cost Model', valueName);
			this.errorSeverity = 'warning-error';
		} else {
			this.error = null;
			this.errorSeverity = null;
		}
	}

	setValueFromTreeCache = () => {
		const newValue: LabeledValue[] = this.treeCache.checkedNodes.map(x => {
			return {
				label: x.label,
				value: x.value,
				key: x.key as string,
			};
		});
		this.value = this.hasHierarchy ? this.sortValue(newValue) : newValue[0]?.value;
	}

	sortValue(value: LabeledValue[]) : LabeledValue[] {
		const topNodeId = this.topCheckedNode?.value;
		if(!topNodeId) {
			return value;
		}
		return value.sort((a, b) => {
			if (a.value == topNodeId) return -1;
			if (b.value == topNodeId) return 1;
			return 0;
		})
	}

	get allCheckedIds() {
		if (!this.hasHierarchy) {
			return [this.value as string | null];
		}
		const node = this.treeCache?.topCheckedNode();
		if(!node) {
			return [];
		}
		if(this.treeCache.allDescendantsChecked(node)) {
			return [node.value, ...this.treeCache.allNodeChildIds(node)];
		} else {
			return [node.value, ...this.treeCache.nodeCheckedDescendants(node)];
		}
	}

	get targetIdAndHierarchyIds() : [string, string[]] {
		let targetId: string;
		let hierarchyIds: string[] = [];

		if(this.hasHierarchy) {
			const node = this.treeCache?.topCheckedNode();
			if(!node) {
				return [null, []];
			}
			targetId = node.value;
			if(this.treeCache.allDescendantsChecked(node)) {
				hierarchyIds = [];
			} else {
				hierarchyIds = this.treeCache.nodeCheckedDescendants(node);
			}
		} else {
			targetId = this.value as string | null;
		}
		return [targetId, hierarchyIds];
	}

	save = async () => {
		const [targetId, hierarchyIds] = this.targetIdAndHierarchyIds

		const costBudgetItem = this.treeCache.getInitialNode(targetId)

		const result = await apiFetch(getCostLinkInfo({
			costTargetId: costBudgetItem.id,
			costTargetType: costBudgetItem.costType,
			costModelId: this.costModelId,
			targetAccountId: this.accountId,
			targetProfileId: this.costProfileId,
			hasHierarchy: this.hasHierarchy,
			percentage: this.percentage,
			hierarchyIds
		}, this.budget.currency, this.budget.startDate.format('YYYY-MM-DD'), this.accountId))

		if(result.success) {
			const newCostLink = result.data
			newCostLink.name = this.linkName;
			this.onSave(newCostLink)
		} else {
			const { message } = result
			this.errorSeverity = 'error'

			const conversionError = getCurrencyConversionErrorString(message)
			this.error = conversionError || i('Could not fetch cost data. There might be an issue with your cost database.')
		}
	}


	loadings: boolean[] = [];
	withLoading = (fn: () => Promise<void>) => {
		const n = this.loadings.length;
		this.loadings[n] = false;
		return async () => {
			const timeout = setTimeout(() => {
				this.loadings[n] = true;
			}, 1000);
			await fn();
			if(timeout) {
				clearTimeout(timeout);
			}
			this.loadings[n] = false;
		}
	}

	get loading() {
		return this.loadings.some(x => x);
	}

	loadAccounts = this.withLoading(async () => {
		const result = await CostsApi.subAccountList(this.accountId);
		this.accounts = result;
	})

	loadProfiles = this.withLoading(async () => {
		const result = await CostsApi.subAccountProfilesList(this.accountId);
		const profiles = result.data.filter((x: { id: string }) => x.id != this.parentProfileId);
		this.costProfiles = profiles;
	});

	loadModels = this.withLoading(async () => {
		const result = await CostsApi.subAccountProfileModelsList(this.accountId, this.costProfileId, this.parentCostModelId);
		this.costModels = result.data;
	});

	loadTree = this.withLoading(async () => {
		const result = await CostsApi.costModelDetails(this.costModelId, this.currency, this.startDate.format('YYYY-MM-DD'));
		if (!result.success) {
			const { message } = result

			const conversionError = getCurrencyConversionErrorString(message)
			if (conversionError) {
				this.error = conversionError
				this.errorSeverity = 'error';
			}
			this.treeCache = null;
			return;
		}
		this.error = null;
		this.errorSeverity = null;
		this.treeCache = new TreeCache([result.data]);
		this.expandedKeys = [result.data.id];
	});

	get treeCheckable() {
		return this.hasHierarchy;
	}

	async fillDataFromInitLink(callback: () => void) {
		const {initLink} = this;
		this.mode = 'update';
		this.accountId = initLink.targetAccountId;
		this.costProfileId = initLink.targetProfileId;
		this.costModelId = initLink.costModelId;
		this.targetId = initLink.costTargetId;
		this.linkName = initLink.name;
		this.hasHierarchy = initLink.hasHierarchy;
		this.percentage = initLink.percentage;

		const promises = [
			this.loadAccounts(),
			this.loadProfiles(),
			this.loadModels(),
			this.loadTree()
		];

		await Promise.all(promises);

		if(initLink.hasHierarchy) {
			const ids = [this.targetId, ...initLink.hierarchyIds];
			this.treeCache.loadAndCheckNecessaryNodes(ids);
			this.setValueFromTreeCache();
		} else {
			this.treeCache.loadAndCheckNecessaryNodes([this.targetId]);
			this.value = this.targetId;
		}
		this.expandedKeys = this.treeCache.loadedNodesIds;

		callback();
	}

	loadChildren = async (node: LegacyDataNode) => {
		this.treeCache.loadChildren(node);
		this.setValueFromTreeCache();
	}

	searchTree = (value: string) => {
		this.searchValue = value;
		if(value.length < 2) {
			return;
		}
		this.treeCache.loadForSearch(value);
		this.expandedKeys = this.treeCache.loadedNodesIds;
	}

	static filterTreeNode(inputValue: string, treeNode: DefaultOptionType) {
		if(inputValue.length < 2) {
			return true;
		}
		return (treeNode.label as string).toLowerCase().includes(inputValue.toLowerCase());
	}

	setExpandedKeys = (keys: Key[]) => { this.expandedKeys = keys as string[] };

	destroy(){
		this.mobx.destroy()
	}
}
