import './antInput.less'

import React, {CSSProperties} from "react"
import { observer } from 'mobx-react'
import Input, {InputProps, TextAreaProps} from "antd/lib/input"

const {TextArea, Password} = Input;

import {
	useEnterEscHandlers,
	captureValueLink,
	ChangeEventHandlerWrapped,
	useBouncedWithEvent,
	wrapOnChange,
	OnKeyUpHolder
} from "controls/react/ant/utils";
import TextareaAutosize, {TextareaAutosizeProps} from "react-textarea-autosize";
import classnames from "classnames";


interface AntInputProps extends Omit<InputProps, 'onChange'|'value'|'width'>{
	valueLink?: any
	invalid?: boolean
	errors?: Array<any>
	onChange?: ChangeEventHandlerWrapped<string, HTMLInputElement>
	ref?: any
	onPressEnter?: () => void
	onPressEsc?: () => void
	bounced?: number
	value?: string
	width?: number
}

export const AntInput:React.FunctionComponent<AntInputProps> = observer(React.forwardRef((props, ref) => {
	let {errors, invalid, ...restProps} = captureValueLink(props)


	const wrappedProps = wrapOnChange(restProps)
	const wrappedProps2 = useCustomWidth(wrappedProps)
	const wrappedProps3 = useEnterEscHandlers(wrappedProps2)

	const {onChange, value, ...restProps2} = wrappedProps3

	const [bouncedValue, bouncedOnChange] = useBouncedWithEvent(props.bounced, value, onChange)

	return <Input ref={ref}
	              onChange={bouncedOnChange}
	              value={bouncedValue}
	              {...restProps2}/>
}))

interface AntPasswordProps extends Omit<InputProps, 'onChange'|'value'|'width'>{
	valueLink?: any
	invalid?: boolean
	errors?: Array<any>
	onChange?: ChangeEventHandlerWrapped<string, HTMLInputElement>
	ref?: any
	onPressEnter?: () => void
	onPressEsc?: () => void
	bounced?: number
	value?: string
	width?: number
}

export const AntPassword = observer((props: AntPasswordProps) => {
	let {errors, invalid, ...restProps} = captureValueLink(props)


	const wrappedProps = wrapOnChange(restProps)
	const wrappedProps2 = useCustomWidth(wrappedProps)
	const wrappedProps3 = useEnterEscHandlers(wrappedProps2)

	const {onChange, value, ...restProps2} = wrappedProps3

	const [bouncedValue, bouncedOnChange] = useBouncedWithEvent(props.bounced, value, onChange)

	return <Input onChange={bouncedOnChange}
	              value={bouncedValue}
	              {...restProps2}/>
})

function useCustomWidth<T extends {width?: number, style?: CSSProperties}>(props: T){
	const {width, style,  ...restProps} = props

	let newStyles = React.useMemo(() => {
		let result: CSSProperties = {
			...(style ?? {})
		}

		if(Number.isInteger(props.width)){
			result.width = props.width + 'px'
		}

		return result

	}, [props.width, props.style])


	return {
		style: newStyles,
		...restProps
	}
}

interface AntTextAreaProps extends Omit<TextAreaProps, 'onChange'>{
	valueLink?: any,
	invalid?: boolean,
	errors?: Array<any>,
	onChange?: ChangeEventHandlerWrapped<string, HTMLTextAreaElement>,
	height?: number,
}

export const AntTextArea = observer((props: AntTextAreaProps) => {
	let {errors, invalid, style, height, ...antNativeProps} = captureValueLink(props);

	const wrappedProps = wrapOnChange(antNativeProps);

	const styleReal = React.useMemo(() => {
		let result = style ?? {}
		if (height != null) {
			result.height = height
		}
		return result
	}, [style, height])

	return <TextArea style={styleReal} {...wrappedProps}/>
})

type AntTextAreaAutoSizeProps = Omit<TextareaAutosizeProps, 'onChange'> & {
	onChange?: ChangeEventHandlerWrapped<string, HTMLTextAreaElement>,
	autoFocus?: boolean
	bounced?: number
} & OnKeyUpHolder<HTMLTextAreaElement>

export const AntTextAreaAutoSize = observer(React.forwardRef<HTMLTextAreaElement, AntTextAreaAutoSizeProps>((props, ref) => {
	const {className, onChange, ...rest} = props
	const autoFocus = props.autoFocus ?? true;

	const onChangeWrapper = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
		onChange?.(event.target.value, event)
	}, [onChange])

	//const props1 = wrapOnChange(rest)
	const props1 = useEnterEscHandlers(rest)
	//const props3 = useEnterEscHandlers(props2)


	const classNameCombined = classnames(className, 'text-area-auto-size')
	const {value, ...props2} = props1

	const [bouncedValue, bouncedOnChange] = useBouncedWithEvent(props.bounced, value, onChangeWrapper)

	return <TextareaAutosize autoFocus={autoFocus}
	                         className={classNameCombined}
	                         onChange={bouncedOnChange}
	                         value={bouncedValue}
	                         {...props2}
	                         ref={ref}

	/>
}))
