import {Api, UrlBuilder} from 'tools';
import {ApiRequest, useRemoteList, useRemoteListLegacy, UseRemoteListOptions} from "framework/api";
import {Service, ServiceElement, ServiceLite} from "framework/entities/service";

export class ServicesApi{
	static getBaseUrl(accountId?: string, sessionId?: string){
		return Api.accountRoot(accountId, sessionId) + 'services';
	}

	static getServiceLiteUrl(accountId?: string, sessionId?: string) {
		return ServicesApi.getBaseUrl(accountId, sessionId) + '/lite?includeSubaccounts=true';
	}

	static getDynamicSearchUrl(accountId?: string, sessionId?: string) {
		return ServicesApi.getBaseUrl(accountId, sessionId) + '/search/lite?includeSubaccounts=true';
	}

	static getServiceUrl(serviceId: string, accountId?: string, sessionId?: string){
		return ServicesApi.getBaseUrl(accountId, sessionId) + '/' + serviceId;
	}

	static getServiceUpdateUrl(serviceId: string, updateMode: boolean, accountId?: string, sessionId?: string) {
		return `${ServicesApi.getBaseUrl(accountId, sessionId)}/${serviceId}/?update=${updateMode}`;
	}

	static getService(serviceId: string, updateMode = false, accountId?: string, sessionId?: string) {
		return Api.fetch(ServicesApi.getServiceUpdateUrl(serviceId, updateMode, accountId, sessionId));
	}

	static getModel(serviceId: string, accountId?: string, cache?: Record<string, any>, sessionId?: string){
		return Api.fetch(
			ServicesApi.getServiceUrl(serviceId, accountId, sessionId) + "/mxmodel",
			null,
			cache,
			"serviceModel" + serviceId);
	}

	static getModelUrl(serviceId: string){
		return new UrlBuilder(ServicesApi.getServiceUrl(serviceId) + "/mxmodel");
	}

	static getModelToEdit(serviceId: string){
		return Api.fetch(
			ServicesApi.getModelUrl(serviceId)
				.addPath("edit")
		);
	}

	static saveModel(serviceId: string, model: object, continueEditing: boolean) {
		let urlBuilder = ServicesApi.getModelUrl(serviceId)
			.add('continueEditing', true, continueEditing);

		return Api.fetchPost(urlBuilder.build(), model);
	}

	static releaseModel(serviceId: string){
		return Api.fetch(ServicesApi.getServiceUrl(serviceId) + "/release");
	}

	static getRevisionsUrl(serviceId: string){
		return ServicesApi.getModelUrl(serviceId)
			.addPath('revisions')
	}

	static getRevisions(serviceId: string){
		return Api.fetch(ServicesApi.getRevisionsUrl(serviceId));
	}

	static deleteRevisions(serviceId: string, revisions: string[]){
		return Api.fetchPost(
			ServicesApi.getRevisionsUrl(serviceId)
				.addPath('delete'),
			revisions
		);
	}

	static updateRevision(serviceId: string, revision: any){
		return Api.fetchPost(
			ServicesApi.getRevisionsUrl(serviceId)
				.addPath(revision.time),
			revision
		);
	}

	static getRevisionToEdit(serviceId: string, revisionId: string){
		return Api.fetch(
			ServicesApi.getRevisionsUrl(serviceId)
				.addPath(revisionId)
				.addPath('model')
		);
	}

	static promoteRevision(serviceId: string, revisionId: string){
		return Api.fetch(
			ServicesApi.getRevisionsUrl(serviceId)
				.addPath(revisionId)
				.addPath('deploy')
		);
	}

	static getDraftUrl(serviceId: string){
		return ServicesApi.getModelUrl(serviceId)
			.addPath('draft')
	}

	static getDraftToEdit(serviceId: string){
		return Api.fetch(ServicesApi.getDraftUrl(serviceId));
	}

	static saveDraft(serviceId: string, model: object, promoteToCurrent = false){
		return Api.fetchPost(
			ServicesApi.getDraftUrl(serviceId).add("deploy", promoteToCurrent),
			model
		);
	}

	static deleteDraft(serviceId: string){
		return Api.fetchDelete(ServicesApi.getDraftUrl(serviceId));
	}

	static getSearchUrl() {
		return ServicesApi.getBaseUrl() + '/search/lite?includeSubaccounts=false';
	}

	static getLastMaintenances(serviceId: string) {
		return Api.fetch(`${ServicesApi.getBaseUrl()}/${serviceId}/maintenance/next3`)
	}
}

export class ServiceModelsApi{
	static getRootUrl(serviceId: string, accountId?: string, sessionId?: string) {
		return sessionId ?
			Api.sessionAndAccountRoot(accountId, sessionId) + "services/" + serviceId + "/" :
			Api.accountRoot(accountId) + "services/" + serviceId + "/";
	}

	static readModelTemplate(templateId: string, serviceId: string, accountId?: string) {
		return Api.fetch(
			ServiceModelsApi.getRootUrl(serviceId, accountId) + "mxmodel/templates/" + templateId
		);
	}

	static saveModelTemplate(serviceId: string, templateData: object, accountId?: string) {
		return Api.fetchPost(
			ServiceModelsApi.getRootUrl(serviceId, accountId) + "mxmodel/templates",
			templateData
		);
	}

	static readElementTemplate(templateId: string, serviceId: string, accountId?: string) {
		return Api.fetch(
			ServiceModelsApi.getRootUrl(serviceId, accountId) + "mxmodel/elements/templates/" + templateId
		);
	}

	static saveElementTemplate(serviceId: string, templateData: object, accountId?: string) {
		return Api.fetchPost(
			ServiceModelsApi.getRootUrl(serviceId, accountId) + "mxmodel/elements/templates",
			templateData
		);
	}

	static getServiceFromModel(modelId: string) {
		return Api.fetch(`${Api.serverRoot()}services/serviceInfo?modelId=${modelId}`)
	}
}

export function useServices(accountId?: string, args?: UseRemoteListOptions) {
	return useRemoteList(getServicesLite({
		accountId
	}), args)
}

export type GetServicesLiteArgs = {
	accountId?: string
	includeSubaccounts?: boolean
	hasModel?: boolean
}

export function getServicesLite(args?: GetServicesLiteArgs){
	const builder = new UrlBuilder('services/lite')
		.add('hasModel', args?.hasModel, args?.hasModel != null)

	return new ApiRequest<ServiceLite[]>({
		url: builder.build(),
		accountBased: true,
		accountId: args?.accountId,
		subaccountsFilter: args?.includeSubaccounts ?? true
	})
}

export function getServiceElementsLite(serviceId: string, accountId?: string){
	return new ApiRequest<{id: string, name: string}[]>({
		url: 'services/' + serviceId + '/elements/lite',
		accountId: accountId,
		accountBased: true,
		subaccountsFilter: true
	})
}

export function getServiceElementQualifiers(serviceId: string, elementId: string, accountId: string){
	return new ApiRequest<{id: string, name: string}[]>({
		url: `services/${serviceId}/elements/${elementId}/qualifiers/lite?update=false`,
		accountId: accountId,
		accountBased: true,
		subaccountsFilter: true
	})
}
export function getServiceQualifiers(serviceId: string, accountId: string){
	return new ApiRequest<{id: string, name: string}[]>({
		url: `services/${serviceId}/qualifiers/lite`,
		accountId: accountId,
		accountBased: true,
		subaccountsFilter: true
	})
}

type CreateLogEntryArgs = {
	message: string
	state: string
	serviceId: string
}

export function createLogEntry(args: CreateLogEntryArgs){
	return new ApiRequest({
		url: `services/${args.serviceId}/notifications/?status=${args.state}`,
		payload: {
			message: args.message
		}
	})
}

export type GetServiceArgs = {
	id: string
	accountId?: string
	updateMode?: boolean
}
export function getService(args: GetServiceArgs){
	return new ApiRequest<Service>({
		url: `services/${args.id}/?update=${args.updateMode}`,
		accountBased: true,
		accountId: args.accountId,
		responseType: Service
	})
}

export type GetServiceModelArgs = {
	id: string
}

export function getServiceModel(args: GetServiceModelArgs){
	return new ApiRequest<any>({
		url: 'services/' + args.id + '/mxmodel'
	})
}

