const baseZIndex = 999900

//modal is shown on top of the app
export const modalZIndex = baseZIndex + 10

//popovers are shown on top of modal (though they will be visible through overlay
// but in general when a modal is opened any currently opened popovers should be closed so that should not be an issue)
export const popoverZIndex = modalZIndex + 10

//popups from controls should bo on top of everything - for example dropdown select list or datepicker select form
export const controlPopupZIndex = popoverZIndex + 10
