import React from "react"
import { observer } from "mobx-react"

import {Section} from "controls/react/layout/section"
import {Toolbar, ToolbarItemsSet} from "controls/react/layout/toolbar"
import {IconButton} from "controls/react/form/iconButton"

import {WidgetConfig} from "controls/designer/features/widgets/widgetConfig"
import {WidgetProps} from "controls/designer/features/widgets/widgetProps"


export interface DefaultWidgetWrapperProps<C extends WidgetConfig, P extends object> extends WidgetProps<C, P>{
	toolbarAtTheEnd?: React.ReactNode
	containerClass?: string
	children: React.ReactNode,
	appearance?: string,
	showHeader?: boolean
	headerPosition?: React.CSSProperties
}

export const DefaultWidgetWrapper = observer(<C extends WidgetConfig, P extends object>(props: DefaultWidgetWrapperProps<C, P>) => {
	let showWidgetHeader = props.showHeader !== undefined
		? props.showHeader
		: props.dashboardSettings.showWidgetHeader

	showWidgetHeader = showWidgetHeader || !props.designer.config.chromeless

	let isReadOnly = props.dashboardSettings.readOnly;

	return (
		<Section contentPadding={false} height={"fit"}
		         appearance={props.appearance}
		         containerClass={props.containerClass}>
			{showWidgetHeader &&
				<Toolbar title={props.config.title} containerStyle={props.headerPosition}>
					{props.toolbarAtTheEnd}
					<ToolbarItemsSet noPadding>
						{!isReadOnly && <IconButton iconName={"pencil"}
													embedded={true}
													onClick={props.actions.editWidget}/>}
						{!isReadOnly && <IconButton iconName={"remove"}
													embedded={true}
													onClick={props.actions.deleteWidget}/>}
					</ToolbarItemsSet>
				</Toolbar>
			}
			{props.children}
		</Section>
	)
})
