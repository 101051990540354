import {Root} from "react-dom/client";
import React from "react";
import {Editor} from "tinymce";

import {WidgetProps} from "controls/designer/features/widgets/widgetProps";
import {AiQueryWidgetConfig} from "controls/designer/features/widgets/ai-query-widget/aiQueryWidgetConfig";
import {
	AiQueryWidgetPersistedState
} from "controls/designer/features/widgets/ai-query-widget/aiQueryWidgetPersistedState";
import {makeAutoObservable} from "mobx";
import {apiFetch} from "framework/api";
import {createContainerRoot} from "controls/react/ant/antModal";
import {getHealthInfo, HealthRequestEntry} from "controls/designer/api";
import {createNode, htmlToText} from "tools/helpers/dom";
import {createTinymceEditor} from "vendor-init/tinymce";
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {AiQueryWidgetChat} from "controls/designer/features/widgets/ai-query-widget/aiQueryWidgetChat";

import {CustomDataListWindowStore} from "controls/designer/features/widgets/custom-data-list/customDataListWindowStore";
import {AntButton} from "controls/react/ant/antButton";
import {PlusOutlined} from "@ant-design/icons";
import {CustomDataListEntry} from "controls/designer/features/widgets/custom-data-list/customDataListEntry";
import {newGuid} from "tools/guid";
import {AiChatEngine} from "controls/chat/aiChatEngine";
import {toStringTimeSelection} from "controls/react/form/timePeriodSelector";
import {formatNumber} from "tools/helpers/math";


const i18n = require('core/localization').translator({
	'Click to add to AI query': { no: 'Klikk for å legge til AI spørringen'}
}, sharedLocalization);

const supportedWidgets = ['metric-single-graph', 'metric-barchart', 'metric-gauge',
	'metric-table', 'metric-stacked-chart', 'metric-sparkline-table', 'metric-single-graph', 'metric-multi-graph-asset']

export class AiQueryWidgetStore {
	popupRoot: Root
	editor: Editor

	dataSources: HealthRequestEntry[]
	query: string

	chatEngine = new AiChatEngine()

	loadingInitial: boolean

	constructor(public props: WidgetProps<AiQueryWidgetConfig, AiQueryWidgetPersistedState>) {
		makeAutoObservable(this)

		this.dataSources = JSON.parse(JSON.stringify(props.persistedState?.dataSources ?? []))
		this.query = props.persistedState?.query ?? ''
	}

	get store(){
		return this.props.designer.store
	}

	sendRequestToAi = async () => {
		await this.chatEngine.send(htmlToText(this.query))
	}

	openPopup = async () => {
		if (this.popupRoot != null)
			return

		this.popupRoot = createContainerRoot()

		const geometry = this.props.designer.getCellAbsolutePosition(this.props.cell)

		const width = 600
		const left = Math.min(geometry.x + geometry.width + 20, window.innerWidth - width)

		const height = 700
		const top = geometry.y

		if (this.query) {
			this.loadingInitial = true
			await this.updateQueryWithActualValues()
			if (this.props.config.autorunQuery) {
				await this.sendRequestToAi()
			}
			this.loadingInitial = false
		}

		this.popupRoot.render(
			<AiQueryWidgetChat width={width}
			                   height={height}
			                   left={left}
			                   top={top}
			                   store={this}
			                   onClose={this.closePopup}/>
		)
	}

	closePopup = () => {
		this.popupRoot?.unmount()
		this.popupRoot = null
	}

	async getActualValues() {
		const response = await apiFetch(getHealthInfo(this.dataSources))

		if (response.success) {
			return response.data
		}

		return []
	}

	async updateQueryWithActualValues() {
		if (!this.query)
			return

		const values = await this.getActualValues()

		console.log(values)

		const rootNode = createNode(this.query)

		this.dataSources.forEach(x => {
			const entryNode = rootNode.querySelector(`[data-data-source-id="${x.id}"]`)
			if (entryNode) {
				console.log(entryNode)
				const valueHolder = values.find(v => x.id == v.id)
				if (valueHolder) {
					console.log(valueHolder)
					if ('metricId' in valueHolder) {
						if (valueHolder.metricInfo.data.length) {
							entryNode.innerHTML = formatNumber(valueHolder.metricInfo.data[0].v, 2)
						} else {
							entryNode.innerHTML = i18n('Value not found')
						}
					} else if ('costProfileId' in valueHolder) {
						entryNode.innerHTML = formatNumber(valueHolder.value, 2)
					} else {
						entryNode.innerHTML = 'COST VALUE'
					}
				}
			}
		})

		if (this.editor) {
			this.editor.setContent(rootNode.innerHTML)
		} else {
			this.query = rootNode.innerHTML
		}
	}

	setDesignerRef = (x: HTMLDivElement) => {
		if (!x) {
			this.editor?.destroy()
			return
		}

		requestIdleCallback(async () => {
			const initialValue = this.query

			const editors = await createTinymceEditor({
				target: x,
				min_height: 200,
				setup: (ed: Editor) => {
					ed.on('Paste Change input Undo Redo NodeChange', () => {
						this.query = ed.getContent()
					})
				},
				height: 200,
				menu: {},
				toolbar: '',
				menubar: '',
				contextmenu: '',
				statusbar: false
			})

			this.editor = editors[0]
			this.editor.setContent(initialValue)
		})
	}

	customDataListWindowStore: CustomDataListWindowStore

	showCustomDataList = () => {
		if (this.customDataListWindowStore == null) {
			this.customDataListWindowStore = new CustomDataListWindowStore(this.store, {
				dataSourcesFilter: item => {
					if (item.dataSource?.type == "metric" || item.dataSource?.type == 'cost') {
						return true
					}

					if (item.widgetConfig?.type && supportedWidgets.indexOf(item.widgetConfig.type) != -1) {
						return true
					}

					return false
				},
				actions: (item) =>
					<AntButton icon={<PlusOutlined/>}
					           title={i18n('Click to add to AI query')}
					           type={'text'} onClick={() => this.insertEntry(item)}/>
			})
		}

		this.customDataListWindowStore.open()
	}

	insertEntry = async (item: CustomDataListEntry) => {
		if (item.customDataId == null)
			return

		let customData = this.store.customData
			.find(x => x.id == item.id)

		if (!customData) {
			console.warn('Custom data should not be empty')
			return
		}

		let id = newGuid()

		if (customData.dataSource?.type == 'metric') {
			this.dataSources.push({
				id,
				metricId: item.customDataId
			})
		} else if (customData.dataSource?.type == 'cost') {
			const dataSource = customData.dataSource

			this.dataSources.push({
				id,
				costProfileId: dataSource.costProfileId,
				costItemId: dataSource.costItemId,
				valueType: dataSource.valueType,
				timePeriod: toStringTimeSelection(dataSource.timePeriod),
				currency: dataSource.currency,
				costModelId: dataSource.modelId
			})

		} else if (customData.widgetConfig) {
			const config = customData.widgetConfig
			switch (config.type) {
				case 'metric-barchart':
				case 'metric-gauge':
				case 'metric-table':
				case "metric-stacked-chart":
				case "metric-sparkline-table":
				case 'metric-single-graph':
				case "metric-multi-graph-asset":
					this.dataSources.push({
						id,
						metricId: item.customDataId
					})
					break
			}
		}

		this.editor.insertContent(` <span data-data-source-id='${id}' title="${item.customDataName}" style="color: #1677ff">{a value will be here}</span> `)

		await this.updateQueryWithActualValues()
	}

	destroy() {
		this.closePopup()
	}
}

function replaceNewLinesWithBr(text: string) {
	if (!text)
		return text

	return text.split("\n").join("<br />");
}
