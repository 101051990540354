import React from "react"
import ReactDOM from "react-dom"

import Utils from 'tools/utils'
import FormEntry from "controls/react/form/formEntry"
import {TagsSelect} from "controls/react/tagsSelect"
import {ApplicationState} from "framework/applicationState"


export class Tags {
	tagsList = []
	enabled = true

	constructor(config) {
		Utils.apply(this, config);
		this.accountId = this.accountId || ApplicationState.accountId;
		this.container = this.renderEl[0].parentNode

		this.renderTags()
	}

	renderTags() {
		ReactDOM.render(
			<FormEntry label={lang.TAGS} vertical width={'fit'}>
				<TagsSelect
					mode={'tags'}
					disabled={!this.enabled}
					accountId={this.accountContext}
					includeSubaccounts={false}
					onChange={this.onChange}
					value={this.tagsList}
					placeholder={lang.TAGS}/>
			</FormEntry>,
			this.container)
	}

	onChange = (tagsList) => {
		this.tagsList = tagsList
		this.renderTags()
	}

	enable(bool) {
		this.enabled = bool
		this.renderTags()
	}

	setValues(array) {
		this.tagsList = array.map(x => x.id)
		this.renderTags()
	}

	setRawValues(rawArray) {
		this.tagsList = rawArray
		this.renderTags()
	}

	getValues() {
		return this.tagsList.map(x => ({
			id: x,
			name: x
		}))
	}

	getRawValues() {
		return this.tagsList
	}

	readonly(bool) {
	}
}
