import {CostBudget} from "../../models/costBudget";
import {BudgetItemYearValues, CostBudgetItem, CostBudgetLink, FetchType} from "../../models/costBudgetItem";
import {ExpandedState} from "./ExpandedState";
import {IReactionDisposer, makeAutoObservable} from "mobx";
import React from "react";
import {ModelType} from "areas/cost/budget/budgetTypes";
import {i} from "../../translations";
import {BudgetEditableColumnType, columns} from "./BudgetTableColumns";
import {CostTableViewType} from "./CostTableViewType";
import {flatTree, SortSettings} from "../../../../tools/tree";
import {set} from "lodash";
import {SortDirection} from 'areas/cost/models/sortDirection';
import {BudgetTableSorter} from "./BudgetTable";
import {AccountsApi} from "areas/administration/accounts/api";
import {ApplicationState} from "framework/applicationState";
import {Api} from "tools/api";
import {MobxManager} from "framework/mobx-integration";
import {getCostModelInfo, getCostBudgetItemChildren} from "../../api";
import {apiFetch} from "../../../../framework/api";
import {CostMetricCache} from "./CostMetricCache";
import {setElementVariables} from "../../../../tools/styles";
import {ResizeCallbackData} from "react-resizable";
import {getValueOnPath} from "tools/helpers/math";
import {message} from "controls/react/ant/antAppWrapper";

export interface ShowColumns {
	cost: boolean,
	costRate: boolean,
	budget: boolean,
	estimate: boolean,
	split: boolean,
	listingPrice: boolean,
	information: boolean,
	name: boolean,
	status: boolean,
	action: boolean,
	yearEstimate: boolean,
	currentMonthEstimate: boolean
}

export const allShowedColumns = ['cost', 'costRate', 'budget', 'estimate', 'yearEstimate', 'currentMonthEstimate', 'listingPrice', 'information', 'name', 'status', 'action'] as (keyof ShowColumns)[]

export interface Size {
	width: number,
	height: number
}

interface ShowSettingsValue {
	displayDecimals: number;
	displayAsThousands: boolean;
	showCost: boolean;
	showCostRate: boolean;
	showBudget: boolean;
	showSplit?: boolean
	showEstimate?: boolean;
	showListingPrice: boolean;
	showInformation: boolean;
	monthOrder: SortDirection;
	currentMonthFirst: boolean;
	showYearEstimate?: boolean;
	showCurrentMonthEstimate?: boolean;
	showName?: boolean;
	showStatus: boolean;
	showAction: boolean;
}

export class BudgetTableStore {
	budget: CostBudget;
	displayAsThousands: boolean;
	displayDecimals: number;
	lockStructure: boolean;
	lockValues: boolean;
	lastLevel: number;

	showNewItemWindow: boolean;
	showImportExternalWindow: boolean;

	thresholdWindowRecord: CostBudgetItem;
	showThresholdWindow: boolean;

	rows: CostBudgetItem[] = [];
	total: CostBudgetItem;

	expandedState: ExpandedState;
	newEntryParent?: CostBudgetItem;
	viewType: CostTableViewType = CostTableViewType.Default;
	sortOrder?: BudgetTableSorter;

	monthOrder: SortDirection;
	currentMonthFirst: boolean;
	showCost: boolean;
	showCostRate: boolean;
	showBudget: boolean;
	showEstimate?: boolean;
	showYearEstimate?: boolean;
	showCurrentMonthEstimate?: boolean;
	showName?: boolean;
	showListingPrice: boolean;
	showSplit: boolean;
	showInformation: boolean;
	showStatus: boolean = true;
	showAction: boolean = true;
	subAccounts: string[] = [];
	highlightedRecordId: string;
	selectedRecordId: string;
	linksDescriptions: Map<string, string> = new Map<string, string>();
	compact: boolean = false;
	onColumnResize: (columnKey: string, width: number) => void;
	linkRedirectDisabled: boolean = false;

	tableSize: Size = {width: 0, height: 0};

	metricCache = new CostMetricCache(this);

	mobx = new MobxManager()

	calculationDisposers = new Map<string, IReactionDisposer>();

	//hack for name column only
	currentColWidths: Map<string, number> = new Map(
		[
			['name', 250],
			['information', 220]
		]
	)
	rootRef: React.RefObject<HTMLDivElement>;

	constructor() {
		this.lastLevel = 2; //default for now
		makeAutoObservable(this);

		this.mobx.reaction(() => this.budget, (value, prev) => {
			this.total = new Total(this.budget);
			this.expandedState = new ExpandedState(value.costBudget.items);
			if (this.viewType === CostTableViewType.Default && this.budget.costBudget?.modelType === ModelType.CLOUD) {
				this.viewType = CostTableViewType.Cloud;
			}
			this.setupCalculations();
		});
		this.mobx.reaction(() => this.flatRows, () => this.updateRows());
	}

	setupCalculations() {
		flatTree(this.budget.costBudget.items, x => x.items).forEach(this.setupItemCalculations)
	}

	setupItemCalculations = (item: CostBudgetItem) => {
		if(this.calculationDisposers.has(item.uiId)) {
			return;
		}
		const disposer = this.mobx.autorun(() => {
			if(item.items.length > 0) {
				BudgetItemYearValues.numberKeys.forEach(key => {
					item.cost[key]= item.sumFromItems(i => i.cost[key]) ?? item.cost[key];
					item.budget[key] = item.sumFromItems(i => i.budget[key]) ?? item.budget[key];
					item.listingPrice[key] = item.sumFromItems(i => i.listingPrice[key]) ?? item.listingPrice[key];
				})
				item.periodEstimate = item.sumFromItems(i => i.periodEstimate);
				item.currentEstimate = item.sumFromItems(i => i.currentEstimate);
			}
		})
		this.calculationDisposers.set(item.uiId, disposer)
	}

	clearItemCalculation(item: CostBudgetItem) {
		const disposer = this.calculationDisposers.get(item.uiId)
		disposer?.()
		this.calculationDisposers.delete(item.uiId)
	}

	get startMonthIndex() {
		return this.budget.startDate.month();
	}

	get showColumns() : ShowColumns {
		return {
			cost: this.showCost,
			costRate: this.showCostRate,
			budget: this.showBudget,
			estimate: this.showEstimate,
			listingPrice: this.showListingPrice,
			split: this.showSplit,
			information: this.showInformation,
			currentMonthEstimate: this.showCurrentMonthEstimate,
			yearEstimate: this.showYearEstimate,
			name: this.showName ?? true,
			status: this.showStatus,
			action: this.showAction
		}
	}

	get anyColumnShowed() {
		return allShowedColumns.some((x) => this.showColumns[x]);
	}

	selectRecord = (record: CostBudgetItem) => {
		this.selectedRecordId = record.id;
	}

	hideNewEntryWindow = () => {
		this.showNewItemWindow = false;
	}

	hideImportExternalWindow = () => {
		this.showImportExternalWindow = false;
	}

	openNewItemWindow = (parent: CostBudgetItem) => {
		this.newEntryParent = parent;
		this.showNewItemWindow = true;
	}

	openImportExternalWindow = (parent: CostBudgetItem) => {
		this.newEntryParent = parent;
		this.showImportExternalWindow = true;
	}

	setBudget = (budget: CostBudget) => {
		this.budget = budget;
	}

	setCompact = (compact: boolean) => {
		this.compact = compact;
	}
	setLinkCellRedirectDisabled = (disable: boolean) => {
		this.linkRedirectDisabled = disable;
	}
	setViewType = (viewType?: CostTableViewType): void => {
		if (!viewType) {
			return;
		}
		this.viewType = viewType;
	}

	setShowSettings(value: ShowSettingsValue) {
		for (const entry of Object.entries(value)) {
			set(this, ...entry);
		}
	}

	setLocking({lockStructure, lockValues}: { lockStructure: boolean, lockValues: boolean }) {
		this.lockStructure = lockStructure;
		this.lockValues = lockValues;
	}

	setOnColumnResize(onColumnResize: (columnKey: string, width: number) => void) {
		this.onColumnResize = onColumnResize;
	}

	deleteRow = (record: CostBudgetItem) => {
		this.clearItemCalculation(record);
		record.remove();
		this.expandedState.cleanupExpandedRowKeys();
	}

	addNewItem = (name: string) => {
		const newItem = CostBudgetItem.newEmptyResource(name);
		this.appendNewItemToSavedParent(newItem);
		this.expandedState.expandToRecord(newItem.id);
		this.hideNewEntryWindow();
	}

	importExternalProfile = (link: CostBudgetItem) => {
		this.appendNewItemToSavedParent(link);
		this.expandedState.expandToRecord(link.id);
		this.hideImportExternalWindow();
	}

	appendNewItemToSavedParent(newItem: CostBudgetItem) {
		this.newEntryParent.addItem(newItem);
	}

	loadItemChildren = async (item: CostBudgetItem) => {
		const profileId = this.budget.parent.costProfileId
		const modelId = this.budget.parent.id
		const budgetId = this.budget.budgetId

		const result = await apiFetch(getCostBudgetItemChildren(profileId, modelId, budgetId, item.id, FetchType.Eager))
		if(result.success) {
			this.clearItemCalculation(item)
			const newItems = result.data
			item.replaceItems(newItems)
			newItems.forEach(child => this.setupItemCalculations(child))
			this.setupItemCalculations(item)
			item.fetchType = FetchType.Eager
		}	else {
			message.error('Can\'t load item')
		}
	}

	updateSortOptions = (sorter: BudgetTableSorter): void => {
		if (this.sortOrder?.columnKey != sorter?.columnKey || this.sortOrder?.order != sorter?.order) {
			this.sortOrder = sorter;
		}
	}

	loadLinkDescription = async (record: CostBudgetItem) => {
		this.linksDescriptions.set(record.costTargetId, await this.linkDescription(record));
	}

	linkDescription = async (record: CostBudgetItem) => {
		if (!record.linkTargetReadable) {
			return i("Linked Cost profile item is not reachable. It might be that it belong on a a higher account level.");
		}

		const result = await apiFetch(getCostModelInfo(record.costTargetId));
		if (!record.targetProfileId || !result.success) {
			return i("No Cost Profile is created for this link. It might be from an Cost collector without a Cost Profile.");
		}
		const { data } = result;
		return [data.accountName, data.profileName, ...data.costItemName].filter(x => x).join('/');
	}

	get rowHeight() {
		return this.compact ? 28 : 36;
	}

	private get flatRows() {
		if(!this.budget) {
			return [];
		}
		const items = this.budget.costBudget.items;
		let sortOption: SortSettings<CostBudgetItem> = undefined;
		if (this.sortOrder) {
			const column = this.columns.find(x => x.key == this.sortOrder?.columnKey)
			const dataIndex = column?.dataIndex;
			sortOption = {
				order: this.sortOrder.order != 'descend' ? ['asc'] : ['desc'],
				sortFieldActions: [(x: CostBudgetItem) => (getValueOnPath(x, [].concat(dataIndex).join('.')) ?? 0)]
			};
		}
		return flatTree(items, r => r.items, r => this.expandedState.expandedRowKeys.includes(r.uiId), sortOption);
	}

	private updateRows() {
		this.rows = [this.total, ...this.flatRows];
	}

	setTableSize(size: Size) {
		this.tableSize = size;
	}

	get showedRows() {
		return this.anyColumnShowed ? this.rows : [];
	}

	handleColResize(width: number, colName: string) {
		this.currentColWidths.set(colName, width);
		if (this.rootRef.current && colName == 'name') {
			setElementVariables(this.rootRef.current, 'budget-table', {nameColumnWidth: `${width}px`});
		}
		this.onColumnResize?.(colName, width);
	}

	addResizeToColumn(columns: BudgetEditableColumnType[]) {
		Array.from(this.currentColWidths.keys()).forEach(colName => {
			const column = columns.find(x => x.key == colName);
			if (!column) {
				return;
			}
			column.width = this.currentColWidths.get(colName);
			// @ts-ignore
			column.onHeaderCell = (col: any) => ({
				width: column.width,
				onResize: (e: React.SyntheticEvent<Element>, data: ResizeCallbackData) => this.handleColResize(data.size.width, colName),
			});
		});
	}

	get columns() {
		const defaultMinWidth = this.compact ? 78 : 100; // HACK
		const initial = columns(this);
		this.addResizeToColumn(initial);

		const widthlessColumnCount = initial.filter(({ width }) => !width).length; // only fixed width, not minWidth
		const minFixedWidth = initial
			.filter(({ width, minWidth }) => width || minWidth)
			.map(({ width, minWidth }) => width as number || minWidth as number || defaultMinWidth)
			.reduce((acc, x) => acc + x, 0);

		const fullExtraWidth = this.tableSize.width - minFixedWidth;
		let extraWidthLeft = fullExtraWidth % widthlessColumnCount;
		const extraColumnWidth = Math.floor(fullExtraWidth / widthlessColumnCount);

		return initial.map((column) => {
			if(this.compact) {
				column.ellipsis = {showTitle: true};
			}
			if (column.width) {
				return column;
			}
			if(fullExtraWidth < 0) {
				return {...column, width: column.minWidth || defaultMinWidth} as BudgetEditableColumnType;
			}

			let width = (column.minWidth || defaultMinWidth) + extraColumnWidth;

			if(extraWidthLeft > 0) {
				width ++
				extraWidthLeft --
			}
			return {...column, width} as BudgetEditableColumnType;
		});
	}

	openThresholdWindow = (record: CostBudgetItem) => {
		this.thresholdWindowRecord = record;
		this.showThresholdWindow = true;
	}

	closeThresholdWindow = () => {
		this.showThresholdWindow = false;
	}

	loadSubAccounts = async () => {
		if (this.subAccounts?.length) {
			return;
		}
		const url = AccountsApi.getLiteUrl(ApplicationState.accountId);
		const response = await Api.fetch(url);
		this.subAccounts = response.map((x: {id: string, name: string}) => x.id);
	}

	highlightRecord = (id: string) => {
		this.highlightedRecordId = id;
		setTimeout(() => {
			this.highlightedRecordId = null;
		}, 5000);
	}

	destroy(){
		this.mobx.destroy()
		this.expandedState.destroy()
	}
}

export class Total extends CostBudgetItem {
	costBudget: CostBudget;

	constructor(costBudget: CostBudget) {
		super()
		this.costBudget = costBudget;
		this.cost = new BudgetItemYearValues({editable: false});
		this.budget = new BudgetItemYearValues({editable: false});
		this.listingPrice = new BudgetItemYearValues({editable: false});
		this.costRate = this.costBudget.costBudget.costRate;
		this.currentEstimateRate = this.costBudget.costBudget.currentEstimateRate;
		this.periodEstimateRate = this.costBudget.costBudget.periodEstimateRate;
		this.id = 'total';
		this.name = i('Total');
		this.items = this.costBudget.costBudget.items;
	}

	addItem(item: CostBudgetItem) {
		this.costBudget.costBudget.addItem(item);
	}
}
