import React from "react";
import {Observer} from "mobx-react";
import {
	DeleteOutlined,
	ExclamationCircleOutlined,
	ExportOutlined,
	FormOutlined, PlusCircleOutlined, ReloadOutlined,
	StopOutlined, TagOutlined,
	ToolOutlined
} from "@ant-design/icons";
import {makeObservable, observable} from "mobx";


import {GridStore} from "controls/grid/gridStore";
import {ServicesListEntry} from "areas/services/services-list/servicesListEntry";
import {AutoReloadPlugin} from "controls/grid/plugins/autoReloadPlugin";
import {GridColumnConfig} from "controls/grid/gridColumnConfig";
import {Link} from "controls/react/link";
import State from "tools/state";
import {ServicesRouter} from 'areas/services/bundleDescription';
import {getHealthStateService} from 'controls/stateRenderer/healthState'
import {getSlaState} from 'controls/stateRenderer/slaState';
import Renderer from 'tools/renderer';
import {ServiceAdminEvent, ServiceSummaryEvent, SlaEvent} from "framework/entities/events";
import {Severity} from "framework/entities/healthData";
import {SeverityIndicator, SeverityPillbox} from "controls/react/severityIndicator";
import {MultiSelectEntry} from "controls/queryBuilder/ruleDefinition";
import {apiFetch} from "framework/api";
import {
	deleteServices,
	endMaintenance,
	exportCSV,
	getServices,
	GetServicesArgs,
	NamedItem,
	setInformation,
	setServicesInMaintenance,
	setTags
} from "./api";
import {createContainer, createContainerRoot, ModalPosition, openModal} from "controls/react/ant/antModal";
import {AntConfirmModal} from "controls/react/ant/antConfirmModal";
import {IncidentsRouter} from "areas/incidents/bundleDescription";
import Settings from 'settings';
import {SetInformationWindow} from 'controls/setInformationWindow';
import {Section} from "controls/react/layout/section";
import FormEntry from "controls/react/form/formEntry";
import {TagsSelect} from "controls/react/tagsSelect";
import {linkModel} from "framework/mobx-integration";
import {ApplicationState} from "framework/applicationState"
import {serviceSummaryToHealthFlags} from "framework/entities/service";
import {operatingStateToLabel} from "framework/entities/operatingState";
import { AssetsRouter } from "areas/assets/bundleDescription";
import { NavigationStore } from "framework/navigationStore";
import {SlaRouter} from "../../sla/bundleDescription";
import {
	CalendarEventBase,
	CeeViewHistoricMaintenanceEventStorageKey,
	openCalendarEventForm
} from "../../administration/calendarEventFormNew";
import moment from "moment";
import LocalEventsManager from "../../../core/localEventsManager";
import {CeeviewNavigator} from "tools/ceeviewNavigator";
import {getTzOffsetDiffs} from "tools/dateTimeUtils";
import {GridMenuPlugin} from "controls/grid/plugins/grid-menu/gridMenuPlugin";
import {GridSelection} from "controls/grid/gridSelection";
import {Permission} from "framework/permission";

const i = require('core/localization/localization').translator({
  "Service Name": {
    "no": "Tjenestenavn",
    "en": "Service name"
  },
  "Service Health": {
    "no": "Tjenestehelse",
    "en": "Servicehealth"
  },
  "Current State": {
    "no": "Nåværende tilstand",
    "en": "Current state"
  },
  "Coverage": {
    "no": "Dekning"
  },
  "Mode": {
    "no": "Modus"
  },
  "SERVICES_REMOVE_CONFIRMATION": {
    "en": "Are you sure you want to delete services?",
    "no": "Vil du slette disse tjenestene?"
  },
  "SERVICE_REMOVE_CONFIRMATION": {
    "en": "Are you sure you want to delete service?",
    "no": "Vil du slette tjenesten?"
  },
  "Create Incident": {
    "no": "Lag sak",
    "en": "Create incident"
  },
  "Set Information": {
    "no": "Sett informasjon",
    "en": "Set information"
  },
  "Export to CSV": {
    "no": "Eksporter til CSV"
  }
});

export const b = require('b_').with('services-list-page')

export type ServicesListStoreProps = GetServicesArgs & {

}

export class ServicesListStore {
	gridStore: GridStore<ServicesListEntry>
	displayRecalculateWindow: boolean = false
	displayDeleteConfirmation: boolean = false
	menuRef: React.MutableRefObject<HTMLElement>;

	constructor() {
		makeObservable(this, {
			gridStore: observable,
			selectedTags: observable,
			displayDeleteConfirmation: observable,
			displayRecalculateWindow: observable
		})
	}

	get title() {
		return i('Service')
	}

	getAutoReloadPlugin() {
		return new AutoReloadPlugin<ServicesListEntry>({
			subscriptions: [
				ServiceAdminEvent.subscription(
					['SERVICE_CREATE', 'SERVICE_UPDATE', 'SERVICE_DELETE'],
						ApplicationState.accountId,
						ApplicationState.includeSubaccounts
					),
				ServiceSummaryEvent.subscription(),
				SlaEvent?.subscription()
			]
		})
	}

	getGridMenuPlugin() {
		return new GridMenuPlugin<ServicesListEntry>({
			actions: this.menuItems,
			mainMenu: 'create delete | endMaintenance recalculate setMaintenance | createIncident export setInformation tags',
			showActionColumn: false
		})
	}

	getDefaultDatasource(props: ServicesListStoreProps){
		return getServices(props)
	}

	columns: GridColumnConfig<ServicesListEntry>[]

	getColumns(navigator?: CeeviewNavigator) {
		if (!this.columns) {
			this.columns = [
				{
					field: 'serviceState',
					title: i('State'),
					renderer: item => <SeverityIndicator {...serviceSummaryToHealthFlags(item)}/>,
					//renderer: item => getServiceState(item.serviceState, '', item.operatingState, item.agentStatus, item.qualifierError, item.assetMaintenance, true),
					filterDropDownRenderer: serviceStateRenderer,
					width: 45
				}, {
					field: 'name',
					title: i('Service Name'),
					renderer: item => <Link navigator={navigator} url={ServicesRouter.details(item.id)}>{item.name}</Link>,
					width: 240
				}, {
					field: 'accountName',
					title: i('Account'),
					renderer: item => item.accountName,
					width: 150
				}, {
					field: 'serviceHealth',
					title: i('Service Health'),
					renderer: item => <Link navigator={navigator}
						onClick={() => this.onServiceHealthClick(item)}>{getHealthStateService(item.srvHealthIndex, '', true)}</Link>,
					filterDropDownRenderer: serviceHealthRenderer,
					width: 140
				}, {
					field: 'slaHealth',
					title: i('SLA'),
					renderer: item => <Link navigator={navigator}
						onClick={() => this.onSlaStateClick(item.id)}>{getSlaState(item.srvSlaIndex, true, true)}</Link>,
					filterDropDownRenderer: serviceSlaRenderer,
					width: 80
				}, {
					field: 'incidentCount',
					title: i('Incident'),
					renderer: item => <Link enabled={item.incidentCount != 0}
					                        navigator={navigator}
					                        onClick={() => this.onIncidentCountClick(item.id, item.accountId)}>{item.incidentCount}</Link>,
					width: 120
				}, {
					field: 'coverage',
					title: i('Coverage'),
					renderer: item => item.coverage,
					width: 140
				}, {
					field: 'operatingState',
					title: i('Mode'),
					renderer: item => i(operatingStateToLabel[item.operatingState]),
					width: 150
				}, {
					field: 'responsibleTeamName',
					title: i('Team'),
					renderer: item => {
						if (item.responsibleTeamName) {
							return <span>{item.responsibleTeamName}</span>
						} else {
							return <span>-</span>
						}
					},
					width: 160
				}, {
					field: 'stateDuration',
					title: i('Current State'),
					renderer: item => Renderer.duration(item.stateDuration, null, true),
					width: 150
				}, {
					field: 'tags',
					title: i('Tags'),
					renderer: item => item.tags.join(','),
					width: 150
				}, {
					field: 'shared',
					title: i('Shared'),
					renderer: item => {
						if (item.shared) {
							return i('Shared')
						} else {
							return ''
						}
					},
					width: 100
				}, {
					field: 'information',
					title: i('Information'),
					renderer: (item) => {
						return item.information || ''
					},
					width: 200
				}, {
					field: 'description',
					title: i('Description'),
					renderer: (item) => {
						return item.description || ''
					}
				}, {
					field: 'created',
					title: i('Created'),
					renderer: (item) => {
						return moment(item.created).format(ApplicationState.dateTimeFormat)
					}
				}, {
					field: 'modified',
					title: i('Modified'),
					renderer: (item) => {
						return moment(item.modified).format(ApplicationState.dateTimeFormat)
					}
				}
			]
		}

		return this.columns
	}

	get menuItems() {
		return [{
			id: 'create',
			icon: <PlusCircleOutlined />,
			label: i('Create'),
			action: () => {
				State.mainApp.onNewService();
				return false;
			},
			visible: () => ApplicationState.hasPermissions(Permission.ServiceCreate)
		}, {
			id: 'delete',
			icon: <DeleteOutlined />,
			label: i('Delete'),
			action: this.showDeleteConfirmation,
			enabled: (selection: GridSelection<ServicesListEntry>) => selection.isAnythingSelected,
			visible: () => ApplicationState.hasPermissions(Permission.ServiceDelete)
		}, {
			id: 'setMaintenance',
			icon: <ToolOutlined />,
			label: i('Set in Maintenance'),
			action: this.showMaintenanceForm,
			enabled: (selection: GridSelection<ServicesListEntry>) => selection.isAnythingSelected,
			visible: () => ApplicationState.hasPermissions(Permission.ServiceMaintenanceCreate)
		}, {
			id: 'endMaintenance',
			icon: <StopOutlined />,
			label: i('End Maintenance'),
			action: this.onEndMaintenance,
			enabled: (selection: GridSelection<ServicesListEntry>) => selection.isAnythingSelected,
			visible: () => ApplicationState.hasPermissions(Permission.ServiceMaintenanceUpdate)
		}, {
			id: 'createIncident',
			icon: <ExclamationCircleOutlined />,
			label: i('Create Incident'),
			action: this.onCreateIncident,
			visible: () => ApplicationState.hasPermissions(Permission.IncidentCreate),
			enabled: (selection: GridSelection<ServicesListEntry>) => selection.isOnlyOneSelected,
		}, {
			id: 'export',
			icon: <ExportOutlined />,
			label: i('Export to CSV'),
			action: this.onExportCSV,
			visible: () => true,
			enabled: () => true,
		}, {
			id: 'setInformation',
			icon: <FormOutlined />,
			label: i('Set Information'),
			action: this.onSetInformation,
			enabled: (selection: GridSelection<ServicesListEntry>) => selection.isAnythingSelected,
		}, {
			id: 'tags',
			icon: <TagOutlined />,
			label: i('Set Tags'),
			action: this.showSetTagsForm,
			visible: () => ApplicationState.hasPermissions(Permission.ServiceUpdate),
			enabled: (selection: GridSelection<ServicesListEntry>) => selection.isAnythingSelected,
		}, {
			id: 'recalculate',
			icon: <ReloadOutlined />,
			label: i('Recalculate'),
			action: this.onServiceRecalculate,
			visible: () => ApplicationState.hasPermissions(Permission.ServiceModelUpdate),
			enabled: (selection: GridSelection<ServicesListEntry>) => selection.isAnythingSelected
		}];
	}

	showDeleteConfirmation = () => {
		return new Promise<boolean>((resolve) => {
			const root = createContainerRoot();

			root.render(<AntConfirmModal onCancel={() => {
											root.unmount()
											resolve(false);
										}}
										 onOk={async () => {
											 const result = await this.deleteSelectedServices();
											 root.unmount();
											 resolve(result);
										 }}
										 title={i('Info')}
										 okText={i('Delete')}
										 icon={'ERROR'}
										 positionType={ModalPosition.Anchor}
										 positionAnchor={this.menuRef}
										 message={i(this.gridStore.selection.ids.length > 1 ? 'SERVICE_REMOVE_CONFIRMATION' : 'SERVICES_REMOVE_CONFIRMATION')}
			/>)
		})
	}

	onServiceRecalculate = () => {
		this.displayRecalculateWindow = true;
		return false;
	}

	onRecalculationCancel = () => {
		this.displayRecalculateWindow = false;
	}

	deleteSelectedServices =  async () => {
		const result = await apiFetch<NamedItem[]>(deleteServices(this.gridStore));

		if (result.success) {
			this.gridStore.dataProvider.silentReload();
			this.gridStore.selection.clear();
		}
		return result.success;
	}

	showMaintenanceForm = () => {
		return new Promise<boolean>((resolve) => {
			openCalendarEventForm({
				event: new CalendarEventBase({
					start: moment().valueOf(),
					end: moment().add(1, 'hours').valueOf(),
					timezone: ApplicationState.timezone
				}),
				onSave: async (ev: CalendarEventBase) => resolve(await this.onSetInMaintenance(ev)),
				onCancel: () => resolve(false),
				mode: 'create',
				positionType: ModalPosition.Anchor,
				positionAnchor: this.menuRef
			});
		})
	}

	onSetInMaintenance = async (event: CalendarEventBase) => {
		const maintenanceData = event.getMaintenanceData();
		const result = await apiFetch<NamedItem[]>(setServicesInMaintenance(this.gridStore, maintenanceData));

		if (result.success) {
			this.planRecalculateOffer(event);
			LocalEventsManager.trigger('maintenance_period_saved', {data: maintenanceData});
			LocalEventsManager.trigger('summaryMaintenancePeriods');
			this.gridStore.dataProvider.silentReload();
			this.gridStore.selection.clear();
		}
		return result.success;
	}

	planRecalculateOffer = (ev: CalendarEventBase) => {
		if (moment(ev.start + getTzOffsetDiffs(ev.timezone, moment.tz.guess())*60000).isSameOrAfter(moment())) {
			return;
		}

		const data = {
			serviceIds: this.gridStore.selection.ids,
			mode: this.gridStore.selection.mode,
			startFrom: ev.start,
			added: new Date()
		}
		sessionStorage.setItem(CeeViewHistoricMaintenanceEventStorageKey, JSON.stringify(data));
	}

	onEndMaintenance = async () => {
		const result = await apiFetch<NamedItem[]>(endMaintenance(this.gridStore));

		if (result.success) {
			this.gridStore.dataProvider.silentReload();
			this.gridStore.selection.clear();
		}
		return result.success;
	}

	onCreateIncident = () => {
		const selectedItem = JSON.parse(JSON.stringify(this.gridStore.selection.dataset.items[0]));
		State.mainApp.navigate(IncidentsRouter.createNew('SERVICE'), {
			extra: {
				serviceId: selectedItem.id,
				serviceName: selectedItem.name,
				accountList: {
					id: selectedItem.accountId,
					name: selectedItem.accountName
				},
			}
		});
		return false;
	}

	onExportCSV = async () => {
		const result = await apiFetch<NamedItem[]>(exportCSV(this.gridStore));
		if (result.success) {
			window.open(Settings.serverPath + 'sessions/exportCSV/' + result.data, '_blank');
			window.focus();
			this.gridStore.dataProvider.silentReload();
			this.gridStore.selection.clear();
		}
		return result.success
	}

	onSetInformation = () => {
		return new Promise<boolean>((resolve) => {
			const root = createContainerRoot();
			root.render(<SetInformationWindow
				onClose={() => {
					root.unmount()
					resolve(false);
				}}
				onUpdate={async (data: string) => {
					root.unmount()
					resolve(await this.onSetInformationUpdate(data))
				}}
				topLeftCorner={true}
			></SetInformationWindow>);
		})
	}

	onSetInformationUpdate = async (informationText: string) => {
		const result = await apiFetch<NamedItem[]>(setInformation(this.gridStore, informationText));

		if (result.success) {
			this.gridStore.dataProvider.silentReload();
			this.gridStore.selection.clear();
		}
		return result.success;
	}

	selectedTags: string[]

	showSetTagsForm = async () => {
		this.selectedTags = []

		return openModal({
				onOk: this.setTags,
				okText: i('Set Tags'),
				title: i('Set Tags'),
				positionType: ModalPosition.Anchor,
				positionAnchor: this.menuRef
			},
			<Observer>{ () =>
				<Section>
					<FormEntry label={i('Tags')}>
						<TagsSelect {...linkModel(this, 'selectedTags')}/>
					</FormEntry>
				</Section>
			}</Observer>
		)
	}

	setTags = async () => {
		const result = await apiFetch(setTags(this.gridStore, this.selectedTags))

		if (result.success) {
			this.gridStore.dataProvider.silentReload();
			this.gridStore.selection.clear();
		}
		return result.success;
	}

	onServiceHealthClick(item: ServicesListEntry) {
		const url = AssetsRouter.assetHealthSummary({
			serviceId: item.id,
			source: 'service_assets',
			accountList: [item.accountId],
			includeSubaccounts: State.includeSubaccounts,
			isView: true,
			preferences: {
				sort: [{
					field: 'statusIndex',
					dir: 'desc'
				}],
				filter: []
			}
		})
		NavigationStore.go(url)
	}

	onSlaStateClick(serviceId: string) {
		const options = {
			isView: true,
			serviceId: serviceId,
			preferences: {
				sort: [{
					field: 'statusIndex',
					dir: 'desc'
				}],
				filter: [] as any[]
			}
		}
		NavigationStore.go(SlaRouter.list(options))
	}

	onIncidentCountClick(serviceId: string, accountId: string) {
		State.mainApp.navigate(IncidentsRouter.list(), {
			extra: {
				filter: 'SERVICE',
				serviceAccountId: accountId,
				serviceId: serviceId
			}
		});
	}

	destroy() {
		this.gridStore?.destroy();
	}
}

export function serviceStateRenderer(item: MultiSelectEntry) {
	let severity: Severity
	switch (item.value){
		case 'DOWN':
			severity = Severity.Critical
			break
		case 'WARNING':
			severity = Severity.Major
			break
		case 'UP':
			severity = Severity.None
			break
		default:
			severity = Severity.Invalid
	}

	return <SeverityPillbox severity={severity}
	                        wrench={item.value == 'AGENT_MAINTENANCE' || item.value == 'ASSET_MAINTENANCE'}
	                        exclamationMark={item.value == 'AGENT_DOWN' || item.value == 'QUALIFIER_ERROR'}
	                        label={item.title}
	/>
}

export function serviceHealthRenderer(item: MultiSelectEntry) {
	let severity = item.value == 'UNAVAILABLE' ? Severity.Invalid : item.value as Severity;

	return <SeverityPillbox severity={severity}
	                        label={item.title}
	/>
}

export function serviceSlaRenderer(item: MultiSelectEntry) {
	let arrowClass;
	switch (item.value) {
		case 'FAILED':
			arrowClass = 'circle-arrow-down';
			break;
		case 'WARNING':
			arrowClass = 'circle-arrow-right';
			break;
		case 'OK':
			arrowClass = 'circle-arrow-top';
			break;
	}

	const containerClass = require('b_').with('severity-pillbox');

	return <span className={containerClass()}>
		<span className={`status_icon glyphicons ${arrowClass}`}></span>
		<span className={containerClass('content')}>{item.title}</span>
	</span>

	// return <SeverityPillbox severity={Severity.Major}
	//                         label={item.title}
	//                         customIcon={<ArrowUpOutlined  />}/>
}
