import React, {useCallback} from "react"
import {FileSearchOutlined} from "@ant-design/icons";
import {State, UrlBuilder} from "../tools";
import DocumentationAPI from "../controls/react/form/documentationApi";
import Api from "../tools/api";
import {translator} from "../core";
import contextualHelpMap, {openHelpWindow} from "./helpMapping";

const b = require('b_').with('page-help-button');
const i = translator({
	'Click to view online documentation.': {
		no: 'Klikk for å se online dokumentasjon.'
	}
})

export const PageHelpButton = () => {
	const onClick = useCallback(async () => {
		// to receive origin and version
		let result = await Api.fetch(DocumentationAPI.getActionTriggerDocumentationUrl());
		if (result.success) {
			const url = new URL(result.data);
			const urlBuilder = new UrlBuilder(url.origin)
				.addPath(url.pathname.split('/').slice(1, 3).join('/'));
			const key = State.mainApp._runtime.routeId ?? State.mainApp._runtime.moduleDescription?.routeId ?? '';
			const path = contextualHelpMap.get(key) ?? ''
			if (!!path) {
				urlBuilder.addPath(path);
			}

			openHelpWindow(urlBuilder.build())
		}
	}, [])
	return <>
		<FileSearchOutlined className={b()} onClick={onClick} title={i('Click to view online documentation.')}/>
	</>
}

