import {
	IObservableArray,
	makeAutoObservable,
	observable,
	toJS,
	isObservable
} from "mobx";
import {orderBy} from "lodash";


import {GridDataEntry} from "controls/grid/gridDataEntry"
import {DataProvider} from "controls/grid/gridConfiguration"
import {RulesConfiguration, RulesHandler} from "controls/queryBuilder/ruleDefinition"
import {GridStore} from "controls/grid/gridStore"
import {MobxManager} from "framework/mobx-integration"
import {GridDataItem} from "controls/grid/gridDataItem"
import ObservableArray = kendo.data.ObservableArray;

export class ArrayDataProvider<DataEntry extends GridDataEntry & {id: string}> implements DataProvider<DataEntry> {
	allData: IObservableArray<DataEntry>

	filtersConfiguration: RulesConfiguration
	onlySelected: boolean = false;

	initialized: boolean
	store: GridStore<DataEntry>

	mobxManager = new MobxManager()
	constructor(data: Array<DataEntry>, filterConfiguration?: RulesConfiguration) {
		this.updateData(data)
		this.initialized = true
		this.filtersConfiguration = filterConfiguration ?? {}

		makeAutoObservable(this)
	}

	convertToDataItems(data: Array<DataEntry>) {
		return data.map((x, i) => ({
			id: x.id,
			type: 'data' as const,
			groupLevel: 1,
			data: x,
			index: i
		}))
	}

	updateData(data: Array<DataEntry>) {
		this.updateDataRaw(data)
	}

	updateDataRaw(data: Array<DataEntry>) {
		if (isObservable(data)) {
			this.allData = data as IObservableArray<DataEntry>
		} else {
			this.allData = observable.array(data)
		}
	}

	get scrollableRowsCount() {
		return this.data.length
	}

	get totalRowsCount() {
		return this.allData.length
	}

	get visibleRowsCount() {
		return this.data.length
	}

	silentReload(): void {
	}

	get data(){
		return observable.array(this.convertToDataItems(this.getRefreshedData()))
	}

	async attach(store: GridStore<DataEntry>) {
		this.store = store
	}

	get(index: number): GridDataItem<DataEntry> {
		if (this.data.length <= index)
			return null

		return this.data[index];
	}

	informVisibleRangeChanged(): void {
	}

	getRefreshedData = () => {
		if (!this.store.actualFilter.valid) {
			return orderBy(this.allData, this.store.actualSorting.map(x => x.field), this.store.actualSorting.map(x => x.dir));
		}

		const handler = new RulesHandler(this.store.filtersConfigurationEffective)
		let result = handler.filter(this.allData, this.store.actualFilter)

		if (this.onlySelected) {
			result = result.filter(x => this.store.selection.isSelected(x))
		}

		if (!this.store.actualSorting) {
			return result;
		}

		return orderBy(result, this.store.actualSorting.map(x => x.field), this.store.actualSorting.map(x => x.dir));
	}

	destroy(): void {
		this.mobxManager.destroy();
	}
}
