import React from "react";
import {Window} from "controls/react/kendoWrappers";
import {Section} from "controls/react/layout/section";
import {Toolbar} from "controls/react/layout/toolbar";
import {ActionButtons} from "controls/react/form";
import {AntSelect, AntSelectRefProps} from "controls/react/ant/antSelect";
import {FormEntry} from "controls/react/form/formEntry";
import {i} from '../translations'
import {AntInput} from "controls/react/ant/antInput";
import {BoxView} from "controls/react/layout/boxView";
import {observer} from "mobx-react";
import {AntTreeSelect} from "controls/react/ant/antTreeSelect";
import {CostBudgetItem, CostBudgetLink} from "../models/costBudgetItem";
import {LinkCostProfileRowWindowStore} from "./linkCostProfileRowWindowStore";
import {CostBudget} from "../models/costBudget";
import {AntCheckbox} from "controls/react/ant/antCheckbox";
import {AntPopover} from "controls/react/ant/antPopover";
import {LoadingOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {AntInputNumber, NumberKeysPattern} from "../../../controls/react/ant/antInputNumber";
import {TreeSelect} from "antd";
import b_ from 'b_';
import "./linkCostProfileRowWindow.less";

const b = b_.with('link-cost-profile-row-window');

export interface LinkCostProfileRowWindowProps {
	onSave: (target: CostBudgetItem, linkData: CostBudgetLink) => void;
	onCancel: () => void;
	budget: CostBudget;
	initLink?: CostBudgetItem;
	parent: CostBudgetItem;
}

class LinkCostProfileRowWindowUnobserved extends React.Component<LinkCostProfileRowWindowProps> {
	private readonly store: LinkCostProfileRowWindowStore;
	profileRef =  React.createRef<AntSelectRefProps>()

	constructor(props: LinkCostProfileRowWindowProps) {
		super(props);
		this.store = new LinkCostProfileRowWindowStore(props);
	}

	render() {
		const {accounts, costProfiles, costModels, treeData, error, errorSeverity, showHierarchyCheckbox, loading} = this.store;
		return (
			<Window title={i('Link')} modal onClose={this.props.onCancel} width={600} titleIcon={this.titleIcon} autoFocus={false} onInit={() => this.profileRef.current?.focus()}>
				{loading && <div className={b('loader')}><LoadingOutlined /></div>}
				{error && <BoxView type={errorSeverity}>{error}</BoxView>}
				<Section childrenPadding={true}>
					<FormEntry label={i('Account')} model={this.store} modelField={'accountId'}>
						<AntSelect dataList={accounts}/>
					</FormEntry>
					<FormEntry label={i('Cost Store/Model')} model={this.store}
					           modelField={'costProfileId'}>
						<AntSelect dataList={costProfiles} componentRef={this.profileRef}/>
					</FormEntry>
					<FormEntry label={i('Cost Sheet')} model={this.store} modelField={'costModelId'}>
						<AntSelect dataList={costModels}/>
					</FormEntry>
					{
						showHierarchyCheckbox &&
						<FormEntry model={this.store} modelField={'hasHierarchy'}>
							<AntCheckbox className={b('has-hierarchy')}>
								{i('Hierarchy cost link')}
								<AntPopover
									overlayPadding
									content={
										<div style={{width: '300px'}}>
											{i('The Cost Item link will include its sub tree of Cost Items if any. Note that this can only be used if created on the first level of a Cost Profile.')}
										</div>
									}
								>
									<QuestionCircleOutlined style={{marginLeft: '10px'}} />
								</AntPopover>
							</AntCheckbox>
						</FormEntry>
					}
					<FormEntry model={this.store} modelField={'value'} onChange={this.store.setValue}>
						<AntTreeSelect
							showSearch
							error={errorSeverity || ''}
							dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
							placeholder={i("Please select")}
							allowClear = {false}
							maxTagCount={1}
							treeData={[...(treeData || [])]}
							treeCheckable={this.store.treeCheckable}
							showCheckedStrategy={TreeSelect.SHOW_PARENT}
							treeCheckStrictly={this.store.treeCheckable}
							loadData={this.store.loadChildren}
							treeExpandedKeys={this.store.expandedKeys}
							onTreeExpand={this.store.setExpandedKeys}
							virtual={true}
							onSearch={this.store.searchTree}
							searchValue={this.store.searchValue}
							treeNodeFilterProp={'label'}
							filterTreeNode={LinkCostProfileRowWindowStore.filterTreeNode}
						/>
					</FormEntry>
					<FormEntry label={i('Percentage')} model={this.store} modelField={'percentage'}>
						<AntInputNumber
							pattern={NumberKeysPattern.PositiveDecimal}
							formatter={value => `${value}%`}
							parser={value => value!.replace('%', '')}
						/>
					</FormEntry>
					<FormEntry label={i('Link name')} model={this.store} modelField={'linkName'}>
						<AntInput/>
					</FormEntry>
				</Section>
				<Toolbar>
					<ActionButtons
						mode={this.store.mode}
						onSave={this.store.save}
						onCancel={this.props.onCancel}
						saveDisabled={!this.store.valid}
					/>
				</Toolbar>
			</Window>
		)
	}

	get titleIcon() {
		return {
			class: 'question-sign',
			tooltip: i('Creates a link to a row in another Cost Profile. Note that only the cost and name is linked. Budget is set per Cost Profile.')
		}
	}

	componentWillUnmount() {
		this.store.destroy()
	}
}

export const LinkCostProfileRowWindow = observer(LinkCostProfileRowWindowUnobserved);
