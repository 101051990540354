import Utils from 'tools/utils';
import Widget from 'areas/service-boards/widget';
import Settings from 'settings';

import RemoteEventsManager from 'core/remoteEventsManager';
import GridSearch from 'controls/gridSearch';
import ErrorHandler from 'core/errorHandler';
import State from 'tools/state';

import {SlaRouter} from 'areas/sla/bundleDescription';
import {getSlaState} from 'controls/stateRenderer/slaState';
import MultiSelectGridFilter from 'controls/multiSelectGridFilter';
import {getGridStateForSaving, updateHiddenColumns} from "controls/react/kendoWrappers/grid";
import {ServicesRouter} from "../../services/bundleDescription";

export function GridWidget(config) {
	Widget.call(this, config);

	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}
	/*
	 * @property hasConfiguration - set to true to import widget specific configuration into serviceboard
	 */
	this.hasConfiguration = true;
	this.configuration.includeSubaccounts = this.configuration.includeSubaccounts || false;
};

export {GridWidget as default};

jQuery.extend(GridWidget.prototype, Widget.prototype, {
	/**
	 * Main init function
	 */
	init: function () {
		this.getTags();
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		widgetContentDiv.parent().addClass('cw_section_slagrid');
		this.gridId = Utils.guid();
		widgetContentDiv.empty().append('<div id="' + this.gridId + '" class="" ></div>');
		this.periodSelector = $('#' + this.id).find('.cw_multi_toggle');
		this.gridMessages = {
			isTrue: '<span class="glyphicons service_state ok-sign"></span>',
			isFalse: '<span class="glyphicons service_state remove-sign"></span>',
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
		this.subscribe();
		this.updateTitle();
		this.periodSelector.off().on('click', 'li', $.proxy(this.onPeriodSelector, this));
		if (!this.sessionId && State.currentApp?.dashboardDesigner?.props?.mode !== 'designer') {
			let gridContainer = $('#' + this.gridId);
			gridContainer.off().on('click', '.js_sla_details', $.proxy(this.onSlaDetails, this));
			gridContainer.on('click', '.status_icon', $.proxy(this.onSlaDetails, this));
		}
		$(window).off('resize', $.proxy(this.onResize, this));
		$(window).on('resize', $.proxy(this.onResize, this));
	},

	getTags: async function () {
		let tags = await Utils.getAccountTags();
		this.filterOptions = {
			tags: tags
		};
	},

	/**
	 * Initializes Kendo components
	 */
	initKendoComponents: function () {
		var filterMessages = lang.grid.filter, url;
		var gridSort = this.persistedState?.sort || this.configuration.sort || [{
			field: 'statusIndex',
			dir: 'desc',
			compare: null
		}];
		var gridFilter = this.persistedState?.filter || this.configuration.filter;

		var savedColumns = this.configuration.columns;
		savedColumns = updateHiddenColumns(this.persistedState?.columns, savedColumns);

		var slasColumns = this.configuration.columns || {
			statusIndex: {
				hidden: false
			},
			name: {
				hidden: false
			},
			accountName: {
				hidden: false
			},
			serviceName: {
				hidden: false
			},
			periodTag: {
				hidden: false
			},
			tags: {
				hidden: false
			},
			compliancePercentage: {
				hidden: false
			},
			actualCompliance: {
				hidden: false
			},
			responsibleTeamName: {
				hidden: false
			},
			downTime: {
				hidden: false
			},
			maxAllowedDownTime: {
				hidden: false
			},
			description: {
				hidden: false
			}
		};

		this.periodSelector.find('input[value=ALL]').parent().addClass('is_selected');
		url = this.requestPath + 'accounts/' + this.configuration.accountId + '/slas/calculations?includeSubaccounts=' + this.configuration.includeSubaccounts.toString();

		this.slasDataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: url,
					contentType: "application/json; charset=utf-8",
					type: "POST",
					dataType: "json",
					cache: false
				},
				parameterMap: (data, type) => {
					if (data.filter?.filters) {
						data.filter.filters = Utils.changeDateFilterToString(data.filter.filters);
					}
					data.showUntagged = this.configuration.showUntagged;

					let tags = this.configuration.tags;
					let tagsArray = [];
					if (tags) {
						for (let i = 0; i < tags.length; i++) {
							if (tags[i].name) {
								tagsArray.push(tags[i].name);
							} else if (typeof tags[i] === 'string') {
								tagsArray.push(tags[i]);
							}
						}
					}
					this.configuration.tags = tagsArray;
					data.tags = this.configuration.tags;

					return kendo.stringify(data);
				}
			},
			schema: {
				model: {
					id: 'id',
					fields: {
						id: {
							type: 'string'
						},
						actualCompliance: {
							type: 'number'
						},
						name: {
							type: 'string'
						},
						compliancePercentage: {
							type: 'number'
						},
						statusIndex: {
							type: 'number'
						},
						downTime: {
							type: 'number'
						},
						maxAllowedDownTime: {
							type: 'number'
						}
					}
				},
				parse: function (result) {
					for (let i = 0; i < result.length; i++) {
						if (result[i].tags) {
							result[i].tags = result[i].tags.join(', ');
						}
					}
					return result;
				}
			},
			sort: gridSort,
			filter: gridFilter,
			error: ErrorHandler.kendoServerError
		});

		this.grid = $('#' + this.gridId).kendoCustomGrid({
			reorderable: true,
			dataSource: this.slasDataSource,
			resizable: true,
			height: '100%',
			selectable: 'single',
			sortable: {
				mode: "multiple",
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ,
						contains: filterMessages.CONTAINS
					},
					number: {
						eq: filterMessages.EQ,
						neq: filterMessages.NEQ,
						gte: filterMessages.GTE,
						gt: filterMessages.GT,
						lte: filterMessages.LTE,
						lt: filterMessages.LT
					}
				},
				messages: this.gridMessages
			},
			pageable: false,
			columns: Utils.rearrangeColumns([{
				field: 'statusIndex',
				title: ' ',
				sortable: true,
				hidden: slasColumns.statusIndex.hidden,
				width: 40,
				//template: '#= Renderer.slaStatus(indicator)#',
				template: item => getSlaState(item.indicator),
				attributes: {
					'class': 'text_center'
				}
			}, {
				field: 'name',
				title: lang.NAME,
				sortable: true,
				filterable: true,
				template: '<a class="cw_grid_link js_sla_details" data-id="#= id #" >#= name #</a>',
				hidden: slasColumns.name.hidden,
				width: 240,
				attributes: {
					'class': 'expand ellipsis'
				}
			}, {
				field: 'accountName',
				title: lang.ACCOUNT,
				sortable: true,
				filterable: true,
				hidden: slasColumns.accountName.hidden,
				width: 150,
				attributes: {
					'class': 'expand ellipsis'
				}
			}, {
				field: 'serviceName',
				title: lang.SERVICE,
				sortable: true,
				filterable: true,
				template: row => {
					return `<a class="cw_grid_link" ${this.navigator.renderLink(ServicesRouter.details(row.serviceId))}>
						${row.serviceName}
					</a>`
				},
				hidden: slasColumns.serviceName.hidden,
				width: 150,
				attributes: {
					'class': 'expand ellipsis'
				}
			}, {
				field: 'periodTag',
				title: lang.service.PERIOD,
				sortable: true,
				filterable: false,
				hidden: slasColumns.periodTag.hidden,
				width: 150
			}, {
				field: 'tags',
				title: lang.TAGS,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						let multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'tags',
							grid: this.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: this.filterOptions.tags,
							static: true
						});
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					}
				},
				hidden: slasColumns.tags ? slasColumns.tags.hidden : false,
				width: 150,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'compliancePercentage',
				title: lang.service.COMPLIANCE_GOAL,
				sortable: true,
				filterable: true,
				attributes: {
					'class': 'text_center'
				},
				hidden: slasColumns.compliancePercentage.hidden,
				width: 150
			}, {
				field: 'actualCompliance',
				title: lang.service.ACTUAL_COMPLIANCE,
				sortable: true,
				filterable: true,
				attributes: {
					'class': 'text_center'
				},
				hidden: slasColumns.actualCompliance.hidden,
				width: 150
			}, {
				field: 'responsibleTeamName',
				title: lang.service.RESPONSIBLE_TEAM,
				filterable: true,
				sortable: true,
				hidden: slasColumns.responsibleTeamName.hidden,
				width: 150,
				attributes: {
					'class': 'expand ellipsis'
				}
			}, {
				field: 'downTime',
				title: lang.slas.DOWNTIME,
				filterable: {
					ui: function (element) {
						element.kendoDropDownList({
							dataSource: [{
								text: '1 ' + lang.HOUR,
								value: 3600000
							}, {
								text: '5 ' + lang.HOURS,
								value: 18000000
							}, {
								text: '10 ' + lang.HOURS,
								value: 36000000
							}, {
								text: '1 ' + lang.DAY,
								value: 84600000
							}],
							dataTextField: 'text',
							dataValueField: 'value',
							optionLabel: lang.grid.FILTER_SELECT_VALUE
						});
					}
				},
				sortable: true,
				template: '#=Renderer.duration(downTime) !== "<span>N/A</span>" ? Renderer.duration(downTime) : "' + lang.slas.NO_DOWNTIME + '"#',
				hidden: slasColumns.downTime ? slasColumns.downTime.hidden : false,
				width: 150
			}, {
				field: 'maxAllowedDownTime',
				title: lang.slas.MAX_DOWNTIME,
				filterable: {
					ui: function (element) {
						element.kendoDropDownList({
							dataSource: [{
								text: '1 ' + lang.HOUR,
								value: 3600000
							}, {
								text: '5 ' + lang.HOURS,
								value: 18000000
							}, {
								text: '10 ' + lang.HOURS,
								value: 36000000
							}, {
								text: '1 ' + lang.DAY,
								value: 84600000
							}],
							dataTextField: 'text',
							dataValueField: 'value',
							optionLabel: lang.grid.FILTER_SELECT_VALUE
						});
					}
				},
				sortable: true,
				template: '#=Renderer.duration(maxAllowedDownTime)#',
				hidden: slasColumns.maxAllowedDownTime ? slasColumns.maxAllowedDownTime.hidden : false,
				width: 150
			},
				{
					field: 'description',
					title: lang.DESCRIPTION,
					sortable: true,
					filterable: true,
					attributes: {
						'class': 'expand ellipsis'
					},
					hidden: slasColumns.description.hidden
				}], savedColumns),
			columnMenu: true,
			change: $.proxy(this.onRowExpand, this),
			dataBound: $.proxy(this.onGridDataBound, this)
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();

		var searchId = $('#' + this.id).find('.cw_search_box').attr('id');
		this.gridSearch = new GridSearch({
			id: searchId,
			dataSource: this.slasDataSource,
			fields: ['name', 'serviceName', 'periodTag', 'description', 'accountName']
		});

		if (slasColumns.accountName.hidden) {
			this.grid.hideColumn('accountName');
		}
	},
	/**
	 * Called when an event is received from the server
	 * @param {Object} data The event data object
	 */
	onEvent: function (data) {
		this.refresh();
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function (e) {
		var selectedRow = $(e.sender.select());
		selectedRow.find('.expand').toggleClass('ellipsis');
	},
	/**
	 * Handler function for the grid data bound event
	 */
	onGridDataBound: function () {
		//setTimeout(() => this.adjustSectionHeight(this.id), 200);
	},
	/**
	 * Handler function for the click event on the period selector
	 * @param {Object} e
	 */
	onPeriodSelector: function (e) {
		var target = $(e.target);
		var period = target.text();
		target.parent().find('.is_selected').removeClass('is_selected');
		target.addClass('is_selected');
		if (target.find('input').val() === 'ALL') {
			this.slasDataSource.filter([]);
		} else {
			this.slasDataSource.filter({
				field: 'periodTag',
				operator: "equals",
				value: period
			});
		}
	},
	/**
	 * Handler for the service name click
	 * @param {Object} e The click event
	 */
	onSlaDetails: function (e) {

		e.preventDefault();
		var rowId = $(e.currentTarget).closest('tr').attr('data-uid');
		var slaItem = $('#' + this.gridId).data('kendoCustomGrid').dataSource.getByUid(rowId);

		this.navigator.go({
			url: SlaRouter.details(slaItem.id)
		});

		e.stopPropagation();
	},
	/**
	 * Updates the widget title
	 */
	updateTitle: function () {
		if (!this.title) {
			var titleSpan = this.isKendoWindow ? $('#' + this.id).closest('.k-window').find('.k-window-title') : $('#' + this.id).find('.cw_section_title');
			titleSpan.text(lang.widget.SERVICE_LEVEL_AGREEMENT);
		}
	},
	/**
	 * Triggered after widget resize
	 * @param {Object} event The resize event
	 * @param {Object} ui The UI element - see http://api.jqueryui.com/resizable/
	 */
	onResize: function (event, ui) {
		// TODO the resize functionality
		//this.adjustSectionHeight(this.id);
	},
	/**
	 * Refresh the widget
	 */
	refresh: function () {
		this.grid.dataSource.transport.options.read.url = this.requestPath + 'accounts/' + this.configuration.accountId + '/slas/calculations?includeSubaccounts=' + this.configuration.includeSubaccounts.toString();
		this.grid.dataSource.read();
	},
	/**
	 * Adjust section height based on id
	 * @param {String} id The section CSS id
	 */
	// adjustSectionHeight: function (id) {
	// 	let heightAdjust = 0;
	// 	if (this.isDashboardMode() && !this.isWithoutHeader()) {
	// 		heightAdjust = 70;
	// 	} else {
	// 		heightAdjust = 30;
	// 	}
	// 	let section = $('#' + id);
	// 	let sectionHeight = section.height();
	// 	let contentHeight = sectionHeight - heightAdjust;
	// 	section.find('.cw_section_content').css('height', sectionHeight + 'px');
	// 	section.find('.k-grid-content').css('height', contentHeight + 'px');
	//
	// 	let shownContent = section.find('.k-grid-content');
	// 	let actualContentHeight = section.find('.k-grid-content table').height();
	// 	let shownContentHeight = shownContent.height();
	// 	if (actualContentHeight < shownContentHeight) {
	// 		section.find('.k-scrollbar-vertical').addClass('hide');
	// 	} else {
	// 		section.find('.k-scrollbar-vertical').removeClass('hide');
	// 	}
	// },
	/**
	 * Subscribes to server events
	 */
	subscribe: function () {
		var subscriptionObj = [{
			eventType: 'Sla',
			accountId: this.configuration.accountId,
			includeSubaccounts: this.configuration.includeSubaccounts
		}, {
			eventType: 'SlaAdmin',
			actionTypes: ['SLA_CREATE', 'SLA_UPDATE', 'SLA_DELETE'],
			accountId: this.configuration.accountId,
			includeSubaccounts: this.configuration.includeSubaccounts
		}];
		this.subscriberId = this.id;
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
		this.initKendoComponents();
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		$(window).off('resize', $.proxy(this.onResize, this));
		Widget.prototype.destroy.call(this);
	},

	getStateForSaving(){
		return getGridStateForSaving(this.grid);
	}
});
