import Utils from 'tools/utils';
import Application from 'core/application';
import PreferencesPanel from 'controls/preferencesPanel';
import Dialog from 'controls/dialog';
import GridMenu from 'controls/gridMenu';
import CustomNotification from 'controls/customNotification';
import UserPrefs from 'tools/userPrefs';
import State from 'tools/state';
import Settings from 'settings';
import Cookies from 'core/cookies';
import Renderer from 'tools/renderer';
import ChangeStatusWindow from 'areas/incidents/changeStatusWindow';
import LocalEventsManager from 'core/localEventsManager';
import ModalWindow from 'controls/modalWindow';
import {IncidentsRouter} from "./bundleDescription";
import MultiSelectGridFilter from 'controls/multiSelectGridFilter';
import {openTagsFormWindow} from "controls/tagsForm";
import EventsToolbar from 'controls/eventsToolbar';
import {IncidentApi} from "areas/incidents/api";
import RemoteEventsManager from 'core/remoteEventsManager';
import lang from 'core/localization/lang';
import {topLeftCornerPosition} from 'controls/modalWindow'
import {saveUserPrefs} from "tools/userPrefs";

/**
 * @class ceeview.incidents.Application This class is used as an entry point in
 * the Incidents application.
 * @namespace ceeview.incidents
 * @extends Application
 * @constructor
 * @param {Object} config The config object
 */

export default class Incidents {
	init() {
		this.subscriberId = Utils.guid();

		this.getTags();
		this.showGridView = true;

		State.mainApp.contextMenu.setActionButton('INCIDENT');

		this.actionNotification = new CustomNotification({
			appendToElement: '#cw_incidents_list',
		});

		this.removeListeners();
		this.attachListeners();
		this.loadUserPreferences();

		this.initialized({ title: 'Incidents' });
	}

	/**
	 * Removes listeners
	 */
	removeListeners () {
		$('#cw_incidents_list').off();
		LocalEventsManager.unbind('incidentsstatuschanged', $.proxy(this.onStatusChanged, this));
	}

	/**
	 * Attaches listeners
	 */
	attachListeners () {
		$('#cw_incidents_list').on('click', '.cw_grid_check', $.proxy(this.onIncidentsCheck, this));
		$('#cw_incidents_list').on('click', '.cw_grid_check_all', $.proxy(this.onIncidentsCheckAll, this));
		LocalEventsManager.bind('incidentsstatuschanged', $.proxy(this.onStatusChanged, this));
		$(window).on('resize', $.proxy(this.adjustSectionHeight, this));
	}

	async getTags() {
		let tags = await Utils.getAccountTags();
		this.filterOptions = {
			tags: tags
		};
	}

	/**
	 * Initializes kendo components
	 */
	initKendoComponents () {
		var filterMessages = lang.grid.filter;
		let eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
				startFrom: 0,
				playing: true
			};
		this.eventsToolbar = new EventsToolbar({
			renderTo: $('#cw_incidents_events_toolbar'),
			callBackFn: $.proxy(this.onEventsTimeout, this),
			subscriberId: this.subscriberId,
			startFrom: eventStatus.startFrom,
			playing: eventStatus.playing
		});
		this.gridMessages = {
			isTrue: '<span class="glyphicons service_state ok-sign"></span>',
			isFalse: '<span class="glyphicons service_state remove-sign"></span>',
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			status: {
				hidden: false,
				width: 80
			},
			incidentNumber: {
				hidden: false,
				width: 110
			},
			subject: {
				hidden: false,
				width: 240
			},
			accountName: {
				hidden: !State.includeSubaccounts,
				width: 150
			},
			tags: {
				hidden: false,
				width: 150
			},
			contentType: {
				hidden: false,
				width: 150
			},
			priorityName: {
				hidden: false,
				width: 150
			},
			impactName: {
				hidden: false,
				width: 150
			},
			owner: {
				hidden: false,
				width: 150
			},
			created: {
				hidden: false,
				width: 220
			},
			modified: {
				hidden: false,
				width: 220
			},
			createdBy: {
				hidden: false,
				width: 150
			},
			assignedTo: {
				hidden: false,
				width: 150
			},
			assignedTo: {
				hidden: false,
				width: 150
			},
			description: {
				hidden: true,
				width: 200
			}
		};
		var incidentsSort;
		var defaultFsView = UserPrefs.get('defaultFsView');
		if (Utils.isGuid(defaultFsView)) {
			var pref = JSON.parse(UserPrefs.get(defaultFsView));
			pref = pref || {
					sort: [],
					filter: []
				};
			incidentsSort = pref.sort;
			this.incidentsFilter = pref.filter;
			this.incidentsColumns = pref.columns;
		} else {
			if (UserPrefs.get('incidentsSort')) {
				incidentsSort = JSON.parse(UserPrefs.get('incidentsSort'));
			} else {
				incidentsSort = [{
					field: 'incidentNumber',
					dir: 'desc',
					compare: null
				}];
			}
			if (UserPrefs.get('incidentsFilter')) {
				this.incidentsFilter = JSON.parse(UserPrefs.get('incidentsFilter'));
			} else {
				this.incidentsFilter = [];
			}
		}
		var incidentsColumns = this.incidentsColumns || JSON.parse(UserPrefs.get('incidentsColumns')) || this.defaultColumns;
		incidentsColumns = Utils.completeColumns(incidentsColumns, this.defaultColumns);
		this.dontLoadDefault = false;

		this.gridUrl = IncidentApi.getExistingIncidentsUrl(this, Cookies.CeesoftCurrentAccountId);

		this.incidentsDataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: this.gridUrl,
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			schema: {
				model: {
					fields: {
						created: {
							type: 'date'
						},
						modified: {
							type: 'date'
						}
					}
				},
				parse: function (data) {
					for (var i = 0, length = data.length; i < length; i++) {
						data[i].created = new Date(data[i].created);
						data[i].modified = new Date(data[i].modified);
						if (data[i].tags) {
							data[i].tags = data[i].tags.join(', ');
						}
					}
					return data;
				}
			},
			sort: incidentsSort,
			filter: this.incidentsFilter
		});
		this.grid = $('#cw_incidents_list').kendoCustomGrid({
			dataSource: this.incidentsDataSource,
			resizable: true,
			selectable: 'single',
			reorderable: true,
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					},
					number: {
						eq: filterMessages.EQ,
						neq: filterMessages.NEQ,
						gte: filterMessages.GTE,
						gt: filterMessages.GT,
						lte: filterMessages.LTE,
						lt: filterMessages.LT
					},
					date: {
						gte: filterMessages.IAE,
						gt: filterMessages.IA,
						lte: filterMessages.IBE,
						lt: filterMessages.IB
					}
				},
				messages: this.gridMessages
			},
			pageable: false,
			columns: Utils.rearrangeColumns([{
				field: 'id',
				title: lang.SELECTOR,
				sortable: false,
				filterable: false,
				menu: false,
				template: '<input type="checkbox" class="cw_grid_check" data-id="${id}" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				attributes: {
					'class': 'text_center'
				},				headerAttributes: {
					'class': 'text_center'
				},
				hidden: incidentsColumns.id.hidden,
				width: incidentsColumns.id.width
			}, {
				field: 'status',
				title: lang.service.STATUS,
				sortable: true,
				filterable: true,
				template: '#: status || "" #',
				hidden: incidentsColumns.status.hidden,
				width: incidentsColumns.status.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'incidentNumber',
				title: lang.incidents.NUMBER,
				template: item => {
					return `<a class="cw_grid_link" href="#${IncidentsRouter.details(item.id)}">${item.incidentNumber}</a>`
				},
				sortable: true,
				filterable: true,
				hidden: incidentsColumns.incidentNumber.hidden,
				width: incidentsColumns.incidentNumber.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'subject',
				title: lang.incidents.SUBJECT,
				sortable: true,
				filterable: true,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				hidden: incidentsColumns.subject.hidden,
				width: incidentsColumns.subject.width
			}, {
				field: 'accountName',
				title: lang.ACCOUNT,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						return Renderer.filterGridByOwnElementDS.call(this, element, 'accountName');
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				hidden: !State.includeSubaccounts || incidentsColumns.accountName.hidden,
				width: incidentsColumns.accountName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'tags',
				title: lang.TAGS,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						let multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'tags',
							grid: this.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: this.filterOptions.tags,
							static: true
						});
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					}
				},
				hidden: incidentsColumns.tags.hidden,
				width: incidentsColumns.tags.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'contentType',
				title: lang.TYPE,
				sortable: true,
				filterable: true,
				hidden: incidentsColumns.contentType.hidden,
				width: incidentsColumns.contentType.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'priorityName',
				title: lang.assethealth.PRIORITY,
				sortable: true,
				filterable: true,
				hidden: incidentsColumns.priorityName.hidden,
				width: incidentsColumns.priorityName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'impactName',
				title: lang.assethealth.IMPACT,
				sortable: true,
				filterable: true,
				hidden: incidentsColumns.impactName.hidden,
				width: incidentsColumns.impactName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'owner',
				title: lang.incidents.OWNER,
				sortable: true,
				filterable: true,
				hidden: incidentsColumns.owner.hidden,
				width: incidentsColumns.owner.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'created',
				title: lang.incidents.CREATED,
				sortable: true,
				filterable: {
					extra: true,
					ui: function (el) {
						el.kendoDateTimePicker({
							format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
							timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
						});
					}
				},
				template: '#= Renderer.browserDateRenderer(created, "datetime")#',
				hidden: incidentsColumns.created.hidden,
				width: incidentsColumns.created.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'modified',
				title: lang.incidents.MODIFIED,
				template: '#= Renderer.browserDateRenderer(modified, "datetime")#',
				sortable: true,
				filterable: {
					extra: true,
					ui: function (el) {
						el.kendoDateTimePicker({
							format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
							timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
						});
					}
				},
				hidden: incidentsColumns.modified.hidden,
				width: incidentsColumns.modified.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'createdBy',
				title: lang.incidents.REPORTED_BY,
				sortable: true,
				filterable: true,
				hidden: incidentsColumns.createdBy.hidden,
				width: incidentsColumns.createdBy.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'assignedTo',
				title: lang.incidents.ASSIGNED_TO,
				sortable: true,
				filterable: true,
				hidden: incidentsColumns.assignedTo.hidden,
				width: incidentsColumns.assignedTo.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'description',
				title:lang.DESCRIPTION,
				sortable: true,
				filterable: true,
				hidden: incidentsColumns.description.hidden,
				width: incidentsColumns.description.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}], incidentsColumns),
			change: $.proxy(this.onRowExpand, this),
			dataBound: $.proxy(this.onGridDataBound, this),
			columnMenu: true
		}).data('kendoCustomGrid')
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.adjustSectionHeight();
		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
		this.gridMenu = new GridMenu({
			renderTo: 'cw_incidents_grid_menu',
			items: [{
				id: 'cw_incidents_create',
				icon: 'plus-sign',
				text: lang.CREATE,
				fn: this.onIncidentCreate,
				scope: this,
				disabled: false,
				role: 'INCIDENT_CREATE'
			}, {
				id: 'cw_incidents_delete',
				icon: 'bin',
				text: lang.DELETE,
				fn: this.onIncidentDelete,
				scope: this,
				disabled: true,
				role: 'INCIDENT_DELETE'
			}, {
				id: 'cw_incidents_change_status',
				icon: 'lightbulb',
				text: lang.incidents.CHANGE_STATUS,
				fn: this.onIncidentStatusChange,
				scope: this,
				disabled: true,
				role: 'INCIDENT_UPDATE'
			}, {
				id: 'cw_set_tags',
				icon: 'tag',
				text: lang.SET_TAGS,
				fn: $.proxy(function () {
					return openTagsFormWindow('incident', this);
				}, this),
				scope: this,
				disabled: true,
				role: 'INCIDENT_UPDATE'
			}]
		});
		var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('incidentsSearchPhrase') || '');
		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_incidents_filters',
			grid: this.grid,
			modulePrefName: 'Incidents',
			defaultPrefViewKey: 'defaultFsView',
			dontLoadDefault: this.dontLoadDefault,
			prefListKey: 'incidentsFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['status', 'subject', 'incidentNumber', 'accountName', 'contentType', 'owner', 'createdBy', 'assignedTo'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns
		});
	}

	subscribe() {
		let accountId = this.gridUrl.split('accounts/')[1].split('/')[0];
		let subscriptionObj = [{
			eventType: 'Incident',
			accountIds: [accountId],
			includeSubaccounts: State.includeSubaccounts
		}];
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	}

	unsubscribe() {
		RemoteEventsManager.unsubscribe(this.subscriberId);
	}

	onEventsTimeout () {
		this.checkboxStates = Utils.getCheckboxStates(this.grid);
		this.grid.dataSource.read();
	}

	reloadByTags () {
		this.grid.dataSource.read();
	}

	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand (e) {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#cw_incidents_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	}

	/**
	 * Handler event for the data source bound event
	 */
	onGridDataBound () {
		$('#cw_incidents_list').find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (event) {
				return event.target.text().split("\n").join("<br />");
			}
		});
	}

	/**
	 * Saves user preferences
	 */
	saveUserPreferences () {
		saveUserPrefs({
			category: 'Incidents',
			filterPanel: this.gridFilterPanel,
			grid: this.grid,
			eventsToolbar: this.eventsToolbar,
			keys: {
				searchPhrase: 'incidentsSearchPhrase',
				columns: 'incidentsColumns',
				sort: 'incidentsSort',
				filter: 'incidentsFilter',
				FsViews: 'incidentsFSViews'
			}
		}, this.eventsToolbar, this.userPref);
	}

	/**
	 * Loads user preferences
	 */
	loadUserPreferences () {
		this.userPref = [];
		UserPrefs.load('Incidents', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				this.initKendoComponents();
				this.subscribe();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	}

	/*
	 * Handler function for incident create
	 */
	onIncidentCreate (e) {
		var accountList = [{
			id: Cookies.CeesoftCurrentAccountId,
			name: Cookies.CeesoftCurrentAccountName
		}];
		this.app.navigate(IncidentsRouter.createNew('GENERIC'), {
			extra: {
				accountList
			}
		});
	}

	/**
	 * Handler for the delete incident button
	 */
	onIncidentDelete () {
		var checkboxes = $('.cw_grid_check');
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/incidents/delete';
		var incidents = [], checked = 0;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				incidents.push($(checkboxes[i]).data('id'));
				checked++;
			}
		}
		if (incidents.length) {
			var dialog = new Dialog({
				title: lang.INFO,
				msg: checked > 1 ? lang.incidents.messages.INCIDENTS_REMOVE_CONFIRMATION : lang.incidents.messages.INCIDENT_REMOVE_CONFIRMATION,
				icon: 'ERROR',
				actionText: 'DELETE',
				position: topLeftCornerPosition,
				fn: $.proxy(function (value, button) {
					if (button === 'ok') {
						this.gridMenu.disableItem('cw_delete');
						Utils.ajax(url, 'POST', JSON.stringify(incidents), $.proxy(function (result) {
							if (result.success) {
								var status = $('.status');
								status.find('p').addClass('success');
								status.find('p').text(lang.incidents.messages.SUCCESS_DELETED);
								status.slideDown().delay(2000).slideUp();
								// reload data source
								this.incidentsDataSource.read();
							} else {
								Utils.showInfo(lang.ALERT, result.message, result.details, this.grid);
							}
						}, this));
					}
				}, this),
				deleteFromGrid: this.grid
			});
			dialog.show();
		}
	}

	/**
	 * Handler function for the change status menu item
	 */
	onIncidentStatusChange () {
		var checkboxes = $('.cw_grid_check:checked');
		var incidents = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			var incidentId = $(checkboxes[i]).attr('data-id');
			var record = this.grid.dataSource.get(incidentId);
			var accountId = record.accountId;
			incidents.push(incidentId);
		}
		this.changeStatusWindow = new ModalWindow({
			id: 'change_incident_status_window',
			title: lang.incidents.CHANGE_INCIDENT_STATUS,
			width: 400,
			resizable: false,
			minHeight: 120,
			height: 120,
			position: topLeftCornerPosition,
			modal: true,
			actions: [],
			url: 'include/Incidents/ChangeStatusWindow.jsp',
			refresh: $.proxy(function () {
				var changeStatusWin = new ChangeStatusWindow({
					accountId: accountId,
					incidents: incidents,
					contentCategory: record.contentCategory
				});
			}, this)
		});
		this.changeStatusWindow.open();
	}

	/**
	 * Handler for the service checkbox click
	 * @param {Object} e The click event
	 */
	onIncidentsCheck (e) {
		e.stopPropagation();
		var checkboxes = $('.cw_grid_check');
		var accountId, contentCategory;
		var counter = 0;
		var isSameAccountAndCategory = true;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				counter++;
				var record = this.grid.dataSource.get($(checkboxes[i]).attr('data-id'));
				if (accountId) {
					if (accountId !== record.accountId || contentCategory !== record.contentCategory) {
						isSameAccountAndCategory = false;
					}
				} else {
					accountId = record.accountId;
					contentCategory = record.contentCategory;
				}
			}
		}
		if (counter) {
			this.gridMenu.enableItem('cw_incidents_delete');
			this.gridMenu.enableItem('cw_set_tags');
			if (counter === 1) {
				this.gridMenu.enableItem('cw_incidents_edit');
			} else {
				this.gridMenu.disableItem('cw_incidents_edit');
			}
			if (isSameAccountAndCategory) {
				this.gridMenu.enableItem('cw_incidents_change_status');
			} else {
				this.gridMenu.disableItem('cw_incidents_change_status');
			}
		} else {
			this.gridMenu.disableItem('cw_incidents_delete');
			this.gridMenu.disableItem('cw_set_tags');
			this.gridMenu.disableItem('cw_incidents_edit');
			this.gridMenu.disableItem('cw_incidents_change_status');
		}
	}

	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onIncidentsCheckAll (e) {
		var checkAll = $('.cw_grid_check_all').is(':checked');
		var checkboxes = $('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		var accountId, contentCategory;
		var isSameAccountAndCategory = true;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				var record = this.grid.dataSource.get($(checkboxes[i]).attr('data-id'));
				if (accountId) {
					if (accountId !== record.accountId || contentCategory !== record.contentCategory) {
						isSameAccountAndCategory = false;
					}
				} else {
					accountId = record.accountId;
					contentCategory = record.contentCategory;
				}
			}
		}
		if (checkAll && checkboxes.length) {
			this.gridMenu.enableItem('cw_incidents_delete');
			this.gridMenu.enableItem('cw_set_tags');
			if (checkboxes.length === 1) {
				this.gridMenu.enableItem('cw_incidents_edit');
			} else {
				this.gridMenu.disableItem('cw_incidents_edit');
			}
			if (isSameAccountAndCategory) {
				this.gridMenu.enableItem('cw_incidents_change_status');
			} else {
				this.gridMenu.disableItem('cw_incidents_change_status');
			}
		} else {
			this.gridMenu.disableItem('cw_incidents_delete');
			this.gridMenu.disableItem('cw_set_tags');
			this.gridMenu.disableItem('cw_incidents_edit');
			this.gridMenu.disableItem('cw_incidents_change_status');
		}
	}

	/**
	 * Handler funtion for the incidentsstatuschanged event
	 */
	onStatusChanged () {
		this.actionNotification.setOptions({
			message: lang.incidents.messages.STATUS_CHANGED,
			status: 'success'
		}).show();
		this.grid.dataSource.read();
	}

	/**
	 * Method by Andy
	 *
	 */
	adjustSectionHeight () {
		var section = $('.cw_section_full');
		var sectionHeight = section.height();
		section.css('margin-top', 15);
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 55);
	}

	/**
	 * Called when application is unloaded/destroyed
	 */
	destroy () {
		if (this.eventsToolbar) {
			this.eventsToolbar.destroy();
		}
		this.saveUserPreferences();
		this.unsubscribe();
	}
}
