import React from 'react';
import ReactDOM from "react-dom";
import {Utils} from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';

import MultiToggle from 'controls/multiToggle';
import ErrorHandler from 'core/errorHandler';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import {AntSelect} from "controls/react/ant/antSelect";
import FormEntry from "controls/react/form/formEntry";
import aggregationTypeDataSource from 'areas/service-boards/widgets/common/dataSources/aggregationTypeDataSource';
import {renderIntoLegacy} from "../../../controls/react/form/timePeriodSelector";

export function MultigraphWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export {MultigraphWidgetConfiguration as default};

jQuery.extend(MultigraphWidgetConfiguration.prototype, ConfigurationBase.prototype, {

	initComponent: function () {
		this.legendData = [];
		var widgetObj = this.widgetObj;
		var html = `<div class="cw_field">
			<label class="cw_inline">${lang.TITLE}: </label>
			<input type="text" class="cw_widget_title cw_inline" value="${widgetObj.title || ''}" />
		</div>
		<div class="cw-separator-frame selection">
			<div class="cw-separator-frame__title">${lang.SELECTION}</div>
			<div class="cw_field" id="cw_multigraph_account">
				<label class="cw_inline">${lang.ACCOUNT}: </label>
				<div class="cw_dropdown_container">
					<input id="cw_multigraph_accountid" type="text" />
				</div>
			</div>
			<div class="cw_field" id="cw_multigraph_service">
				<label class="cw_inline">${lang.widget.SERVICE}: </label>
				<div class="cw_dropdown_container">
					<input id="cw_multigraph_sid" type="text" />
				</div>
			</div>
			<div class="cw_field" id="cw_multigraph_se">
				<label class="cw_inline">${lang.designer.SERVICE_ELEMENT}: </label>
				<div class="cw_dropdown_container">
					<input id="cw_multigraph_seid" type="text" />
				</div>
			</div>
			<div class="cw_field" id="cw_multigraph_sq">
				<label class="cw_inline">${lang.designer.SERVICE_QUALIFIER}: </label>
				<div class="cw_dropdown_container">
					<input id="cw_multigraph_sqid" type="text" />
				</div>
			</div>
			<div class="cw_field cw_multigraph_button" id="cw_multigraph_service_button">
				<button class="k-button k-primary" id="cw_add_service_qualifier_button" disabled>${lang.ADD}</button>
			</div>
		</div>
		<div class="cw_field">
			<span class="cw_inline_full">${lang.designer.SERVICE_QUALIFIERS}: </span>
			<div class="left w100">
				<input id="cw_multigraph_service_qualifiers_list" type="text" class="w100"/>
			</div>
		</div>`;

		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.widget.PERIOD + ': </label>';
		html += '<div id="cw_multigraph_period_control" class="cw_multi_toggle right"></div>';
		html += '<div class="cw_hide_time_selector"><input type="checkbox" class="cw_include k-checkbox" id="cw_hide_time_selector"' + (widgetObj.configuration.hideTimeSelector ? 'checked="checked"' : '') + '></span>';
		html += '<label for="cw_hide_time_selector" class="k-checkbox-label">' + lang.widget.HIDE_TIME_SELECTOR + '</label></div>';
		html += '</div>';

		html += '<div class="cw_field">';
		html += '<input type="checkbox" class="cw_include k-checkbox" id="cw_ignore_missing_data" title="' + lang.serviceBoard.messages.MISSING_DATA_MESSAGE + '"></span>';
		html += '<label for="cw_ignore_missing_data" class="k-checkbox-label">' + lang.serviceBoard.IGNORE_MISSING_DATA + '</label>';
		html += '</div>';

		html += '<div class="cw_field">';
		html += '<input type="checkbox" class="k-checkbox" id="cw_show_regression">';
		html += '<label for="cw_show_regression" class="k-checkbox-label">' + lang.SHOW_REGRESSION + '</label>';
		html += '</div>';

		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.AGGREGATION_TYPE + '</label>';
		html += '<div class="cw_dropdown_container">';
		html += '<input id="cw_aggregation_type" type="text" />';
		html += '</div>';
		html += '</div>';

		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TIMEZONE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_multigraph_timezone" type="text" value="' + (widgetObj.configuration.timezone || Cookies.CeesoftTimezone) + '"/></div>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<span class="cw_inline_full">' + lang.LEGEND + ': </span>';
		html += '<input id="cw_multigraph_legend" type="text" />';
		html += '</div>';
		this.widgetConfigurationFormEl.empty().append($(html).addClass('service-boards-multigraphWidgetConfiguration'));

		// to support created before configurations
		if (!!widgetObj.configuration.period.period) {
			widgetObj.configuration = Object.assign(widgetObj.configuration, widgetObj.configuration.period);
		}
		this.addTimeSelector(widgetObj.configuration, document.getElementById("cw_multigraph_period_control"));

		this.chartTypeToggle = new MultiToggle({
			id: 'cw_multigraph_type_toggle',
			cssClass: 'cw_multi_toggle right',
			value: 'line',
			type: 'label',
			items: [{
				id: 'cw_multigraph_type_line',
				title: lang.widget.LINE,
				label: lang.widget.LINE,
				selected: false,
				value: 'line'
			}, {
				id: 'cw_multigraph_type_area',
				title: lang.widget.AREA,
				label: lang.widget.AREA,
				selected: false,
				value: 'area'
			}, {
				id: 'cw_multigraph_type_range',
				title: lang.widget.RANGE,
				label: lang.widget.RANGE,
				selected: true,
				value: 'range'
			}]
		});

		if (!widgetObj.configuration.labelTemplate) {
			this.legendMultiList = this.legendDataSource;
		} else {
			this.legendMultiList = widgetObj.configuration.labelTemplate;
		}

		this.renderLegendMultiSelect();
		this.initKendoComponents();


		$('#cw_add_service_qualifier_button').off().on('click', $.proxy(this.onServiceQualifierAddClick, this));
		$('#cw_add_system_qualifier_button').off().on('click', $.proxy(this.onSystemQualifierAddClick, this));

	},
	renderLegendMultiSelect: function() {
		this.legendDataSource = [{
			name: '<Account>',
			id: '<Account>'
		}, {
			name: '<Service>',
			id: '<Service>'
		}, {
			name: '<Serviceelement>',
			id: '<Serviceelement>'
		}, {
			name: '<Servicequalifier>',
			id: '<Servicequalifier>'
		}];
		ReactDOM.render(<FormEntry label={lang.LEGEND} vertical={true}>
			<AntSelect
				dataList={this.legendDataSource}
				mode={"multiple"}
				onChange={value => this.onLegendChange(value)}
				defaultValue={this.legendMultiList || []}
				placeholder={lang.SELECT} />
		</FormEntry>,  $('#cw_multigraph_legend').parent().get()[0]);
	},
	onLegendChange: function(value) {
		this.legendMultiList = value || [];
	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		var widgetObj = this.widgetObj;
		if (this.wasUpdate) {
			$('#cw_hide_time_selector').prop('checked', widgetObj.configuration.hideTimeSelector);
			$('#cw_ignore_missing_data').prop('checked', widgetObj.configuration.ignoreMissingData);
			$('#cw_show_regression').prop('checked', widgetObj.configuration.showRegression);
		}

		this.aggregationType = $('#cw_aggregation_type').kendoDropDownList({
			dataSource: aggregationTypeDataSource,
			dataTextField: 'text',
			dataValueField: 'value',
			value: widgetObj.configuration.aggregationType || 'high'
		}).data('kendoDropDownList');

		this.multigraphAccounts = $('#cw_multigraph_accountid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_multigraph_service'),
							label: lang.widget.SERVICE
						}, {
							container: $('#cw_multigraph_se'),
							label: lang.designer.SERVICE_ELEMENT
						}, {
							container: $('#cw_multigraph_sq'),
							label: lang.designer.SERVICE_QUALIFIER
						}],
						length: e.items.length
					});
				}, this),
				transport: {
					read: {
						url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/subaccounts/allLevels/lite',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			template: '<span id="${ data.id }">${ data.name }</span>',
			placeholder: lang.SELECT,
			enable: true,
			autoBind: false,
			clearButton: false,
			select: $.proxy(function (e) {
				if (e.item) {
					var id = e.dataItem.id
					if (id) {
						this.widgetAccountId = id;
						this.isAccountChanged = true;
						this.multigraphFormObject = this.multigraphFormObject || {};
						this.multigraphFormObject.accountId = id;
						var servicesUrl = Settings.serverPath + `accounts/${this.multigraphFormObject.accountId}/services/lite?hasModel=true`;
						this.multigraphServices.dataSource.options.transport.read.url = servicesUrl;
						this.multigraphServices.dataSource.read();
						this.multigraphServices.value('');
						this.resetDropdowns([this.multigraphServices, this.multigraphElements, this.multigraphQualifiers]);
					}
				}
				var multigraphServiceQualifiersCombo = $('#cw_multigraph_sqid').data('kendoComboBox');
				if (multigraphServiceQualifiersCombo.value() === lang.SELECT) {
					$('#cw_add_service_qualifier_button').prop('disabled', 'disabled');
				}
			}, this),
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this)
		}).data('kendoComboBox');
		this.multigraphFormObject = {
			accountId: this.mode === 'create' ? Cookies.CeesoftCurrentAccountId : this.widgetAccountId,
			serviceId: 0,
			elementId: 0,
			qualifierId: 0,
			qualifierName: ''
		};

		$('#cw_multigraph_timezone').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'sessions/timezones/?addUserTime=true',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			autoBind: true,
			clearButton: false,
		});

		this.multigraphServices = $('#cw_multigraph_sid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_multigraph_se'),
							label: lang.designer.SERVICE_ELEMENT
						}, {
							container: $('#cw_multigraph_sq'),
							label: lang.designer.SERVICE_QUALIFIER
						}],
						length: e.items.length
					});
				}, this),
				transport: {
					read: {
						url: Settings.serverPath + 'accounts/' + this.multigraphFormObject.accountId + '/services/lite?hasModel=true',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			clearButton: false,
			template: '<span id="${ data.id }">${ data.name }</span>',
			//text: lang.SELECT,
			placeholder: lang.SELECT,
			enable: true,
			autoBind: false,
			select: $.proxy(function (e) {
				if (e.item) {
					var id = e.dataItem.id;
					if (id) {
						this.isServiceModelChanged = true;
						this.isInputChanged = true;
						this.multigraphFormObject.serviceId = id;
						var serviceElementsUrl = Settings.serverPath + 'services/' + this.multigraphFormObject.serviceId + '/elements/nonAggregatedOnly/lite';
						this.multigraphElements.dataSource.options.transport.read.url = serviceElementsUrl;
						this.multigraphElements.dataSource.read();
						this.multigraphElements.value('');
						this.resetDropdowns([this.multigraphElements, this.multigraphQualifiers]);
					}
				}
				var multigraphServiceQualifiersCombo = $('#cw_multigraph_sqid').data('kendoComboBox');
				if (multigraphServiceQualifiersCombo.value() === lang.SELECT) {
					$('#cw_add_service_qualifier_button').prop('disabled', 'disabled');
				}
			}, this),
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.isAccountChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this)
		}).data('kendoComboBox');

		this.multigraphElements = $('#cw_multigraph_seid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_multigraph_sq'),
							label: lang.designer.SERVICE_QUALIFIER
						}],
						length: e.items.length
					});
				}, this),
				transport: {
					read: {
						url: Settings.serverPath + 'services/' + this.multigraphFormObject.serviceId + '/elements/nonAggregatedOnly/lite',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			clearButton: false,
			template: '<span id="${ data.id }">${ data.name }</span>',
			//text: lang.SELECT,
			placeholder: lang.SELECT,
			enable: false,
			autoBind: false,
			select: $.proxy(function (e) {
				if (e.item) {
					var id = e.dataItem.id;
					if (id) {
						this.isServiceElementChanged = true;
						this.isInputChanged = true;
						this.multigraphFormObject.elementId = id;
						var serviceQualifiersUrl = Settings.serverPath + 'services/' + this.multigraphFormObject.serviceId + '/elements/' + this.multigraphFormObject.elementId + '/qualifiers/?update=false';
						this.multigraphQualifiers.dataSource.options.transport.read.url = serviceQualifiersUrl;
						this.multigraphQualifiers.dataSource.read();
						this.multigraphQualifiers.value('');
						this.resetDropdowns([this.multigraphQualifiers]);
					}
				}
				var multigraphServiceQualifiersCombo = $('#cw_multigraph_sqid').data('kendoComboBox');
				if (multigraphServiceQualifiersCombo.value() === lang.SELECT) {
					$('#cw_add_service_qualifier_button').prop('disabled', 'disabled');
				}
			}, this),
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.isServiceModelChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this)
		}).data('kendoComboBox');

		this.multigraphQualifiers = $('#cw_multigraph_sqid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				change: $.proxy(function (e) {
					if (e.items.length === 0) {
						$('#cw_add_service_qualifier_button').prop('disabled', 'disabled');
					}
				}, this),
				transport: {
					read: {
						url: Settings.serverPath + 'services/' + this.multigraphFormObject.serviceId + '/elements/' + this.multigraphFormObject.elementId + '/qualifiers/?update=false',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			template: '<span id="${ data.id }">${ data.name }</span>',
			//text: lang.SELECT,
			placeholder: lang.SELECT,
			clearButton: false,
			enable: false,
			autoBind: false,
			//autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			select: $.proxy(function (e) {
				if (e.item) {
					var id = e.dataItem.id;
					if (id) {
						this.multigraphFormObject.qualifierId = id;
						this.multigraphFormObject.qualifierName = this.multigraphAccounts.text() + '/' + this.multigraphServices.text() + '/' + e.dataItem.name;
						$('#cw_add_service_qualifier_button').removeAttr('disabled');
					} else {
						$('#cw_add_service_qualifier_button').prop('disabled', 'disabled');
					}
				}
			}, this),
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {

				if (this.isServiceElementChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this)
		}).data('kendoComboBox');

		if (this.mode === 'update' || this.wasUpdate) {
			this.multigraphServiceListDataSource = new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'services/qualifiers/byId/lite',
						contentType: "application/json; charset=utf-8",
						type: "POST",
						dataType: "json",
						cache: false
					},
					parameterMap: function (options, operation) {
						if (operation === 'read') {
							return JSON.stringify(widgetObj.configuration.qualifiersList);
						}
					}
				},
				schema: {
					parse: function (response) {
						var items = [];
						for (var i = 0, length = response.length; i < length; i++) {
							var shownAccountName = response[i].accountName || '';
							var shownServiceName = response[i].serviceName || '';
							var shownName = response[i].name || '';
							response[i].name = shownAccountName + '/' + shownServiceName + '/' + shownName;
							if (shownAccountName || shownServiceName || shownName) {
								items.push(response[i]);
							}
						}
						return items;
					}
				},
				error: ErrorHandler.kendoServerError
			});
		} else {
			this.multigraphServiceListDataSource = new kendo.ceeview.DataSource({
				data: []
			});
		}
		var scope = this;
		this.multigraphServiceQualifiersList = $('#cw_multigraph_service_qualifiers_list').kendoSortedMultiSelect({
			dataSource: this.multigraphServiceListDataSource,
			dataTextField: 'name',
			dataValueField: 'id',
			tagTemplate: '<span title="${name}">${name}</span>',
			placeholder: lang.service.NO_QUALIFIERS,
			change: function (e) {
				var previous = this.savedOld;
				var current = this.value();
				var diff = [];
				if (previous) {
					diff = $(previous).not(current).get();
				}
				this.savedOld = current.slice(0);
				if (diff.length > 0) {
					var data = scope.multigraphServiceListDataSource.data();
					var dataLength = data.length;
					for (var i = 0; i < dataLength; i++) {
						if (data[i].id === diff[0]) {
							var dataItem = scope.multigraphServiceListDataSource.at(i);
							scope.multigraphServiceListDataSource.remove(dataItem);
							break;
						}
					}
				}
			},
			dataBound: $.proxy(function (e) {
				if (this.newServiceQualifierAdded) {
					this.newServiceQualifierAdded = false;
					$('#cw_multigraph_service_qualifiers_list_listbox').find('li:last').trigger('click');
				} else if (this.mode === 'update' || this.wasUpdate) {
					var data = e.sender.dataSource.data(), length = data.length, values = [];
					for (var i = 0; i < length; i++) {
						values.push(data[i].id);
					}
					e.sender.value(values);
					if (!values.length) {
						e.sender.wrapper.css('border', '1px solid red');
					}
				}
			}, this)
		}).data('kendoSortedMultiSelect');
		if (this.mode === 'create') {
			this.multigraphAccounts.value(Cookies.CeesoftCurrentAccountId);
		}
	},
	/**
	 * Handler function for the click event on the ignore missing data chckbox
	 * @param {type} e
	 */
	onIgnoreCheckbox: function (e) {
		var checkbox = $(e.currentTarget);
		checkbox.toggleClass('check unchecked');
	},
	findRecord: function (dataSource, id) {
		var record = null;
		var length = dataSource.total();
		for (var i = 0; i < length; i++) {
			var r = dataSource.at(i);
			if (r.id === id) {
				record = r;
				break;
			}
		}
		return record;
	},
	getValues: function () {
		var title = $('.cw_widget_title').val().trim() || lang.MULTIGRAPH;
		var ignoreMissingData = $('#cw_ignore_missing_data').is(':checked');
		var showRegression = $('#cw_show_regression').is(':checked');
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'multigraph',
			title: title,
			configuration: {
				...this.periodToggle,
				timezone: $('#cw_multigraph_timezone').data('kendoComboBox').value(),
				hideTimeSelector: $('#cw_hide_time_selector').is(':checked'),
				labelTemplate: this.legendMultiList,
				ignoreMissingData: ignoreMissingData,
				showRegression: showRegression,
				aggregationType: this.aggregationType.value()
			}
		};
		var qualifiersIds = [], qualifiers = [], length, values;
		var multigraphServiceQualifiersListCombo = $('#cw_multigraph_service_qualifiers_list').data('kendoSortedMultiSelect');
		values = multigraphServiceQualifiersListCombo.dataItems();
		length = values.length;
		for (var i = 0; i < length; i++) {
			qualifiersIds.push(values[i].id);
		}

		widgetObj.configuration.qualifiersList = qualifiersIds;
		widgetObj.configuration.qualifiers = qualifiers;
		return widgetObj;
	},
	/*
	 * Handler function for the click event of the Add service qualifier button (Metrics/Multigraph)
	 * @param {type} e
	 */
	onServiceQualifierAddClick: function (e) {
		var data = this.multigraphServiceListDataSource.data(), length = data.length, i, canAdd = true, values = [],
			record;
		if (length) {
			for (i = 0; i < length; i++) {
				if (data[i].id === this.multigraphFormObject.qualifierId) {
					canAdd = false;
					break;
				}
			}
		}
		if (canAdd) {
			record = {
				name: this.multigraphFormObject.qualifierName,
				id: this.multigraphFormObject.qualifierId
			};
			this.newServiceQualifierAdded = true;
			this.multigraphServiceListDataSource.add(record);
		}
	},
	/*
	 * Handler function for the click event of the Add system qualifier button (Metrics/Multigraph)
	 * @param {type} e
	 */
	onSystemQualifierAddClick: function (e) {
		var systemQualifierCombo = $("#cw_multigraph_system_qualifier_id").data('kendoComboBox');
		if (systemQualifierCombo && Utils.isGuid(systemQualifierCombo.value())) {
			var systemQualifierId = systemQualifierCombo.value();
			var systemQualifierName = systemQualifierCombo.text();
			this.multigraphFormObject.qualifierId = systemQualifierId;
			if (this.chooseSeletionTypeControl.getValue() === 'by_asset') {
				this.multigraphFormObject.qualifierName = this.multigraphAccounts.text() + '/' + this.monitorCombo.text() + '/' + this.instanceCombo.text() + '/' + systemQualifierName;
			} else {
				this.multigraphFormObject.qualifierName = this.multigraphAccounts.text() + '/' + this.monitorComboAlt.text() + '/' + this.instanceCombo.text() + '/' + systemQualifierName;
			}
			var record = this.systemQualifierCombo.dataSource.get(systemQualifierId);
			this.multigraphFormObject.configuration = {
				description: record.description,
				maxValue: record.maxValue,
				minValue: record.minValue,
				metricType: record.metricType,
				name: record.name,
				unitType: record.unitType,
				unitTypeSymbol: record.unitTypeSymbol
			};
			var data = this.multigraphSystemListDataSource.data(), length = data.length, i, canAdd = true, values = [],
				record;
			if (length) {
				for (i = 0; i < length; i++) {
					if (data[i].id === this.multigraphFormObject.qualifierId) {
						canAdd = false;
						break;
					}
				}
			}
			if (canAdd) {
				record = {
					name: this.multigraphFormObject.qualifierName,
					id: this.multigraphFormObject.qualifierId,
					accountId: this.multigraphAccounts.value(),
					assetId: this.chooseSeletionTypeControl.getValue() === 'by_asset' ? this.assetCombo.value() : this.assetComboAlt.value(),
					monitorId: this.chooseSeletionTypeControl.getValue() === 'by_asset' ? this.monitorCombo.value() : this.monitorComboAlt.value(),
					configuration: this.multigraphFormObject.configuration
				};
				this.newSystemQualifierAdded = true;
				this.multigraphSystemListDataSource.add(record);
			}
		} else {
			var status = $('#create_widget').find('.status');
			status.find('p').text(lang.widget.SELECT_QUALIFIER);

			status.find('p').addClass('error');
			status.slideDown().delay(4000).slideUp();
		}

	},
	isValid: function () {
		var valid = true;
		this.validationMessage = '';
		var multigraphServiceQualifiersListCombo = $('#cw_multigraph_service_qualifiers_list').data('kendoSortedMultiSelect');
		if (!multigraphServiceQualifiersListCombo.value().length) {
			valid = false;
			this.validationMessage = lang.widget.ADD_QUALIFIER;
		}
		if (this.errorFields && this.errorFields.length) {
			valid = false;
			if (this.errorFields.length > 1) {
				this.validationMessage = lang.widget.SELECT_MISSING_FIELDS;
			} else {
				this.validationMessage = lang.widget.SELECT_MISSING_FIELD;
			}
		}

		return {
			valid: valid,
			validationMessage: this.validationMessage
		};
	},
	/*
	 * Handler function which reset the below dropdowns     *
	 * @param list {Array} Array of below dropdowns
	 */
	resetDropdowns: function (list) {
		for (var i = 0; i < list.length; i++) {
			//list[i].value('');
			if (i >= 1) {
				list[i].enable(false);
			} else {
				list[i].enable(true);
			}
		}
	}
});
