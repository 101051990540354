import React from "react";
import TreeSelect, {TreeSelectProps, SelectValue} from 'antd/lib/tree-select'
import {observer} from "mobx-react";
import "./antTreeSelect.less"

export type AntTreeSelectValue = SelectValue;

export interface AntTreeSelectProps<VT extends AntTreeSelectValue> extends TreeSelectProps<VT> {
}

const AntTreeSelectUnobserved = <VT extends AntTreeSelectValue>(props: AntTreeSelectProps<VT>) => {
	return <TreeSelect {...props}/>;
}

export const AntTreeSelect = observer(AntTreeSelectUnobserved);
