import React from "react";
import {observer} from "mobx-react";
import {Grid} from "controls/grid/grid";
import {GridStore} from "controls/grid/gridStore";
import {IReactionDisposer, makeObservable, observable, reaction} from "mobx";
import {GridColumnConfig} from "controls/grid/gridColumnConfig";
import {CostGridItem, GetCostResources, getCostResources, getCostResourcesFilters} from "./GridWidgetApi";
import {formatNumber} from "tools/helpers/math";
import {RemoteDataProvider} from "controls/grid/remoteDataProvider";
import {GridSelectionType} from "controls/grid/gridConfiguration";
import {AutoReloadPlugin} from "controls/grid/plugins/autoReloadPlugin";
import {CostBudgetEvent, CostReportEvent} from "framework/entities/events";
import {getTimeSelection} from "./budget/timeSettings";
import {ExternalStateProvider} from "controls/grid/stateProviders";
import {deserialize} from "serializr";
import {GridState} from "controls/grid/gridState";
import {convertLegacyState} from "controls/grid/legacyGridState";
import {apiFetch, ApiResponse, PagedList} from 'framework/api';
import {SortDirection} from "areas/cost/models/sortDirection";
import {putItemFirst} from "../cloudServices/shared";
import {ApplicationState} from 'framework/applicationState';
import {sharedLocalization} from 'areas/services/localization';
import {costTranslates} from 'areas/cost/translations';
import {sharedLocalization as widgetsSharedLocalization} from "controls/designer/features/widgets/localization";
import {uniq} from "lodash";
import {Link} from "../../../../react/link";
import {CeeviewNavigator} from "../../../../../tools/ceeviewNavigator";
import {CostsRouter} from "../../../../../areas/cost/bundleDescription";
import {GridDataItem} from "../../../../grid/gridDataItem";
import {message, notification} from "../../../../react/ant/antAppWrapper";

const i = require('core/localization').translator({
	'Year estimate': {
		no: 'Årsestimat'
	}
}, sharedLocalization, widgetsSharedLocalization, costTranslates);

export let CostGridContext = React.createContext<CostGridStore>(null);
const monthColumns = ['budget', 'cost', 'listingPrice'];

export class CostGridProps {
	config: GetCostResources & {
		decimalsNumbers: string,
		displayAsThousands: boolean,
		budgetItemIds: string[],
		columns: any[],
		monthOrder: SortDirection,
		currentMonthFirst: boolean
	};
	persistedState: any;
	onFilter: (filter: GridStore<CostGridItem>) => void;
	navigator: CeeviewNavigator
}

export class CostGridStore {
	props: CostGridProps;
	gridStore: GridStore<CostGridItem>;
	reactions: IReactionDisposer[] = [];
	columns: GridColumnConfig<any>[] = [];
	columnsLoaded = false;


	constructor(props: CostGridProps) {
		this.props = props;
		makeObservable(this, {
			gridStore: observable,
			columns: observable
		});
		this.init();
		this.reactions.push(reaction(() => (this.gridStore?.dataProvider as RemoteDataProvider<CostGridItem>)?.onFilterChanged,
			() => this.props.onFilter(this.gridStore)));
	}

	getDefaultDataSource(defaults: {[x: string]: any} = {}) {
		const params = Object.assign({}, defaults, this.props.config)
		return getCostResources(params)
	}

	getFiltersConfiguration() {
		return getCostResourcesFilters({
			year: this.props.config.timeDisplay == 'annual' && this.props.config.timeDisplayValue || undefined,
			historicMonths: this.props.config.timeDisplay == 'monthly' && parseInt(this.props.config.timeDisplayValue) || undefined
		});
	}

	getAutoReloadPlugin(): any {
		const timeSelection = getTimeSelection(this.props.config.timeDisplay, this.props.config.timeDisplayValue);
		return new AutoReloadPlugin<CostGridItem>({
			getSubscriptions: (store) => {
				return [
					CostBudgetEvent.subscriptionByBudget(this.props.config.budgetId, this.props.config.budgetItemIds),
					CostReportEvent.subscription(this.props.config.modelId, timeSelection)
				];
			}
		});
	}

	onGridDataLoaded = (response: ApiResponse<PagedList<GridDataItem<CostGridItem>>>) => {
		if(this.columnsLoaded) {
			return;
		}
		const monthData = this.extractMonths(response.data);
		this.columns = this.getColumns(monthData);
		this.columnsLoaded = true;
		// here we force to update columns
		this.gridStore.state.validate(this.gridStore)
	}

	async getState() {
		if(this.props.persistedState?.grid) {
			return deserialize<GridState<CostGridItem>>(GridState, this.props.persistedState.grid)
		} else if(this.props.persistedState?.columns) {
			const filtersResult = await apiFetch(this.getFiltersConfiguration());
			if(!filtersResult.success) {
				return null
			}
			const filtersConfiguration = filtersResult.data;
			const gridData = await apiFetch(this.getDefaultDataSource(this.getCustomPayloadDefaults()));
			const monthData = this.extractMonths(gridData.data);
			const columns = this.getColumns(monthData);
			return convertLegacyState(columns, this.props.persistedState, filtersConfiguration, {})
		}
	}

	async init() {
		const state = await this.getState();

		// clear old unused data
		state && (state.customPayload = undefined)

		this.gridStore = new GridStore<CostGridItem>({
			columns: () => this.columns,
			stateProviders: [
				new ExternalStateProvider(state),
			],
			dataProvider: new RemoteDataProvider({
				dataSource: this.getDefaultDataSource(),
				filtersSource: this.getFiltersConfiguration(),
				onBatchLoaded: this.onGridDataLoaded
			}),
			plugins: [
				this.getAutoReloadPlugin(),
			],
			selection: GridSelectionType.None,
			onLoadErrorMessage: i('Costmodel/store/sheet is not found or has been deleted.')
		});
	}

	extractMonths(data: any) {
		if (!data) {
			return [];
		}
		const regex = /(cost|budget)\d+/gm;
		return uniq(Object.keys(data).filter(key => regex.exec(key) != null)
			.map(key => key.replace('budget', '').replace('cost', '')))
			.map(key => {
				const monthData = key.split('').reverse();
				const year = parseInt(monthData.splice(0, 4).reverse().join(''));
				const month = parseInt(monthData.reverse().join(''));
				return {year, month};
			});
	}

	getCustomPayloadDefaults() {
		return {
			currency: this.props.config.currency ?? 'NOK',
			decimals: this.props.config.decimalsNumbers ? parseInt(this.props.config.decimalsNumbers, 10) : 0
		}
	}

	getColumns(months: any) {
		let columns = [{
			title: i('Name'),
			field: 'name',
			align: 'left',
			renderer: (item) => <Link navigator={this.props.navigator} url={CostsRouter.details(this.props.config.budgetId, item.id, this.props.config.modelId)}>{item.name}</Link>,
			width: 200
		}, {
			title: i('Split'),
			field: 'split',
			align: 'center',
			renderer: (item) => item.split,
			width: 70
		}, {
			title: i('Budget'),
			field: 'budget',
			align: 'right',
			renderer: (item) => this.formatNumber(item.budget),
			width: 100
		}, {
			title: i('Cost'),
			field: 'cost',
			align: 'right',
			renderer: (item) => this.formatNumber(item.cost),
			width: 70
		}, {
			title: i('Listing price'),
			field: 'listingPrice',
			align: 'right',
			renderer: (item) => this.formatNumber(item.listingPrice),
			width: 100
		}, {
			title: i('Year estimate'),
			field: 'periodEstimate',
			align: 'right',
			renderer: (item) => this.formatNumber(item.periodEstimate),
			width: 130
		}, {
			title: i('Current month estimate'),
			field: 'currentEstimate',
			align: 'right',
			renderer: (item) => this.formatNumber(item.currentEstimate),
			width: 150
		}] as GridColumnConfig<any>[]

		columns = columns.filter(x => this.props.config.columns.includes(x.field) || this.props.config.columns.some(y => y.field == x.field));
		const show = monthColumns.reduce((out: any, key: any) => {
			out[key] = this.props.config.columns.some(c => [c, c.field].includes(key));
			return out;
		}, {});

		months = this.props.config.monthOrder === 'Desc' ? [...months].reverse() : [...months];
		const currentDate = new Date();
		const currentMonthIsPresented = months.some((m: any) => m.month === currentDate.getMonth() + 1 && m.year === currentDate.getFullYear());
		if (this.props.config.currentMonthFirst) {
			if (currentMonthIsPresented) {
				months = putItemFirst(months, (m: any) => m.month === currentDate.getMonth() + 1 && m.year === currentDate.getFullYear());
			}
		}
		for (let current of months) {
			let monthString = `${current.month}${current.year}`;
			const yearFieldNames = monthColumns.reduce((out: any, x) => {
				out[x] = `${x}${monthString}`;
				return out;
			}, {});

			const date = new Date(current.year, current.month - 1, 1);
			const monthNameData = [date.toLocaleString(ApplicationState.locale, {month: 'long'}), current.year];
			if (this.props.config.currentMonthFirst && current === months[0] && currentMonthIsPresented) {
				monthNameData.push(`(${i('current')})`);
			}
			const monthName = monthNameData.join(' ');

			const names = {
				budget: i('Budget'),
				cost: i('Cost'),
				listingPrice: i('Listing price')
			}

			monthColumns
				.filter(key => show[key])
				.forEach((key) => {
					const fieldName = yearFieldNames[key];
					columns.push({
						field: fieldName,
						title: names[key as keyof {budget: string, cost: string, listingPrice: string }] + ' ' + monthName,
						renderer: (item: any) => this.getValue(item, fieldName),
						align: 'right',
						width: 180
					});
				});
		}
		return columns;
	}


	formatNumber = (value: number | string): string => {
		return formatNumber(value, this.props.config.decimalsNumbers, this.props.config.displayAsThousands);
	}

	getValue = (item: any, field: string) => {
		const value = item[field]
		if (value) {
			return this.formatNumber(value);
		}
		return '';
	}

	destroy() {
		this.reactions.forEach(x => x());
		this.gridStore?.destroy();
	}
}

export const CostGrid = observer(class CostGridInner extends React.PureComponent<CostGridProps>{
		store: CostGridStore;

		constructor(props: CostGridProps) {
			super(props);
			this.store = new CostGridStore(props);
		}

		render() {
			return <CostGridContext.Provider value={this.store}>
				{this.store.gridStore && <Grid store={this.store.gridStore}/>}
			</CostGridContext.Provider>
		}

		componentWillUnmount() {
			this.store.destroy()
		}
	}
)
