import React, { useEffect, useState, useMemo } from "react";
import { Section } from "controls/react/layout/section";
import {AntSelect} from "controls/react/ant/antSelect";
import { FormEntry } from "controls/react/form";
import { AntInputNumber, NumberKeysPattern } from "controls/react/ant/antInputNumber";
import { sharedLocalization } from "controls/designer/features/widgets/localization";
import BudgetApi from './api';
import {observer} from "mobx-react";
import {TimePeriodType} from "controls/react/form/timePeriodType";

const i = require('core/localization').translator({
  "Time Display": {
    "no": "Tids visning",
    "en": "Time display"
  },
  "Annual": {
    "no": "Årlig"
  },
  "Monthly": {
    "no": "Månedlig"
  },
  "Values can be between 1-60": {
    "no": "Verdier kan være mellom 1-60"
  }
}, sharedLocalization);

export const getTimeSelection = (timeDisplay, timeDisplayValue) => {
	if (timeDisplay === 'annual') {
		if (timeDisplayValue === 'CURRENT_YEAR') {
			return {period: TimePeriodType.CurrentYear};
		} else {
			return {period: TimePeriodType.Year, year: timeDisplayValue};
		}
	} else {
		return {period: TimePeriodType.Month, months : timeDisplayValue};
	}
}

export const TimeSettings = observer(props => {
	const {configLink, onlyAnnual} = props;
	const timeDisplayLink = configLink.get('timeDisplay').changed((value) => {
		timeDisplayValueLink.update(value === 'monthly' ? 12 : 'CURRENT_YEAR');
		if(value === 'annual'){
			loadAvailableYears()
		}
	}).required();

	const [yearsList, setYearsList] = useState([]);

	const timeDisplayList = useMemo(() => {
		const values = [{name: i('Annual'), id: 'annual'}];
		if (!onlyAnnual) {
			values.push({name: i('Monthly'), id: 'monthly'});
		}
		return values;
	}, [onlyAnnual]);


	const timeDisplayValueLink = configLink.get('timeDisplayValue').check(i('Values can be between 1-60'), x => {
		if(timeDisplayLink.value === 'monthly'){
			return x >= 1 && x <= 60
		}
		return true;
	}).required();


	useEffect(() => {
		loadAvailableYears();
	}, [props.accountId, props.profileId, props.modelId])

	const loadAvailableYears = () => {
		if(props.accountId && props.profileId && props.modelId) {
			BudgetApi.getAvailableYears({
				accountId: props.accountId,
				profileId: props.profileId,
				modelId: props.modelId
			}).then(response => {
				if(response.success)
				{
					setYearsList(response.data);
					if(!timeDisplayValueLink.value)
						timeDisplayValueLink.update('CURRENT_YEAR')
				}
			});
		} else {
			setYearsList([{id: 'CURRENT_YEAR', name: 'Current Year'}])
		}
	}
	return (
		<Section appearance={"frame"} title={i('Time settings')} childrenPadding={true}>
			<FormEntry label={i('Time Display')}>
				<AntSelect {...timeDisplayLink.props} dataList={timeDisplayList} disabled={onlyAnnual}/>
			</FormEntry>
			{timeDisplayLink.value==='annual' && <FormEntry label={i('Year')} width={'fit'} valueLink={timeDisplayValueLink}>
				<AntSelect dataList={yearsList} placeholder={i('Select...')}/>
			</FormEntry>}
			{timeDisplayLink.value==='monthly' && <FormEntry label={i('Months')} width={'fit'} valueLink={timeDisplayValueLink}>
				<AntInputNumber pattern={NumberKeysPattern.PositiveInteger}/>
			</FormEntry>}
			{props.children}
		</Section>
	);
})
