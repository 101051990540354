import RRule from "rrule";
import ICAL from "ical.js";
import moment from "moment/moment";
import {getTzOffsetDiffs} from "../../../tools/dateTimeUtils";


export const parseStringValueToRrule = (value) => {
	return RRule[value.toUpperCase()]
}

export const parseStringValueToEnum = (enumType, value) => {
	return enumType[value.toUpperCase()]
}


export function updateStartTimeToCurrent(icalendar) {
	if (!icalendar) {
		return
	}
	const jcalData = ICAL.parse(icalendar);
	const vcalendar = new ICAL.Component(jcalData);
	const events = vcalendar.getAllSubcomponents('vevent').filter(x => x.getFirstPropertyValue('extendedprops')?.includes('"startIsCurrentTime":true'));
	events.forEach(ev => {
		const timeZone = ev.getFirstProperty('dtstart').getFirstParameter('tzid');
		const newStart = moment(moment().valueOf() - getTzOffsetDiffs(moment.tz.guess(), timeZone)*60000).format('YYYY-MM-DDTHH:mm:ss');
		ev.getFirstProperty('dtstart').setValue(newStart);
		const ext = JSON.parse(ev.getFirstPropertyValue('extendedprops'));
		delete ext.startIsCurrentTime;
		const extStr = JSON.stringify(ext);
		if (extStr == '{}') {
			ev.removeProperty('extendedprops');
		} else {
			ev.getFirstProperty('extendedprops').setValue(extStr);
		}
	});
	return vcalendar.toString();
}
