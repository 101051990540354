import './antPopover.less';

import React from 'react';
import {observer} from 'mobx-react';
import {Popover, PopoverProps} from 'antd';
import b_ from 'b_';
import classnames from 'classnames'

import {controlPopupZIndex} from "controls/react/ant/zIndexes";
import {useDefaultZIndex} from "framework/react-integration";

const b = b_.with('ant-popover-wrapper');

export interface AntPopoverProps extends PopoverProps {
	onModal?: boolean,
	overlayPadding?: boolean,
}

export const AntPopover = observer((props: AntPopoverProps) => {
	const {overlayClassName, overlayPadding, overlayStyle, ...rest} = props;

	const withoutPadding = !overlayPadding;
	const fullOverlayClassName = classnames(b('overlay', {'without-padding': withoutPadding}), props.overlayClassName);

	const overlayStyleWrapped = useDefaultZIndex(overlayStyle, controlPopupZIndex)

	return <Popover className={b()}
	                {...rest}
	                overlayClassName={fullOverlayClassName}
	                overlayStyle={overlayStyleWrapped}/>
})

type UsePopoverManuallyArgs = {
	timeout: number
}

export function usePopoverManually(args: UsePopoverManuallyArgs) {
	const [opened, setOpened] = React.useState(false)
	const [label, setLabel] = React.useState("")

	const timeoutHolder = React.useRef<number>()

	const open = React.useCallback((label: string) => {
		setOpened(true)
		setLabel(label)

		timeoutHolder.current && clearTimeout(timeoutHolder.current)

		timeoutHolder.current = window.setTimeout(() => {
			setOpened(false)
		}, args.timeout)

	}, [args.timeout])

	return {
		opened,
		open,
		label
	}
}
