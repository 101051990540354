import './sla.less';
import Utils from 'tools/utils';
import Application from 'core/application';
import PreferencesPanel from 'controls/preferencesPanel';
import Dialog from 'controls/dialog';
import GridMenu from 'controls/gridMenu';
import CustomNotification from 'controls/customNotification';
import UserPrefs from 'tools/userPrefs';
import State from 'tools/state';
import Settings from 'settings';
import Cookies from 'core/cookies';
import Renderer from 'tools/renderer';
import RemoteEventsManager from 'core/remoteEventsManager';
import EventsToolbar from 'controls/eventsToolbar';
import ErrorHandler from 'core/errorHandler';
import moment from 'moment';
import {SlaRouter} from './bundleDescription';
import MultiSelectGridFilter from 'controls/multiSelectGridFilter';
import {openTagsFormWindow} from "controls/tagsForm";
import {getSlaState} from 'controls/stateRenderer/slaState';
import {translator} from "core/localization";
import {topLeftCornerPosition} from 'controls/modalWindow'
import {ServicesRouter} from "../services/bundleDescription";
import {RecalculationWindow} from "../services/services-list/recalculationWindow";
import React from 'react';
import {createRoot} from 'react-dom/client';
import {SlaGridPayloadProvider} from "./slaGridPayloadProvider";
import {startSlaRecalculation} from "./api";
import {saveUserPrefs} from "../../tools/userPrefs";


const t = translator({
  "State": {
    "no": "Tilstand"
  },
  "Recalculate": {
    "no": "Rekalkuler"
  },
  "SLA failed to recalculate": {
    "no": "SLA ble ikke rekalkulert"
  },
  "SLA recalculated": {
    "no": "SLA rekalkulert"
  },
  "Select the time you want to recalculate the SLA from": {
    "no": "Velg tid du vil rekalkulere SLA fra"
  },
  "Exclude periode": {
    "no": "Eksluderingsperiode"
  },
  "For SLA with different timezone we do not convert time from this form.": {
    "no": "For SLA med forskjellig tidsone vi konverterer ikke tiden fra denne menyen."
  },
  "In this form we use system time and do not convert the time to SLA timezone if it differ. To convert enter SLA and create time from Exlude period tab.": {
    "no": "I denne menyen bruker vi systemtid og konverterer ikke til SLA tidsone om den er forskjellig. For konvertert tid må det gjøres per SLA i SLA/Eklsuderingstid fanen.",
    "en": "In this form we use system time and do not convert time to SLA timezone if it differ. To convert enter SLA and create time from exlude period tab."
  }
});

export default class Sla {
	recalculateDestroyer = undefined

	async init() {
		this.hasEvents = true;
		this.subscriberId = Utils.guid();
		this.subscribe()

		this.getTags();
		this.showGridView = true;
		this.actionNotification = new CustomNotification(
			{
				appendToElement: '.cw_section_content',
			});
		this.excludeNotification = new CustomNotification(
			{
				appendToElement: '#cw_sla_exclude_period_window',
				style: 'top: 100px'
			});

		/*if (!this.isView) {
			$('#nav li').removeClass('current').parent().find(
				'#slas').addClass('current');
		}*/
		State.mainApp.contextMenu.setActionButton('SLA');
		this.removeListeners();
		this.attachListeners();
		// removes loading mask
		this.userPref = await this.loadUserPreferences();
		this.includeSubaccounts = this.includeSubaccounts !== undefined ? this.includeSubaccounts : State.includeSubaccounts;

		this.initKendoComponents();
		this.initialized({title: t('SLA')})
	}
	async getTags() {
		let tags = await Utils.getAccountTags();
		this.filterOptions = {
			tags: tags
		};
	}
	/**
	 * Removes listeners
	 */
	removeListeners() {
		$('#cw_slas_list').off();
		$('.cw_view_button').off();
		$('#cw_sladetails_exclude_save').off();
		$('#cw_sladetails_exclude_close').off();
	}
	/**
	 * Attaches listeners
	 */
	attachListeners() {
		if (State.mainApp.session.hasRole('SLA_READ')) {
			$('#cw_slas_list').on('click', '.js_sla_details',
				$.proxy(this.onSlaDetails, this));
			$('#cw_slas_list').on('click', '.status_icon',
				$.proxy(this.onSlaDetails, this));
		}
		$('#cw_slas_list').on('click', '.cw_grid_check',
			$.proxy(this.onSlaCheck, this));
		$('#cw_slas_list').on('click', '.cw_grid_check_all',
			$.proxy(this.onSlaCheckAll, this));
	}
	/**
	 * Initializes kendo components
	 */
	initKendoComponents() {
		var slasFilter, slasSort, i, length, periodTag, url, statusIndex = 0;
		var filterMessages = lang.grid.filter;
		var defaultFsView = UserPrefs
			.get('defaultFsView');
		this.gridMessages = {
			isTrue: '<span class="glyphicons service_state ok-sign"></span>',
			isFalse: '<span class="glyphicons service_state remove-sign"></span>',
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
		var eventStatus = JSON.parse(UserPrefs
				.get('eventsStatus'))
			|| {
				startFrom: 0,
				playing: true
			};
		this.eventsToolbar = new EventsToolbar(
			{
				renderTo: $('#cw_slas_events_toolbar'),
				callBackFn: $.proxy(this.onEventsTimeout,
					this),
				subscriberId: this.subscriberId,
				startFrom: eventStatus.startFrom,
				playing: eventStatus.playing
			});
		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			statusIndex: {
				hidden: false,
				width: 55
			},
			name: {
				hidden: false,
				width: 240
			},
			accountName: {
				hidden: !this.includeSubaccounts,
				width: 150
			},
			serviceName: {
				hidden: false,
				width: 150
			},
			periodTag: {
				hidden: false,
				width: 150
			},
			tags: {
				hidden: false,
				width: 150
			},
			compliancePercentage: {
				hidden: false,
				width: 150
			},
			actualCompliance: {
				hidden: false,
				width: 150
			},
			responsibleTeamName: {
				hidden: false,
				width: 150
			},
			downTime: {
				hidden: false,
				width: 200
			},
			maxAllowedDownTime: {
				hidden: false,
				width: 200
			},
			description: {
				hidden: false
			}
		};

		let defaultExternalPreferences = {
			filter: [],
			sort: [{
				field: 'name',
				order: 'asc'
			}]
		};

		let accountId = this.accountId || Cookies.CeesoftCurrentAccountId;
		if (this.indicator && this.serviceId) {
			url = Settings.serverPath + 'accounts/' + accountId + '/services/' + this.serviceId + '/slas/indicators/'	+ this.indicator + '?includeSubaccounts=' + this.includeSubaccounts.toString();
			this.preferences = defaultExternalPreferences;
		} else if (this.indicator) {
			url = Settings.serverPath + 'accounts/' + accountId + '/slas/indicators/' + this.indicator + '?includeSubaccounts=' + this.includeSubaccounts.toString();
			this.preferences = defaultExternalPreferences;
		} else if (this.serviceId) {
			url = Settings.serverPath + 'accounts/' + accountId + '/services/' + this.serviceId + '/slas/calculations?includeSubaccounts=' + this.includeSubaccounts.toString();
			this.preferences = defaultExternalPreferences;
		} else {
			url = Settings.serverPath + 'accounts/'	+ accountId + '/slas/calculations?includeSubaccounts=' + this.includeSubaccounts.toString();
		}

		if(this.tags?.length) {
			this.tags.forEach(tag => url+= '&tag=' + encodeURIComponent(tag));
			url+='&showUntagged=' + this.showUntagged || false;
		}

		if (Utils.isGuid(defaultFsView)) {
			var pref = JSON.parse(UserPrefs.get(defaultFsView));
			if (pref) {
				slasSort = pref.sort;
				slasFilter = pref.filter;
				this.slasColumns = pref.columns;
			} else {
				slasSort = [];
				slasFilter = [];
			}

		} else {
			if (UserPrefs.get('slasSort')) {
				slasSort = JSON.parse(UserPrefs.get('slasSort'));
			} else {
				slasSort = [{
					field: 'statusIndex',
					dir: 'desc',
					compare: null
				}];
			}
			if (UserPrefs.get('slasFilter')) {
				slasFilter = JSON.parse(UserPrefs.get('slasFilter'));
			} else {
				slasFilter = [];
			}
		}
		var slasColumns = this.slasColumns || JSON.parse(UserPrefs.get('slasColumns')) || this.defaultColumns;
		slasColumns = Utils.completeColumns(slasColumns, this.defaultColumns);
		if (this.preferences) {
			slasSort = this.preferences.sort || slasSort;
			slasFilter = this.preferences.filter || slasFilter;
			this.columns = this.defaultColumns;
		}
		if (slasFilter && slasFilter.filters
			&& slasFilter.filters.length) {
			length = slasFilter.filters.length;
			for (i = 0; i < length; i++) {
				if (slasFilter.filters[i].field === 'periodTag') {
					periodTag = slasFilter.filters[i].value;
				}
				if (slasFilter.filters[i].field === 'statusIndex') {
					statusIndex = slasFilter.filters[i].value;
				}
			}
			if (periodTag) {
				switch (periodTag) {
					case 'Day':
						$('#cw_slas_period_selector').find(
							'input[value=DAY]').parent()
							.addClass('is_selected');
						break;
					case 'Week':
						$('#cw_slas_period_selector').find(
							'input[value=WEEK]').parent()
							.addClass('is_selected');
						break;
					case 'Month':
						$('#cw_slas_period_selector').find(
							'input[value=MONTH]').parent()
							.addClass('is_selected');
						break;
				}
			}
		} else {
			$('#cw_slas_period_selector').find(
				'input[value=ALL]').parent().addClass(
				'is_selected');
		}


		for (let i = 0; i < slasFilter?.filters?.length; i++) {
			let current = slasFilter.filters[i];
			if (current && current.filters) {
				for (let j = 0; j < current.filters.length; j++) {
					if (current.filters[j].isTag) {
						let op = current.filters[j].op;
						current.filters[j].operator = function(tags, value) {
							if (op === 'eq') {
								if (tags[i] === value)
									return true;
							}
							if (op === 'neq'){
								let commonTags = tags.filter(x => values.includes(x));
								if (!commonTags.length) {
									return true;
								}
							}
						}
					}
				}
			}
		}


		this.slasDataSource = new kendo.ceeview.DataSource(
			{
				transport: {
					read: {
						url: url,
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				schema: {
					model: {
						id: 'id',
						fields: {
							id: {
								type: 'string'
							},
							actualCompliance: {
								type: 'number'
							},
							name: {
								type: 'string'
							},
							compliancePercentage: {
								type: 'number'
							},
							statusIndex: {
								type: 'number'
							},
							downTime: {
								type: 'number'
							},
							maxAllowedDownTime: {
								type: 'number'
							}
						}
					},
					parse: function (result) {
						for (let i = 0; i < result.length; i++) {
							if (result[i].tags) {
								result[i].tags = result[i].tags.join(', ');
							}
						}
						return result;
					}
				},
				sort: slasSort,
				filter: this.accountName ? {
					field: 'accountName',
					operator: 'equals',
					value: this.accountName
				} : slasFilter,
				error: ErrorHandler.kendoServerError
			});
		this.hasReadRole = State.mainApp.session.hasRole('SLA_READ');
		this.hasServiceReadRole = State.mainApp.session
			.hasRole('SERVICE_READ');
		this.grid = $('#cw_slas_list')
			.kendoCustomGrid(
				{
					dataSource: this.slasDataSource,
					resizable: true,
					reorderable: true,
					selectable: 'single',
					sortable: {
						mode: "multiple",
						allowUnsort: true
					},
					filterable: {
						extra: false,
						operators: {
							string: {
								startswith: filterMessages.STARTS_WITH,
								neq: filterMessages.NEQ,
								eq: filterMessages.EQ
							},
							number: {
								eq: filterMessages.EQ,
								neq: filterMessages.NEQ,
								gte: filterMessages.GTE,
								gt: filterMessages.GT,
								lte: filterMessages.LTE,
								lt: filterMessages.LT
							}
						},
						messages: this.gridMessages
					},
					filteredContent: this.indicator,
					eraseFilteredContent: this.eraseFilteredContent,
					scope: this,
					pageable: false,
					columns: Utils.rearrangeColumns([
							{
								field: 'id',
								title: lang.SELECTOR,
								sortable: false,
								filterable: false,
								menu: false,
								hidden: slasColumns.id.hidden,
								width: slasColumns.id.width,
								template: '<input type="checkbox" class="cw_grid_check" data-id="${id}" />',
								headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
								attributes: {
									'class': 'text_center'
								},
								headerAttributes: {
									'class': 'text_center'
								}
							}, {
								field: 'statusIndex',
								title: t("State"),
								sortable: true,
								hidden: slasColumns.statusIndex.hidden,
								width: slasColumns.statusIndex.width,
								//template: '#= Renderer.slaStatus(indicator)#',
								template: item => getSlaState(item.indicator),
								attributes: {
									'class': 'text_center'
								},
								filterable: {
									ui: $
										.proxy(
											this.getFilterableForStatus,
											{
												scope: this,
												field: 'statusIndex',
												filters: slasFilter
											}),
									messages: this.gridMessages
								},
								valueRenderer: Renderer.slaIndicatorStatus

							}, {
								field: 'name',
								title: lang.NAME,
								sortable: true,
								filterable: true,
								template: item => {
									if (!this.hasReadRole)
										return item.name;

									return `<a class="cw_grid_link" href="#${SlaRouter.details(item.id)}">${item.name}</a>`;
								},
								hidden: slasColumns.name.hidden,
								width: slasColumns.name.width,
								attributes: {
									'class': 'tooltip ellipsis to_expand'
								}
							}, {
								field: 'accountName',
								title: lang.ACCOUNT,
								sortable: true,
								filterable: {
									ui: $
										.proxy(
											function (element) {
												return Renderer.filterGridByOwnElementDS
													.call(
														this,
														element,
														'accountName');
											},
											this),
									messages: this.gridMessages,
									extra: false,
									operators: {
										string: {
											neq: filterMessages.NEQ,
											eq: filterMessages.EQ
										}
									}
								},
								hidden: !this.includeSubaccounts
								|| slasColumns.accountName.height,
								width: slasColumns.accountName.width,
								attributes: {
									'class': 'tooltip ellipsis to_expand'
								}
							}, {
								field: 'serviceName',
								title: lang.SERVICE,
								sortable: true,
								filterable: true,
								template: (row) => {
									if(this.hasServiceReadRole) {
										return `<a href="#${ServicesRouter.details(row.serviceId)}" class="cw_grid_link ellipsis to_expand">${row.serviceName}</a>`
									}
									return row.serviceName
								},
								hidden: slasColumns.serviceName.hidden,
								width: slasColumns.serviceName.width,
								attributes: {
									'class': 'tooltip ellipsis to_expand'
								}
							}, {
								field: 'periodTag',
								title: lang.service.PERIOD,
								sortable: true,
								template: '<span class="pointer js_sla_details ellipsis to_expand">#= periodTag || "" #</span>',
								filterable: {
									ui: function (element) {
										element
											.kendoDropDownList({
												dataSource: [
													{
														text: lang.DAY,
														value: lang.DAY
													},
													{
														text: lang.WEEK,
														value: lang.WEEK
													},
													{
														text: lang.MONTH,
														value: lang.MONTH
													}],
												dataTextField: 'text',
												dataValueField: 'value',
												change: Utils.onFilterDropDownChange,
												optionLabel: lang.grid.FILTER_SELECT_VALUE
											});
									}
								},
								attributes: {
									'class': 'tooltip ellipsis to_expand'
								},
								hidden: slasColumns.periodTag.hidden,
								width: slasColumns.periodTag.width
							}, {
								field: 'tags',
								title: lang.TAGS,
								sortable: true,
								filterable: {
									ui: $.proxy(function (element) {
										let multiselect = new MultiSelectGridFilter({
											element: element,
											field: 'tags',
											grid: this.grid,
											itemTemplate: '#=data.text#',
											tagTemplate: '#=data.text#',
											dataSource: this.filterOptions.tags,
											static: true
										});
									}, this),
									messages: this.gridMessages,
									extra: false,
									operators: {
										string: {
											eq: filterMessages.EQ,
											neq: filterMessages.NEQ
										}
									}
								},
								hidden: slasColumns.tags.hidden,
								width: slasColumns.tags.width,
								attributes: {
									'class': 'tooltip ellipsis to_expand'
								}
							}, {
								field: 'compliancePercentage',
								title: lang.service.COMPLIANCE_GOAL,
								sortable: true,
								filterable: true,
								template: '<span class="pointer js_sla_details ellipsis to_expand">#= compliancePercentage === null ? "" : compliancePercentage  #</span>',
								attributes: {
									'class': 'text_center tooltip ellipsis to_expand'
								},
								hidden: slasColumns.compliancePercentage.hidden,
								width: slasColumns.compliancePercentage.width
							}, {
								field: 'actualCompliance',
								title: lang.service.ACTUAL_COMPLIANCE,
								sortable: true,
								filterable: true,
								template: this.hasReadRole ? '<span class="pointer js_sla_details ellipsis to_expand">#= actualCompliance === null ? "" : actualCompliance  #</span>'
									: '#= actualCompliance === null ? "" : actualCompliance  #',
								attributes: {
									'class': 'text_center tooltip ellipsis to_expand'
								},
								hidden: slasColumns.actualCompliance.hidden,
								width: slasColumns.actualCompliance.width
							}, {
								field: 'responsibleTeamName',
								title: lang.service.RESPONSIBLE_TEAM,
								filterable: true,
								sortable: true,
								hidden: slasColumns.responsibleTeamName.hidden,
								width: slasColumns.responsibleTeamName.width,
								attributes: {
									'class': 'tooltip ellipsis to_expand'
								}
							}, {
								field: 'downTime',
								title: lang.slas.DOWNTIME,
								filterable: {
									ui: function (element) {
										element
											.kendoDropDownList({
												dataSource: [
													{
														text: '1 '
														+ lang.HOUR,
														value: 3600000
													},
													{
														text: '5 '
														+ lang.HOURS,
														value: 18000000
													},
													{
														text: '10 '
														+ lang.HOURS,
														value: 36000000
													},
													{
														text: '1 '
														+ lang.DAY,
														value: 84600000
													}],
												dataTextField: 'text',
												dataValueField: 'value',
												optionLabel: lang.grid.FILTER_SELECT_VALUE
											});
									}
								},
								sortable: true,
								template: '#=Renderer.duration(downTime) !== "<span>N/A</span>" ? Renderer.duration(downTime) : "'
								+ lang.slas.NO_DOWNTIME
								+ '"#',
								hidden: slasColumns.downTime.hidden,
								width: slasColumns.downTime.width,
								attributes: {
									'class': 'tooltip ellipsis to_expand'
								}
							}, {
								field: 'maxAllowedDownTime',
								title: lang.slas.MAX_DOWNTIME,
								filterable: {
									ui: function (element) {
										element
											.kendoDropDownList({
												dataSource: [
													{
														text: '1 '
														+ lang.HOUR,
														value: 3600000
													},
													{
														text: '5 '
														+ lang.HOURS,
														value: 18000000
													},
													{
														text: '10 '
														+ lang.HOURS,
														value: 36000000
													},
													{
														text: '1 '
														+ lang.DAY,
														value: 84600000
													}],
												dataTextField: 'text',
												dataValueField: 'value',
												optionLabel: lang.grid.FILTER_SELECT_VALUE
											});
									}
								},
								sortable: true,
								template: '#=Renderer.duration(maxAllowedDownTime)#',
								hidden: slasColumns.maxAllowedDownTime.hidden,
								width: slasColumns.maxAllowedDownTime.width,
								attributes: {
									'class': 'tooltip ellipsis to_expand'
								}
							}, {
								field: 'description',
								title: lang.DESCRIPTION,
								sortable: true,
								filterable: true,
								attributes: {
									'class': 'tooltip ellipsis to_expand'
								},
								hidden: slasColumns.description.hidden,
								width: slasColumns.description.width
							}],
						slasColumns),
					columnMenu: true,
					change: $.proxy(this.onExpand,
						this),
					dataBound: function () {
						var tooltip = $('#cw_slas_list').find('.k-grid-content').data('kendoTooltip');
						if (tooltip) {
							tooltip.destroy();
						}
						$('#cw_slas_list').find('.k-grid-content').kendoTooltip({
							filter: "td.tooltip:not(:empty)",
							content: function (event) {
								var returnValue;
								var target = event.target;
								var span = target.find('span');
								var seconds = parseInt($(span).attr('data-s'));
								var milliseconds = parseInt($(span).attr('data-ms'));
								if (!seconds && milliseconds) {
									returnValue = milliseconds + ' ' + lang.SHORT_MILISECOND;
								} else {
									returnValue = target.text().split("\n").join("<br />")
								}
								return returnValue;
							}
						});
						if (!State.mainApp.session
							.hasRole('SLA_READ')) {
							$('.pointer')
								.attr('style',
									'cursor: default !important');
						}
					}
				}).data('kendoCustomGrid')
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.adjustSectionHeight();
		this.grid.thead.find(
			"[data-field='id']>.k-header-column-menu")
			.remove();
		this.gridMenu = new GridMenu({
			renderTo: 'cw_slas_grid_menu',
			items: [{
				id: 'cw_slas_create',
				icon: 'plus-sign',
				text: lang.CREATE,
				fn: this.onCreateSla,
				scope: this,
				disabled: false,
				role: 'SLA_CREATE'
			}, {
				id: 'cw_slas_delete',
				icon: 'bin',
				text: lang.DELETE,
				fn: this.onDeleteSla,
				scope: this,
				disabled: true,
				role: 'SLA_DELETE'
			}, {
				id: 'cw_set_tags',
				icon: 'tag',
				text: lang.SET_TAGS,
				fn: $.proxy(function () {
					return openTagsFormWindow('sla', this);
				}, this),
				scope: this,
				disabled: true,
				role: 'SLA_UPDATE'
			}, {
				id: 'cw_create_exclude_period',
				icon: 'plus-sign',
				text: lang.slas.CREATE_EXCLUDE_PERIOD,
				fn: this.onExcludePeriodCreate,
				scope: this,
				disabled: true,
				role: 'SLA_UPDATE'
			}, {
				id: 'cw_recalculate',
				icon: 'refresh',
				text: t('Recalculate'),
				fn: this.onRecalculate,
				disabled: true,
				scope: this
			}]
		});
		var searchValue = UserPrefs
			.get('defaultFsView') ? '' : (UserPrefs
			.get('slasSearchPhrase') || '');
		this.gridFilterPanel = new PreferencesPanel(
			{
				renderTo: 'cw_slas_filters',
				dontLoadDefault: this.accountName ? true
					: this.preferences ? true : false,
				grid: this.grid,
				modulePrefName: 'SLAs',
				defaultPrefViewKey: 'defaultFsView',
				prefListKey: 'slasFSViews',
				userPref: this.userPref?.data ?? [],
				onRemove: $.proxy(
					this.saveUserPreferences, this),
				searchFields: ['name', 'serviceName',
					'periodTag', 'description',
					'accountName'],
				searchValue: searchValue,
				defaultColumns: this.defaultColumns,
				activateResetFilterIcon: this.indicator,
				eraseFilteredContent: this.eraseFilteredContent,
				scope: this
			});
	}
	/**
	 * Handler function for the selection of one service log
	 * grid row
	 */
	onExpand(e) {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#cw_slas_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	}

	onRecalculate() {
		const container = $('<div></div>');
		$('#content_area').append(container);
		const gridDataProvider = new SlaGridPayloadProvider(this.getSelectedSlas());
		this.recalculateWindowRoot = createRoot(container.get(0))
		this.recalculateWindowRoot.render(
			<RecalculationWindow
				onCancel={() => this.onRecalculationClose()}
				 gridStore={gridDataProvider}
				 startRecalculationRequest={this.startRecalculation}
				 recalculatedLabel={t('SLA recalculated')}
				 recalculationFailedLabel={t('SLA failed to recalculate')}
				 infoLabel={t('Select the time you want to recalculate the SLA from')}
			/>
		);
		this.recalculateDestroyer = () => {
			this.recalculateWindowRoot.unmount();
			$('#content_area').remove(container);
		};
	}

	onRecalculationClose() {
		this.recalculateDestroyer?.();
	}

	getSelectedSlas() {
		return Array.from($('.cw_grid_check:checked').map((i, x) => $(x).data('id')));
	}

	startRecalculation(store, additionalData) {
		const [startTime] = additionalData.getPayload();
		return startSlaRecalculation(store, startTime);
	}

	eraseFilteredContent(scope) {
		if (scope.grid.options.filteredContent) {
			let accountId = this.accountId || Cookies.CeesoftCurrentAccountId;
			let url;
			if (this.serviceId) {
				url = Settings.serverPath + 'accounts/' + accountId + '/services/' + this.serviceId + '/slas/calculations?includeSubaccounts=' + State.includeSubaccounts.toString();
			} else {
				url = Settings.serverPath + 'accounts/'	+ accountId + '/slas/calculations?includeSubaccounts=' + State.includeSubaccounts.toString();
			}
			scope.grid.options.filteredContent = null;
			scope.grid.dataSource.transport.options.read.url = url;
			scope.grid.dataSource.read();
			$('.cw_new_tag_indicator').remove();
		}
	}

	reloadByTags() {
		this.grid.dataSource.read();
	}
	/**
	 * Handler for the sla checkbox click
	 *
	 * @param {Object}
	 *            e The click event
	 */
	onSlaCheck(e) {
		var checkboxes = $('.cw_grid_check');
		var count = 0;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				count++;
			}
		}
		if (count) {
			this.gridMenu.enableItem('cw_slas_delete');
			this.gridMenu.enableItem('cw_set_tags');
			this.gridMenu.enableItem('cw_create_exclude_period');
			this.gridMenu.enableItem('cw_recalculate');
		} else {
			this.gridMenu.disableItem('cw_slas_delete');
			this.gridMenu.disableItem('cw_set_tags');
			this.gridMenu.disableItem('cw_create_exclude_period');
			this.gridMenu.disableItem('cw_recalculate');
		}
	}
	/**
	 * Handler for the check all checkbox click
	 *
	 * @param {Object}
	 *            e The click event
	 */
	onSlaCheckAll(e) {
		var checkAll = $('.cw_grid_check_all').is(':checked');
		var checkboxes = $('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		if (checkAll && checkboxes.length) {
			this.gridMenu.enableItem('cw_slas_delete');
			this.gridMenu.enableItem('cw_set_tags');
			this.gridMenu.enableItem('cw_create_exclude_period');
			this.gridMenu.enableItem('cw_recalculate');
		} else {
			this.gridMenu.disableItem('cw_slas_delete');
			this.gridMenu.disableItem('cw_set_tags');
			this.gridMenu.disableItem('cw_create_exclude_period');
			this.gridMenu.disableItem('cw_recalculate');
		}
	}
	onExcludePeriodClose() {
		$('#cw_sla_exclude_period_window').data("kendoWindow").destroy();
		$('#cw_slas_list').find('.cw_grid_check').show().prop('checked', false);
		this.gridMenu.disableItem('cw_create_exclude_period');
		this.gridMenu.enableItem('cw_slas_create');
	}
	onExcludePeriodSave() {
		var fromDateVal = $('#cw_sladetails_exclude_from').val();
		var	toDateVal = $('#cw_sladetails_exclude_to').val();
		var	fromStr, toStr, excludePeriodData, length, currentFrom, currentTo;
		var addPeriod = true;
		var updatePeriod = true;
		if (fromDateVal && toDateVal) {
			let fromDate = new Date(fromDateVal);
			let fromHour = fromDate.getHours();
			if (fromHour === 2) {
				this.excludeNotification.setOptions({
					message: lang.messages.CALENDAR_2_HOUR_BUG,
					status: 'error'
				}).show();
				$('#cw_sladetails_exclude_from').addClass('invalid');
			} else {
				fromStr = Utils.customDateToString($('#cw_sladetails_exclude_from').data('kendoDateTimePicker').value(), 'YYYYMMDDHHmmss');
				toStr = Utils.customDateToString($('#cw_sladetails_exclude_to').data('kendoDateTimePicker').value(), 'YYYYMMDDHHmmss');
				currentFrom = moment(new Date(fromDateVal).getTime()).format('YYYYMMDDHHmmss');
				currentTo = moment(new Date(toDateVal).getTime()).format('YYYYMMDDHHmmss');
				excludePeriodData = this.grid.dataSource.data();
				length = excludePeriodData.length;

				if (this.excludePeriodMode === 'edit') {
					//this.excludePeriodId
					var item;
					for (var i = 0; i < length; i++) {
						item = excludePeriodData[i];
						if (moment(item.fromTime).utc().format('YYYYMMDDHHmmss') === currentFrom && moment(item.toTime).utc().format('YYYYMMDDHHmmss') === currentTo) {
							updatePeriod = false;
							break;
						}
					}
				} else { //create
					var item;
					for (var i = 0; i < length; i++) {
						item = excludePeriodData[i];
						if (moment(item.fromTime).utc().format('YYYYMMDDHHmmss') === currentFrom && moment(item.toTime).utc().format('YYYYMMDDHHmmss') === currentTo) {
							addPeriod = false;
							break;
						}
					}
				}

				if (!addPeriod && !updatePeriod) {
					$('#cw_sladetails_exclude_from').addClass('invalid');
					$('#cw_sladetails_exclude_to').addClass('invalid');
				} else {
					$('#cw_sladetails_exclude_from').removeClass('invalid');
					$('#cw_sladetails_exclude_to').removeClass('invalid');
					var slaIds = [], uid, item;
					var checkboxes = $('#cw_slas_list .cw_grid_check:checked');
					for (var i = 0; i < checkboxes.length; i++) {
						uid = $(checkboxes[i]).closest('tr').data('uid');
						item = this.grid.dataSource.getByUid(uid);
						slaIds.push(item.id);
					}
					var description = $('#cw_sladetails_exclude_description textarea').val().trim();
					var exclude = {
						id: null,
						reason: description,
						fromTime: fromStr,
						toTime: toStr,
						timeZone: Cookies.CeesoftTimezone,
						slaIds: slaIds
					};
					var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/slas/excludePeriods';
					Utils.ajax(url, 'POST', JSON.stringify(exclude), $.proxy(function (result) {
						$('#cw_sladetails_exclude_description textarea').val('');
						if (result.success) {
							// NOTE: this updates both the grid datasource and the list of exclude periods
							this.grid.dataSource.read();
							this.onExcludePeriodClose();
						} else {
							Utils.showInfo(lang.ALERT, result.message, result.details);
						}
					}, this));
				}
			}
		} else {
			if (!fromDateVal) {
				$('#cw_sladetails_exclude_from').addClass('invalid');
			}
			if (!toDateVal) {
				$('#cw_sladetails_exclude_to').addClass('invalid');
			}
		}
	}
	/**
	 * Handler function for delete sla button
	 */
	onDeleteSla() {
		var checkboxes = $('.cw_grid_check');
		var url = Settings.serverPath + 'accounts/'	+ Cookies.CeesoftCurrentAccountId + '/slas/delete';
		var slas = [], checked = 0;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				slas.push($(checkboxes[i]).data('id'));
				checked++;
			}
		}
		if (slas.length) {
			var dialog = new Dialog(
				{
					title: lang.INFO,
					msg: checked > 1 ? lang.slas.messages.SLAS_REMOVE_CONFIRMATION
						: lang.slas.messages.SLA_REMOVE_CONFIRMATION,
					icon: 'ERROR',
					actionText: 'DELETE',
					position: topLeftCornerPosition,
					fn: $.proxy(function (value, button) {
						if (button === 'ok') {
							this.gridMenu.disableItem('cw_slas_delete');
							Utils.ajax(url, 'POST',	JSON.stringify(slas), $.proxy(function (result) {
								if (result.success) {
									this.actionNotification.setOptions({
										message: lang.service.messages.SLA_DELETED,
										status: 'success'
										}).show();
									// reload data source
									this.slasDataSource.read();
								} else {
									Utils.showInfo(lang.ALERT, result.message, null, this.grid);
								}
							},this));
						}
					}, this),
					deleteFromGrid: this.grid
				});
			dialog.show();
		}
	}
	/**
	 * Handler function for delete sla button
	 */
	onCreateSla(e) {
		State.mainApp.navigate(SlaRouter.createNew());
	}
	/**
	 * Handler for the sla edit button
	 */
	onSlaDetailsItem(e) {
		var checkboxes = $('.cw_grid_check'), slas = [], slaId, rowId, slaItem, config;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				slas.push($(checkboxes[i]).data('id'));
			}
		}
		if (slas.length === 1) {
			slaId = slas[0];
			rowId = $('input[data-id=' + slaId + ']').closest(
				'tr').attr('data-uid');
			slaItem = $('#cw_slas_list')
				.data('kendoCustomGrid').dataSource
				.getByUid(rowId);
			State.mainApp.navigate(SlaRouter.details(slaItem.id));
		}
	}
	/**
	 * Handler function for the click event on the sla name
	 *
	 * @param {Object}
	 *            e
	 */
	onSlaDetails(e) {
		e.preventDefault();

		const rowId = $(e.currentTarget).closest('tr')
				.attr('data-uid');

		const slaItem = $('#cw_slas_list').data('kendoCustomGrid')
				.dataSource.getByUid(rowId);

		State.mainApp.navigate(SlaRouter.details(slaItem.id));
		e.stopPropagation();
	}
	getFilterableForStatus(element) {
		const statusIndexFilters = (this.filters?.filters ?? []).filter(x => x.field == this.field).map(x => x.value);
		var menu = $(element).parent();
		menu.find(".k-filter-help-text").text(
			lang.grid.filter.SHOW_ITEMS);
		// menu.find("[data-role=dropdownlist]").remove();
		element.removeAttr("data-bind");
		var operatorEl = menu.find("[data-role=dropdownlist]");
		operatorEl.find('option[value="gte"]').remove();
		operatorEl.find('option[value="gt"]').remove();
		operatorEl.find('option[value="lte"]').remove();
		operatorEl.find('option[value="lt"]').remove();
		operatorEl.attr('data-index', '0');
		var multiSelect = element
			.kendoSortedMultiSelect(
				{
					dataSource: new kendo.data.DataSource({
						data: [{
							text: lang.service.BREACHED,
							icon: 'sla-state-icon circle-arrow-down',
							value: 2
						}, {
							text: lang.WARNING,
							icon: 'sla-state-icon circle-arrow-right',
							value: 1
						}, {
							text: lang.service.IN_COMPLIANCE,
							icon: 'sla-state-icon circle-arrow-top',
							value: 0
						}, {
							text: lang.summary.NO_SLA_AVAILABLE,
							icon: 'sla-state-icon cw_status_indicator cw_status_widget_color cw_color6',
							value: -1
						}], serverSorting: true
					}),
					dataTextField: 'text',
					dataValueField: 'value',
					optionLabel: lang.grid.FILTER_SELECT_VALUE,
					itemTemplate: '<span class="glyphicons status_icon pointer #=data.icon#"></span><span>${data.text}</span>',
					tagTemplate: '<span class="glyphicons status_icon pointer #=data.icon#"></span><span>${data.text}</span>'
				}).data('kendoSortedMultiSelect');

		menu.find('[type=submit]').on('click', {
			widget: multiSelect,
			operatorElement: operatorEl
		}, $.proxy(this.scope.filterByStatus, {
			scope: this.scope,
			dataSource: this.scope.slasDataSource,
			field: this.field
		}));
		menu.find('[type=reset]').on('click',
			$.proxy(function (e) {
				multiSelect.value([]);
			}, this));
		// fix for default value
		setTimeout(function () {
			operatorEl.data('kendoDropDownList').select(0);
			multiSelect.value(statusIndexFilters);
		}, 100);
	}
	filterByStatus(e) {
		var indicators = e.data.widget.value();
		var operator = e.data.operatorElement.data(
			'kendoDropDownList').value();
		var logic = (operator === 'eq') ? 'or' : 'and';
		var currentFilter = this.scope.slasDataSource.filter();
		var filter = {
			logic: logic,
			filters: []
		};
		for (var i = 0; i < indicators.length; i++) {
			filter.filters.push({
				field: this.field,
				operator: operator,
				value: indicators[i]
			});
		}
		if (currentFilter && currentFilter.filters) {
			for (var i = 0; i < currentFilter.filters.length; i++) {
				if (currentFilter.filters[i].filters) {
					for (var j = 0; j < currentFilter.filters[i].filters.length; j++) {
						if (currentFilter.filters[i].filters[j].field === this.field) {
							currentFilter.filters[i].filters
								.splice(j, 1);
							j--;
						}
					}
				}
				if (currentFilter.filters[i].field === this.field) {
					currentFilter.filters.splice(i, 1);
					i--;
				}
			}
			if (filter.filters.length) {
				currentFilter.filters.push(filter);
			}
			this.scope.slasDataSource.filter(currentFilter);
		} else {
			this.scope.slasDataSource.filter(filter);
		}
	}
	/**
	 * Saves user preferences
	 */
	saveUserPreferences() {
		if (this.isView) {
			return
		}
		saveUserPrefs({
			category: 'SLAs',
			filterPanel: this.gridFilterPanel,
			grid: this.grid,
			eventsToolbar: this.eventsToolbar,
			keys: {
				searchPhrase: 'slasSearchPhrase',
				columns: 'slasColumns',
				sort: 'slasSort',
				filter: 'slasFilter',
				FsViews: 'slasFSViews'
			}
		}, null, this.userPref.data);
	}
	/**
	 * Loads user preferences
	 */
	async loadUserPreferences() {
		try {
			return UserPrefs.load('SLAs')
		} catch (result) {
			Utils.showInfo(lang.ALERT, result.message, result.details);
			return {}
		}
	}

	onEventsTimeout(events) {
		this.slasDataSource.read();
	}
	/**
	 * Called when one or multiple events are received from the
	 * server
	 *
	 * @param {Object}
	 *            event
	 */
	handleEvents(events) {
		this.eventsToolbar?.addEvents(events);
	}
	/**
	 * Handler function for exclude period create
	 */
	onExcludePeriodCreate() {
		this.openExcludePeriod();
		this.onCreateExcludePeriod = true;
	}
	/*
	 * Handler function for opening the exclude period form
	 * */
	openExcludePeriod() {
		var container = $('#cw_slas_section');
		container.append('<div id="cw_sla_exclude_period_window"></div>');

		$('#cw_sla_exclude_period_window').kendoWindow({
			animation: false,
			width: '730px',
			height: '240px',
			title: t('Exclude periode'),
			modal: true,
			position: topLeftCornerPosition,
			visible: false,
			activate: $.proxy(function () {
				$('#cw_sla_exclude_period_window').find('input:text:not([readonly])').first().focus();
			}, this),
			close: $.proxy(function () {
				$('#cw_sla_exclude_period_window').data("kendoWindow").destroy();
				if (this.mode === 'update') {
					RemoteEventsManager.discard(this.id);
				}
			}, this)
		});

		var html = '<div class="cw_section_content">';
		html += '<div class="w100 cw_sla-timezone-info">';
		html += t('For SLA with different timezone we do not convert time from this form.');
		html += '<span class="glyphicons question-sign" title="';
		html += t('In this form we use system time and do not convert the time to SLA timezone if it differ. To convert enter SLA and create time from Exlude period tab.');
		html += '"></span></div>'
		html += '<div class="w100 left"><div class="cw_exclude_date_control">';
		html += '<div class="cw_add_exclude_time">';
		html += '<div class="cw_excludetime_selectors">';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.reports.DATE_FROM + '</label>';
		html += '<div class="cw_dropdown_container">';
		html += '<input id="cw_sladetails_exclude_from" />';
		html += '</div></div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.reports.DATE_TO + '</label>';
		html += '<div class="cw_dropdown_container">';
		html += '<input id="cw_sladetails_exclude_to" />';
		html += '</div></div></div>';
		html += '<div class="cw_field" id="cw_sladetails_exclude_description">';
		html += '<label class="cw_inline_full">' + lang.DESCRIPTION + '</label>'
		html += '<textarea class="cw_inline"></textarea>';
		html += '</div></div></div></div></div>';
		html +=	'<div class="cw_action_buttons">';
		html += '<button id="cw_sladetails_exclude_close" class="k-button">' + lang.CLOSE + '</button>';
		html += '<button id="cw_sladetails_exclude_save" class="k-button k-primary">' + lang.CREATE + '</button>';
		html += '</div>';
		html += '</div>';

		this.window = $('#cw_sla_exclude_period_window').data('kendoWindow');
		this.window.content(html);
		var from = new Date();
		var to = new Date(from.getTime() + 1 * 60 * 1000);

		this.excludeFrom = $('#cw_sladetails_exclude_from').kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserDateTimeFormat),
			value: from,
			change: $.proxy(this.startChange, this),
			open: $.proxy(this.onStartOpen, this),
			interval: 15
		}).data('kendoDateTimePicker');

		this.excludeTo = $('#cw_sladetails_exclude_to').kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserDateTimeFormat),
			value: to,
			change: $.proxy(this.endChange, this),
			open: $.proxy(this.onEndOpen, this),
			interval: 15
		}).data('kendoDateTimePicker');


		$('#cw_sladetails_exclude_save').on('click', $.proxy(this.onExcludePeriodSave, this));
		$('#cw_sladetails_exclude_close').on('click', $.proxy(this.onExcludePeriodClose, this));

		this.gridMenu.disableItem('cw_slas_create');
		this.gridMenu.disableItem('cw_slas_delete');
		this.gridMenu.disableItem('cw_set_tags');
		this.window.open();
	}
	/**
	 * Method by Andy
	 */
	adjustSectionHeight() {
		var section = $('.cw_section_full');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height',
			sectionHeight);
		section.find('.k-grid-content').css('height',
			sectionHeight - 40);
	}
	/**
	 */
	startChange() {
		var startDate = this.excludeFrom.value(),
			endDate = this.excludeTo.value();
		$('#cw_sladetails_exclude_from').removeClass('invalid');
		if (startDate) {
			/*		startDate = new Date(startDate);
			 startDate.setDate(startDate.getDate());*/
			this.excludeTo.min(new Date(startDate.getTime() + 15 * 60 * 1000));
			if (endDate && startDate.getTime() > endDate.getTime()) {
				endDate = new Date(startDate.getTime() + 15 * 60 * 1000);
				this.excludeTo.value(endDate);
			}
		} else if (endDate) {
			//this.excludeFrom.max(new Date(endDate.getTime() - 15*60*1000));
		} else {
			endDate = new Date(kendo.date.today().getTime() + 15 * 60 * 1000);
			//this.excludeFrom.max(endDate);
			this.excludeTo.min(endDate);
		}
	}
	/**
	 */
	endChange() {
		$('#cw_sladetails_exclude_to').removeClass('invalid');
	}
	/**
	 *
	 */
	onStartOpen() {
		var startDate = this.excludeFrom.value(),
			from;
		if (!startDate) {
			from = kendo.date.today();
			this.excludeFrom.value(from);
		}
	}
	/**
	 */
	onEndOpen() {
		var startDate = this.excludeFrom.value(),
			endDate = this.excludeTo.value(),
			from, to;
		if (!endDate) {
			if (startDate) {
				this.excludeTo.value(new Date(startDate.getTime + 15 * 60 * 1000));
			} else {
				from = kendo.date.today();
				to = new Date(from.getTime + 15 * 60 * 1000);
				this.excludeFrom.value(from);
				this.excludeTo.value(to);
			}
		}
	}
	/**
	 * Sliding View Mechanism
	 *
	 */
	slideViewSettings(e) {
		e.stopPropagation();
		var currentTarget = $(e.currentTarget);
		var slidingBox = currentTarget
			.closest('.cw_filter_wrapper');
		if (!slidingBox.hasClass('is_open')) {
			slidingBox.addClass('is_open');
		} else {
			slidingBox.removeClass('is_open');
		}
	}
	/**
	 * Subscribes to the events
	 */
	subscribe() {
		this.isDataSourceSubscribed = true;
		var subscriptionObj = [
			{
				eventType: 'Sla',
				accountId: Cookies.CeesoftCurrentAccountId,
				includeSubaccounts: this.includeSubaccounts
			},
			{
				eventType: 'SlaAdmin',
				actionTypes: ['SLA_CREATE', 'SLA_UPDATE',
					'SLA_DELETE'],
				accountId: Cookies.CeesoftCurrentAccountId,
				includeSubaccounts: this.includeSubaccounts
			}];
		RemoteEventsManager.subscribe(this.subscriberId,
			subscriptionObj);
	}
	/**
	 * Called when application is unloaded/destroyed
	 */
	destroy() {
		this.saveUserPreferences();
		if (this.eventsToolbar) {
			this.eventsToolbar.destroy();
		}
		this.recalculateDestroyer?.();

		Application.prototype.destroy.call(this);
	}
}
