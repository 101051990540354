import {observer} from "mobx-react";
import React, {useCallback, useState} from "react";
import {BudgetTableContext} from "./BudgetTableContext";
import {formatNumber} from "../../../../tools/helpers/math";
import {CostBudgetItem} from "../../models/costBudgetItem";
import {costTranslates} from "../../translations";
import {BellOutlined, DeleteOutlined, DownloadOutlined, FolderAddOutlined, LinkOutlined, LoadingOutlined} from "@ant-design/icons";
import {Popconfirm, Popover} from "antd";
import {CostMetricButton} from "areas/cost/components/MetricCellWindow";
import {Api, State} from "tools";
import {AssetsRouter} from "areas/assets/bundleDescription";
import {AssetsApi} from "api";
import {ToolbarItemPosition} from "controls/react/layout";
import {EventSummaryButton} from "./popups/eventSummaryButton"
import {CostType} from "../budgetTypes";
import {LinkCell} from "./LinkCell";
import "./BudgetTableCells.less";
import b_ from "b_";
import {translator} from "../../../../core";

const b = require('b_').with('budget-table');

const i = translator({
  "Cost item is of Metric type and does not reflect currency": {
    "no": "Dette kostelementet er metrikktype og reflekterer ikke valuta",
    "en": "Costitem is metric type and do not reflect currency"
  },
  "External link": {
    "no": "Ekstern lenke"
  }
}, costTranslates);

export const SimpleCell = observer(({ content }: { content: () => React.ReactNode }) => {
	return <>{content()}</>;
})

export const AssetCell = observer(({text, assetId}: { text: () => string, assetId: string }) => {
	const [popoverContent, setPopoverContent] = useState('');
	const onOpenChange = (e: boolean) => {
		if(e && !popoverContent) {
			Api.fetch(AssetsApi.getAssetUrl(assetId)).then(r => {
				if(r.success)
					setPopoverContent(`${r.data.accountName}/${r.data.name}`)
			});
		}
	}
	return <Popover content={popoverContent}
					onOpenChange={onOpenChange}
					position={ToolbarItemPosition.AT_THE_END}>
			<a className={b('asset-link')} onClick={() => State.mainApp?.navigate(AssetsRouter.details(assetId))}><span className="glyphicons heart" /> {text()}</a>
		</Popover>;
})

export const NumberCell = observer(({text, title}: { text: () => string, title?: string }) => {
	const context = React.useContext(BudgetTableContext);
	const {displayDecimals, displayAsThousands} = context;
	if(title) {
		return <span title={title}>{formatNumber(text(), displayDecimals, displayAsThousands)}</span>
	}
	return <>{formatNumber(text(), displayDecimals, displayAsThousands)}</>;
})

export const RateCell = observer(({value, period}: { value: () => number, period: 'month' | 'year'}) => {
	const val = value() * 100;
	if (Number.isNaN(val)) {
		return null;
	}
	const textValue = `${Math.abs(val).toFixed(0)}%`;
	const titleText = val == 0
		? i(`No change from previous ${period}`)
		: val < 0
			? `${textValue} ${i(`decrease from previous ${period}`)}`
			: `${textValue} ${i(`increase from previous ${period}`)}`;
	return <span className={b('rate-value', {negative: val < 0, positive: val > 0})} title={titleText}>{val < 999 && val > -999 ? textValue : 'MAX'}</span>;
})

export const ActionCell = observer(({record}: { record: CostBudgetItem }) => {
	const context = React.useContext(BudgetTableContext);
	const {lockStructure, openNewItemWindow, openImportExternalWindow, deleteRow, subAccounts, openThresholdWindow} = context;
	if (lockStructure) {
		return null;
	}

	return (
		<div className={b('action-cell-content')}>
			{record.id != 'total' && !record.inLink && <a className={b('action', {threshold: true})} title={i('Threshold')} type={'cost'} onClick={() => openThresholdWindow(record)}>
				<BellOutlined />
			</a>}
			{(record.addChildrenEnabled || record.id === 'total') &&
			<>
				<a className={b('action', {add: true})} title={i('Add')}
					onClick={() => openNewItemWindow(record)}><FolderAddOutlined/></a>
				<a className={b('action', {import: true})} title={i('Import external cost data')} onClick={() => {
					openImportExternalWindow(record);
				}}><DownloadOutlined/></a>
			</>
			}
			{record.id != 'total' && !record.editDisabled(subAccounts) && !record.inLink &&
			<>
				<Popconfirm className={b('action', {delete: true})} title="Sure to delete?" onConfirm={() => deleteRow(record)}>
					<a title={i('Delete')}><DeleteOutlined/></a>
				</Popconfirm>
			</>
			}
			{record.id != 'total' && <span className={b('action', {'cost-metric': true})}><CostMetricButton record={record} type={'cost'} /></span>}
			{record.hasMetrics && <span className={b('action', {'generic-metric': true})}><CostMetricButton record={record} type={'generic'} /></span>}
		</div>
	);
});

export const MetricActionCell = observer(({record}: {record: CostBudgetItem}) => {
	return (
		<div className={b('action-cell-content')}>
			{record.id != 'total' && <CostMetricButton record={record} type={'cost'} />}
			{!!record.hasMetrics && <CostMetricButton record={record} type={'generic'} />}
		</div>
	);
});

export const NameCell = observer(({record}: {record: CostBudgetItem}) => {
	const content = () => {
		if(record.isDeletedCostLink){
			return <SimpleCell content={() => <del title={i('The link\'s cost profile is deleted. Please remove this link.')}>{record.name}</del>} />
		}
		if(record.costTargetType && record.costTargetType === 'ASSET' && !record.costModelId && record.costTargetId){
			return <AssetCell text={() => record.name} assetId={record.costTargetId} />
		}
		if (!record.isCostLink) {
			return <SimpleCell content={() => record.name}/>;
		}
		return <LinkCell record={record}/>;
	}

	return <SimpleCell content={() => <div className={b('name-content')}>
		<div className={b('name-content-main', {targetType: record.costTargetType?.toLowerCase()})}>
			{content()}
		</div>
	</div>} />
});


// status cell
const statusB = b_.with('cost-status-cell')
const LinkStatusIcon = observer(({record}: {record: CostBudgetItem}) => {
	switch(record.costTargetType) {
		case CostType.METRIC:
			return <div className={statusB('item', {type: 'metric'})} title={i('Cost item is of Metric type and does not reflect currency')}>
				<span className={'material-icons'}>query_stats</span>
			</div>
		case CostType.EXPRESSION:
			return <div className={statusB('item', {type: 'expression'})} title={i('Expression')}>
				<span className={'material-icons'}>r_mobiledata</span>
			</div>
	}

	return <div className={statusB('item', {type: 'link'})} title={i('Link')}>
		<DownloadOutlined />
	</div>
})

const ExternalLinkIcon = observer(({record}: {record: CostBudgetItem}) => {
	const { metricCache } = React.useContext(BudgetTableContext);

	const loading = metricCache.loading(record.id);
	const onClick = React.useCallback(async () => {
		const url = await metricCache.getExternalLink(record.id)
		window.open(url, 'new-tab')
	}, [record.id])

	return <a onClick={onClick} className={statusB('item', {type: 'external-link'})} title={i('External link')}>
		{loading ? <LoadingOutlined/> : <LinkOutlined />}
	</a>
})

export const StatusCell = observer(({record}: {record: CostBudgetItem}) => {
	const context = React.useContext(BudgetTableContext)
	const {openThresholdWindow} = context

	const thresholdClickCallback = useCallback((e) => {
		e.preventDefault();
		e.stopPropagation();
		openThresholdWindow(record)
	}, [record]);

	return <div className={statusB()}>
		{record.hasEvents && <EventSummaryButton record={record} className={statusB('item', {type: 'events'})}/>}
		{(record.costAlarmId || record.costAlarm) && <a className={statusB('item', {type: 'threshold'})} title={i('Threshold')} type={'cost'} onClick={thresholdClickCallback}>
			<BellOutlined />
		</a>}
		{record.isCostLink && <LinkStatusIcon record={record}/>}
		{record.hasExternalLink && <ExternalLinkIcon record={record}/>}
	</div>
})
