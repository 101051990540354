import React from "react"

import {StateWidgetConfiguration} from 'areas/services/widgets/stateWidget/stateWidgetConfiguration'
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {ApplicationStateSummary} from "areas/application/widgets/stateWidget/applicationStateSummary"
import {ApplicationStateDashboardWidgetConfig} from "./applicationStateDashboardWidgetConfig"
import {ApplicationHealthEvent} from "framework/entities/events";
import {apiFetch} from "framework/api"
import {ApiRequest} from "framework/api"
import {Severity} from "framework/entities/healthData"
import {ApplicationsRouter} from "areas/application/bundleDescription";
import {
	ApplicationSeverity,
	ApplicationStateWidget, getApplicationStateDatasource
} from 'areas/views/applicationStateWidget'
import {
	StateWidgetProps,
	Subscription, WidgetState,
	withDashboardData,
	withDataLoad
} from "../../../../controls/react/barsStatesWidget";
import {ApplicationState} from "../../../../framework/applicationState";

const i = require('core/localization').translator(sharedLocalization);

export function getWidgetDescription(){
	return {
		form: StateWidgetConfiguration,
		defaultConfig: {
			type: 'application-state',
			title: i('State'),
			configuration: {
				accountId: ApplicationState.accountId,
				isDashboard: true,
				severities: [
					ApplicationSeverity.None,
					ApplicationSeverity.Minor,
					ApplicationSeverity.Major,
					ApplicationSeverity.Critical,
					ApplicationSeverity.Invalid
				]
			}
		},
		widget: Widget,
		legacyForm: true,
		fullTitle: i('Application') + '/' + i('State'),
	}
}

const WidgetInner = withDashboardData(withDataLoad(ApplicationStateWidget, config => apiFetch(getApplicationsState(config))));

class Widget extends React.Component<StateWidgetProps<ApplicationStateDashboardWidgetConfig, ApplicationStateSummary>, WidgetState<ApplicationStateSummary>> {
	static CustomDataSource = getApplicationStateDatasource();
	subscriptions: Subscription[];
	constructor(props: StateWidgetProps<ApplicationStateDashboardWidgetConfig, ApplicationStateSummary>) {
		super(props);
		this.subscriptions = [
			ApplicationHealthEvent.subscription(this.props.config)
		];
	}

	render() {
		return <WidgetInner {...this.props} type={this.props.config.type} subscriptions={this.subscriptions} onClick={this.onClick} />
	}

	onClick = (severity: Severity) => {
		let state = '';
		switch(severity){
			case Severity.Critical: state = 'CRITICAL'; break;
			case Severity.Invalid: state = 'IDLE'; break;
			case Severity.Major: state = 'MAJOR'; break;
			case Severity.Minor: state = 'MINOR'; break;
			case Severity.None: state = 'NONE'; break;
		}
		let filterObj = {};
		if (state) {
			filterObj = {
				stateFilter: state,
				accountId: this.props.config.accountId,
				includeSubaccounts: this.props.config.includeSubaccounts,
				isView: true
			}
		}

		this.props.navigator.go({
			url: ApplicationsRouter.list(filterObj)
		})
		//State.mainApp.navigate(ApplicationsRouter.list(), filterObj);
	}
}

type GetApplicationsStateParameters = {
	accountId?: string
	tags?: string[],
	includeSubaccounts?: boolean,
	showUntagged?: boolean
	ignoreUserTags?: boolean

}

export function getApplicationsState(p: GetApplicationsStateParameters) {
	return new ApiRequest<ApplicationStateSummary>({
		url: 'summary/applicationAggregate',
		accountBased: true,
		accountId: p.accountId,
		includeSubaccounts: p.includeSubaccounts,
		subaccountsFilter: true,
		method: 'POST',
		payload: {
			tags: p.tags,
			showUntagged: p.showUntagged,
			ignoreUserTags: p.ignoreUserTags === undefined ? true : p.ignoreUserTags
		}
	})
}
