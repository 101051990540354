import 'react'

declare module 'react' {
	interface CSSProperties {
		//[key: `--ceeview-${string}`]: string | number
	}
}

export const initCssVariables = () => {
	document.body.classList.add("css-var-r0")
}

class CssVariablesInternal{
	severityCritical: string
	severityMajor: string
	severityMinor: string
	severityNone: string
	severityInvalid: string

	loadVariablesFromLayout(){
		let style = getComputedStyle(document.body)
		this.severityCritical = style.getPropertyValue("--ceeview-severity-critical")
		this.severityMajor = style.getPropertyValue("--ceeview-severity-major")
		this.severityMinor = style.getPropertyValue("--ceeview-severity-minor")
		this.severityNone = style.getPropertyValue("--ceeview-severity-none")
		this.severityInvalid = style.getPropertyValue("--ceeview-severity-invalid")
	}
}

export const CssVariables = new CssVariablesInternal()
