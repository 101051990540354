import React from "react"
import {observer} from "mobx-react"

import {WidgetConfigurationProps} from "controls/designer/features/widgets/widgetConfig";
import {AiQueryWidgetConfig} from "controls/designer/features/widgets/ai-query-widget/aiQueryWidgetConfig";
import {Section} from "controls/react/layout/section";
import {FormEntryNew} from "controls/react/form/formEntryNew";
import {AntInput} from "controls/react/ant/antInput";
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {AntAlert} from "controls/react/ant/antAlert";
import {AntCheckbox} from "controls/react/ant/antCheckbox";

const i18n = require('core/localization').translator({
	'Help text': {
		en: 'To create a query click AI query icon in dashboard. Datasource from dashboard can be added directly in prompt. The AI query in prompt is persisted when updating dashboard.',
		no: 'For å opprette et søk, klikk på AI-spørringsikonet i dashbordet. Datakilde fra dashbord kan legges til direkte i ledeteksten. AI-spørringen i ledeteksten lagres når dashbordet oppdateres.'
	},
	'Run AI query on click of icon': {
		no: 'Kjør AI spørring på klikk av ikon'
	},
},
sharedLocalization);

export const AiQueryWidgetConfiguration = observer((props: WidgetConfigurationProps<AiQueryWidgetConfig>) => {
	return <Section appearance={"none"}
	                contentPadding={false}
	                childrenPadding={true}>
		<FormEntryNew label={i18n('Title')} model={props.config} modelField={"title"}>
			<AntInput/>
		</FormEntryNew>
		<FormEntryNew model={props.config} modelField={"autorunQuery"}>
			<AntCheckbox>{i18n('Run AI query on click of icon')}</AntCheckbox>
		</FormEntryNew>
		<AntAlert message={i18n('Help text')}/>
	</Section>
})
