import { IObservableArray } from "mobx"

import {GridColumnConfig} from "controls/grid/gridColumnConfig"
import {GridDataEntry} from "controls/grid/gridDataEntry"
import { GridStore } from "./gridStore"
import {RulesConfiguration} from "controls/queryBuilder/ruleDefinition"
import { GridState } from "./gridState"
import {GridStateProvider} from "controls/grid/stateProviders"
import {GridDataItem} from "controls/grid/gridDataItem";
import {CSSProperties} from "react";

export enum GridSelectionType {
	None,
	One,
	Many
}

export interface DataProvider<DataEntry extends GridDataEntry>{
	data: IObservableArray<GridDataItem<DataEntry>>
	initialized: boolean

	//total number of data rows
	visibleRowsCount: number
	//total number of rows in the grid including group headers (since they are represented as a grid row)
	scrollableRowsCount: number
	//unfiltered rows count
	totalRowsCount: number

	filtersConfiguration: RulesConfiguration
	attach: (store: GridStore<DataEntry>) => Promise<void>
	//reload: () => void
	silentReload: () => void
	informVisibleRangeChanged: (startIndex: number, stopIndex: number) => void

	get: (index: number) => GridDataItem<DataEntry>

	destroy: () => void

	groupBySupported?: boolean
}

export type GridDefaultFilterEntry = {
	field: string,
	operator: string
	value: string[]
}

export type GridConfiguration<DataItem extends GridDataEntry> = {
	dataProvider: DataProvider<DataItem>
	columns: GridColumnConfig<DataItem>[] | (() => GridColumnConfig<DataItem>[])
	autoWidth?: boolean

	selection?: GridSelectionType

	plugins?: Array<GridPlugin<DataItem>>

	persistState?: boolean

	state?: GridState<DataItem>

	fit?: boolean
	heightByContent?: boolean

	stateProviders?: GridStateProvider<DataItem>[]

	emptyMessage?: string

	onFiltersCleared?: (store: GridStore<DataItem>) => void

	groupBy?: boolean

	defaults?: {
		payload?: Record<string, any>,
		data?: Record<string, any>,
		sorting?: {field: string, direction: 'asc'|'desc'}[],
		columns?: string[]
		filters?: GridDefaultFilterEntry[]
		selectedIds?: string[],
		showInlineFilters?: boolean
	}

	customization?: {
		isSelectionDisabled?: (item: GridDataItem<DataItem>, store: GridStore<DataItem>) => boolean | void | {
			disabled: boolean,
			reason: string
		}
		styling?: GridCustomStyling,
		view?: 'default' | 'light'
	}
}

export type GridCustomStyling = {
	transparent?: boolean
	backgroundColor?: string
	fontSize?: string
	color?: string
	fontFamily?: string
	fontWeight?: string
	fontStyle?: string
	textDecoration?: string
	defaultRowHeight?: number
}

export interface GridPlugin<DataItem extends GridDataEntry>{
	id?: string
	attach(store: GridStore<DataItem>): Promise<void>
	afterStoreInitialized?: () => void

	destroy?: () => void
}
