import {ConfigProvider} from "antd";
import React from "react";
import {observer} from "mobx-react";
import {ApplicationState} from "framework/applicationState";
import {CssVariables} from "styles/cssVariables";


export type AntConfigProviderProps = {
	children: React.ReactNode;
	theme?: any
}

export const AntConfigProvider = observer((props: AntConfigProviderProps) => {
	//const theme = React.useMemo(() => {
		const theme = {
			token: {
				fontFamily: 'Oxygen, Arial, sans-serif',
				fontSize: 12,
				lineHeight: 1.5,
				colorError: CssVariables.severityCritical,
				colorSuccess: CssVariables.severityNone,
				colorWarning: CssVariables.severityMajor,
			},
			components: {
				Tabs: {
					horizontalMargin: '0 0 6px 0'
				}
			},
			cssVar: true,
			hashed: false
		};

		if (props.theme) {
			const {token, ...others} = props.theme;
			theme.token = Object.assign(theme.token, token);
			Object.assign(theme, others);
		}
		//return defaultTheme;
	//}, [props.theme])

	return <ConfigProvider theme={theme}>
		{props.children}
	</ConfigProvider>
})
