import React from 'react'
import {observer} from "mobx-react"
import {FileTextOutlined} from '@ant-design/icons'
import {sharedLocalization} from "controls/designer/features/widgets/localization"
import {WidgetProps} from "controls/designer/features/widgets/widgetProps";
import {DefaultWidgetWrapper} from "controls/designer/features/widgets/defaultWidgetWrapper";
import {AntButton} from "controls/react/ant/antButton";
import {AiQueryWidgetConfig} from "controls/designer/features/widgets/ai-query-widget/aiQueryWidgetConfig";
import {
	AiQueryWidgetPersistedState
} from "controls/designer/features/widgets/ai-query-widget/aiQueryWidgetPersistedState";
import {
	AiQueryWidgetConfiguration
} from "controls/designer/features/widgets/ai-query-widget/aiQueryWidgetConfiguration";
import {AiQueryWidgetStore} from "controls/designer/features/widgets/ai-query-widget/aiQueryWidgetStore"
import {Section} from "controls/react/layout/section";
import {WidgetDescription} from "controls/designer/features/widgets/allWidgets";


const i18n = require('core/localization').translator({
		'Click icon to open AI query prompt': {
			no: 'Klikk ikon for å åpne AI spørring'
		}
	},
	sharedLocalization);

export function getWidgetDescription(){
	return {
		form: AiQueryWidgetConfiguration,
		defaultConfig: {
			type: 'ai-query',
			title: i18n('AI Query'),
			dataSources: [] as any[]
		},
		width: 100,
		height: 100,
		widget: AiQueryWidget,
		fullTitle: i18n('AI Query'),
		containerStyles: {
			noBorder: true,
			transparent: true,
			header: 'on-hover'
		}
	}
}

const b = require('b_').with('ai-query-widget');

export const AiQueryWidget = observer(
	class InnerWidget extends React.Component<WidgetProps<AiQueryWidgetConfig, AiQueryWidgetPersistedState>> {
		private store: AiQueryWidgetStore

		constructor(props: WidgetProps<AiQueryWidgetConfig, AiQueryWidgetPersistedState>) {
			super(props)

			this.store = new AiQueryWidgetStore(props)
		}

		render() {
			return <DefaultWidgetWrapper appearance={"none"}
			                             {...this.props}
			                             showHeader={false}>
				<Section appearance={"none"} height={"fit"} containerOverlay={this.store.loadingInitial} overlaySpinner={true}>
					<AntButton shape={"circle"}
					           title={i18n('Click icon to open AI query prompt')}
					           onClick={this.store.openPopup}
					           icon={<FileTextOutlined />} type={'primary'}/>
				</Section>
			</DefaultWidgetWrapper>
		}

		componentWillUnmount() {
			this.store.destroy()
		}

		getStateForSaving() {
			return {
				dataSources: this.store.dataSources,
				query: this.store.query
			};
		}
	})


