import {WidgetConfig} from "controls/designer/features/widgets/widgetConfig";

export const allWidgets : Record<string,{
	getWidgetDescription: () => WidgetDescription<any>
}>=
	{
		'application-preview': require ('areas/application/widgets/preview'),
		'application-state': require('areas/application/widgets/stateWidget/state'),
		'service-preview': require('areas/services/widgets/preview'),
		'service-summary': require('areas/services/widgets/summary'),
		'service-summary-grid': require('areas/services/widgets/serviceSummaryWidget/service-summary-grid'),
		'service-history': require('areas/services/widgets/history'),
		'service-selective': require('areas/services/widgets/selectiveServices'),
		'service-grid': require('areas/services/widgets/servicesGridWidget'),
		'service-state': require('areas/services/widgets/stateWidget/state'),
		'service-events': require('areas/services/widgets/serviceEventsWidget'),
		'service-single-graph': require('areas/services/widgets/singleGraph'),
		'service-multi-graph': require('areas/services/widgets/multiGraph'),
		'service-gauge': require('areas/services/widgets/gauge'),
		'service-document': require('areas/service-boards/widgets/serviceDocument/serviceDocumentWidget'),
		'metric-single-graph': require('areas/service-boards/widgets/metricSingleGraph'),
		'metric-multi-graph-asset': require('areas/service-boards/widgets/metricMultigraphAsset'),
		'metric-multi-graph-asset-group': require('areas/service-boards/widgets/metricMultigraphAssetGroup'),
		'metric-gauge': require('areas/service-boards/widgets/metricGauge'),
		'metric-stacked-chart': require('areas/service-boards/widgets/stackChartWidget/stackedChart'),
		'metric-custom': require('areas/service-boards/widgets/customMetrics/metricCustom'),
		'metric-barchart': require('areas/service-boards/widgets/barchartMetrics/barchartWidget'),
		'metric-table': require('./metrics/tableWidget'),
		'metric-sparkline-table': require('./metrics/sparklineTable/sparklineTableWidget'),
		'health-single-graph': require('areas/assets/widgets/singleGraph'),
		'health-multi-graph': require('areas/assets/widgets/multiGraph'),
		'health-gauge': require('areas/assets/widgets/gauge'),
		'asset-grid': require('areas/assets/widgets/grid'),
		'asset-console': require('areas/assets/widgets/console'),
		'asset-state': require('areas/assets/widgets/stateWidget/state'),
		'sla': require('areas/sla/widgets/sla'),
		'sla-history': require('areas/sla/widgets/history'),
		'sla-state': require('areas/sla/widgets/stateWidget/state'),
		'sla-time': require('areas/sla/widgets/time'),
		'sla-grid': require('areas/sla/widgets/grid'),
		'kpi-single-graph': require('areas/kpi/widgets/singleGraph'),
		'kpi-multi-graph': require('areas/kpi/widgets/multiGraph'),
		'kpi-gauge': require('areas/kpi/widgets/gauge'),
		'kpi-history': require('areas/kpi/widgets/history'),
		'summary-grid': require('areas/service-boards/widgets/grids/summary'),
		'reasons-grid': require('areas/service-boards/widgets/grids/reasons'),
		'incidents-grid': require('areas/service-boards/widgets/grids/incidents'),
		'data-sources-grid': require('controls/designer/features/widgets/datasources/dataSourceGridWidget'),
		'event-summary-grid': require('areas/service-boards/widgets/grids/eventsSummary'),
		'text': require('areas/service-boards/widgets/text'),
		'time': require('controls/designer/features/widgets/custom/timeWidget'),
		'ai-query': require('controls/designer/features/widgets/ai-query-widget/aiQueryWidget'),
		'gps': require('controls/designer/features/widgets/custom/gps/gpsWidget'),
		'web-page': require('areas/service-boards/widgets/webPageWidget'),
		'dashboard': require('controls/designer/features/widgets/custom/dashboard'),
		'report': require('controls/designer/features/widgets/reportWidget'),
		'cloud-services-cost-bar-chart': require('controls/designer/features/widgets/cloudServices/costBarChart'),
		'cloud-services-cost-grid': require('controls/designer/features/widgets/cloudServices/costGrid'),
		'cloud-services-instances-grid': require('controls/designer/features/widgets/cloudServices/instancesGrid'),
		'cloud-services-buggle-chart': require('controls/designer/features/widgets/cloudServices/bubbleChart'),
		'cost-grid': require('controls/designer/features/widgets/cost/gridWidget'),
		'cost-profile': require('controls/designer/features/widgets/cost/profile/costProfileWidget'),
		'budget-cost-bar-chart': require('controls/designer/features/widgets/cost/budget/budgetBarChart'),
		'event-state': require('areas/summary/events/eventStateDashboardWidget')
	}

export function getWidgetDescription<T extends WidgetConfig>(widgetType: string) {
	const module = allWidgets[widgetType];
	return module?.getWidgetDescription();
}

export type WidgetDescription<T> = {
	defaultConfig: {
		type: string
		configuration?: any
	},
	form: new () => any,
	fullTitle: string,
	preProcessConfig?: (config: T) => T,
	minWidth?: number
	width?: number
	height?: number
	persistStateInViewMode?: boolean
	containerStyles?: {
		noBorder?: boolean
		transparent?: boolean
		header: 'default' | 'on-hover'
	}
}

export function getAllDescriptions(){
	return Promise.all(Object.keys(allWidgets).map(x => getWidgetDescription(x)))
}
