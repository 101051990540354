import React from 'react';
import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {Designer as DesignerControl} from 'controls/designer/designer';
import PresentationDesignerExtension from "areas/dashboards/graph-editor-extensions/presentationDesignerExtension";
import {PresentationApi} from "areas/dashboards/api";
import {PropertiesForm} from "areas/dashboards/propertiesForm";

import "./dashboardDesigner.less"
import {LayoutType} from "controls/designer/features/adaptiveLayout/layoutType";
import {toJS} from "mobx";
import {Action} from "../../../controls/designer/actionsManager/action";
import {Actions} from "../../../controls/designer/actionsManager/actions";
import {DesktopOutlined} from "@ant-design/icons";
import {sharedLocalization} from "areas/dashboards/localization";
import {serialize} from "serializr";


const i = require('core/localization').translator({
  "info-message": {
    "en": "To get started add a shape ",
    "no": "For å komme igang legg til en form"
  },
  "info-message2": {
    "en": " or widget ",
    "no": " eller widget"
  },
  "info-message3": {
    "en": " to the canvas.",
    "no": " til arbeidsbakgrunnen"
  }
}, sharedLocalization);

const b = require('b_').with('dashboard-designer');

export class DashboardDesigner extends React.PureComponent{
	containerRef = React.createRef();

	skipDirtyCheck = false;

	render(){
		return <div ref={this.containerRef}></div>
	}

	async componentDidMount(){
		if(this.props.dashboard == null) {
			this.dashboard = await this.loadDashboard();
		}else{
			this.dashboard = this.props.dashboard;
		}

		if(this.dashboard == null)
			return;

		if(this.dashboard.sharedSession == null){
			this.dashboard.sharedSession = false
		}

		this.props.dashboardLoaded && this.props.dashboardLoaded(this.dashboard);

		let config = {
			accountId: this.dashboard.accountId,
			container: this.containerRef.current,
			onLoaded: this.designerLoaded,
			toolbar: this.getToolbar(),
			data:  this.dashboard?.type == 'DASHBOARD' || this.dashboard?.type == 'PRESENTATION'
					? this.dashboard.content
					: null,
			padding: 5,
			mode: 'presentation',
			chromeless: this.props.mode != 'designer',
			layoutType: this.props.layoutType,
			disableDynamicContent: this.props.dashboard != null,
			navigator: this.props.navigator,
			features: {
				serviceModelImporting: true,
				widgets: true,
				shapesCollection: true,
				moveToFrontOnExpand: true,
				scrollToBottomIfExpanded: true,
				redirectOnClick: true,
				adaptiveLayout: true,
				presentationMode: true,
				snapToGrid: true,
				preview: true,
				resolutionSelector: this.props.mode == 'designer-preview',
				gspMap: true
			},
			forceHideWindows: !this.props.id && !this.props.xml,
			extensionsManager: new PresentationDesignerExtension(),
			...this.props.config
		};

		this.designer = new DesignerControl(config);
	}

	getToolbar(){
		if(this.props.mode == 'designer') {
			return 'zoom-in zoom-out scale undo redo | delete | to-front to-back | preview-toggle shape-properties-toggle | collections-toggle widgets-wizard-show custom-data-list | edit-dashboard-properties'
		}

		if(this.props.mode == 'designer-preview'){
			return 'zoom-in zoom-out scale | set-container-size'
		}

		return false
	}

	loadDashboard = async () => {
		if(!this.props.id && !this.props.xml) {
			return {
				tag: "",
				description: "",
				informationOption: 'locked',
				position: 'left',
				isPublic: true,
				sharedSession: false,
				showName: true,
				showUpdateTime: true,
				type: 'DASHBOARD',
				tags:[]
			};
		}

		const result = await PresentationApi.read(this.props.id, this.props.sessionId);
		if(!result.success){
			return null;
		}

		return result.data;
	}

	componentWillUnmount(){
		this.designer?.destroy();
	}

	designerLoaded = designer => {
		this.initialDashboard = JSON.stringify({
			...this.dashboard,
			content: {
				xml: designer.initialXml,
				customData: designer.store.persistableCustomData
			}
		});

		this.designer = designer;
		this.props.onLoaded && this.props.onLoaded(this, this.dashboard);
		this.updateContainerClasses();

		this.designer.editorUi.editor.graph.addListener(mxEvent.CELLS_FOLDED, this.onCellFolded);

		this.designer.store.am.add(new Action({
			id: Actions.EditDashboardSettings,
			title: i('Dashboard property'),
			callback: this.editProperties,
			icon: DesktopOutlined
		}))
	}

	onCellFolded = () => {
		if(this.designer.dashboardSettings.layoutType == LayoutType.Scale) {
			setTimeout(() => this.designer?.fit(), 0);
		}
	}

	editProperties = () => {
		return new Promise(resolve => {
			const container = document.createElement('div');
			document.body.appendChild(container);

			const root = createRoot(container)

			const unmount = () =>{
				root.unmount()
				document.body.removeChild(container)
			}

			const onCancel = () => {
				this.skipDirtyCheck = true;
				resolve(false);
				unmount();
			}

			const onSaveWrapper = properties => {
				this.updateProperties(properties);
				resolve(true);
				unmount();
			}

			const properties = this.getProperties();

			root.render(<PropertiesForm onSave={onSaveWrapper}
			                            onCancel={onCancel}
			                            properties={properties}/>)
		});
	}

	updateProperties = properties => {
		for(const key of Object.keys(this.designer.dashboardSettings)){
			this.designer.dashboardSettings[key] = properties[key];
		}
		if (properties.adaptiveAlgorithm) {
			this.designer.dashboardSettings.adaptiveAlgorithm = properties.adaptiveAlgorithm;
		}

		for(const key of Object.keys(this.dashboard)){
			if(properties[key] != undefined) {
				this.dashboard[key] = properties[key];
			}
		}

		if(!properties.id){
			this.designer.graph.setGridEnabled(true);
		}

		this.designer.graph.refresh();
		this.updateContainerClasses();
	}

	updateContainerClasses(){
		let classes = {
			'widget-settings_header-static': this.designer.dashboardSettings.showWidgetHeader,
			'widget-settings_header-on-hover': !this.designer.dashboardSettings.showWidgetHeader && this.props.mode == 'designer',
			'widget-settings_header-hidden': !this.designer.dashboardSettings.showWidgetHeader && this.props.mode != 'designer'
		};

		for(let className of Object.keys(classes)){
			this.containerRef.current.classList.remove(className)
		}

		this.containerRef.current.classList.add(classnames(classes));
	}

	getProperties(){
		let properties = {};

		for(const key of Object.keys(this.designer.dashboardSettings)){
			properties[key] = this.designer.dashboardSettings[key];
		}

		for(const key of Object.keys(this.dashboard)){
			properties[key] = this.dashboard[key];
		}

		return properties;
	}

	getDashboardForSaving(skipCleanUp) {
		if (!skipCleanUp) {
			this.designer.cleanUp();
		}

		let dashboard = {
			...this.dashboard,
			content: {
				xml: this.designer.getXml(),
				customData: this.designer.store.persistableCustomData
			}
		};

		if(dashboard.type != 'DASHBOARD'){
			if(dashboard.type == 'LEGACY'){
				delete dashboard.id;
			}
			dashboard.type = 'DASHBOARD';
		}

		return dashboard;
	}

	isDashboardDirty() {
		if (this.props.mode === 'viewer' || this.skipDirtyCheck) {
			return false;
		}

		if (this.designer && this.designer.isDirty())
			return true;

		let skipCleanUp = true;

		let currentDashboard = this.getDashboardForSaving(skipCleanUp);
		return JSON.stringify(currentDashboard) !== this.initialDashboard;
	}

	isDirty(){
		return this.designer.isDirty();
	}
}

DashboardDesigner.propTypes = {
	id: PropTypes.any,
	dashboard: PropTypes.object,
	mode: PropTypes.oneOf(["viewer", "designer", 'designer-preview']),
	onLoaded: PropTypes.func
}
