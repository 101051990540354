import Utils from 'tools/utils';
import Widget from 'areas/service-boards/widget';
import Settings from 'settings';
import CustomNotification from 'controls/customNotification';
import RemoteEventsManager from 'core/remoteEventsManager';
import Renderer from 'tools/renderer';
import ErrorHandler from 'core/errorHandler';
import State from 'tools/state';
import {translator} from 'core';
import {IncidentsRouter} from "areas/incidents/bundleDescription";
import {getServiceState} from 'controls/stateRenderer/serviceState';
import {ServicesRouter} from "../bundleDescription";

const i = translator({
  "Selective services": {
    "no": "Utvalgte tjenester",
    "en": "Selective services"
  }
});

export function SelectiveServicesWidget(config) {
	Widget.call(this, config);

	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}
	this.initComponent();
};

export {SelectiveServicesWidget as default}

jQuery.extend(SelectiveServicesWidget.prototype, Widget.prototype, {
	initComponent: function () {
		var widgetContentDiv;
		if (!this.renderTo) {
			this.renderTo = $('#' + this.id).find('.cw_section_content');
			widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		}

		this.getData();
		this.subscribe();
		this.setColumns();
		this.statusNotification = new CustomNotification({
			animationTime: 0,
			appendToElement: widgetContentDiv,
		});
		if (this.configuration.showIncidentCounter && this.configuration.showIncidentOverview) {
			this.setToolTip();
		}

		//quickfix for 2.5: removed assign not supported in ie11; to be handled by polyfill
		//this.instanceConfiguration = Object.assign({}, this.configuration);
		this.instanceConfiguration = $.extend({}, this.configuration);

		this.setTitleIfEmpty(i('Selective services'));
		this.setTimer();
	},
	setTimer: function () {
		var scope = this;
		this.interval = setInterval(function() {
			scope.getData();
		}, 30000)
	},
	/**
	 * Get chart data
	 */
	getData: function () {
		kendo.ui.progress(this.renderTo, true);
		var data, url = this.requestPath + 'services/selective/states';
		if (this.configuration.dynamicServices) {
			data = {
				'accountIds': this.configuration.accountsList
			};
		} else {
			data = {
				'serviceIds' : this.configuration.servicesList
			};
		}

		const tags = [];
		if (this.configuration.tags) {
			for (var i = 0; i < this.configuration.tags.length; i++) {
				if (this.configuration.tags[i].name) {
					tags.push(this.configuration.tags[i].name);
				}
				else {
					tags.push(this.configuration.tags[i]);
				}
			}
		}
		data.tags = tags;

		const sort = [];

		data.sort = sort;

		const fieldMap = {
			'NAME': 'name',
			'STATE_DURATION': 'stateDuration',
			'STATE': 'serviceState',
			'INCIDENT': 'openIncidents'
		};

		if (this.configuration.sorting) {
			for (const sortingItem of this.configuration.sorting) {
				sort.push({ field: fieldMap[sortingItem.type], dir: sortingItem.sorting.toLowerCase() })
			}
		}
		else if (this.configuration.sortingOption && this.configuration.sortingDirection) {
			sort.push({ field: fieldMap[this.configuration.sortingOption], dir: this.configuration.sortingDirection.toLowerCase() })
		}

		Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (result) {
			var length = result.length;
			if (!length) {
				//this.renderTo.empty().append('<div class="cw_section_placeholder"><span class="glyphicons status_icon exclamation-sign"></span><span class="cw_placeholder_message">' + result.message + '</span></div>');
				this.renderTo.empty();
				this.statusNotification.setOptions({
					message: result.message,
					status: 'error'
				}).show();
			} else {
				this.data = result;
				this.dataLength = length;
				this.render();
			}
		}, this), function () {
			kendo.ui.progress(this.renderTo, false);
		}, 60000, $.proxy(function () {
			kendo.ui.progress(this.renderTo, false);
			this.statusNotification.setOptions({
				message: lang.messages.NO_DATA_AVAILABLE,
				status: 'error'
			}).show();
		}, this));
	},

	filterServiceState: function (item, serviceStateList, operator) {
		var isInclude;
		if (serviceStateList.indexOf(item.serviceState) !== -1) {
			isInclude = true;
		} else {
			isInclude = false;
		}
		if (item.operatingState === 'IN_MAINTENANCE') {
			if (serviceStateList.indexOf(item.operatingState) !== -1) {
				isInclude = true;
			} else {
				isInclude = false;
			}
		}
		switch (operator) {
			case '==':
				return isInclude;
			case '!=':
				return !isInclude;
			default:
				return true;
		}
	},

	filterDurationOperator: function (item, durationOperator, duration, durationUnit) {
		let stateDuration = Math.floor(item.stateDuration / 1000);
		let limitDuration;

		duration = +duration;

		switch (durationUnit) {
			case 'SECONDS':
				limitDuration = duration;
				break;
			case 'MINUTES':
				limitDuration = duration * 60;
				break;
			case 'HOURS':
				limitDuration = duration * 3600;
				break;
			case 'DAYS':
				limitDuration = duration * 24 * 3600;
				break;
		}

		switch (durationOperator) {
			case '==':
				return stateDuration === limitDuration;
			case '!=':
				return stateDuration !== limitDuration;
			case '>=':
				return stateDuration >= limitDuration;
			case '>':
				return stateDuration > limitDuration;
			case '<=':
				return stateDuration <= limitDuration;
			case '<':
				return stateDuration < limitDuration;
		}
	},
	filterIncidents: function (item, incidentsOperator, incidents) {
		let incidentCount = (item && item.openIncidents) || 0;

		switch (incidentsOperator) {
			case '==':
				return incidentCount === incidents;
			case '!=':
				return incidentCount !== incidents;
			case '>=':
				return incidentCount >= incidents;
			case '>':
				return incidentCount > incidents;
			case '<=':
				return incidentCount <= incidents;
			case '<':
				return incidentCount < incidents;
		}
	},

	checkFilter: function (item) {
		let serviceStateList = this.instanceConfiguration.serviceStateList;
		let operator = this.instanceConfiguration.operator;
		let durationOperator = this.instanceConfiguration.durationOperator;
		let duration = this.instanceConfiguration.duration;
		let durationUnit = this.instanceConfiguration.durationUnit;
		let incidents = this.instanceConfiguration.incidents;
		let incidentsOperator = this.instanceConfiguration.incidentsOperator;

		let value = true;

		if (serviceStateList && serviceStateList.length && operator) {
			let serviceStateListValues = [];
			for (let i = 0; i < serviceStateList.length; i++) {
				serviceStateListValues.push(serviceStateList[i].value);
			}
			value = this.filterServiceState(item, serviceStateListValues, operator);
		}

		if (value && durationOperator && duration && durationUnit) {
			value = this.filterDurationOperator(item, durationOperator, duration, durationUnit);
		}

		if (value && incidents && incidentsOperator) {
			value = this.filterIncidents(item, incidentsOperator, incidents);
		}

		return value;
	},

	/*
	 * Handler function for rendering the widget HTML
	 */
	render: function () {
		var HTML = '';

		if (this.data && this.data.length) {
			HTML += this.data
				.filter((item) => this.checkFilter(item))
				.map((item) => this.renderItem(item)).join('');
		} else {
			HTML += '<div class="cw_field"></div>';
		}

		this.renderTo.empty().append(HTML);

		//workaround to fix exclamation mark not rendered on the correct item even though appended right in html
		let exclamationMarks = this.renderTo.find('.glyphicons.exclamation-mark');
		$(exclamationMarks).each(function() {
			$(this).attr('style', 'margin: 7px 0 0 7px!important; position: initial');
		});

		Utils.applyTooltip();
		if (State.currentApp?.dashboardDesigner?.props?.mode !== 'designer') {
			this.renderTo.find('.cw_service_part').off().on('click', $.proxy(this.onServiceClick, this));
			this.renderTo.find('.cw_incident_counter').off('click').on('click', $.proxy(this.onIncidentCounterClick, this));
			this.renderTo.find('.cw_incident_counter').off('mouseover').on('mouseover', $.proxy(this.onIncidentCounterOver, this));
		}

		$('#' + this.id).find('.cw_toggle_options').remove();
	},
	/*
	 * Handler function for rendering service item
	 * */
	renderItem: function (data) {
		var HTML = '', coverage;
		HTML += '<div class="cw_service_part modify_exclamation_mark" data-state-id="' + data.id + '" data-agent-status="' + data.agentStatus + '" data-coverage="' + data.coverage + '" data-operating-state="' + data.operatingState + '" data-qualifier-status="' + data.serviceState + '" data-incident-counter="' + data.openIncidents + '">';

		data.operatingState = data.operatingState;
		//HTML += Renderer.renderSummaryState(data.serviceState, '', data.operatingState, data.agentStatus, data.qualifierError, data.assetMaintenance);
		HTML += getServiceState(data.serviceState, '', data.operatingState, data.agentStatus, data.qualifierError, data.assetMaintenance);
		HTML += '<ul class="left w100 pointer cw_service_link" data-serviceid="' + data.id + '">';
		if (this.configuration.showAccountName) {
			HTML += '<li class="item"><span class="value">' + data.accountName + ' \u00bb ' + data.name + '</span></li>';
		} else {
			HTML += '<li class="item"><span class="value">' + data.name + '</span></li>';
		}
		HTML += '<li class="item"><span class="label">' + lang.STATE_DURATION + ': </span><span class="value">' + Renderer.duration(data.stateDuration) + '</span></li>';
		HTML += '<li class="item"><span class="value cw_operating_state ' + Renderer.operatingStateClass(data.operatingState) + '">' + Renderer.operatingState(data.operatingState) + '</span></li>';
		if (this.configuration.showDescription && data.description) {
			HTML += '<li class="item" title="' + data.description + '">' + '<span class="label">' + lang.DESCRIPTION + ': </span>' + '<span class="value">' + data.description + '</span>' + '</li>';
		}
		if (this.configuration.showInformation && data.information) {
			HTML += '<li class="item" title="' + data.information + '">' + '<span class="label">' + lang.INFORMATION + ': </span>' + '<span class="value">' + data.information + '</span>' + '</li>';
		}
		if (this.configuration.showIncidentCounter && data.openIncidents) {
			HTML += '<li class="item">' + lang.widget.INCIDENT_COUNTER + ': <span class="value cw_incident_counter">' + data.openIncidents + '</span></li>';
		}
		HTML += '</ul>';
		HTML += '</div>';
		return HTML;
	},
	/*
	 * Event click handler
	 */
	onServiceClick: function (e) {
		const target = $(e.currentTarget);
		const serviceId = target.data('state-id');
		this.navigator.go({
			url: ServicesRouter.details(serviceId)
		})
	},
	/*
	 * Handler function for clicking an incident counter
	 * @param {Object} e The click event object
	 * */
	onIncidentCounterClick: function (e) {
		this.renderTo.append('<div class="cw_incidents_grid"></div>');
		e.stopPropagation();
		e.preventDefault();
		var target = $(e.currentTarget);
		this.stateId = target.closest('.cw_service_part').data('state-id');
		var item = Utils.getFromListById(this.data, this.stateId);

		this.window = $('.cw_incidents_grid').kendoWindow({
			width: '1400px',
			height: '500px',
			title: '<a class="cw_header_grid_link">' + lang.INCIDENTS + '</a>',
			modal: true,
			close: $.proxy(function () {
				if (this.incidentsCounterGrid) {
					this.incidentsCounterGrid.destroy();
				}
				if (this.window) {
					this.window.destroy();
				}
			}, this),
			visible: false
		}).data('kendoWindow');
		var html = '<div class="cw_incidents_counter_grid">';
		html += '</div>';
		this.window.content(html);
		var columns = this.configuration.columns || {
			status: {
				hidden: false
			},
			incidentNumber: {
				hidden: false
			},
			subject: {
				hidden: false
			},
			accountName: {
				hidden: false
			},
			contentType: {
				hidden: false
			},
			priorityName: {
				hidden: false
			},
			owner: {
				hidden: false
			},
			created: {
				hidden: false
			},
			modified: {
				hidden: false
			},
			createdBy: {
				hidden: false
			},
			assignedTo: {
				hidden: false
			},
			description: {
				hidden: false
			}
		};
		var url = this.requestPath + 'accounts/' + item.accountId + '/services/' + item.id + '/incidents?status=OPEN';
		if (this.configuration.showClosedIncidents === true) {
			url = this.requestPath + 'accounts/' + item.accountId + '/services/' + item.id + '/incidents';
		}
		var sortField = '';
		if (!columns.created.hidden) {
			sortField = 'created';
		} else if (!columns.status.hidden) {
			sortField = 'status';
		}
		this.incidentsCounterDataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: url,
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			sort: [{
				field: sortField,
				dir: 'asc'
			}],
			error: ErrorHandler.kendoServerError
		});
		this.incidentsCounterGrid = $('.cw_incidents_counter_grid').kendoCustomGrid({
			dataSource: this.incidentsCounterDataSource,
			resizable: true,
			selectable: 'row',
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			columns: [{
				field: 'status',
				title: lang.STATUS,
				sortable: true,
				hidden: columns.status.hidden,
				width: 70
			}, {
				field: 'incidentNumber',
				title: lang.incidents.NUMBER,
				sortable: true,
				hidden: columns.incidentNumber.hidden,
				template: '<a class="cw_grid_link">#= incidentNumber#</a>',
				width: 60
			}, {
				field: 'subject',
				title: lang.incidents.SUBJECT,
				sortable: true,
				width: 240,
				attributes: {
					"class": "expand ellipsis"
				}
			}, {
				field: 'accountName',
				title: lang.account.ACCOUNT_NAME,
				sortable: true,
				hidden: columns.accountName.hidden,
				width: 100,
				attributes: {
					"class": "expand ellipsis"
				}
			}, {
				field: 'contentType',
				title: lang.TYPE,
				sortable: true,
				hidden: columns.contentType.hidden,
				width: 100,
				attributes: {
					"class": "expand ellipsis"
				}
			}, {
				field: 'priorityName',
				title: lang.incidents.PRIORITY_NAME,
				sortable: true,
				hidden: columns.priorityName.hidden,
				width: 65,
				attributes: {
					"class": "expand ellipsis"
				}
			}, {
				field: 'owner',
				title: lang.incidents.OWNER,
				sortable: true,
				hidden: columns.owner.hidden,
				width: 80,
				attributes: {
					"class": "expand ellipsis"
				}
			}, {
				field: 'created',
				title: lang.incidents.CREATED,
				sortable: true,
				template: '#= Renderer.browserDateRenderer(created, "datetime", "")#',
				hidden: columns.created.hidden,
				width: 120
			}, {
				field: 'modified',
				title: lang.incidents.MODIFIED,
				sortable: true,
				template: '#= Renderer.browserDateRenderer(modified, "datetime", "")#',
				hidden: columns.modified.hidden,
				width: 120
			}, {
				field: 'createdBy',
				title: lang.incidents.CREATED_BY,
				sortable: true,
				hidden: columns.createdBy.hidden,
				width: 90,
				attributes: {
					"class": "expand ellipsis"
				}
			}, {
				field: 'assignedTo',
				title: lang.incidents.ASSIGNED_TO,
				sortable: true,
				hidden: columns.assignedTo.hidden,
				width: 90,
				attributes: {
					"class": "expand ellipsis"
				}
			}, {
				field: 'description',
				title: lang.DESCRIPTION,
				sortable: true,
				hidden: columns.description.hidden,
				width: 120,
				attributes: {
					"class": "expand ellipsis"
				}
			}],
			dataBound: $.proxy(function () {
				this.window.open().center();
				if (State.currentApp?.dashboardDesigner?.props?.mode !== 'designer') {
					$('.cw_incidents_counter_grid').off();
					$('.cw_header_grid_link').on('click', $.proxy(this.onIncidentHeaderClick, this));
					$('.cw_incidents_counter_grid').on('click', '.cw_grid_link', $.proxy(this.onIncidentNumberClick, this));
				}
			}, this),
			change: $.proxy(this.onRowExpand, this)
		}).data('kendoCustomGrid');
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function (e) {
		var selectedRow = $(e.sender.select());
		selectedRow.find('.expand').toggleClass('ellipsis');
	},
	/*
	 * Handler function for mouse over incident counter
	 * @param {Object} e The mouse over event object
	 * */
	onIncidentHeaderClick: function (e) {
		var item = Utils.getFromListById(this.data, this.stateId);

		this.navigator.go({
			url: IncidentsRouter.list({
				filter: 'SERVICE',
				serviceAccountId: item.accountId,
				serviceId: item.id
			})}
		)

		if (this.window) {
			this.window.close();
		}
	},
	onIncidentCounterOver: function (e) {
		var target = $(e.currentTarget);
		var stateId = target.closest('.cw_service_part').data('state-id');
		var item = Utils.getFromListById(this.data, stateId);
		var html = item.openIncidents + ' ' + lang.widget.messages.OPEN_INCIDENTS + ' ' + lang.AND.toLowerCase() + ' ' + item.closedIncidents + ' ' + lang.widget.messages.CLOSED_INCIDENTS;
		html += '<br />' + lang.widget.messages.COUNTER_CLICK;
		if (this.tooltipOverview) {
			this.tooltipOverview.options.content = html;
		}
	},
	onIncidentNumberClick: function (e) {

		var target = $(e.currentTarget);
		var uid = target.closest('tr').attr('data-uid');
		var data = this.incidentsCounterDataSource.getByUid(uid);

		this.navigator.go({url: IncidentsRouter.details(data.id)});

		if (this.window) {
			this.window.close();
		}

	},
	/*
	* Handler function for setting the tooltip
	* */
	setToolTip: function () {
		this.tooltipOverview = this.renderTo.kendoTooltip({
			filter: '.cw_incident_counter',
			autoHide: false,
			position: 'bottom',
			width: 260,
			show: function (e) {
				e.sender.popup.element.addClass('warning_tooltip');
			}
		}).data("kendoTooltip");
	},
	/**
	 * Called when a metric event is received
	 * @param {Object} data The event data object
	 */
	onEvents: function (data) {
		var reload = false, elem, agentStatus, coverage, operatingState, serviceState, incidentCounter;
		for (var i = 0, length = data.length; i < length; i++) {
			elem = $('[data-state-id="' + data[i].id + '"]');
			if (elem.length) {
				agentStatus = elem.data('agent-status');
				coverage = elem.data('coverage');
				operatingState = elem.data('operating-state');
				serviceState = elem.data('qualifier-status');
				incidentCounter = elem.data('incident-counter');

				if (data[i].agentStatus !== agentStatus || data[i].coverage !== coverage || data[i].operatingState !== operatingState || data[i].serviceState !== serviceState || (this.configuration.showIncidentCounter && data[i].openIncidents !== incidentCounter)) {
					reload = true;
					break;
				}

			} else {
				reload = true;
			}
		}

		if (reload) {
			this.getData();
		}
	},
	/**
	 * Triggered after widget resize
	 */
	onResize: function () {
		this.setColumns();
	},
	/**
	 * Set number of columns for the widget
	 */
	setColumns: function () {
		var width = this.renderTo.width();
		var widget = this.renderTo.parent();
		widget.removeClass('three_cols').removeClass('two_cols').removeClass('three_cols');
		if (width >= 1280) {
			widget.addClass('four_cols');
		} else if (width >= 960 && width < 1280) {
			widget.addClass('three_cols');
		} else if (width >= 560 && width < 960) {
			widget.addClass('two_cols');
		}
	},
	/**
	 * Subscribes to qualifier emtrics events
	 */
	subscribe: function () {
		var subscriptionObj = [], event = {};
		event.eventType = 'ServiceSummary';
		if (this.configuration.dynamicServices) {
			event.accountIds = this.configuration.accountsList;
		} else {
			event.serviceIds = this.configuration.servicesList;
		}
		subscriptionObj.push(event);

		var event = {};
		event.eventType = 'ServiceAdmin';
		event.actionTypes = ['SERVICE_CREATE', 'SERVICE_UPDATE', 'SERVICE_DELETE'];
		if (this.configuration.dynamicServices) {
			event.accountIds = this.configuration.accountsList;
		} else {
			event.serviceIds = this.configuration.servicesList;
		}
		subscriptionObj.push(event);

		this.subscriberId = this.id;
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		if (this.tooltipOverview) {
			this.tooltipOverview.destroy();
		}
		clearInterval(this.interval);
		Widget.prototype.destroy.call(this);
	}
});
