import {ConfigProvider} from "antd";
import React from "react";
import {observer} from "mobx-react";


export type AntConfigProviderProps = {
	children: React.ReactNode;
	theme?: any
}

export const AntConfigProvider = observer((props: AntConfigProviderProps) => {
	const theme = React.useMemo(() => {
		const defaultTheme = {
			token: {
				fontFamily: 'Oxygen, Arial, sans-serif',
				fontSize: 12,
				lineHeight: 1.5
			},
			cssVar: true,
			hashed: false
		};
		if (props.theme) {
			const {token, ...others} = props.theme;
			defaultTheme.token = Object.assign(defaultTheme.token, token);
			return Object.assign(defaultTheme, others);
		}
		return defaultTheme;
	}, [props.theme])

	return <ConfigProvider theme={theme}>
		{props.children}
	</ConfigProvider>
})
