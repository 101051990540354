import {CustomDataEntry} from "controls/designer/customDataEntry";
import {CustomDataListEntry} from "controls/designer/features/widgets/custom-data-list/customDataListEntry";
import React from "react";
import {GridStore} from "controls/grid/gridStore";
import {getWidgetDescription, WidgetDescription} from "controls/designer/features/widgets/allWidgets";
import {MobxManager} from "framework/mobx-integration";
import {DesignerStore} from "controls/designer/designerStore";
import {makeAutoObservable, toJS} from "mobx";
import {getDataSourcesList} from "controls/designer/features/widgets/custom-data-list/api";
import {serialize} from "serializr";
import {apiFetch} from "framework/api";
import {getEntityDataSource} from "areas/dashboards/graph-editor-extensions/datasources/entityDataSource";
import {RuleConfigurationBuilder} from "controls/queryBuilder/ruleDefinition";
import {ArrayDataProvider} from "controls/grid/arrayDataProvider";
import {GridSelectionType} from "controls/grid/gridConfiguration";
import {EditableCell, textAreaEditorRenderer} from "controls/grid/renderers/editableCell";
import {Actions} from "controls/designer/actionsManager/actions";
import {createContainer, openModal} from "controls/react/ant/antModal";
import {DataRegistryWindow} from "areas/management/data-registry/dataRegistryWindow";
import {ApplicationState} from "framework/applicationState";
import {Observer} from "mobx-react";
import {Section, Toolbar} from "controls/react/layout";
import {DataSizeIndicator} from "controls/grid/dataSizeIndicator";
import {GridSearchInput} from "controls/grid/gridSearchInput";
import {Grid} from "controls/grid/grid";


const i18n = require('core/localization/localization').translator({
	"Widget name": {
		"no": "Widget navn"
	},
	"Data type": {
		"no": "Data type"
	},
	"Show Data sources list": {
		"no": "Vis datakilde liste",
		"en": "Show datasource list"
	},
	"Notes": {
		"no": "Notater"
	},
	"Shape/{0}": {
		"no": "Form/{0}"
	},
	"Dashboard data": {
		"no": "Dashboard data"
	},
});

type CustomDataListWindowStoreOptions = {
	dataSourcesFilter?: (items: CustomDataEntry) => boolean
	actions?: (item: CustomDataListEntry) => React.ReactNode
}

export class CustomDataListWindowStore {
	gridStore: GridStore<CustomDataListEntry>
	descriptions: WidgetDescription<any>[]

	mobx = new MobxManager()
	opened: boolean = false

	constructor(public store: DesignerStore, public options?: CustomDataListWindowStoreOptions) {
		makeAutoObservable(this)

		this.options ??= {}

		this.options.dataSourcesFilter ??= () => true
	}

	async open(){
		if(this.opened)
			return

		this.createGridStore()

		this.opened = true

		await openModal({
			footer: null,
			width: 1200,
			style: {
				top: 170,
			},
			height: 400,
			mask: false,
			maskClosable: false,
			resizable: true,
			draggable: true
		},
			<Observer>{() =>{
				if(!this.gridStore)
					return null

				return <Section contentPadding={false}>
					<Toolbar title={i18n('Dashboard data')}>
						<DataSizeIndicator store={this.gridStore}/>
						<GridSearchInput store={this.gridStore}/>
					</Toolbar>
					<Grid store={this.gridStore}/>
				</Section>
			}
			}</Observer>
		)

		this.opened = false
	}

	createGridStore() {
		if (this.gridStore != null)
			return

		this.mobx.reaction(() => getDataSourcesList({
			dataSources: this.store.customData
				.filter(x => x.dataSource || x.widgetConfig)
				.filter(this.options.dataSourcesFilter)
				.map(x => serialize(x))
		}), async request => {
			const response = await apiFetch(request)

			if (!response.success) {
				return
			}

			//we need to make all fields searchable so instead of building values during the render time
			//we need to pre-poulate the array with the searchable daat
			response.data.forEach(item => {
				const customData = this.store.customData.find(x => x.id == item.id)

				if (customData.widgetConfig) {
					item.name = customData.widgetConfig.title
					const description = getWidgetDescription(customData.widgetConfig.type)
					item.customDataType = description?.fullTitle ?? customData.widgetConfig.type
				} else if (customData.dataSource) {
					item.name = i18n('Shape/{0}', getEntityDataSource().find(x => x.id == customData.dataSource.type)?.name)
					item.customDataType = customData.dataSource?.type
				}
			})

			const filters = RuleConfigurationBuilder.create<CustomDataListEntry>()
				.addText('name', i18n('Widget name'))
				.addText('customDataType', i18n('Data type'))
				.addText('customDataName', i18n('Name'))
				.build();

			this.gridStore = new GridStore<CustomDataListEntry>({
				dataProvider: new ArrayDataProvider(response.data, filters),
				selection: GridSelectionType.None,
				columns: [{
					field: 'name',
					title: i18n('Widget name'),
					renderer: (item) => ({
						content: item.name,
						onClick: () => this.selectCell(item)
					}),
					width: 200
				}, {
					field: 'customDataType',
					title: i18n('Data type'),
					renderer: (item) => ({
						content: item.customDataType,
						onClick: () => this.selectCell(item)
					}),
					width: 200
				}, {
					field: 'customDataName',
					title: i18n('Name'),
					renderer: (item) => ({
						content: item.customDataName,
						onClick: () => this.selectCell(item)
					}),
				}, {
					field: 'notes',
					title: i18n('Notes'),
					renderer: (item, extra) => {
						const customData = this.getCustomData(item)
						if (!customData)
							return null

						return {
							content: <EditableCell model={customData.notes}
							                       field={item.customDataId ? item.customDataId : 'default'}
							                       extra={extra}
							                       rendererEditor={textAreaEditorRenderer}/>
						}
					},
					minWidth: 200
				}, {
					field: 'action',
					title: i18n('Action'),
					renderer: (item) => this.options.actions(item),
					width: 90
				}]
			})
		}, {
			fireImmediately: true
		})
	}

	selectCell = (customDataListEntry: CustomDataListEntry) => {
		this.store.graph.setSelectionCells([this.getCustomData(customDataListEntry).cell])
	}

	getCustomData = (customDataWindowListEntry: CustomDataListEntry) => {
		return this.store.customData
			.find(x => x.id == customDataWindowListEntry.id)
	}

	destroy() {
		this.mobx.destroy()
	}
}
