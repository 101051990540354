import './antDatePicker.less';

import React from "react";
import {DatePicker, DatePickerProps, TimePicker} from "antd";
import {observer} from "mobx-react";
import {RangePickerProps} from "antd/lib/date-picker";
import classnames from "classnames";
import enLocale from "antd/es/date-picker/locale/en_GB"
import noLocale from "antd/es/date-picker/locale/nb_NO"
import {ApplicationState} from "framework/applicationState";
import {useDefaultZIndex} from "framework/react-integration";
import {controlPopupZIndex} from "controls/react/ant/zIndexes";

const datePickerB = require('b_').with('ant-date-picker');

type AntDatePickerProps = DatePickerProps & {}

export const AntDatePicker = observer((props: AntDatePickerProps) => {
	const className = classnames(datePickerB(), props.className)

	const {format, popupStyle, ...others} = props;

	const popupStyleWrapped = useDefaultZIndex(popupStyle, controlPopupZIndex)

	return <DatePicker format={format ?? ApplicationState.dateFormat}
	                   className={className}
	                   popupStyle={popupStyleWrapped}
	                   locale={ApplicationState.locale == 'no' ? noLocale : enLocale}
	                   {...others}
	/>;
});

const {RangePicker} = DatePicker;

type AntRangePickerProps = RangePickerProps & {};

export const AntRangePicker = observer((props: AntRangePickerProps) => {
	const locale = ApplicationState.locale == 'no' ? noLocale : enLocale
	const {format, popupStyle, ...others} = props;

	const popupStyleWrapped = useDefaultZIndex(popupStyle, controlPopupZIndex)

	return <RangePicker format={format ?? ApplicationState.dateFormat}
	                    locale={locale}
	                    popupStyle={popupStyleWrapped}
	                    {...others}/>
})

export const AntTimeRangePicker = observer((props: AntRangePickerProps) => {
	const locale = ApplicationState.locale == 'no' ? noLocale : enLocale

	const {format, popupStyle, ...others} = props;

	const popupStyleWrapped = useDefaultZIndex(popupStyle, controlPopupZIndex)

	return <TimePicker.RangePicker format={format ?? ApplicationState.timeFormat}
	                               popupStyle={popupStyleWrapped}
	                               locale={locale}
	                               {...others}/>
})

export const AntTimePicker = observer((props: AntDatePickerProps) => {
	const locale = ApplicationState.locale == 'no' ? noLocale : enLocale

	const {format, popupStyle, ...others} = props;

	const popupStyleWrapped = useDefaultZIndex(popupStyle, controlPopupZIndex)

	return <TimePicker format={format ?? ApplicationState.timeFormat}
	                   popupStyle={popupStyleWrapped}
	                   locale={locale}
	                   {...others}/>
})
