import React from "react";
import ReactDOM from "react-dom";

import {observer} from "mobx-react";
import {Popconfirm, PopconfirmProps} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {useDefaultZIndex} from "framework/react-integration";
import {controlPopupZIndex} from "controls/react/ant/zIndexes";

export interface AntPopconfirmProps extends PopconfirmProps {
	type?: 'question' | 'message'
}

export const AntPopconfirm = observer((props: AntPopconfirmProps) => {
	const {overlayStyle, ...rest} = props

	const getIcon = React.useCallback(() => {
		switch (props.type) {
			case 'question':
				return <QuestionCircleOutlined style={{color: 'red'}}/>
			default:
				return undefined;
		}
	}, [props.type]);

	const overlayStyleWrapped = useDefaultZIndex(overlayStyle, controlPopupZIndex)

	return <Popconfirm {...props}
	                   overlayStyle={overlayStyleWrapped}
	                   icon={getIcon()}/>;
});

export function showAtElement(left: number, top: number, props: AntPopconfirmProps) : Promise<boolean>{
	return new Promise<boolean>(resolve => {
		const {onOpenChange, visible, onCancel, onConfirm, ...rest} = props

		const container = document.createElement("div")

		document.body.append(container)
		container.style.position = 'absolute'
		container.style.left = (left - 50) + "px"
		container.style.top = top + "px"
		container.style.width = "100px"

		const onOpenChangedWrapper = (visible: boolean) => {
			onOpenChange?.(visible)
			ReactDOM.unmountComponentAtNode(container)
		}

		const onConfirmWrapper = (e?: React.MouseEvent<HTMLElement>) => {
			onConfirm?.(e)
			resolve(true)
		}

		const onCancelWrapper = (e?: React.MouseEvent<HTMLElement>) => {
			onCancel?.(e)
			resolve(false)
		}

		ReactDOM.render(
			<AntPopconfirm open={true}
			               onOpenChange={onOpenChangedWrapper}
			               onConfirm={onConfirmWrapper}
			               onCancel={onCancelWrapper}
			               {...rest}>
				<div></div>
			</AntPopconfirm>,
			container
		)
	})
}
