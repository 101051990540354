import React from "react"
import {observer} from "mobx-react"
import ReactDOM from "react-dom"

import {Toolbar} from "controls/react/layout/toolbar"
import {Section} from "controls/react/layout/section"
import MenuButton from "controls/react/menuButton"
import {RefreshTimer} from "controls/grid/refreshTimer"
import {GridFilteredIndicator} from "controls/grid/gridFilteredIndicator"
import {
	TimePeriodAppearance,
	TimePeriodSelector
} from "controls/react/form/timePeriodSelector"
import {DataSizeIndicator} from "controls/grid/dataSizeIndicator"
import {GridSearchInput} from "controls/grid/gridSearchInput"
import {ViewSelector} from "controls/grid/viewManager/viewSelector"
import {Grid} from "controls/grid/grid"
import {EventsSummaryStore, EventsSummaryStoreProps} from "./eventsSummaryStore"
import {RemoteDataProvider} from "controls/grid/remoteDataProvider"
import {GridStore, linkGridAdditionalPayload} from "controls/grid/gridStore"
import {getEventSummaryFilters} from "./api"
import {GridDefaultFilterEntry, GridSelectionType} from "controls/grid/gridConfiguration"
import {EventsSummaryEntry} from "./eventsSummaryEntry"
import {EventsTimePeriods} from "./eventsSummaryPage"
import {Window} from 'controls/react/kendoWrappers'
import {GridMenuButton} from "controls/grid/plugins/grid-menu/gridMenuButton";
import {TimePeriodType} from "controls/react/form/timePeriodType";

const i = require('core/localization/localization').translator();

export type EventsSummaryPopupProps = EventsSummaryStoreProps & {
	onClose?: () => void,
	columns?: string[],
	sorting?: {field: string, direction: 'asc'|'desc'}[],
	showViewsSelector?: boolean,
	timePeriod?: TimePeriodType,
	eventTypes?: string[],
	filters?: GridDefaultFilterEntry[],
	width?: number,
	height?: number
}

export type WindowDisposer = {
	destroy: () => void
}

export function showEventsSummaryPopup(args?: EventsSummaryPopupProps) : WindowDisposer{
	const container = document.createElement('div')
	document.body.appendChild(container)

	const {onClose, ...rest} = args ?? {}

	const unmount = () => {
		ReactDOM.unmountComponentAtNode(container);
		onClose && onClose()
	}

	ReactDOM.render(
		<EventsSummaryPopup
			onClose={unmount}
			{...rest}
		/>,
		container)

	return {
		 destroy: unmount
	}
}

export const EventsSummaryPopup = observer(
	class InnerWidget extends React.Component<EventsSummaryPopupProps> {
		store: EventsSummaryPopupStore

		constructor(props: EventsSummaryPopupProps) {
			super(props)
			this.store = new EventsSummaryPopupStore(props)
		}

		render() {
			if(!this.store?.gridStore?.selfInitialized)
				return null


			const {width: maxWidth, height: maxHeight} = document.body.getBoundingClientRect()

			return <Window height={Math.min(this.props.height ?? 450, maxHeight)}
			               width={Math.min(this.props.width ?? 750, maxWidth)}
			               title={this.store.title}
			               onClose={this.props.onClose}
			               flexContent={true}
				>
				<Section contentPadding={false} height={'fit'}>
					<Toolbar>
						<GridMenuButton store={this.store.gridStore}/>
						<TimePeriodSelector periods={EventsTimePeriods}
						                    appearance={TimePeriodAppearance.Buttons}
						                    size={"small"}
						                    onModal={true}
						                    {...linkGridAdditionalPayload(this.store.gridStore, "timePeriod")}/>
						<DataSizeIndicator store={this.store.gridStore}/>
						<GridSearchInput store={this.store.gridStore}/>
						{this.props.showViewsSelector && <ViewSelector store={this.store.gridStore}/>}
					</Toolbar>
					<Grid store={this.store.gridStore}/>
				</Section>
			</Window>
		}

		componentWillUnmount() {
			this.store?.destroy()
		}
	}
);

class EventsSummaryPopupStore extends EventsSummaryStore {
	props: EventsSummaryPopupProps

	constructor(props: EventsSummaryPopupProps) {
		super();

		this.props = props
		this.init()
	}

	async init() {
		this.gridStore =  new GridStore<EventsSummaryEntry>({
			columns:  this.getColumns(),
			dataProvider: new RemoteDataProvider({
				dataSource: this.getDefaultDatasource(this.props),
				filtersSource: getEventSummaryFilters()
			}),
			plugins:[
				this.getGridMenuPlugin(),
			],
			defaults:{
				columns: this.props.columns,
				payload: this.getCustomPayloadDefaults(this.props.timePeriod),
				sorting: this.props.sorting,
				filters: this.props.filters
			},
			selection: GridSelectionType.Many
		})
	}
}
