import {observer} from "mobx-react"

import {ApplicationState} from "framework/applicationState";
import {WidgetConfig, WidgetConfigurationProps} from "controls/designer/features/widgets/widgetConfig";
import {GridState} from "controls/grid/gridState";
import React from "react";
import {WidgetProps} from "controls/designer/features/widgets/widgetProps";
import {DefaultWidgetWrapper} from "controls/designer/features/widgets/defaultWidgetWrapper";
import {Toolbar} from "controls/react/layout/toolbar";
import {Section} from "controls/react/layout/section";
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {FormEntryNew} from "controls/react/form/formEntryNew";
import {AntInput} from "controls/react/ant/antInput";
import {AccountSelector} from "controls/react/accountSelector";
import {GridStore, linkGridAdditionalPayload} from "controls/grid/gridStore";
import {
	apiFetch,
	ApiRequest,
	PagedList,
	useRemoteListForSelect
} from "framework/api";
import {RulesConfiguration} from "controls/queryBuilder/ruleDefinition";
import {deserialize, serialize} from "serializr";
import {ExternalStateProvider} from "controls/grid/stateProviders";
import {RemoteDataProvider} from "controls/grid/remoteDataProvider";
import {StylesFromMxGraphCellPlugin} from "controls/grid/plugins/stylesFromMxGraphCellPlugin";
import {GridSelectionType} from "controls/grid/gridConfiguration";
import {GridDataItem} from "controls/grid/gridDataItem";
import {GridColumnConfig} from "controls/grid/gridColumnConfig";
import {Grid} from "controls/grid/grid";
import {TimePeriodAppearance, TimePeriodSelector} from "controls/react/form/timePeriodSelector";
import {DataSizeIndicator} from "controls/grid/dataSizeIndicator";
import {GridSearchInput} from "controls/grid/gridSearchInput";
import {ViewSelector} from "controls/grid/viewManager/viewSelector";
import {EventsTimePeriods} from "areas/summary/events/eventsSummaryPage";
import {makeAutoObservable} from "mobx";
import {AntSelect, AntSelectOption, fromIdName} from "controls/react/ant/antSelect";
import {TimePeriodType} from "controls/react/form/timePeriodType";


const i18n = require('core/localization').translator({

}, sharedLocalization)

export interface DataSourcesGridWidgetConfig extends WidgetConfig {
	dataSourceId: string
}

export type DataSourcesGridWidgetPersistedState = {
	grid: GridState<any>
}

export function getWidgetDescription(){
	return {
		form: WidgetConfiguration,
		defaultConfig: {
			type: 'data-sources-grid',
			title: i18n('Datasource grid'),
			accountId: ApplicationState.accountId
		},
		minWidth: 800,
		widget: DataSourcesGridWidget,
		fullTitle: i18n('Datasource grid'),
	}
}

export const WidgetConfiguration = observer((props:WidgetConfigurationProps<DataSourcesGridWidgetConfig>) => {

	const dataSources = useRemoteListForSelect(getDataSources({
		accountId: props.config.accountId
	}))

	return <Section>
		<Section appearance={"frame-top-only"} title={i18n('General')} childrenPadding={true}>
			<FormEntryNew label={i18n('Title')} model={props.config} modelField={'title'}>
				<AntInput/>
			</FormEntryNew>
		</Section>
		<Section appearance={"frame-top-only"} title={i18n('Data')} childrenPadding={true}>
			<FormEntryNew label={i18n('Account')} model={props.config} modelField={'accountId'}>
				<AccountSelector/>
			</FormEntryNew>
			<FormEntryNew label={i18n('Datasource')} model={props.config} modelField={'dataSourceId'}>
				<AntSelect {...dataSources} nameField={"name"} valueField={'dataSourceId'}/>
			</FormEntryNew>
		</Section>
	</Section>
})

export const DataSourcesGridWidget = observer(
	class InnerWidget extends React.Component<WidgetProps<DataSourcesGridWidgetConfig, DataSourcesGridWidgetPersistedState>>{
		//store: EventsSummaryGridWidgetStore
		store: DataSourcesGridWidgetStore

		constructor(props: WidgetProps<DataSourcesGridWidgetConfig, DataSourcesGridWidgetPersistedState>) {
			super(props)

			this.store = new DataSourcesGridWidgetStore(props)
		}

		render() {
			// if(!this.store?.gridStore?.selfInitialized)
			// 	return null
			//
			// const editMode = !this.props.dashboardSettings.readOnly
			// const showMenu = this.props.config.showMenu
			// const showFilters =  this.props.config.showFilters
			// const showRefreshTimer =  this.props.config.showRefreshTimer
			// const showTimeSettings = this.props.config.showTimeSettings

			if(this.store.gridStore == null)
				return null

			return (
				<DefaultWidgetWrapper {...this.props} appearance={"none"}>
					<Section appearance="none" height={"fit"}>
						<Toolbar>
							<TimePeriodSelector periods={EventsTimePeriods}
							                    appearance={TimePeriodAppearance.Buttons}
							                    size={"small"}
							                    {...linkGridAdditionalPayload(this.store.gridStore, "timePeriod")}/>
							<DataSizeIndicator store={this.store.gridStore}/>
							<GridSearchInput store={this.store.gridStore}/>
							<ViewSelector store={this.store.gridStore}/>
						</Toolbar>
						<Grid store={this.store.gridStore}/>
					</Section>
				</DefaultWidgetWrapper>
			)
		}

		getStateForSaving() {
			if (!this.store.gridStore) {
				return {}
			}

			return {
				grid: serialize(this.store.gridStore?.state)
			};
		}

		componentWillUnmount(){
			this.store?.destroy()
		}
	}
)

export class DataSourcesGridWidgetStore{
	gridStore: GridStore<any>
	constructor(public props: WidgetProps<DataSourcesGridWidgetConfig, DataSourcesGridWidgetPersistedState>) {
		makeAutoObservable(this)

		this.init()
	}

	async init(){
		const filtersResponse = await apiFetch(getDataSourceFiltersConfiguration(this.props.config))

		this.gridStore = new GridStore<any>({
			columns: this.getColumns(filtersResponse.data),
			stateProviders: [
				new ExternalStateProvider(deserialize<GridState<any>>(GridState, this.props.persistedState?.grid)),
			],
			dataProvider: new RemoteDataProvider({
				dataSource: getDataSourceData(this.props.config),
				filtersSource: getDataSourceFiltersConfiguration(this.props.config)
			}),
			plugins: [
				//this.getAutoReloadPlugin(this.props.config),
				new StylesFromMxGraphCellPlugin(this.props.designer.store, this.props.cell)
			],
			defaults:{
				payload: {
					timePeriod: {
						period: TimePeriodType.Last24Hours
					},
					showHistoricEvents: false
				}
			},
			selection: GridSelectionType.Many
		})
	}

	getColumns(rules: RulesConfiguration){
		return Object.keys(rules).map(columnName => {
			let column : GridColumnConfig<any> = {
				field: columnName,
				title: rules[columnName].label
			}

			return column
		})
	}

	destroy(){

	}
}

type GetDatasourceFiltersConfigurationArgs = {
	accountId: string
	dataSourceId: string
}

export function getDataSourceFiltersConfiguration(args: GetDatasourceFiltersConfigurationArgs){
	return new ApiRequest<RulesConfiguration>({
		url: `dataSources/${args.dataSourceId}/ruleConfiguration`,
		accountId: args.accountId,
		accountBased: true,
		method: 'GET'
	})
}

type GetDatasourceDataArgs = {
	accountId: string
	dataSourceId: string
}

export function getDataSourceData(args: GetDatasourceDataArgs){
	return new ApiRequest<PagedList<GridDataItem<any>>>({
		url: `dataSources/${args.dataSourceId}/data`,
		accountBased: true,
		accountId: args.accountId,
		method: 'POST'
	})
}

type GetDataSourcesArgs = {
	accountId?: string
}

export class DataSource {
	accountId: string
	id: string
	parentId: string
	type: 'SCRIPT'
	name: string
	description: string
	caption: string
	unitType: string
	valueType: string
	attributes: {
		path: string
		parameters: string
		timeout: string
	}
	dataSelector: {
		type: 'LASTN'
		value: number
		fromTime: number
		toTime: number
		zoneId: string
		initializeStart: boolean
	}
	ttl: number
	timeCreate: number
	timeUpdate: number
	locale: string
	dataSourceId: string
}

export function getDataSources(args: GetDataSourcesArgs){
	return new ApiRequest<DataSource[]>({
		url: 'dataSources',
		accountBased: true,
		accountId: args?.accountId
	})
}
