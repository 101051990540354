import React from 'react'

import {ApplicationState} from "framework/applicationState";
import {observer} from "mobx-react";
import {WidgetConfig, WidgetConfigurationProps} from "controls/designer/features/widgets/widgetConfig";
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {WidgetProps} from "controls/designer/features/widgets/widgetProps";
import {Section} from "controls/react/layout";
import {FormEntryNew} from "controls/react/form/formEntryNew";
import {AntInput} from "controls/react/ant/antInput";
import {AccountSelector} from "controls/react/accountSelector";
import {apiFetch, useRemoteListForSelect} from "framework/api";
import {
	downloadReport,
	getEmbeddedUrl,
	getReport,
	listReports,
	ReportEntity,
	startReportGeneration
} from "areas/reports/api";
import {AntSelect} from "controls/react/ant/antSelect";
import {
	TimePeriod,
	TimePeriodAppearance,
	TimePeriodLabelSize,
	TimePeriodSelector
} from "controls/react/form/timePeriodSelector";
import {TimePeriodType} from "controls/react/form/timePeriodType";
import {DefaultWidgetWrapper} from "controls/designer/features/widgets/defaultWidgetWrapper";
import {AntButton} from "controls/react/ant/antButton";
import {FilePdfOutlined} from "@ant-design/icons";
import {makeAutoObservable} from "mobx";
import moment from "moment";
import {emulateFileDownload} from "tools/helpers/dom";
import {RedirectModifier} from "tools/ceeviewNavigator";
import {openModal} from "controls/react/ant/antModal";
import Settings from "settings";

const i18n = require('core/localization').translator({
	'PDF Open': {},
	'Click to generate the report': {}
}, sharedLocalization);

export function getWidgetDescription(){
	return {
		form: ReportWidgetConfiguration,
		defaultConfig: {
			type: 'report',
			title: i18n('Report'),
			accountId: ApplicationState.accountId,
			timePeriod: {
				period: TimePeriodType.Last7Days
			},
			openOption: OpenOption.NewTab
		},
		widget: ReportWidget,
		fullTitle: i18n('Presentation') + '/' + i18n('Report'),
		width: 100,
		height: 100,
		containerStyles: {
			noBorder: true,
			transparent: true,
			header: 'on-hover'
		}
	}
}

enum OpenOption {
	NewTab = 'new-tab',
	Popup = 'popup',
	Download = 'download'
}

const timePeriods = [TimePeriodType.PreviousQuarter, TimePeriodType.PreviousMonth,TimePeriodType.PreviousWeek,
	TimePeriodType.Last7Days, TimePeriodType.PreviousDay, TimePeriodType.CurrentDay, TimePeriodType.Custom ]

export interface ReportWidgetConfig extends WidgetConfig {
	reportId: string
	timePeriod: TimePeriod
	openOption: string
}

const ReportWidgetConfiguration = observer((props:WidgetConfigurationProps<ReportWidgetConfig>) => {
	const reports = useRemoteListForSelect(listReports({
		accountId: props.config.accountId,
	}), {
		converter: (r) => ({label: r.name, value: r.id})
	})

	// const openOptions = React.useMemo(() => {
	// 	return [{
	// 		label: i18n('Open in new tab'),
	// 		value: OpenOption.NewTab
	// 	}, {
	// 		label: i18n('Open in popup'),
	// 		value: OpenOption.Popup
	// 	}, {
	// 		label: i18n('Download'),
	// 		value: OpenOption.Download
	// 	}]
	// }, [])

	return <Section appearance={"none"}
	                contentPadding={false}>
		<Section title={i18n('General')} appearance={'frame-top-only'}>
			<FormEntryNew label={i18n('Title')} model={props.config} modelField={"title"}>
				<AntInput/>
			</FormEntryNew>
		</Section>
		<Section title={i18n('Data')} appearance={'frame-top-only'} childrenPadding={true}>
			<FormEntryNew label={i18n('Account')} model={props.config} modelField={"accountId"}>
				<AccountSelector/>
			</FormEntryNew>
			<FormEntryNew label={i18n('Report')} model={props.config} modelField={"reportId"}>
				<AntSelect {...reports}/>
			</FormEntryNew>
		</Section>
		<Section title={i18n('Display settings')} appearance={'frame-top-only'} childrenPadding={true}>
			<FormEntryNew model={props.config} modelField={"timePeriod"}>
				<TimePeriodSelector labelsSize={TimePeriodLabelSize.Full}
				                    appearance={TimePeriodAppearance.Buttons}
				                    periods={timePeriods}/>
			</FormEntryNew>
			{/*<FormEntryNew label={i18n('PDF open')} model={props.config} modelField={"openOption"}>*/}
			{/*	<AntSelect options={openOptions}/>*/}
			{/*</FormEntryNew>*/}
		</Section>
	</Section>
})

class ReportWidgetStore {
	generating = false
	report: ReportEntity

	constructor(public props: WidgetProps<ReportWidgetConfig, any>) {
		makeAutoObservable(this)

		this.init()
	}

	get config(){
		return this.props.config
	}

	async init() {
		const response = await apiFetch(getReport({reportId: this.config.reportId}))
		if (response.success) {
			this.report = response.data
		}
	}

	start = async () => {
		this.props.navigator.go({
			action: async (modifier, popup) => {
				this.generating = true

				const response = await apiFetch(startReportGeneration(({
					reportId: this.config.reportId,
					timeZone: this.report.timeZone,
					timePeriod: this.config.timePeriod
				})))

				if (response.success) {
					const request = downloadReport({
						jobId: response.data.id
					})

					const fileResponse = await apiFetch(request)

					if(modifier == RedirectModifier.Popup){
						await openModal({
								title: 'Report',
								footer: null,
								width: popup.width,
								height: popup.height,
								positionType: popup.position,
								resizable: true
							},
							<embed src={getEmbeddedUrl(response.data.id)}
							       type={"application/pdf"}
							       width={"100%"}
							       height={"100%"}/>
						)
					} else if( modifier == RedirectModifier.NewTab){
						window.open(Settings.httpRoot + `app2.0.jsp#/reports/jobs/${response.data.id}/pdf`, "_blank");
					}else {
						const reportName = response.data.reportName + '-' + moment().format('YYYYDDMM')
						if (fileResponse.success) {
							emulateFileDownload(reportName + '.pdf', fileResponse.data)
						}
					}
				}

				this.generating = false
			}
		})
	}
}

const ReportWidget = observer(
	class ReportWidgetInner extends React.Component<WidgetProps<ReportWidgetConfig>, any>{
		store: ReportWidgetStore

		constructor(props: WidgetProps<ReportWidgetConfig, any>) {
			super(props)

			this.store = new ReportWidgetStore(props);
		}

		render(){
			return <DefaultWidgetWrapper appearance={"none"}
			                             {...this.props}
			                             showHeader={false}>
					<AntButton shape={"circle"}
					           title={this.store.generating ? i18n('Generating, please waite') : i18n('Click to generate the report')}
					           loading={this.store.generating}
					           onClick={this.props.designer.config.chromeless ? this.store.start : null}
					           icon={<FilePdfOutlined />} type={'primary'}/>
			</DefaultWidgetWrapper>
		}
	})
