import {makeAutoObservable} from "mobx";

import {Cookies} from "core/cookies";
import {Permission} from "framework/permission";
import {MonitorType} from "areas/assets/monitors/monitorType";
import {LoginResponse} from "core/api";

// TODO: add other fields
export interface NotificationCounters {
	unreadMessages?: number
	unreadSystemErrors?: number
	eventSummaryCount?: number
	lastChanges?: number
}

class ApplicationStateInner {
	userName: string
	userId: string

	apiToken: string;
	accountId: string;
	accountName: string;
	accountLogoId: string;
	userPictureId: string;

	timezone: string;

	dateFormatLegacy: string;
	timeFormat: string;
	dateTimeFormatLegacy: string;

	locale: string;

	apiUrl: string = '/rest/';

	includeSubaccounts: boolean;
	includeSubaccountsMenuDisabled: boolean = false;

	standalone: boolean;

	enabledMonitors: {
		value: MonitorType
		text: string
		sharedAgents: boolean
	}[] = []

	userTags: string[] = []
	ignoreTags: boolean
	showUntagged: boolean

	permissions: Permission[] = []

	currentRouteId: string

	notificationCounters: NotificationCounters = {}

	constructor() {
		makeAutoObservable(this)
	}

	get isGuestUser(){
		return this.userName == 'public'
	}

	get dateFormat(){
		return this.dateFormatLegacy
			.replace('dd', 'DD')
			.replace('EEE', 'ddd')
			.replace('E', 'ddd')
			.replace('zzz', 'ZZ')
	}

	get dateTimeFormat(){
		return this.dateTimeFormatLegacy
			.replace('dd', 'DD')
			.replace('EEE', 'ddd')
			.replace('E', 'ddd')
			.replace('zzz', 'ZZ')
	}

	get tagsEnabled(){
		return this.userTags?.length > 0 && !this.ignoreTags
	}

	loadStateFromCookies() {
		this.apiToken = Cookies.get('sessionId');
		this.accountId = Cookies.get('CeesoftCurrentAccountId');
		this.accountName = Cookies.get('CeesoftCurrentAccountName');
		this.dateFormatLegacy = Cookies.get('CeesoftUserDateFormat');
		this.timeFormat = Cookies.get('CeesoftUserTimeFormat');
		this.locale = Cookies.get('CeesoftUserLocale');
		this.dateTimeFormatLegacy = Cookies.get('CeesoftUserDateTimeFormat');
		this.timezone = Cookies.get('CeesoftTimezone')
		this.userName = Cookies.get("CeesoftUsername")
		this.userId = Cookies.get("CeesoftUserId")
		this.userPictureId = Cookies.get("CeesoftUserPictureId")
		this.accountLogoId = Cookies.get("CeesoftAccountLogoId")
	}

	loadStateFromLoginResponse(loginData: LoginResponse){
		this.apiToken = loginData.sessionId
		this.accountId = loginData.loginAccountId
		this.accountName = loginData.loginAccountName
		this.dateFormatLegacy = loginData.dateFormat
		this.dateTimeFormatLegacy = loginData.dateTimeFormat
		this.timeFormat = loginData.timeFormat
		this.timezone = loginData.timeZone
		this.locale = loginData.locale
		this.userName = loginData.username
		this.userId = loginData.userId
		this.userPictureId = loginData.pictureId
		this.accountLogoId = loginData.logoId
	}

	hasPermissions = (...permissions: Permission[]) => {
		return permissions.every(p => this.permissions.includes(p))
	}

	hasAnyPermission = (...permissions: Permission[]): boolean => {
		return permissions.some(p => this.hasPermissions(p))
	}
}

export const ApplicationState = new ApplicationStateInner();
