import {makeAutoObservable, toJS} from "mobx"
import {observer} from "mobx-react"
import React from "react"

import {Section} from "controls/react/layout/section"
import FormEntry from "controls/react/form/formEntry"
import {AccountsSelector} from "controls/react/accountSelector"
import {linkModel, MobxManager} from "framework/mobx-integration"
import {CellDataSourceType} from "controls/designer/dataSourcesManager/cellDataSourceType"
import {apiFetch} from "framework/api";
import {ShowAsLabel} from "controls/designer/dataSourcesManager/sharedProperties"
import {UnorderedListOutlined} from "@ant-design/icons"
import {AntButton} from "controls/react/ant/antButton"
import {Asset} from "framework/entities/asset"
import {getMonitors, searchAssetsLite} from "areas/assets/api"
import {Monitor} from "framework/entities/monitor"
import {AntOption, AntSelect} from "controls/react/ant/antSelect"
import {loadChildEntities} from "areas/dashboards/graph-editor-extensions/datasources/loadChildEntities"
import {AntCheckbox} from "controls/react/ant/antCheckbox"
import {designerLocalizations} from "areas/dashboards/graph-editor-extensions/designerLocalizations"
import {MxCell} from "controls/designer/mxGraphInterfaces";
import {Designer} from "controls/designer/designer";
import {AssetDataSourceElement} from "controls/designer/dataSourcesManager/assetDataSourceElement";
import {CellDataSourceBase} from "controls/designer/dataSourcesManager/cellDataSource";
import {showDataSourceWindow} from "controls/designer/dataSourcesManager/dataSourceWindow";
import {DataSourceEditorContext} from "controls/designer/dataSourcesManager/dataSourceEditorContext";
import {DesignerStore} from "controls/designer/designerStore";
import {ApplicationState} from "framework/applicationState";
import {FormEntryNew} from "controls/react/form/formEntryNew";
import {useStore} from "core/react/useStore";

const i18n = require('core/localization/localization').translator({
	"Show Health Reasons": {
		"no": "Vis årsåker",
	},
}, designerLocalizations)

export class AssetDataSourceEditorStore {
	assetsDataListInvalid: boolean = true
	assetsDataListLoading: boolean = false
	assetsDataList: Asset[] = []
	monitorsDataListInvalid: boolean = true
	monitorsDataListLoading: boolean = false
	monitorsDataList: Monitor[] = []
	mobx = new MobxManager()

	dataSource: CellAssetDataSource

	constructor(public designerStore: DesignerStore) {
		makeAutoObservable(this)

		this.dataSource = this.designerStore.dataSourcesManager.selected as CellAssetDataSource
	}

	init(){
		if (!this.dataSource.accounts?.length) {
			this.dataSource.accounts = [ApplicationState.accountId]
		}

		this.mobx.reaction(() => toJS(this.dataSource.accounts), async () => {
			this.dataSource.assets = []
			this.dataSource.monitors = []
			this.assetsDataListInvalid = true
			this.monitorsDataListInvalid = true
		})

		this.mobx.reaction(() => this.dataSource.assets.length, async () => {
			this.dataSource.monitors = []
			this.monitorsDataListInvalid = true
		})

		this.mobx.autorun(async () => {
			if (this.assetsDataListInvalid) {
				await this.loadAssets()
			}
		})

		this.mobx.autorun(async () => {
			if (!this.assetsDataListInvalid && this.monitorsDataListInvalid) {
				await this.loadMonitors()
			}
		})
	}

	loadAssets = async () => {
		if (this.dataSource.accounts.length == 0) {
			return;
		}

		this.assetsDataListLoading = true

		let result = await apiFetch(searchAssetsLite({
			accountIds: this.dataSource.accounts,
			includeSubaccounts: true
		}))

		if (result.success) {
			this.assetsDataList = result.data.items
			this.assetsDataListInvalid = false
		}

		this.assetsDataListLoading = false
	}

	loadMonitors = async () => {
		if (this.dataSource.assets.length == 0) {
			return;
		}

		this.monitorsDataListLoading = true

		this.monitorsDataList = await loadChildEntities<AssetDataSourceEntry, Monitor>(
			this.dataSource.assets,
			asset => getMonitors(asset.id, asset.accountId),
			(monitor, asset, items) => {
				if (items.find(x => x.id == monitor.id))
					return null

				return {
					...monitor,
					accountId: asset.accountId
				}
			}
		)

		this.monitorsDataListInvalid = false
		this.monitorsDataListLoading = false
	}

	showAssetsWindow = () => {
		showDataSourceWindow({
			title: i18n('Asset'),
			selectedIds: this.dataSource?.assets?.map(x => x.id) ?? [],
			data: this.assetsDataList,
			columns: [{
				field: 'name',
				title: i18n('Name'),
				filterable: 'string'
			}, {
				field: 'accountName',
				title: i18n('Account'),
				filterable: 'string'
			}, {
				field: 'assetType',
				title: i18n('Type'),
				filterable: 'string'
			}, {
				field: 'description',
				title: i18n('Description'),
				filterable: 'string'
			}],
			onOk: (items) => {
				this.dataSource.assets = items.map(x => ({
					id: x.id,
					accountId: x.accountId,
				}))
			},
			designerStore: this.designerStore
		})
	}

	showMonitorsWindow = () => {
		showDataSourceWindow({
			title: i18n('Monitor'),
			selectedIds: this.dataSource?.monitors?.map(x => x.id) ?? [],
			data: this.monitorsDataList,
			columns: [{
				field: 'name',
				title: i18n('Name'),
				filterable: 'string'
			}, {
				field: 'typeText',
				title: i18n('Type'),
				filterable: 'string'
			}, {
				field: 'description',
				title: i18n('Description'),
				filterable: 'string'
			}],
			onOk: (items) => {
				this.dataSource.monitors = items.map(x => ({
					id: x.id,
					assetId: x.assetId,
					accountId: x.accountId,
				}))
			},
			designerStore: this.designerStore
		})
	}

	destroy() {
		this.mobx.destroy()
	}
}

export const AssetDataSourceEditor = observer(() => {
	const designerStore = React.useContext(DataSourceEditorContext)

	const store = useStore(() => new AssetDataSourceEditorStore(designerStore),{
		deps: [designerStore.dataSourcesManager.selected]
	})

	return <Section appearance={'none'} childrenPadding={true}>
		<FormEntryNew label={i18n('Account')} model={store.dataSource} modelField={"accounts"}>
			<AccountsSelector hidePlaceholder={true} />
		</FormEntryNew>

		<AssetsSelector store={store}/>

		{!designerStore.isApplicationMode  && <MonitorsSelector store={store}/>}

		<ShowAsLabel datasource={store.dataSource}/>

		<FormEntry>
			<AntCheckbox {...linkModel(store.dataSource, 'showHI')}>{i18n('Show Health Index')}</AntCheckbox>
		</FormEntry>

		<FormEntry>
			<AntCheckbox {...linkModel(store.dataSource, 'showReasons')}>{i18n('Show Health Reasons')}</AntCheckbox>
		</FormEntry>
	</Section>
})

const AssetsSelector = observer( (props: {store: AssetDataSourceEditorStore}) => {
	const store = props.store

	const icon = <AntButton icon={<UnorderedListOutlined/>}
	                               size={"small"}
	                               onClick={store.showAssetsWindow}
	                               disabled={store.assetsDataListInvalid}/>

	return <FormEntryNew label={i18n('Asset')} model={store.dataSource} modelField={"assets"} headerAdditionalContent={icon}>
		<AntSelect disabled={store.assetsDataListInvalid}
		           dataList={store.assetsDataList}
		           loading={store.assetsDataListLoading}
		           valueAsObject={true}
		           triggerChangeOnClose={true}
		           objectFields={['id', 'accountId']}
		           mode={'multiple'}/>
	</FormEntryNew>
})

const MonitorsSelector = observer( (props: {store: AssetDataSourceEditorStore}) => {
	const store = props.store

	const icon = <AntButton icon={<UnorderedListOutlined/>}
	                               size={"small"}
	                               onClick={store.showMonitorsWindow}
	                               disabled={store.monitorsDataListInvalid}/>

	return <FormEntryNew label={i18n('Monitor')} model={store.dataSource} modelField={"monitors"} headerAdditionalContent={icon}>
		<AntSelect disabled={store.monitorsDataListInvalid}
		           dataList={store.monitorsDataList}
		           loading={store.monitorsDataListLoading}
		           valueAsObject={true}
		           triggerChangeOnClose={true}
		           objectFields={['id', 'accountId', 'assetId']}
		           mode={'multiple'} />
	</FormEntryNew>;
})

export class CellAssetDataSource implements CellDataSourceBase{
	id: string
	accounts: string[] = []
	showAsLabel: boolean
	type: CellDataSourceType.Asset = CellDataSourceType.Asset
	assets: AssetDataSourceEntry[] = []
	monitors: MonitorDataSourceEntry[] = []
	showHI: boolean
	showReasons: boolean

	constructor() {
		makeAutoObservable(this)
	}

	valid(){
		return this.assets?.length > 0
	}

	attachToCell(designer: Designer, cell: MxCell) {
		return new AssetDataSourceElement(designer, cell)
	}
}

export type AssetDataSourceEntry = {
	id: string
	accountId: string
}

export type MonitorDataSourceEntry = {
	id: string
	assetId: string
	accountId: string
}
