import React, {CSSProperties} from "react";
import {AntButton} from "controls/react/ant/antButton";
import {CaretDownOutlined, CaretUpOutlined, EditFilled} from "@ant-design/icons";
import {ToolbarItemPosition} from "controls/react/layout";
import {AntInput} from "controls/react/ant/antInput";
import {linkModel} from "framework/mobx-integration";
import {controlPopupZIndex} from "controls/react/ant/zIndexes";

export function useMemoPermanent<T extends { destroy?: () => void }>(func: () => T, deps: ReadonlyArray<any> = null): T {
	const [value, setValue] = React.useState<T>()

	React.useEffect(() => {
		let innerValue = func()
		setValue(innerValue)
		return () => {
			innerValue?.destroy?.()
		}
	}, deps ?? [])

	return value
}

export type UseEditableButtonOptions = {
	position?: ToolbarItemPosition
}

export function useEditableButton<T extends object, K extends keyof T>(model: T, field: K, options?: UseEditableButtonOptions) {
	options ??= {}

	const [editing, setEditing] = React.useState(false)
	const [originalValue, setOriginalValue] = React.useState(model[field])

	const cancelEditing = React.useCallback(() => {
		model[field] = originalValue
		setEditing(false)
	}, [])

	const startEditing = React.useCallback(() => {
		setOriginalValue(model[field])
		setEditing(true)
	}, [])

	const confirmChange = React.useCallback(() => {
		if (!model[field]) {
			model[field] = originalValue
		}
		setEditing(false)
	}, [originalValue])

	const editor = React.useMemo(() => {
		return <AntInput onPressEsc={cancelEditing}
		                 onBlur={confirmChange}
		                 autoFocus={true}
		                 onPressEnter={confirmChange}
		                 {...linkModel(model, field)} position={options.position}/>
	}, [model, field, model[field]])

	const button = React.useMemo(() => {
		return <AntButton icon={<EditFilled/>} type={"text"} onClick={startEditing} position={options.position}/>
	}, [])

	return [
		editing ? editor : button,
		editing ? null : model[field] as string
	] as const
}

export function useExpandCollapseButton(initialValue: boolean = false) {
	const [collapsed, setCollapsed] = React.useState(initialValue)

	const toggleCollapsed = React.useCallback(() => {
		setCollapsed(!collapsed)
	}, [collapsed])

	const button = React.useMemo(() => {
		if (collapsed) {
			return <AntButton icon={<CaretDownOutlined/>} type={"text"} onClick={() => setCollapsed(false)}/>
		} else {
			return <AntButton icon={<CaretUpOutlined/>} type={"text"} onClick={() => setCollapsed(true)}/>
		}
	}, [collapsed])

	return [collapsed, button, toggleCollapsed] as const
}

export function useDefaultZIndex(styles: CSSProperties, zIndex: number){
	return React.useMemo(() => {
		if(styles?.zIndex != null){
			return styles
		}

		return {
			...(styles ?? {}),
			zIndex: controlPopupZIndex
		}
	},[styles] )
}
