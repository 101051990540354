import {sharedLocalization} from "./shared";
import {sharedLocalization as widgetsSharedLocalization} from "controls/designer/features/widgets/localization";
import {AssetsRouter} from "areas/assets/bundleDescription";
import Lang from "core/localization/lang";
import {renderSeverityTag} from "tools/states";
import MultiSelectGridFilter from "controls/multiSelectGridFilter";

const i = require('core/localization').translator(sharedLocalization, widgetsSharedLocalization);

export const getInstancesGridColumns = dataProvider => {
	return [{
		field: 'severity',
		title: i('Severity'),
		width: 36,
		template: item => renderSeverityTag(item.severity, item, 'hiSeverity'),
		attributes: {
			style: 'text-align: right'
		},
	},{
		field: 'budgetSeverity',
		title: i('Budget Severity'),
		width: 36,
		template: item => renderSeverityTag(item.budgetSeverity, item, 'budgetSeverity'),
		attributes: {
			style: 'text-align: right'
		},
	},{
		field: 'state',
		title: i('State'),
		width: 100,
		template: item => item.state != null ? (item.state == 1 ? i('Up') : i('Down')) : i('N/A'),
		attributes: {
			class: 'to_expand'
		}
	},{
		field: 'assetName',
		title: i('Asset'),
		width: 150,
		template: item => `<a class="cw_grid_link" ${dataProvider.props.navigator.renderLink(AssetsRouter.details(item.assetId))}>${item.assetName}</a>`,
		attributes: {
			class: 'to_expand'
		}
	}, {
		field: 'cloudTypeText',
		title: i('Cloud Provider'),
		width: 150,
		attributes: {
			class: 'to_expand'
		}
	}, {
		field: 'subscription',
		title: i('Subscription'),
		width: 150,
		attributes: {
			class: 'to_expand'
		}
	},{
		field: 'instance',
		title: i('Instance'),
		width: 150,
		attributes: {
			class: 'to_expand'
		}
	}, {
		field: 'serviceName',
		title: i('Cloud Service'),
		width: 100,
		attributes: {
			class: 'to_expand'
		}
	},{
		field: 'monthToDate',
		title: i('Month to date'),
		width: 100,
		template: item => dataProvider.formatNumber(item.monthToDate),
		attributes: {
			style: 'text-align: right'
		}
	}, {
		field: 'estimate',
		title: i('Monthly Estimate'),
		width: 100,
		template: item => dataProvider.formatNumber(item.estimate),
		attributes: {
			style: 'text-align: right'
		}
	},{
		field: 'monthlyBudget',
		title: i('Monthly Budget'),
		template: item => dataProvider.formatBudgetColumn(item),
		width: 100,
		attributes: {
			style: 'text-align: right'
		}
	}, {
		field: 'lastMonth',
		title: i('Last Month'),
		width: 100,
		template: item => item.months.length ? dataProvider.formatNumber(item.months.slice(-1)[0].amount) : '',
		attributes: {
			style: 'text-align: right'
		}
	}, {
		field: 'tags',
		title: i('Cloud Tags'),
		attributes: {
			class: 'to_expand'
		},
		filterable: {
			ui: element => {
				let multiselect = new MultiSelectGridFilter({
					element: element,
					field: 'tags',
					grid: dataProvider.getGrid(),
					itemTemplate: '#=data.text#',
					tagTemplate: '#=data.text#',
					dataSource: dataProvider.filterOptions?.tags
				});
			},
			//messages: this.gridMessages,
			messages: {},
			extra: false,
			operators: {
				string: {
					eq: Lang.grid.filter.EQ,
					neq: Lang.grid.filter.NEQ
				}
			}
		},
	}, {
		field: 'utilization',
		title: i('Utilization'),
		width: 100,
		attributes: {
			style: 'text-align: right'
		},
		template: item => item.utilization!=null ? dataProvider.formatNumber(item.utilization) : i('N/A')
	}];
}
