import {Cookies} from "core/cookies";

import moment from "moment-timezone";
import {ApplicationState} from "framework/applicationState";

export const i18n = require('core/localization').translator({
	'{0} (Differs from User timezone)': {
		no: '{0} (Forskjellig fra bruker tidssone)'
	}
})

export const dateToString = (time, dateType, optionalFormat, optionalTimezone) => {
	if (!time)
		return '';

	let dateFormat = '';
	if (optionalFormat) {
		dateFormat = parseDateFormat(optionalFormat);
	} else {
		switch (dateType) {
			case 'datetime':
				dateFormat = parseDateFormat(Cookies.CeesoftUserDateTimeFormat);
				break;
			case 'date':
				dateFormat = parseDateFormat(Cookies.CeesoftUserDateFormat);
				break;
			case 'time':
				dateFormat = parseDateFormat(Cookies.CeesoftUserTimeFormat);
				break;
			default:
				dateFormat = parseDateFormat(Cookies.CeesoftUserDateTimeFormat);
				break;
		}
	}
	let lang = 'en';
	if (Cookies.CeesoftUserLocale == 'no') {
		lang = 'nb';
	}

	let timezone = ApplicationState.timezone;
	if (optionalTimezone && optionalTimezone != 'undefined') {
		timezone = optionalTimezone;
	}

	return moment.tz(time, timezone).locale(lang).format(dateFormat);
}

export const dateTimeToString = time => {
	return dateToString(time, 'datetime');
}

export const parseDateFormat = pattern => {
	// default pattern in case there are no cookies set (standalone and guest sb)
	var newPattern = pattern || 'dd.MM.yy HH:mm';
	// convert dd into DD (day of month long)
	const dayRegExPattern = /([^d]|^)dd([^d]|$)/g;
	newPattern = newPattern.replace(dayRegExPattern, '$1DD$2');
	// convert E into ddd || EEE indo ddd || EEEE into dddd (name of
	// day)
	if (newPattern.indexOf('EEEE') !== -1) {
		newPattern = newPattern.replace('EEEE', 'dddd');
	} else if (newPattern.indexOf('E') !== -1 || newPattern.indexOf('EEE') !== -1) {
		newPattern = newPattern.replace('EEE', 'ddd').replace('E', 'ddd');
	}
	// convert yyyy into YYYY (year long)
	newPattern = newPattern.replace('yyyy', 'YYYY');
	// convert yy into YY (year short)
	newPattern = newPattern.replace('yy', 'YY');
	// convert ssss into SSS (milliseconds)
	newPattern = newPattern.replace('ssss', 'SSS');
	// convert zzz to zz
	if (newPattern.indexOf('zzz') !== -1 || newPattern.indexOf('zzzz') !== -1) {
		newPattern = newPattern.replace('zzzz', 'zz').replace('zzz', 'zz');
	}

	return newPattern;
}

export const zonedTimeToUtc = (date, timezone) => {
	return moment(moment(date).format('YYYY-MM-DDTHH:mm:ss') + moment.tz(timezone).format('Z')).toDate();
}

export function combineDateAndTime(dateComponent, timeComponent, dateOnly = false){
	if (dateOnly) {
		return new Date(dateComponent.getFullYear(), dateComponent.getMonth(), dateComponent.getDate())
	} else {
		return new Date(dateComponent.getFullYear(), dateComponent.getMonth(), dateComponent.getDate(),
			timeComponent.getHours(), timeComponent.getMinutes())
	}
}

export function convertTimezone(date, currentTimezone, targetTimezone) {
	if(typeof date == 'string'){
		date = new Date(date)
	}

	return new Date(moment(date).tz(currentTimezone, true).tz(targetTimezone).format('YYYY-MM-DD HH:mm:ss'))
}

const daysOfWeek = {
	sunday: 0,
	monday: 1,
	tuesday: 2,
	wednesday: 3,
	thursday: 4,
	friday: 5,
	saturday: 6,
};

export const getIsoWeekday = (date, firstWeekday = 'monday') => {
	return (date.getDay() + 7 - daysOfWeek[firstWeekday.toLowerCase()]) % 7;
}

export function generateKendoTimePickerPeriods(date, startValue){
	const startDate = new Date(date);
	startDate.setHours(0, 0, 0);
	const startMoment = startValue ? moment(startValue) : moment(startDate);
	return new Array(48).fill(moment(startDate)).reduce((result, current) => {
		current.add(30, 'minutes');
		if (current.isAfter(startMoment)) {
			result.push(current.toDate());
		}
		return result;
	}, []);
}

export function isTimeZoneDifferentFromCurrent(timezone) {
	const date = new Date().getTime();

	return dateToString(date, 'datetime', null, ApplicationState.timezone) !=
		dateToString(date, 'datetime', null, timezone);
}

export function getCurrentTimeStringInTimeZone(timezone) {
	const time = dateToString(new Date().getTime(), 'datetime', null, timezone);
	if (isTimeZoneDifferentFromCurrent(timezone)) {
		return i18n('{0} (Differs from User timezone)', time);
	}
	return time;
}

export function getTzOffsetDiffs(firstTz, secondTz) {
	return moment.tz(firstTz).utcOffset() - moment.tz(secondTz).utcOffset();
}

export function fromLegacyFormat(legacyFormat){
	return legacyFormat?.replace('dd', 'DD')
		.replace('EEE', 'ddd')
		.replace('E', 'ddd')
		.replace('zzz', 'ZZ')
}
