import Cookies from 'core/cookies';
import Utils from 'tools/utils';
import State from 'tools/state';
import {getUrlBuilderForCurrentHash} from "tools/urlBuilder";
import TagFilterEnabledIndicator from "tools/tagFilterEnabledIndicator";
import Configuration from 'configuration';
import React from "react";
import ReactDOM from "react-dom";
import {NavigationStore} from "framework/navigationStore";
import {createRoot} from "react-dom/client";
import {getCurrentMaximumIndex} from "controls/react/ant/antModal";

const customSortable = function () {
	const sortable = this.options.sortable
	if (!sortable) {
		return
	}
	const columns = this.columns
	this.thead.find("th:not(.k-hierarchy-cell,.k-group-cell)").each((index, elem) => {
		const column = columns[index]
		if (column.sortable == false || column.command || !column.field) {
			return
		}
		const $elem = $(elem)

		const sortableMode = typeof sortable === 'object' ? sortable.mode : 'single'
		$elem.attr('data-sortablemode', sortableMode);

		const sorterOptions = Object.assign({}, sortable, {
			dataSource: this.dataSource,
			compare: typeof column.sortable === 'object' ? column.sortable.compare : null,
			aria: true
		})
		$elem.attr("data-" + kendo.ns + "field", column.field).kendoCustomColumnSorter(sorterOptions);
	});
}

let Kendo = {

	init: function () {
		this.extendSelect();
		this.extendDataSource();
		this.extendColumnSorter();
		this.extendColumnSorter();
		this.extendGrid();
		this.extendComboBox();
		this.extendTooltip();
		this.extendWindow();
		this.extendMultiSelect();
		this.customSplitter();
		this.extendTabStrip();
		this.extendTreeList();
	},

	extendSelect: function () {
		if (kendo.ui.Select) {
			kendo.ui.Select.fn._index = function (value) {
				var that = this, idx, length, data = that._data(), valueFromData;
				for (idx = 0, length = data.length; idx < length; idx++) {
					//valueFromData = that._value(data[idx]).text;
					valueFromData = that._value(data[idx]);
					if (valueFromData === undefined) {
						valueFromData = that._text(data[idx]);
					}
					if (valueFromData === value) {
						return idx;
					}
				}
				return -1;
			};
		}
	},

	extendDataSource: function () {
		// add ceeview namespace if we need it
		kendo.ceeview = kendo.ceeview || {};
		kendo.ceeview.DataSource = kendo.data.DataSource.extend({
			init: function (options) {
				State.orderedGridSort = null;
				if (options.transport && options.transport.read) {
					options.transport.read.beforeSend = function (xhr) {
						if (Cookies.sessionId) {
							xhr.setRequestHeader('Auth-Token', Cookies.sessionId);
						}
					};

					if (options.serverSorting) {
						let cachedParameterMap = options.transport.parameterMap;
						options.transport.parameterMap = function(data, type) {
							if (this.oldGridSort?.length) {
								let orderedGridSort = keepGridSortOrder(data.sort, this.oldGridSort);
								data.sort = orderedGridSort;
								State.orderedGridSort = orderedGridSort;
							}

							this.oldGridSort = data.sort ? JSON.parse(JSON.stringify(data.sort)) : [];

							return cachedParameterMap(data, type);
						}
					}
				}

				kendo.data.DataSource.fn.init.call(this, options);
			}
		});
		if (kendo.data.TreeListDataSource) {
			kendo.ceeview.TreeListDataSource = kendo.data.TreeListDataSource.extend({
				init: function (options) {
					if (options.transport && options.transport.read) {
						options.transport.read.beforeSend = function (xhr) {
							if (Cookies.sessionId) {
								xhr.setRequestHeader('Auth-Token', Cookies.sessionId);
							}
						};
					}
					kendo.data.TreeListDataSource.fn.init.call(this, options);
				}
			});
		}
	},

	extendColumnSorter: function () {
		var kendo = window.kendo, DIR = "data-dir", ASC = "asc", FIELD = "data-field", DESC = "desc",
			ARIASORT = "aria-sort";
		if (kendo.ui.ColumnSorter) {
			var MyColumnSorter = kendo.ui.ColumnSorter.extend({
				init: function (element, options) {
					kendo.ui.ColumnSorter.fn.init.call(this, element, options);
				},
				options: {
					name: "CustomColumnSorter",
					autobind: true,
					templet: null,
					template: null
				},
				refresh: function () {
					if (!this.dataSource) {
						return;
					}

					const that = this;
					const sort = State.orderedGridSort || that.dataSource.sort() || [];
					const element = that.element;
					const field = element.attr(FIELD);
					const sortableMode = element.data('sortablemode');

					element.removeAttr(DIR);
					element.removeAttr(ARIASORT);

					let descriptor;
					let index = -1;

					for (let idx = 0; idx < sort.length; idx++) {
						descriptor = sort[idx];

						if (field === descriptor.field) {
							element.attr(DIR, descriptor.dir);
							index = idx;
						}
					}

					const dir = element.attr(DIR);
					element.find(".cw_grid_sort").remove();
					var iconName = 'a';
					switch (index) {
						case 0:
							iconName = 'd';
							break;
						case 1:
							iconName = 'c';
							break;
						case 2:
							iconName = 'b';
							break;
					}
					var indexIconCode = '<span data-icon="' + iconName + '"></span>';
					if (dir === ASC) {
						$('<span class="cw_grid_sort"><span class="cw_sort_icon_arrow_up"></span>' + (sortableMode === 'multiple' ? indexIconCode : '') + '</span>').appendTo(that.link);
						element.attr(ARIASORT, "ascending");
					} else if (dir === DESC) {
						$('<span class="cw_grid_sort"><span class="cw_sort_icon_arrow_down"></span>' + (sortableMode === 'multiple' ? indexIconCode : '') + '</span').appendTo(that.link);
						element.attr(ARIASORT, "descending");
					}
				},
				_click: function (e) {
					var t, n = window.kendo, i = n.ui, r = i.widget, o = "dir", a = "asc", s = "single", l = "field",
						c = "desc", d = ".kendoColumnSorter", u = ".k-link", h = "aria-sort", p = e.proxy;
					var i, r, d = this, u = d.element, h = u.attr(n.attr(l)), p = u.attr(n.attr(o)), f = d.options,
						g = null === d.options.compare ? t : d.options.compare, m = d.dataSource.sort() || [];
					if (e.preventDefault(), !f.filter || u.is(f.filter)) {
						if (p = p === a ? c : p === c && f.allowUnsort ? t : a, f.mode === s)
							m = [{field: h, dir: p, compare: g}];
						else if ("multiple" === f.mode) {
							for (i = 0, r = m.length; r > i; i++)
								if (m[i].field === h) {
									m.splice(i, 1);
									break
								}
							m.push({field: h, dir: p, compare: g});
						}
						this.dataSource.sortNow = p;
						this.dataSource.sort(m);
					}
				}
			});
			kendo.ui.plugin(MyColumnSorter);
		}
	},

	extendGrid: function () {
		if (!kendo.ui.Grid) {
			return;
		}

		var MyGrid = kendo.ui.Grid.extend({
			init: function (element, options) {
				kendo.ui.Grid.fn.init.call(this, element, options);

				$(element).data('kendoCustomGrid').bind('dataBound', $.proxy(this._onDefaultDataBound, this));
				$(element).data('kendoCustomGrid').table.on("click", ".cw_grid_check", $.proxy(this._handleCheckboxClick, this));
			},
			options: {
				name: "CustomGrid",
				autobind: true,
				templet: null,
				template: null
			},
			_traverse: function ($currentRow, direction) {
				var $currentEl = $currentRow;
				var $nextEl;
				var elements = [];
				var $inputCheckbox;
				var found = false;

				while (true) {
					if (direction === 'up') {
						$nextEl = $currentEl.prev();
					}
					else {
						$nextEl = $currentEl.next();
					}

					if (!$nextEl.length) {
						break;
					}

					$inputCheckbox = $nextEl.find('input.cw_grid_check');

					if ($inputCheckbox.is(':checked')) {
						found = true;
						break;
					}

					elements.push($inputCheckbox);

					$currentEl = $nextEl;
				}

				return found ? elements : null;
			},
			setChecked: function (array) {
				array.forEach(function (item) {
					$(item).prop('checked', true);
				})
			},
			_noRecordsClick: function(e) {
				this.options.onNoRecordsClicked(e);
			},
			_handleCheckboxClick: function (e) {
				if ($('.cw_grid_check_all', this.wrapper).is(':checked')) {
					$('.cw_grid_check_all', this.wrapper).prop('checked', false);
				}
				if (!e || !e.shiftKey || !$(e.currentTarget).is(':checked')) {
					return;
				}

				var $currentRow = $(e.currentTarget).parents('tr[role="row"]').first();

				if (!$currentRow) {
					return;
				}

				var upList = this._traverse($currentRow, 'up');

				if (upList) {
					this.setChecked(upList);
				}
				else {
					var downList = this._traverse($currentRow, 'down');

					if (downList) {
						this.setChecked(downList);
					}
				}
			},

			_onDefaultDataBound: function (e) {
				let grid = $(e.sender.wrapper).data('kendoCustomGrid');
				if (grid) {
					if (this.options.onNoRecordsClicked) {
						$(e.sender.wrapper).find('.k-grid-norecords').on("click", $.proxy(this._noRecordsClick, this));
					}
					Utils.updateGridFilterIndicators(grid);
					Utils.restoreScrollPos(grid);
					this._restoreCheckboxesState();
					kendo.ui.progress(grid.wrapper, false);

					if (this.oldGridSort?.length && !this.gridArtificiallySorted && !grid.dataSource?.options.serverSorting && grid.dataSource.data().length) {
						let orderedGridSort = keepGridSortOrder(grid.dataSource.sort(), this.oldGridSort);

						this.gridArtificiallySorted = true;
						setTimeout(() => {
							this.gridArtificiallySorted = false;
						}, 0);

						grid.dataSource.sort(orderedGridSort);
					}

					this.oldGridSort = grid.dataSource.sort() ? JSON.parse(JSON.stringify(grid.dataSource.sort())) : [];

					this._appendTagFilterInfoContainer(grid);

					styleCheckboxColumn(grid, 'grid');
					hideCheckboxColumnHeaderMenu(grid);

					if (grid.wrapper.find('.k-grid-norecords').length) {
						setTimeout(() => {
							grid.wrapper?.find('.cw_grid_check_all').prop('checked', false);
						}, 50);
					}
				}
			},

			_appendTagFilterInfoContainer(grid) {
				let detailsGridContainer = grid.wrapper.closest('.cw_section').find('.cw_section_title');
				let siblingToAppend = detailsGridContainer;
				let isDetailsGrid = true;
				let isHomeGrid = false;

				if (siblingToAppend.next().hasClass('k-widget')) {
					siblingToAppend = siblingToAppend.next();
					isHomeGrid = true;
				}

				let containerToAppend = [];
				if (!siblingToAppend.length || isHomeGrid) {
					isDetailsGrid = false;
					let mainGridContainer = grid.wrapper.closest('#content_area').find('.window_title');
					containerToAppend = detailsGridContainer.length && !isHomeGrid ? detailsGridContainer : mainGridContainer;
				}

				if (!siblingToAppend.length && !containerToAppend.length) {
					isDetailsGrid = false;
					let mainReactGridSection = grid.wrapper.closest('.main-section-grid');
					let detailsReactGridSection = grid.wrapper.closest('.section');
					let reactGridSection = mainReactGridSection.length ? mainReactGridSection : detailsReactGridSection;
					let reactGridFavoritesContainer = reactGridSection.find('.grid_favorite_item').closest('.toolbar__entry');
					let reactGridTitleContainer =  reactGridSection.find('.toolbar__entry_title');
					siblingToAppend = reactGridFavoritesContainer.length ? reactGridFavoritesContainer : reactGridTitleContainer;

					let closestToolbar = grid.wrapper.closest('.c-grid').prevAll('.toolbar').first();
					if (closestToolbar.length) {
						siblingToAppend = closestToolbar.find('.toolbar__entry_title');
					}
				}

				if ($('#cw_group_asset_grid_header').length) {
					return;
				}

				if (containerToAppend.length && !containerToAppend.find('.cw_new_tag_indicator').length) {
					containerToAppend.append(('<div class="cw_new_tag_indicator"></div>'));
				} else if (siblingToAppend.length && !siblingToAppend.siblings('.cw_new_tag_indicator').length) {
					$('<div class="cw_new_tag_indicator"></div>').insertAfter(siblingToAppend);
				}

				this.appendHandler = containerToAppend.length ? containerToAppend : siblingToAppend;
				let newTagIndicatorContainer = this.appendHandler?.find('.cw_new_tag_indicator').length ? this.appendHandler?.find('.cw_new_tag_indicator') : this.appendHandler?.siblings('.cw_new_tag_indicator');

				if (isHomeGrid) {
					newTagIndicatorContainer.addClass('cw_home_tag_indicator');
				}

				if (newTagIndicatorContainer?.length) {
					setTimeout(() => {
						let filter = grid.dataSource.filter();
						let filtersEnabled;
						if (filter?.filters?.length || grid.options.filteredContent) {
							filtersEnabled = true;
						} else {
							filtersEnabled = false;
						}

						let onTagFilterInfoContainerClick = () => {
							if (grid.options.eraseFilteredContent) {
								grid.options.eraseFilteredContent(grid.options.scope);
							}
							grid.dataSource.filter([]);
							this.appendHandler.parent().find('.cw_search_box').val('');
							this.appendHandler.parent().find('.c-textbox').val('');
						};

						grid.filteredInficatorReactRoot?.unmount()
						grid.filteredInficatorReactRoot = createRoot(newTagIndicatorContainer.get(0))
						grid.filteredInficatorReactRoot .render(<TagFilterEnabledIndicator onClick={() => {onTagFilterInfoContainerClick()}}
							                                   filtersEnabled={filtersEnabled} />)

						if (isDetailsGrid) {
							newTagIndicatorContainer.find('.box-view').addClass('filtered-content-details-grids');
						}
					});
				}
			},

			_restoreCheckboxesState: function () {
				if ($('.cw_grid_check_all', this.wrapper).is(':checked')) {
					$('.cw_grid_check', this.wrapper).each(function () {
						$(this).prop('checked', false)
					});
					$('.cw_grid_check_all', this.wrapper).prop('checked', false)
				}
				if (this.options.cacheScrollCheckboxes) {
					State.markedCheckboxes = State.markedCheckboxes || [];
					//timeout necessary for grid scroll to be created
					setTimeout($.proxy(function () {
						//restore grid scroll position when coming back from breadcrumb
						if (State.dynamicGridPos && State.isFromBreadcrumb && !State.gridPosLoaded) {
							grid.wrapper.find(".k -scrollbar").scrollTop(State.dynamicGridPos);
							State.gridPosLoaded = true;
						}
						//restore already selected checkboxes when loading new pages of the grid
						for (var i = 0; i < State.markedCheckboxes.length; i++) {
							var checkbox = $('[data-id="' + State.markedCheckboxes[i] + '"]');
							checkbox.prop('checked', true);
						}
						if ($('.cw_grid_check:checked', this.wrapper).length === $('.cw_grid_check', this.wrapper).length) {
							$('.cw_grid_check_all', this.wrapper).prop('checked', true);
						}
					}, this), 0);
				}
			},

			_sortable: customSortable
		});
		kendo.ui.plugin(MyGrid);
	},

	customSplitter: function () {
		if (!kendo.ui.Splitter) {
			return;
		}

		const customSplitter = kendo.ui.Splitter.extend({
			addTitles: function () {
				const firstPane = this.element.children('.k-pane')[0];

				if (!firstPane) {
					return;
				}

				const collapsed = $(firstPane).hasClass('k-collapsed')

				if (collapsed) {
					const expandNode = $('.k-splitbar-horizontal', this.element).find('.k-expand-prev').addClass('glyphicons chevron-right')[0];
					expandNode.setAttribute('title', 'Maximize');
				} else {
					const collapseNode = $('.k-splitbar-horizontal', this.element).find('.k-collapse-prev').addClass('glyphicons chevron-left')[0];
					collapseNode.setAttribute('title', 'Minimize');
				}
			},
			init: function (element, options) {
				kendo.ui.Splitter.fn.init.call(this, element, options);

				if(this.options.placeholderPane == null)
					return;

				$('.k-splitbar-horizontal', element).find('.k-expand-prev').addClass('glyphicons chevron-right');
				$('.k-splitbar-horizontal', element).find('.k-collapse-prev').addClass('glyphicons chevron-left');

				$(options.placeholderPane).append(`<div class="placeholder hide"><div class="label">${options.placeholderLabel}</div></div>`)

				const firstPane = $(element).children('.k-pane')[0];

				if (firstPane) {
					const collapsed = $(firstPane).hasClass('k-collapsed')

					if (collapsed) {
						$('.placeholder', element).removeClass('hide')
					}
				}

				this.collapse = (pane) => {
					kendo.ui.Splitter.fn.collapse.call(this, pane);
					$('.placeholder', this.element).removeClass('hide')
				}

				this.expand = (pane) => {
					kendo.ui.Splitter.fn.expand.call(this, pane);
					$('.placeholder', this.element).addClass('hide')
				}
			},
			_resize: function () {
				kendo.ui.Splitter.fn._resize.call(this);

				if(this.options.placeholderPane == null)
					return;

				this.addTitles();
			},
			options: {
				name: "CustomSplitter",
			},
		});
		kendo.ui.plugin(customSplitter);
	},


	extendComboBox: function () {
		if (kendo.ui.ComboBox) {
			var MyComboBox = kendo.ui.ComboBox.extend({
				options: {
					filter: 'contains'
				}
			});
			kendo.ui.plugin(MyComboBox);
		}
	},

	extendTooltip: function () {
		if (kendo.ui.Tooltip) {
			var MyTooltip = kendo.ui.Tooltip.extend({
				options: {
					showAfter: 1000,
					position: 'top'
				}
			});
			kendo.ui.plugin(MyTooltip);
		}
	},

	extendWindow(){
		if( kendo.ui.Window){
			let originalResizable = kendo.ui.Window.fn._resizable;

			kendo.ui.Window.fn._resizable = function(){
				originalResizable.apply(this, arguments);
				this.wrapper.off('dblclick');
			}

			//this is mostly the copypast from the original function
			//the only line which is added is
			//zIndex = getCurrentMaximumIndex() to take AntModal windows into account
			kendo.ui.Window.fn.toFront = function(e, avoidFocus){
				var that = this,
					wrapper = that.wrapper,
					currentWindow = wrapper[0],
					containmentContext = that.containment && !that._isPinned,
					openAnimation = this._animationOptions("open"),
					zIndex = +wrapper.css('zIndex'),
					originalZIndex = zIndex,
					target = (e && e.target) || null;

				$('.k-window').each(function(i, element) {
					var windowObject = $(element),
						zIndexNew = windowObject.css('zIndex'),
						contentElement = windowObject.children('.k-window-content');

					if (!isNaN(zIndexNew)) {
						zIndex = Math.max(+zIndexNew, zIndex);
					}

					wrapper.data("isFront", element == currentWindow);
					// Add overlay to windows with iframes and lower z-index to prevent
					// trapping of events when resizing / dragging

					if (element != currentWindow &&
						contentElement.find(".k-content-frame").length &&
						!contentElement.find('.k-overlay').length) {
						contentElement.append(templates.overlay);
					}
				});

				zIndex = getCurrentMaximumIndex()

				if (!wrapper[0].style.zIndex || originalZIndex < zIndex) {
					wrapper.css('zIndex', zIndex + 2);
				}
				that.element.find("> .k-overlay").remove();

				if (that._shouldFocus(target)) {
					if (!avoidFocus) {
						setTimeout(function() {
							that.wrapper.focus();
						}, openAnimation ? openAnimation.duration : 0);
					}

					var scrollTop = containmentContext ? that.containment.scrollTop() : $(window).scrollTop(),
						windowTop = parseInt(wrapper.position().top, 10);

					if (!that.options.pinned && windowTop > 0 && windowTop < scrollTop) {
						if (scrollTop > 0) {
							$(window).scrollTop(windowTop);
						} else {
							wrapper.css("top", scrollTop);
						}
					}
				}

				wrapper = null;

				return that;
			}

			//I have not been able to find a way to override code in kendo.custom.min.js using prototypes or some other way
			//So i modified it directly there.
			//The issue with dragstart was that it used getOffset(..., 'position') call instead of 'offset' so when
			//kendo window was attached not to body it used wrong offset and when user started dragging you could see
			//window size leaping to bigger value. The new method is here just in case we replace kendo.custom.min.js in future

			// dragstart: function (t) {
			// 	var e = this, n = e.owner, o = n.wrapper;
			// 	e._preventDragging = n.trigger(A), e._preventDragging || (e.elementPadding = parseInt(o.css("padding-top"), 10), e.initialPosition = a.getOffset(o, "position"), e.resizeDirection = t.currentTarget.prop("className").replace("k-resize-handle k-resize-", ""), e.initialSize = {
			// 		width: o.width(),
			// 		height: o.height()
			// 	}, e.containerOffset = a.getOffset(n.appendTo, "offset"), o.children(y).not(t.currentTarget).hide(), i(v).css(C, t.currentTarget.css(C)))
			// }
		}
	},

	extendTabStrip: function () {
		if (!kendo.ui.TabStrip) {
			return;
		}
		let originalTabStrip = kendo.ui.TabStrip.fn.activateTab;
		let originalInit = kendo.ui.TabStrip.fn.init;
		var tabStrip = kendo.ui.TabStrip.extend({
			init: function () {
				let urlBuilder = getUrlBuilderForCurrentHash();
				originalInit.apply(this, arguments);
				let tabIndex = parseInt(urlBuilder.params.tabIndex);
				if (tabIndex) {
					this.select(tabIndex);
				}
			},
			activateTab: function (e) {
				originalTabStrip.apply(this, arguments);

				if(!this.initialActivateHappened){
					this.initialActivateHappened = true
					return
				}

				const tabIndex = parseInt($(e).index());
				NavigationStore.updateCurrentItemUrl({
					tabIndex: tabIndex
				})
			}
		});
		kendo.ui.plugin(tabStrip);
	},

	extendTreeList() {
		if (!kendo.ui.TreeList) {
			return;
		}

		var MyTreeList = kendo.ui.TreeList.extend({
			init: function (element, options) {
				kendo.ui.TreeList.fn.init.call(this, element, options);

				$(element).data('kendoCustomTreeList').bind('dataBound', $.proxy(this._onDefaultDataBound, this));
			},
			options: {
				name: "CustomTreeList"
			},
			_onDefaultDataBound: function (e) {
				$('.cw_new_treelist_tag_indicator').remove();
				let treeList = $(e.sender.wrapper).data('kendoCustomTreeList');
				if (treeList) {
					let containerToAppend;
					let isMainAssetGroup;
					if ($('#cw_asset_groups_grid_menu').length) {
						containerToAppend = $('#cw_asset_groups_grid_menu').closest('.window_title');
						isMainAssetGroup = true;
					}
					if ($('#cw_group_treelist_header').length) {
						containerToAppend = $('.window_title');
					}
					this.appendHandler = containerToAppend;
					if (this.appendHandler) {
						this.appendHandler.append(('<div class="cw_new_treelist_tag_indicator"></div>'));
						if ($('.cw_new_treelist_tag_indicator').length) {
							if (isMainAssetGroup) {
								$('.cw_new_treelist_tag_indicator').css('margin-left', 0);
							}
							let filter = treeList.dataSource.filter();
							let filtersEnabled = false;
							if (filter?.filters?.length) {
								filtersEnabled = true;
							}

							let onTagFilterInfoContainerClick = () => {
								treeList.dataSource.filter([]);
								this.appendHandler.parent().find('.cw_search_box').val('');
								const assetsGroupGrid = $('#cw_group_assets_list')?.data('kendoCustomGrid');
								if(assetsGroupGrid)
									assetsGroupGrid.dataSource.filter([]);
							};

							treeList.tagFilterEnabledIndicatorReactRoot?.unmount()
							treeList.tagFilterEnabledIndicatorReactRoot = createRoot($('.cw_new_treelist_tag_indicator').get(0))
							treeList.tagFilterEnabledIndicatorReactRoot.render(
								<TagFilterEnabledIndicator
									onClick={() => {onTagFilterInfoContainerClick()}}
									filtersEnabled={filtersEnabled}
								/>
							)
						}
					}

					styleCheckboxColumn(treeList, 'treeList')
				}
			},
			_sortable: customSortable,
		});
		kendo.ui.plugin(MyTreeList);
	},

	extendMultiSelect: function () {
		if (!kendo.ui.MultiSelect) {
			return;
		}

		var CustomMultiSelect = kendo.ui.MultiSelect.extend({
			init: function (element, options) {
				kendo.ui.MultiSelect.fn.init.call(this, element, options);
				this._removeEmptyStringValues();
				options.init && options.init(this);
				let multiSelect = $(element).data('kendoSortedMultiSelect');
				multiSelect.bind('dataBound', $.proxy(this._onDefaultDataBound, this));
				multiSelect.bind('change', $.proxy(this._onDefaultChange, this));
				multiSelect.bind('open', $.proxy(this._onDefaultOpen, this));
			},
			options: {
				name: "SortedMultiSelect",
			},
			_removeEmptyStringValues: function() {
				let values = this.value();
				let onlyEmptyStringValues = true;
				for (let i = 0; i < values.length; i++) {
					if (values[i] !== '') {
						onlyEmptyStringValues = false;
					}
				}
				if (values && values.length && onlyEmptyStringValues) {
					this.value([]);
				}
			},
			_onDefaultDataBound: function (e) {
				//do not execute while manual input item text
				if (!e.sender._prev) {
					//timeout needed for the case when manual adding a new item (at enter press/focusout)
					setTimeout($.proxy(function() {
						this._sortMultiSelectValues(e);
					}, this), 0)
				}
			},
			_onDefaultChange: function (e) {
				this._sortMultiSelectValues(e);
			},
			_onDefaultOpen: function (e) {
				let textField = e.sender.options.dataTextField;
				if (!this.options.editableMultiselect) {
					if (textField && this.options.disableSorting != true) {
						e.sender.dataSource.sort({ field: textField, dir: "asc" });
					}
				} else {
					//if editable item do not sort directly, it would break edit functionality
					//for editable multiselects prevent opening directly; open and sort only after checking that the desired action is actual opening, not editing item
					State.isItemEdited = false;
					if (!this.defaultPrevented) {
						e.preventDefault();
						this.defaultPrevented = true;
					} else {
						if (textField && this.options.disableSorting != true) {
							e.sender.dataSource.sort({ field: textField, dir: "asc" });
						}
					}
					setTimeout($.proxy(function() {
						if (!State.isItemEdited) {
							this.open();
						}
						this.defaultPrevented = false;
					}, this), 100)
				}
			},
			_sortMultiSelectValues: function(e) {
				let multiSelect = e.sender;

				if(multiSelect.options.disableSorting)
					return;

				let dataSource = multiSelect.dataSource.data();
				let textField = multiSelect.options.dataTextField;
				let valueField = multiSelect.options.dataValueField;
				let ids = multiSelect.value();
				let idValueArray = [];
				for (let i = 0; i < ids.length; i++) {
					let current = ids[i];
					for (let j = 0; j < dataSource.length; j++) {
						if (current === dataSource[j][valueField] || current === dataSource[j]) {
							idValueArray.push({
								id: current,
								value: dataSource[j][textField] || dataSource[j]
							})
						}
					}
				}

				idValueArray.sort(function (a, b) {
					if (typeof a.value === 'string' && typeof b.value === 'string') {
						return (a.value.toLowerCase() < b.value.toLowerCase()) ? - 1 : (a.value.toLowerCase() > b.value.toLowerCase()) ? 1 : 0;
					}
				});

				let idArray = [];
				for (let i = 0; i < idValueArray.length; i++) {
					idArray.push(idValueArray[i].id);
				}
				multiSelect.value(idArray);
			}
		});
		kendo.ui.plugin(CustomMultiSelect);
	}
}

Kendo.init();

export function keepGridSortOrder(sort, oldSort) {
	let newGridSort = sort;

	let oldSortFields = [];
	for (let sortItem of oldSort) {
		oldSortFields.push(sortItem.field);
	}

	let orderedGridSort = [];
	for (let i = 0; i < oldSortFields?.length; i++) {
		for (let j = 0; j < newGridSort?.length; j++) {
			if (oldSortFields[i] === newGridSort[j].field) {
				orderedGridSort.push(newGridSort[j]);
			}
		}
	}

	for (let i = 0; i < newGridSort?.length; i++) {
		if (oldSortFields.indexOf(newGridSort[i].field) === -1) {
			orderedGridSort.push(newGridSort[i]);
		}
	}

	return orderedGridSort;
}

export function styleCheckboxColumn(control, type) {
	let gridContent = control.wrapper.find('.k-grid-content-locked').length ? control.wrapper.find('.k-grid-content-locked') : control.wrapper.find('.k-grid-content');

	let checkboxHeader = control.wrapper.find('th').find('input[type="checkbox"]');
	if (checkboxHeader.length) {
		checkboxHeader.css('margin-left', '2px');
		checkboxHeader.closest('th').removeClass('text_center');
		let firstColumn = control.wrapper.find('.k-grid-header colgroup col').first();
		firstColumn.css('min-width', '40px');
	}

	let gridCells = gridContent.find('td');
	gridCells.each(function() {
		let checkboxCell = $(this).find('input[type="checkbox"]');
		if (checkboxCell.length) {
			let marginLeft = (type === 'grid' || type === 'treeList') ? '5px' : '-10px';
			checkboxCell.css('margin-left', marginLeft);
			$(this).removeClass('text_center');
			let firstColumn = gridContent.find('colgroup col').first();
			firstColumn.css('min-width', '40px');
		}
	})
}

export function hideCheckboxColumnHeaderMenu(grid) {
	let checkboxHeader = grid.wrapper.find('th').find('input[type="checkbox"]');
	let checkboxHeaderContainer = checkboxHeader.closest('th');
	let menuHeader = checkboxHeaderContainer.find('.k-header-column-menu');
	menuHeader.remove();
}
