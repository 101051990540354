import {AntMenu} from "controls/react/ant/antMenu"
import React, {ReactNode, useState} from "react"
import {observer} from "mobx-react"
import {sortBy} from "lodash"
import {makeAutoObservable, runInAction} from "mobx"
import b_ from 'b_'
import './mainNavigation.less'
import {MonitorsRouter} from "../areas/assets/monitors/bundleDescription";
import {AppstoreOutlined, CloseCircleOutlined, PushpinOutlined, RetweetOutlined} from "@ant-design/icons"
import {MenuInfo, MenuMode} from 'rc-menu/lib/interface'
import {filterTree, findNode, flatTree, sortTree} from "../tools/tree";
import {ItemType} from "antd/lib/menu/hooks/useItems";
import {NavigationAction, NavigationStore} from "../framework/navigationStore";
import {ServicesRouter} from "../areas/services/bundleDescription";
import {ApplicationsRouter} from "../areas/application/bundleDescription";
import {ApplicationState} from "../framework/applicationState";
import {SlaRouter} from "../areas/sla/bundleDescription";
import {AssetsRouter} from "../areas/assets/bundleDescription";
import {AssetGroupRouter} from "../areas/assetgroups/bundleDescription";
import {CostsRouter} from "../areas/cost/bundleDescription";
import {AdministrationRouter} from "../areas/administration/bundleDescription";
import {DashboardsRouter} from "../areas/dashboards/bundleDescription";
import {ReportsRouter} from "../areas/reports/bundleDescription";
import {SummaryRouter} from "../areas/summary/bundleDescription";
import {ManagementRouter} from "../areas/management/bundleDescription";
import {AutomationRouter} from "../areas/automation/bundleDescription";
import {KpiRouter} from "../areas/kpi/bundleDescription";
import {AntPopover} from "controls/react/ant/antPopover"
import {IncidentsRouter} from "../areas/incidents/bundleDescription";
import {Permission} from "../framework/permission";
import {ConfigProvider} from "antd"
import {costTranslates} from "../areas/cost/translations";
import {Favorite, FavoritesStore} from "./favoritesStore";
import {State} from "tools/state"
import {UrlBuilder} from "../tools";
import {AntSelect} from "../controls/react/ant/antSelect";
import {LayoutMenuOrientation, LayoutStore} from "./layoutStore";
import classnames from "classnames"
import {AntInput} from "../controls/react/ant/antInput";
import {OutsideClickHandler} from "../controls/react/outsideClickHandler";
import {AntConfigProvider} from "vendor-init/ant";

const b = b_.with('main-navigation')

const i = require('core/localization').translator({
  "All Monitors": {
    "no": "All monitors"
  },
  "(Legacy)": {
    "no": "(Legacy)"
  },
  "Utility": {
    "no": "Støttefunksjoner"
  },
  "Configuration Server": {
    "no": "Configuration server"
  },
  "Switch between vertical or horisontal menu. Only applies when menu is pinned.": {
    "no": "Bytt mellom vertikal og horisontal meny. Det gjelder kun når meny er festet."
  },
  "Pin menu": {
    "no": "Fest meny"
  },
  "Unpin menu": {
    "no": "Løsne meny"
  }
}, costTranslates);

const monitorIcons = {
	'CONNECTIVITY': 'engineering-networks',
	'WINEVENTS': 'list-alt',
	'EXTERNAL': 'new-window-alt',
	'HYPERV': 'show-big-thumbnails',
	'IBMSVC': 'hdd',
	'SOLARWINDS': 'door',
	'MAILGTW': 'envelope',
	'LOGMONITOR': 'log-book',
	'MSCLUSTER': 'show-big-thumbnails',
	'NIMSOFT': 'door',
	'SYSTEM': 'cogwheels',
	'PROCESSES': 'cluster',
	'WINSERVICE': 'share-alt',
	'PRTG': 'door',
	'VMWARE': 'server',
	'VMM': 'show-big-thumbnails',
	'NAGIOS': 'door',
	'FILESEARCH': 'file',
	'SNMP': 'coins',
	'SNMPTRAP': 'thumbnails-list',
	'XEN_SERVER': 'times',
	'REMOTEISERIES': 'server',
	'REMOTESYSTEM': 'indent-right',
	'ICINGA': 'door',
	'PROCESSMONITOR': 'cluster',
	'GROOVY': 'code',
	'METRICMONITOR': 'hdd',
	'DATABASE': 'database',
	'EATONUPS': 'battery-charged',
	'MODBUS': 'artificial-intelligence',
	'URL': 'link',
	'DBWATCH': 'database-search',
	'SCRIPT': 'code',
	'CONTAINER': 'palette-package',
	'INTERFACETRAFFIC': 'indent-right',
	'WEBHOOK': 'link',
	'AZURE': 'cloud'
}

type MonitorKey = keyof typeof monitorIcons

type PermissionsBehavior = 'any' | 'all'
interface NavigationItem {
	label: ReactNode,
	labelText?: string;
	key: string,
	permissions?: Permission[],
	url?: string,
	children?: NavigationItem[],
	iconName?: string,
	iconType?: 'glyphicons' | 'material-icons',
	permissionsBehavior?: PermissionsBehavior,
	popupOffset?: [number, number],
	keepEmpty?: boolean
	skipSort?: boolean // works only on first level
}

const Icon = ({name, type}: {name: string, type: 'glyphicons' | 'material-icons'}) => {
	const iconType = type ?? 'glyphicons'
	if(iconType == 'glyphicons') {
		return <span className={`${b('icon', {name})} ${iconType} ${name}`}/>
	} else if (iconType == 'material-icons') {
		return <i className={`${b('icon', {name})} ${iconType}`}>{name}</i>
	}
}

const items = (favorites: NavigationItem[], monitors: NavigationItem[]) : NavigationItem[] => {
	return [
		{label: i('Home'), key: 'service_summary', permissions: [Permission.HomeMenu], url: '/', iconName: 'home'},
		{label: i('Favorites'), key: 'favorites', /*iconName: 'star',*/ children: favorites, keepEmpty: true, skipSort: false},
		{
			label: i('Infrastructure'),
			key: 'asset_health',
			permissionsBehavior: 'any',
			/*iconName: 'heartbeat',*/
			children: [ // or roles
				{label: i('Asset'), key: 'assethealth_summary', iconName: 'heart', permissions: [Permission.AssetMenu], url: AssetsRouter.assetHealthSummary()},
				{label: i('Asset Group'), key: 'assethealth_group_summary', iconName: 'folder-heart', permissions: [Permission.AssetGroupMenu], url: AssetGroupRouter.list()},
				{label: i('Application'), key: 'nav_applications', iconName: 'artificial-intelligence', permissions: [Permission.ApplicationMenu], url: ApplicationsRouter.list()},
				{label: i('Filter'), key: 'assethealth_filters', iconName: 'filter', permissions: [Permission.FilterMenu], url: AssetsRouter.assetHealthFilters()},
				{label: i('Monitor'), key: 'assethealth_monitors', iconName: 'cardio', permissions: [Permission.MonitorMenu], children: monitors },
				{label: i('Reason'), key: 'assethealth_reasons', iconName: 'align-left', permissions: [Permission.ReasonMenu], url: AssetsRouter.assetHealthReasons()}
			]
		},
		{label: i('Service'), key: 'service_menu', /*iconName: 'git-merge',*/
			children: [
				{label: i('Service'), key: 'service', permissions: [Permission.ServiceMenu], iconName: 'git-merge', url: ServicesRouter.list()},
				{label: i('SLA'), key: 'slas', permissions: [Permission.SlaMenu], iconName: 'record', url: SlaRouter.list()},
				{
					label: i('Templates'), key: 'administration_templates', iconName: 'file', permissions: [Permission.TemplateMenu], children: [
						{label: i('Service Model'), key: 'administration_servicemodel_templates', iconName: 'file', url: AdministrationRouter.serviceModelTemplates()},
						{label: i('Service Element'), key: 'administration_serviceelement_templates', iconName: 'file', url: AdministrationRouter.serviceElementTemplates()},
						{label: i('Service Qualifier'), key: 'administration_servicequalifier_templates', iconName: 'file', url: AdministrationRouter.serviceQualifierTemplates()},
					]
				},
			]
		},
		{
			label: i('Cost'), key: 'nav_costs', permissions: [Permission.CostMenu], /*iconName: 'euro',*/ children: [
				{label: i('Cost Model'), key: 'cost_model', iconName: 'coins', url: CostsRouter.listByType('model')},
				{label: i('Cost Store'), key: 'cost_store', iconName: 'database', url: CostsRouter.listByType('store')},
				{label: i('Cost Gateway'), key: 'cost_gateway', iconName: 'database', url: CostsRouter.gatewayList()}
			]
		},
		{label: i('Presentation'), key: 'presentation', /*iconName: 'newspaper',*/
			children: [
				{label: i('Dashboard'), key: 'board', permissions: [Permission.DashboardMenu], iconName: 'dashboard', url: DashboardsRouter.list()},
				{label: i('Report'), key: 'reports', permissions: [Permission.ReportMenu], iconName: 'newspaper', url: ReportsRouter.list()},
			]
		},
		{label: i('Event'), key: 'event', permissions: [], /*iconName: 'article',*/
			children: [
				{label: i('Event summary'), key: 'event_summary', iconName: 'article', permissions: [Permission.EventsMenu], permissionsBehavior: 'any', url: SummaryRouter.events()},
			]
		},
		{
			label: i('Management'),
			key: 'management',
			permissionsBehavior: 'any',
			/*iconName: 'settings',*/
			children: [
				{label: i('Administration'), key: 'management_administration', iconName: 'settings',
					children: [
						{label: i('Accounts'), key: 'administration_accounts', iconName: 'book', permissions: [Permission.AccountMenu], url: AdministrationRouter.listAccounts()},
						{label: i('Authentications'), key: 'administration_authentications', iconName: 'keys', permissions: [Permission.AuthenticationMenu], url: AdministrationRouter.authentications()},
						{label: i('Roles'), key: 'administration_usercategories', iconName: 'user-group', permissions: [Permission.RolesMenu], url: AdministrationRouter.listRoles()},
						{label: i('Teams'), key: 'administration_teams', iconName: 'parents', permissions: [Permission.TeamMenu], url: AdministrationRouter.listTeams()},
						{label: i('Users'), key: 'administration_users', iconName: 'user', permissions: [Permission.UserMenu], url: AdministrationRouter.listUsers()},
					]
				},
				{label: i('Server'), key: 'management_server_menu', iconName: 'server',
					children: [
						{label: i('Configuration Server'), key: 'management_server', iconName: 'server', permissions: [Permission.ServerMenu], url: ManagementRouter.serverDetails()},
						{label: i('Modules'), key: 'management_server_modules', iconName: 'stop', permissions: [Permission.ModuleList, Permission.ServerMenu], permissionsBehavior: 'all', url: ManagementRouter.modules()},
						{label: i('Repository'), key: 'management_server_repository', iconName: 'database', permissions: [Permission.RepositoryRead, Permission.ServerMenu], permissionsBehavior: 'all', url: ManagementRouter.repository()},
						{label: i('Active Users'), key: 'management_users', iconName: 'user', permissions: [Permission.ActiveUserMenu], url: ManagementRouter.activeUsers()}
					]
				},
				{label: i('Agents'), key: 'management_agents', iconName: 'macbook', permissions: [Permission.AgentMenu], url: ManagementRouter.agentsList()},
				{label: i('Automation'), key: 'automation', iconName: 'cogwheels', permissions: [Permission.AutomationMenu], url: AutomationRouter.list()},
				{label: i('Calendar'), key: 'administration_calendar', iconName: 'calendar', permissions: [Permission.CalendarMenu], url: AdministrationRouter.listCalendars()},
				{label: i('KPI'), key: 'kpi', permissions: [Permission.KpiMenu], iconName: 'equalizer', url: KpiRouter.list()},
				{label: i('Utility'), key: 'management_utility', iconName: 'circle-empty-plus',
					children: [

						{label: i('Data registry'), key: 'management_server_data_registry', iconName: 'computer', permissions: [Permission.RegistryMenu], url: ManagementRouter.dataRegistryList()},
						{label: i('System messages'), key: 'management_system_errors', iconName: 'cogwheels', permissions: [Permission.SystemMessagesMenu], url: ManagementRouter.systemMessages()},
						{label: i('Integration'), key: 'management_server_integration', iconName: 'disk-import', permissions: [Permission.IntegrationMenu], url: ManagementRouter.integration()},
						{label: i('Audit'), key: 'management_audit', iconName: 'cogwheel', permissions: [Permission.AuditMenu], url: ManagementRouter.audit()},
					]
				},
				{label: i('Incident'), key: 'incidents', iconName: 'construction-cone', permissions: [Permission.IncidentMenu], url: IncidentsRouter.list()}
			]
		},
	]
}

class MainNavigationStore {
	// items: NavigationItem[]
	showMenu: boolean = false
	searchRef: {focus: () => void, blur: () => void}
	search: string = null
	openKeys: string[] = []
	layoutStore: LayoutStore

	constructor(layoutStore: LayoutStore) {
		this.layoutStore = layoutStore
		makeAutoObservable(this)
	}

	get loaded() {
		return this.layoutStore.loaded
	}

	get items() {
		return items(this.favoritesMenuItems, this.monitorsMenuItems)
	}

	get favoritesMenuItems(): NavigationItem[] {
		const routes = State.mainApp?._routesTable.routes as Record<string, string>;
		if(!routes) {
			return []
		}
		return FavoritesStore.favorites.map((x) => {
			const url = routes[x.routeId]
			const builder = new UrlBuilder(url)
			const resultUrl = builder.add(x.params).build()
			return {
				label: <div className={b('favorites-label')}>
					<span className={b('favorites-title')} title={x.title}>{x.title}</span>
					<CloseCircleOutlined className={b('favorites-delete')} onClick={(e) => {this.removeFavorite(x); e.stopPropagation();}}/>
				</div>,
				labelText: x.title,
				key: x.key,
				url: resultUrl
			}
		})
	}

	removeFavorite(favorite: Favorite) {
		FavoritesStore.removeFromFavorites(favorite)
	}

	get monitorsMenuItems() {
		const menuItems = [{
			label: i('All Monitors'),
			iconName: 'cardio',
			key: 'assethealth_monitor_all',
			url: '/monitors'
		}]
		ApplicationState.enabledMonitors
		.filter(({value}) => monitorIcons[value as MonitorKey])
		.forEach(({value, text}) => {
			menuItems.push({
				label: value === 'GROOVY' ? `${text} ${i('(Legacy)')}` : text,
				iconName: monitorIcons[value as MonitorKey],
				key: `monitor_${value}`,
				url: MonitorsRouter.list(value)
			})
		})
		return sortBy(menuItems, i => i.label)
	}

	private get filteredByRoles() {
		return filterTree(
			this.sortedItems,
			(item) => this.hasRoles(item.permissions, item.permissionsBehavior),
			{childrenKey: 'children', includeParents: false}
		)
	}

	private get filteredByEmpty() {
		return filterTree(
			this.filteredByRoles,
			(item) => item.keepEmpty || !item.children || item.children.length > 0,
			{childrenKey: 'children', includeParents: true}
		)
	}

	get menuItems(): ItemType[] {
		return this.filteredByEmpty.map(this.toMenuItem)
	}

	get filteredMenuItems(): ItemType[] {
		if(!this.showFilteredMenu) {
			if(!this.showSearchResultsInMenu) {
				return []
			} else {
				return this.menuItems
			}
		}
		const search = this.search?.toLowerCase()
		return flatTree(this.filteredByEmpty, (x) => x.children, null, null, true).filter((x) => {
			if(typeof x.label == 'string') {
				return x.label.toLowerCase().includes(search)
			} else {
				return x.labelText.toLowerCase().includes(search)
			}
		}).map(this.toMenuItem)
	}

	get sortedItems() {
		return this.items.map(item => {
			return {
				...item,
				children: item.skipSort ? item.children : sortTree(item.children,
					(a, b) => {
						if (a.children && !b.children) {
							return -1
						} else if (!a.children && b.children) {
							return 1
						}
						const aLabel = a.labelText ?? a.label
						const bLabel = b.labelText ?? b.label
						if (aLabel == bLabel) {
							return 0
						}
						return aLabel > bLabel ? 1 : -1
					}, {childrenKey: 'children'})
			}
		})
	}

	toMenuItem = (item: NavigationItem) : ItemType => {
		const children = item.children?.map(this.toMenuItem)
		const length = children?.length
		const className= length > 0 ? b('submenu', {long: length > 10}) : null
		return {
			label: item.label,
			key: item.key,
			children,
			icon: item.iconName ? <Icon name={item.iconName} type={item.iconType}/> : null,
			popupClassName: className,
			popupOffset: item.popupOffset
		}
	}

	hasRoles = (permissions: Permission[], permissionsBehavior: PermissionsBehavior) => {
		if(!permissions || permissions.length == 0) {
			return true
		}
		if (permissionsBehavior === 'all') {
			return ApplicationState.hasPermissions(...permissions)
		}
		return permissions.some(p => ApplicationState.hasPermissions(p))
	}

	onMenuOpen = (open: boolean) => {
		this.showMenu = open
		setTimeout(() => this.searchRef?.focus(), 0)
		if(!open) {
			this.search = null
		}
	}

	closeMenu = () => {
		if(this.layoutStore.pinned) {
			return
		}
		this.showMenu = false
	}

	onMenuItemClick = (info: MenuInfo) => {
		const {key} = info
		this.onSearchItemSelected(key)
	}

	onSearchItemSelected = (key: string) => {
		const item = findNode(key, this.items)
		if(item.url) {
			NavigationStore.go(item.url, {navigationAction: NavigationAction.Clear})
		}
		this.closeMenu()
		this.search = null
		setTimeout(() => this.searchRef?.blur(), 0)
	}

	onMenuOutsideClick = () => {
		if (this.layoutStore.orientation !== 'vertical') {
			return;
		}

		this.search = null
	}

	get menuMode(): MenuMode {
		return this.layoutStore.pinned ? this.layoutStore.orientation : LayoutMenuOrientation.Vertical
	}

	get showFilteredMenu() {
		return this.search?.length >= 2
	}

	setSearchRef = (ref: {focus: () => void, blur: () => void}) => {
		this.searchRef = ref
		if(!this.layoutStore.pinned) {
			ref?.focus()
		}
	}

	setOpenKeys = (keys: string[]) => {
		this.openKeys = keys
	}

	get showSearchResultsInMenu() {
		return !this.layoutStore.pinned || this.layoutStore.orientation == LayoutMenuOrientation.Vertical
	}
}

const MenuWrapper = observer(({store}: {store: MainNavigationStore}) => {
	const {showSearchResultsInMenu} = store
	return <OutsideClickHandler
		onOutsideClick={store.onMenuOutsideClick}
		containerClass={b('menu-wrapper', {orientation: store.layoutStore.orientation})}
	>
		<div className={b('menu-header')}>
			{!showSearchResultsInMenu && <AntSelect
				showSearch
				placeholder={i('Search...')}
				defaultActiveFirstOption={false}
				suffixIcon={null}
				filterOption={false}
				onSearch={(value: string) => store.search = value}
				onChange={store.onSearchItemSelected}
				notFoundContent={null}
				className={b('search')}
				value={null}
				componentRef={(r: {focus: () => void, blur: () => void}) => store.setSearchRef(r)}
				searchValue={store.search}
				popupClassName={b('search-popup')}
				options={store.filteredMenuItems.map((d) => ({
					value: d.key,
					label: <div className={b('search-item')}>{d.icon}{d.label}</div>,
				}))}
			/>}
			{showSearchResultsInMenu && <AntInput
				placeholder={i('Search...')}
				onChange={(value: string) => store.search = value}
				className={b('search')}
				value={store.search}
				allowClear={true}
				ref={(r: {focus: () => void, blur: () => void}) => store.setSearchRef(r)}
			/>}
			<div className={b('pin')} onClick={store.layoutStore.togglePinned}>
				{store.layoutStore.pinned ? <PushpinOutlined title={i('Unpin menu')} /> : <PushpinOutlined title={i('Pin menu')}/>}
			</div>
			{store.layoutStore.pinned && <div className={b('spin')} onClick={store.layoutStore.toggleOrientation} title={i('Switch between vertical or horisontal menu. Only applies when menu is pinned.')}>
				<RetweetOutlined />
			</div>}
		</div>
		<AntMenu
			className={b('menu')}
			items={!showSearchResultsInMenu ? store.menuItems : store.filteredMenuItems}
			mode={store.menuMode}
			onClick={store.onMenuItemClick}
			onOpenChange={store.setOpenKeys}
			openKeys={store.openKeys}
			subMenuOpenDelay={0.1}
			subMenuCloseDelay={0.25}
		/>
	</OutsideClickHandler>
})

export const MainNavigation = observer(({layoutStore, className}: {layoutStore: LayoutStore, className: string}) => {
	const [store] = useState(() => new MainNavigationStore(layoutStore))

	return 	<AntConfigProvider theme={{token: {colorSplit: 'white'}, components: {Menu: {itemColor: 'rgba(0, 0, 0, 0.8)'}}}}>
		{store.loaded && <div className={classnames(className, b({pinned: store.layoutStore.pinned}))}>
			{!store.layoutStore.pinned && <AntPopover placement={'bottom'} content={<MenuWrapper store={store}/>} trigger={'hover'} onOpenChange={store.onMenuOpen} open={store.showMenu}>
				<div className={b('toggle-icon-wrapper')}>
					<AppstoreOutlined className={b('toggle-icon')}/>
				</div>
			</AntPopover>}
			{store.layoutStore.pinned && <MenuWrapper store={store}/>}
		</div>}
	</AntConfigProvider>
})
