import 'framework/styling/themes/light.less'
import 'vendor-init/jquery';
import 'vendor-init/kendo/kendo';
import 'vendor-init/jquery.bind-first';
import 'vendor-init/mobx';

import 'styles/override-ant.less';


import 'ol/ol.css'

import "styles/glyphicons.css";
import 'styles/style.less';
import 'vendor-init/kendo/overrides.less';
import 'material-icons/iconfont/material-icons.css';

require('es6-promise').polyfill();
require('isomorphic-fetch');

require('jquery.maskedinput/src/jquery.maskedinput');

import 'mxgraph/javascript/src/css/common.css';

import './app.less'


import Cookies, {createCookies} from 'core/cookies';

import HighchartsInit from 'vendor-init/highcharts';

import MxGraphInit from 'vendor-init/mxGraph';
import State from 'tools/state'
import Renderer from "./tools/renderer";
import Utils from 'tools/utils';
import Constants from 'tools/constants';
import Lang from "./core/localization/lang";
import moment from 'moment';
import 'reflect-metadata';

import jss from 'jss'
import preset from 'jss-preset-default'

import LoginModule from './login';
import WebLogin from './weblogin';
import ChangePassword from './changePassword';
import Wizard from './wizard';
import {marketOffer} from './marketOffer';
import {marketOfferManage} from './marketOfferManage';

import React from 'react';
import {createRoot} from 'react-dom/client';
import {ReactApp} from 'reactApp';
import {Api} from "tools/api"
import Settings from "settings";
import {getUrlBuilderForCurrentHash} from "tools/urlBuilder";
import {ApplicationState, checkForIsolatedSession} from "framework/applicationState";
import ShutDownPage from "./areas/db-shutdown/shutdownPage";
import {apiFetch} from "framework/api";
import {getPermissions} from "core/api";

jss.setup(preset())

document.addEventListener('DOMContentLoaded', async function () {
	HighchartsInit.init();
	MxGraphInit.init();
	checkForIsolatedSession();

	switch (page) {
		case 'wizard':
			Wizard();
			break;

		case 'changePassword':
			ChangePassword();
			break;

		case 'login':
			LoginModule();
			break;

		case 'weblogin':
			WebLogin();
			break;

		case 'marketOffer':
			marketOffer();
			break;

		case 'marketOfferManage':
			marketOfferManage();
			break;

		case 'index':
		default:
			const url = window.location.href;
			const match = url.match(/\/dashboards\/(.*)\?.*guestMode=1.*/);
			if(match != null){
				const dashboardId = match[1];
				await loginToDashboard(dashboardId);
			}

			const dbShutdownMatch = url.match(/\/db-shutdown/);
			const isDbShutdown = localStorage.getItem("isDbShutdown");
			if(dbShutdownMatch && isDbShutdown === "true") {
				localStorage.setItem("isDbShutdown", "false");
				const root = createRoot(document.getElementById('reactApp'))
				root.render(<ShutDownPage/>);
				break;
			}

			if(Cookies.CeesoftUserId == null){
				//we are clearing sessionId or we might get into infinite login loop because
				//guest user might be logged in for guest service board
				Cookies.erase('sessionId');
				Cookies.erase('guestSessionId');
				let loginUrl = Settings.defaultLogoutPage + "?forceLogin=1";
				Utils.redirectTo(loginUrl, true);
				return;
			}

			ApplicationState.loadStateFromCookies();

			const response = await apiFetch(getPermissions())
			if (response.success) {
				ApplicationState.permissions = response.data
			}

			const root = createRoot(document.getElementById('reactApp'))
			root.render(<ReactApp/>)

			break
	}
})

async function loginToDashboard(dashboardId){
	if(Cookies.CeesoftLoggedIntoDashboardId == dashboardId)
		return;

	const result = await Api.fetch(Api.server + 'accounts/dashboards/' + dashboardId + '/login');
	if(!result.success)
		return false;

	createCookies(result.data)
	ApplicationState.loadStateFromCookies()

	Cookies.create("CeesoftLoggedIntoDashboardId", dashboardId, Settings.COOKIE_TIMEOUT);
}

//this is so kendo templates could work
window.Renderer = Renderer;
window.Utils = Utils;
window.State = State;
window.Constants = Constants;
window.moment = moment;
window.lang = Lang;



if(module.hot){
	module.hot.accept('core/mainApplication', function(){
		State.mainApp.reloadCurrentModule();
	})
}
