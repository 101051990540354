import './antButton.less'

import React from 'react';
import {observer} from 'mobx-react';
import classnames from 'classnames';

import {Button, ButtonProps} from 'antd';
import {AntConfigProvider} from "vendor-init/ant";

export interface AntButtonProps extends ButtonProps{
	width?: number
}

export const AntButton = observer((props:AntButtonProps) => {
	let {style, className, width, ...restProps} = props

	className = React.useMemo(() => classnames(className, 'ceeview-ant-btn'), [className])

	const styleActual = React.useMemo(() => {
		const result = {...(style ?? {})}

		if (width != null) {
			result.width = width + 'px'
		}

		return result
	}, [style, width])

	return <AntConfigProvider><Button className={className} style={styleActual} {...props}/></AntConfigProvider>
});
