import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';
import CustomNotification from 'controls/customNotification';
import RemoteEventsManager from 'core/remoteEventsManager';
import LocalEventsManager from 'core/localEventsManager';
import Renderer from 'tools/renderer';
import MultiToggle from 'controls/multiToggle';
import GridMenu from 'controls/gridMenu';
import ErrorHandler from 'core/errorHandler';
import State from 'tools/state';
import UserPrefs from 'tools/userPrefs';
import Application from 'core/application';
import EventsToolbar from 'controls/eventsToolbar';
import PreferencesPanel from 'controls/preferencesPanel';
import ModalView from 'controls/modalView';
import MaintenancePeriod from 'controls/maintenancePeriod';
import Dialog from 'controls/dialog';
import {NewServiceWindow} from "areas/services/newServiceWindow";
import MultiSelectGridFilter from 'controls/multiSelectGridFilter';
import {IncidentsRouter} from "../incidents/bundleDescription";
import {ServicesRouter} from "./bundleDescription";
import {openTagsFormWindow} from "controls/tagsForm";
import {getServiceState} from 'controls/stateRenderer/serviceState'
import {getHealthStateService} from 'controls/stateRenderer/healthState'
import {getSlaState} from 'controls/stateRenderer/slaState';
import {SetInformationWindow} from 'controls/setInformationWindow';
import React from "react";
import ReactDOM from "react-dom";
import {translator} from "core/localization";
import {topLeftCornerPosition} from 'controls/modalWindow'
import {NavigationStore} from "../../framework/navigationStore";
import {AssetsRouter} from "../assets/bundleDescription";
import {SlaRouter} from "../sla/bundleDescription";

const i = translator({
	"State": {
		"no": "Tilstand"
	}
});

export default function ServicesList(config) {
	// initializing data inside this class
	Utils.apply(this, config);
	this.module = {
		name: 'Service'
	}
	this.hasEvents = true;
	this.subscriberId = Utils.guid();
	this.initComponent();
};

jQuery.extend(ServicesList.prototype, Application.prototype, {
	/**
	 * @property
	 * @type string
	 */
	type: 'Application',
	initComponent: function () {
		Application.prototype.initComponent.call(this);


		if (State.mainApp.session.hasRole('SERVICE_READ') || State.mainApp.session.hasRole('SERVICE_UPDATE')) {
			this.hasReadRole = true;
		}

		this.actionNotification = new CustomNotification({
			appendToElement: '.window_area',
		});

		/*if (!this.isView) {
			$('#nav li').removeClass('current').parent().find('#service').addClass('current');
		}*/
		this.checkboxStates = [];
		this.showGridView = true;
		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			serviceState: {
				hidden: false,
				width: 45
			},
			name: {
				hidden: false,
				width: 240
			},
			accountName: {
				hidden: !State.includeSubaccounts,
				width: 150
			},
			srvHealthIndex: {
				hidden: false,
				width: 140
			},
			srvSlaIndex: {
				hidden: false,
				width: 80
			},
			incidentCount: {
				hidden: false,
				width: 120
			},
			coverage: {
				hidden: false,
				width: 140
			},
			Operational: {
				hidden: false,
				width: 150
			},
			responsibleTeamName: {
				hidden: false,
				width: 160
			},
			stateDuration: {
				hidden: false,
				width: 150
			},
			tags: {
				hidden: false,
				width: 150
			},
			shared: {
				hidden: false,
				width: 100
			},
			information: {
				hidden: false,
				width: 200
			},
			description: {
				hidden: false
			}
		};
		this.loadUserPreferences();
		State.mainApp.contextMenu.setActionButton('SERVICE');
		this.cardGridToggle = new MultiToggle({
			id: 'cw_services_view_toggle',
			cssClass: 'cw_multi_toggle right',
			value: 'false',
			items: [{
				id: 'cw_grid_view',
				title: lang.GRID_VIEW,
				selected: false,
				cssClass: 'glyphicons justify',
				fn: this.onViewToggle,
				scope: this,
				value: 'true'
			}, {
				id: 'cw_card_view',
				title: lang.CARD_VIEW,
				selected: true,
				cssClass: 'glyphicons show-big-thumbnails',
				fn: this.onViewToggle,
				scope: this,
				value: 'false'
			}]
		});

		this.gridMenu = new GridMenu({
			renderTo: 'cw_service_grid_menu',
			indexedByCategory: true,
			sortingDisabled: true,
			items: [{
				id: 'cw_create',
				icon: 'plus-sign',
				text: lang.CREATE,
				fn: () => State.mainApp.onNewService(),
				scope: State.mainApp,
				role: 'SERVICE_CREATE',
				categoryIndex: 1
			}, {
				id: 'cw_delete',
				icon: 'bin',
				text: lang.DELETE,
				fn: this.onServiceDelete,
				scope: this,
				disabled: true,
				role: 'SERVICE_DELETE',
				categoryIndex: 1
			}, {
				id: 'cw_set_in_maintenance',
				icon: 'wrench',
				text: lang.SET_IN_MAINTENANCE,
				fn: this.onSetInMaintenance,
				scope: this,
				disabled: true,
				role: 'SERVICE_MAINTENANCE_CREATE',
				categoryIndex: 2
			}, {
				id: 'cw_end_maintenance',
				icon: 'no-symbol',
				text: lang.END_MAINTENANCE,
				fn: this.onEndMaintenance,
				scope: this,
				disabled: true,
				role: 'SERVICE_MAINTENANCE_UPDATE',
				categoryIndex: 2
			}, {
				id: 'cw_incidents_create',
				icon: 'construction-cone',
				text: lang.incidents.CREATE_INCIDENT,
				fn: this.onCreateIncident,
				scope: this,
				role: 'INCIDENT_CREATE',
				disabled: true
			}, {
				id: 'cw_export_csv',
				icon: 'file-export',
				text: lang.EXPORT_CSV,
				fn: this.onExportCsv,
				scope: this,
				disabled: false
			}, {
				id: 'cw_set_information',
				icon: 'info-sign',
				text: lang.SET_INFORMATION,
				fn: this.onSetInformation,
				scope: this,
				disabled: true
			}, {
				id: 'cw_set_tags',
				role: 'SERVICE_UPDATE',
				icon: 'tag',
				text: lang.SET_TAGS,
				fn: $.proxy(function () {
					return openTagsFormWindow('service', this);
				}, this),
				scope: this,
				disabled: true
			}, {
				id: 'cw_recalculate',
				icon: 'refresh',
				text: lang.RECALCULATE,
				fn: this.onServiceRecalculate,
				scope: this,
				disabled: true,
				role: 'SERVICE_MODEL_UPDATE'
			}]
		});
		State.markedCheckboxes = [];
		this.removeListeners();
		this.attachListeners();
		this.removeMask();
	},
	/**
	 * Removes the listeners for the form's buttons
	 */
	removeListeners: function () {
		$('#cw_filter_reset').off();
		$('.services_grid').off();
		LocalEventsManager.unbind('maintenance_period_saved');
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#cw_filter_reset').on('click', $.proxy(this.onClearFilters, this));

		$('.services_grid').on('click', '.cw_grid_check', $.proxy(this.onServiceCheck, this));
		$('.services_grid').on('click', '.cw_grid_check_all', $.proxy(this.onServiceCheckAll, this));
		LocalEventsManager.bind('maintenance_period_saved', $.proxy(this.onMaintenanceSaved, this));
	},
	/**
	 * Handler for the view toggle buttons click
	 * @param {String} value
	 */
	onViewToggle: function (value) {
		if (value === 'true') {
			this.showGridView = true;
			State.mainApp.navigate(ServicesRouter.list())
		} else {
			this.cachedGrouping = this.grid.dataSource.group();
			this.servicesGroup = this.cachedGrouping;
			this.grid.dataSource.group([]);
			$('.cw_service_grid').addClass('hide');
			if (this.innerView) {
				this.showGridView = false;
				$('#cw_services').removeClass('hide');
			} else {
				this.initCardView();
			}
		}

		this.unsubscribe();
		this.subscribe();
	},
	/**
	 * Init card component
	 */
	initCardView: function () {
		var template = this.buildTemplate();

		this.showGridView = false;
		this.cardGridToggle.select('cw_card_view');
		$('.cw_service_grid').addClass('hide');

		this.innerView = new ModalView({
			createButtonText: lang.service.CREATE_NEW_SERVICE,
			viewDataSource: this.dataSource,
			template: template,
			autoLoad: true,
			id: 'cw_services',
			cssClass: 'cw_services_list',
			title: 'Services',
			pager: false,
			onCreateButton: function (e) {
				if (State.mainApp.session.hasRole('SERVICE_CREATE')) {
					new NewServiceWindow({});
				}
				e.stopPropagation();
			},
			onEditButton: $.proxy(function (e) {
				const serviceId = e.currentTarget.parentNode.parentNode.id;
				NavigationStore.go(ServicesRouter.details(serviceId));
				e.stopPropagation();
			}, this),
			onDeleteButton: $.proxy(function (e) {
				var id = e.currentTarget.parentNode.parentNode.id;
				var url = Settings.serverPath + 'services/' + id;
				var dialog = new Dialog({
					title: lang.service.DELETE_SERVICE,
					msg: lang.service.messages.SERVICE_REMOVE_CONFIRMATION,
					scope: this,
					icon: 'ERROR',
					actionText: 'DELETE',
					fn: $.proxy(function (value, button) {
						if (button === 'ok') {
							Utils.ajax(url, 'DELETE', {}, $.proxy(function (result) {
								if (result.success) {
									this.actionNotification.setOptions({
										message: lang.service.messages.SERVICE_SUCCESS_DELETED,
										status: 'success'
									}).show();
								} else {
									Utils.showInfo(lang.ALERT, result.message, result.details);
								}
							}, this));
						}
					}, this)
				});
				dialog.show();
				e.stopPropagation();
			}, this),
			onCardClick: $.proxy(function (e) {
				var serviceId = $(e.currentTarget).attr('id');
				NavigationStore.go(ServicesRouter.details(serviceId));
				e.stopPropagation();
			}, this),
			dataBound: () => {
				$('#cw_services').removeClass('hide');
				Utils.applyTooltip();
				//add mouseover if ellipsis
				$('.cw_team_name').each(function() {
					var cloneEl = $(this).clone()
						.css({display: 'inline', width: 'auto', visibility: 'hidden'})
						.appendTo('body');
					if ($(this).width() < $(cloneEl).width()) {
						$(this).attr('title', $(this).text());
					}
					$(cloneEl).remove();
				});
				$('.cw_states').off().on('click', $.proxy(this.onCardStateClick, this));
			}
		});
	},

	onCardStateClick(e) {
		e.stopPropagation();
		const state = $(e.currentTarget).data('state');
		const serviceId = $(e.currentTarget).closest('.cw_card').attr('id');
		switch (state) {
			case 'service':
				NavigationStore.go(ServicesRouter.details(serviceId));
				break;
			case 'model':
				State.mainApp.navigate(ServicesRouter.viewer(serviceId));
				break;
			case 'sla':
				const options = {
					isView: true,
					serviceId: serviceId,
					preferences: {
						sort: [{
							field: 'statusIndex',
							dir: 'desc'
						}],
						filter: []
					}
				}
				NavigationStore.go(SlaRouter.list(options))
				break;
		}
	},

	buildTemplate: function () {
		var template = '', deleteClass = State.mainApp.session.hasRole('SERVICE_DELETE') ? '' : 'hide';

		template += '<li>';
		template += '<div class="cw_card" id="${id}" style="height: 185px">';
		template += '<div class="cw_card_avatar" style="height: 145px">';
		template += '# if (hasModel && State.mainApp.session.hasRole("SERVICE_READ") && State.mainApp.session.hasRole("SERVICE_MODEL_READ") ) { #';
		template += '<div class="cw_avatar cw_avatar_service no_bg">';
		template += '<img id="model_${id}" src="' + Settings.serverPath + 'sessions/' + Cookies.sessionId + '/services/${id}/preview?_dc=${timestamp}" class="service_model_preview" />';
		template += '</div>';
		template += '#} else { #';
		template += '<div class="cw_avatar cw_avatar_service"></div>';
		template += '#}#';
		template += '</div>';
		template += '<div class="cw_content">';
		template += '<h2 class="cw_title">${name}</h2>';

		if (State.mainApp.session.hasRole('SERVICE_READ')) {
			template += '<div class="cw_field">${description == null ? \'\' : description}</div>';
			template += '<div class="cw_field">';
			template += '<span class="label">' + lang.ACCOUNT + ': </span>';
			template += '<span class="value">${accountName}</span>';
			template += '</div>';

			if (State.mainApp.session.hasRole('TEAM_READ') || State.mainApp.session.hasRole('TEAM_LIST')) {
				template += '<div class="cw_field">';
				template += '<span style="overflow: hidden" class="label">' + lang.service.RESPONSIBLE_TEAM + ': </span>';
				template += '<span class="cw_team_name value">#= responsibleTeamName ? responsibleTeamName : "-" #</span>';
				template += '</div>';
			}

			template += '<div class="cw_field">';
			template += '<span class="label">' + lang.service.INCIDENT + ': </span>';
			template += '<span class="value">${incidentCount}</span>';
			template += '</div>';

			template += '<div class="cw_field">';
			template += '<span class="label">' + lang.service.CURRENT_STATE + ': </span>';
			template += '<span class="value">#=Renderer.duration(stateDuration)#</span>';
			template += '</div>';

			template += '<div class="cw_footer">';


			template += '# if (operatingState !== "OPERATIONAL" ) { #';
			template += '<div class="cw_operating_state text_center #= Renderer.operatingStateClass(operatingState)#">#= Renderer.operatingState(operatingState)#</div>';
			template += '# } else { #';
			template += '<div class="cw_states" data-state="service">';
			template += '<span class="cw_card_service_state">#= Renderer.renderSummaryState(serviceState, "", operatingState, agentStatus, qualifierError, assetMaintenance)#</span>';
			//template += '<span class="cw_card_service_state">#= getServiceState(serviceState, "", operatingState, agentStatus, qualifierError, assetMaintenance)#</span>';
			template += '<span class="label">' + lang.SERVICE + '</span>';
			template += '</div>';
			template += '<div class="cw_states" data-state="model">';
			template += '<span class="cw_card_model_state">#= Renderer.renderSummaryState(serviceState, "", operatingState, agentStatus, qualifierError, assetMaintenance)#</span>';
			//template += '<span class="cw_card_model_state">#= getServiceState(serviceState, "", operatingState, agentStatus, qualifierError, assetMaintenance)#</span>';
			template += '<span class="label">' + lang.serviceBoard.MODEL_STATE + '</span>';
			template += '</div>';
			template += '<div class="cw_states" data-state="sla">';
			template += '<span class="cw_card_sla_state">#= Renderer.serviceSla(srvSlaIndex)#</span>';
			//template += '<span class="cw_card_sla_state">#= getSlaState(srvSlaIndex)#</span>';
			template += '<span class="label">' + 'SLA' + '</span>';
			template += '</div>';
			template += '# } #';
			template += '</div>';
		}

		template += '</div>';
		template += '<div class="cw_card_options">';
		template += '<span class="glyphicons bin ' + deleteClass + '"></span>';
		template += '</div>';
		template += '</div>';
		template += '</li>';

		return template;

	},
	/**
	 * Init grid component
	 * @param {boolean} showGrid Defaults to true
	 */
	initGridView: function (showGrid) {
		var filterMessages = lang.grid.filter,
			loadGrid = true;
		this.gridMessages = {
			isTrue: ' ' + lang.YES + ' ',
			isFalse: ' ' + lang.NO + ' ',
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
		if (showGrid === false) {
			loadGrid = false;
			this.gridViewLoaded = false;
		} else {
			this.gridViewLoaded = true;
		}
		if (loadGrid) {
			this.showGridView = true;
			this.cardGridToggle.select('cw_grid_view');
			$('#cw_services').addClass('hide');
			$('.cw_service_grid').removeClass('hide');
		}

		var servicesColumns = this.servicesColumns || JSON.parse(UserPrefs.get('servicesColumns')) || this.defaultColumns;
		servicesColumns = Utils.completeColumns(servicesColumns, this.defaultColumns);

		if (this.preferences) {
			servicesColumns = this.defaultColumns;
		}
		var scope = this;
		this.grid = $('.services_grid').kendoCustomGrid({
			height: $('.cw_service_grid').height(),
			dataSource: this.dataSource,
			resizable: true,
			cacheScrollCheckboxes: true,
			scrollable: {
				virtual: true
			},
			reorderable: true,
			selectable: 'row',
			sortable: {
				mode: "multiple",
				allowUnsort: true
			},
			noRecords: {
				template: lang.service.messages.NO_RECORDS
			},
			onNoRecordsClicked: (e) => {
				State.mainApp.onNewService(e);
			},
			columnResize: $.proxy(this.onGridReorderResize, this),
			columnReorder: $.proxy(this.onGridReorderResize, this),
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						eq: filterMessages.EQ,
						neq: filterMessages.NEQ,
						contains: filterMessages.CONTAINS
					},
					number: {
						eq: filterMessages.EQ,
						neq: filterMessages.NEQ,
						gte: filterMessages.GTE,
						gt: filterMessages.GT,
						lte: filterMessages.LTE,
						lt: filterMessages.LT
					}
				},
				messages: this.gridMessages
			},
			columns: Utils.rearrangeColumns([{
				field: 'id',
				title: lang.SELECTOR,
				sortable: false,
				filterable: false,
				menu: false,
				template: '<input type="checkbox" class="cw_grid_check" data-id="#= id #" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				attributes: {
					'class': 'text_center'
				},
				headerAttributes: {
					'class': 'text_center'
				},
				hidden: servicesColumns.id.hidden,
				width: servicesColumns.id.width,
				//groupable: false
			}, {
				field: 'serviceState',
				title: i("State"),
				//template: '#= Renderer.renderSummaryState(serviceState, "", operatingState, agentStatus, qualifierError, assetMaintenance)#',
				template: item => getServiceState(item.serviceState, '', item.operatingState, item.agentStatus, item.qualifierError, item.assetMaintenance),
				attributes: {
					'class': 'cw_service_state text_center'
				},
				headerAttributes: {
					'class': 'text_center'
				},
				sortable: {
					compare: $.proxy(function (a, b) {
						return Utils.customCompare(a, b, 'serviceState', 3, this.dataSource.sortNow);
					}, this)
				},
				filterable: {
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					},
					ui: function (element) {
						let menu = $(element).parent();
						let operatorEl = menu.find("[data-role=dropdownlist]");

						let multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'serviceIndicator',
							grid: scope.grid,
							dataSource: new kendo.data.DataSource({data: [{
									text: lang.DOWN,
									value: 1,
									config: {serviceState: 'INACTIVE', agentStatus: 'ACTIVE', qualifierError: false}
								}, {
									text: lang.WARNING,
									value: 2,
									config: {serviceState: 'WARNING', agentStatus: 'ACTIVE', qualifierError: false}
								}, {
									text: lang.UP,
									value: 3,
									config: {serviceState: 'ACTIVE', agentStatus: 'ACTIVE', qualifierError: false}
								}, {
									text: lang.UNAVAILABLE,
									value: 4,
									config: {serviceState: 'INVALID', agentStatus: 'ACTIVE', qualifierError: false}
								}, {
									text: lang.account.messages.AGENT_WARNING,
									imgClass: 'glyphicons exclamation-sign',
									value: 5,
									config: {serviceState: '', agentStatus: 'AGENT_DOWN', qualifierError: false}
								}, {
									text: lang.account.messages.AGENT_MAINTENANCE,
									imgClass: 'glyphicons exclamation-sign',
									value: 6,
									config: {serviceState: '', agentStatus: 'AGENT_MAINTENANCE', qualifierError: false}
								}, {
									text: lang.account.messages.ASSET_MAINTENANCE,
									imgClass: 'glyphicons exclamation-sign',
									value: 7,
									config: {serviceState: '', agentStatus: '', qualifierError: '', assetMaintenance: true}
								}, {
									text: lang.account.messages.QUALIFIER_ERROR,
									imgClass: 'glyphicons exclamation-sign',
									value: 8,
									config: {serviceState: '', agentStatus: 'ACTIVE', qualifierError: true}
								}], serverSorting: true}),
							itemTemplate: item => getServiceState(item.config.serviceState, item.text, null, item.config.agentStatus, item.config.qualifierError, item.config.assetMaintenance),
							tagTemplate: item => getServiceState(item.config.serviceState, item.text, null, item.config.agentStatus, item.config.qualifierError, item.config.assetMaintenance),
						});
					},
					messages: this.gridMessages
				},
				valueRenderer: function (value) {
					return Renderer.summaryStateFilter({value: value, text: ''});
				},
				hidden: servicesColumns.serviceState.hidden,
				width: servicesColumns.serviceState.width
			}, {
				field: 'name',
				title: lang.NAME,
				sortable: true,
				filterable: true,
				template: item => {
					return this.hasReadRole ?
						`<a class="cw_grid_link ellipsis to_expand" href="#${ServicesRouter.details(item.id)}">${_.escape(item.name)}</a>`
						: _.escape(item.name)
				},
				hidden: servicesColumns.name.hidden,
				width: servicesColumns.name.width,
				attributes: {
					"class": "tooltip ellipsis to_expand"
				},
				//groupable: false
			}, {
				field: 'accountName',
				title: lang.ACCOUNT,
				sortable: true,
				filterable: {
					ui: function (element) {
						var multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'accountName',
							grid: scope.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: scope.filterOptions['accountName']
						});
					},
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					}
				},
				hidden: !State.includeSubaccounts || servicesColumns.accountName.hidden,
				width: servicesColumns.accountName.width,
				template: '<span data-accountid="${accountId}">${accountName}</span>',
				attributes: {
					"class": "tooltip ellipsis to_expand"
				}
			}, {
				field: 'srvHealthIndex',
				title: lang.summary.SERVICE_HEALTH,
				//template: '<span class="cw_status_indicator cw_status_widget_color cw_color#=Utils.severityToColor(srvHealthIndex)# # if (srvHealthIndex !== 6) { # pointer # } #"></span>',
				template: item => getHealthStateService(item.srvHealthIndex),
				attributes: {
					'class': 'cw_service_health text_center'
				},
				sortable: {
					compare: $.proxy(function (a, b) {
						return Utils.customCompare(a, b, 'srvHealthIndex', 6, this.dataSource.sortNow);
					}, this)
				},
				filterable: {
					operators: {
						number: {
							eq: filterMessages.ISIN,
							neq: filterMessages.ISNOTIN
						}
					},
					ui: function (element) {
						element.kendoDropDownList({
							dataSource: [{
								text: lang.CRITICAL,
								value: 3
							}, {
								text: lang.MAJOR,
								value: 2
							}, {
								text: lang.MINOR,
								value: 1
							}, {
								text: lang.OK,
								value: 0
							}, {
								text: lang.UNAVAILABLE,
								value: 6
							}],
							dataTextField: 'text',
							dataValueField: 'value',
							optionLabel: lang.grid.FILTER_SELECT_VALUE,
							change: Utils.onFilterDropDownChange,
							//template: '<span class="cw_status_indicator pointer cw_status_widget_color cw_color#=Utils.severityToColor(data.value)#"></span><span>${data.text}</span>'
							template: item => getHealthStateService(item.value, item.text),
						});
					}
				},
				valueRenderer: function (value) {
					var data = [lang.CRITICAL, lang.MAJOR, lang.MINOR, lang.OK, '', '', lang.UNAVAILABLE];
					return '<span class="cw_status_indicator cw_status_widget_color cw_color' + Utils.severityToColor(value) + '"></span><span>' + data[value] + '</span>';
				},
				hidden: servicesColumns.srvHealthIndex.hidden,
				width: servicesColumns.srvHealthIndex.width
			}, {
				field: 'srvSlaIndex',
				title: lang.widget.SLA,
				//hidden: !State.mainApp.session.hasRole('SLA_LIST'),
				//template: '#= Renderer.serviceSla(srvSlaIndex)#',
				template: item => getSlaState(item.srvSlaIndex),
				attributes: {
					'class': 'cw_service_sla text_center'
				},
				sortable: {
					compare: $.proxy(function (a, b) {
						return Utils.customCompare(a, b, 'srvSlaIndex', 4, this.dataSource.sortNow);
					}, this)
				},
				filterable: {
					operators: {
						number: {
							eq: filterMessages.ISIN,
							neq: filterMessages.ISNOTIN
						}
					},
					ui: function (element) {
						element.kendoDropDownList({
							dataSource: [{
								text: lang.service.IN_COMPLIANCE,
								icon: 'glyphicons status_icon circle-arrow-top',
								value: 1
							}, {
								text: lang.WARNING,
								icon: 'glyphicons status_icon circle-arrow-right',
								value: 2
							}, {
								text: lang.service.BREACHED,
								icon: 'glyphicons status_icon circle-arrow-down',
								value: 3
							}, {
								text: lang.summary.NO_SLA_AVAILABLE,
								icon: 'cw_status_indicator cw_status_widget_color status_icon cw_color6',
								value: 4
							}],
							dataTextField: 'text',
							dataValueField: 'value',
							optionLabel: lang.grid.FILTER_SELECT_VALUE,
							change: Utils.onFilterDropDownChange,
							template: '<span class="${data.icon}"></span> <span>${data.text}</span>'
						});
					}
				},
				valueRenderer: function (value) {
					var data = [{}, {
						text: lang.service.IN_COMPLIANCE,
						icon: 'glyphicons status_icon circle-arrow-top'
					}, {
						text: lang.WARNING,
						icon: 'glyphicons status_icon circle-arrow-right'
					}, {
						text: lang.service.BREACHED,
						icon: 'glyphicons status_icon circle-arrow-down'
					}, {
						text: lang.summary.NO_SLA_AVAILABLE,
						icon: 'cw_status_indicator cw_status_widget_color status_icon cw_color6'
					}];
					return '<span class="' + data[value].icon + '"></span> <span>' + data[value].text + '</span>';
				},
				hidden: servicesColumns.srvSlaIndex.hidden,
				width: servicesColumns.srvSlaIndex.width,
				//groupable: false
			}, {
				field: 'incidentCount',
				title: lang.service.INCIDENT,
				sortable: true,
				filterable: true,
				//template: '<span>#: incidentCount#</span>',
				template: '<a class="# if (incidentCount) {#pointer #}#cw_incidents_link" data-filter="ACCOUNT">#= incidentCount#</a>',
				attributes: {
					'class': 'text_center tooltip ellipsis to_expand'
				},
				hidden: servicesColumns.incidentCount.hidden,
				width: servicesColumns.incidentCount.width,
				//groupable: false
			}, {
				field: 'coverage',
				title: lang.service.COVERAGE,
				sortable: true,
				filterable: true,
				attributes: {
					'class': 'tooltip ellipsis to_expand text_center'
				},
				hidden: servicesColumns.coverage.hidden,
				width: servicesColumns.coverage.width,
				//groupable: false
			}, {
				field: 'Operational',
				title: lang.service.MODE,
				sortable: true,
				filterable: {
					operators: {
						string: {
							eq: filterMessages.ISIN,
							neq: filterMessages.ISNOTIN
						}
					},
					ui: function (element) {
						var multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'Operational',
							grid: scope.grid,
							dataSource: [{
								text: lang.ACTIVE,
								value: 'OPERATIONAL'
							}, {
								text: lang.service.OUTSIDE_OPERATION_PERIOD,
								value: 'NON_OPERATIONAL'
							}, {
								text: lang.service.IN_MAINTENANCE,
								value: 'IN_MAINTENANCE'
							}],
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#'
						});
					},
					messages: this.gridMessages
				},
				hidden: servicesColumns.Operational.hidden,
				width: servicesColumns.Operational.width,
				attributes: {
					'class': '#if (operatingState === "OPERATIONAL") {# operational #} else {# maintenance #}# text_center tooltip ellipsis to_expand'
				}
			}, {
				field: 'responsibleTeamName',
				title: lang.TEAM,
				sortable: true,
				filterable: {
					ui: function (element) {
						var multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'responsibleTeamName',
							grid: scope.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: scope.filterOptions['responsibleTeamName']
						});
					},
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					}
				},
				hidden: (State.mainApp.session.hasRole('TEAM_READ') || State.mainApp.session.hasRole('TEAM_LIST')) ? servicesColumns.responsibleTeamName.hidden : true,
				template: '<span>#= responsibleTeamName ? responsibleTeamName : "-" #</span>',
				width: servicesColumns.responsibleTeamName ? servicesColumns.responsibleTeamName.width : 200,
				attributes: {
					"class": "tooltip ellipsis to_expand"
				}
			}, {
				field: 'stateDuration',
				title: lang.service.CURRENT_STATE,
				sortable: true,
				filterable: {
					ui: function (element) {
						element.kendoDropDownList({
							dataSource: [{
								text: '1 ' + lang.HOUR,
								value: 3600000
							}, {
								text: '5 ' + lang.HOURS,
								value: 18000000
							}, {
								text: '10 ' + lang.HOURS,
								value: 36000000
							}, {
								text: '1 ' + lang.DAY,
								value: 84600000
							}],
							dataTextField: 'text',
							dataValueField: 'value',
							optionLabel: lang.grid.FILTER_SELECT_VALUE
						});
					}
				},
				hidden: servicesColumns.stateDuration ? servicesColumns.description.hidden : false,
				width: servicesColumns.stateDuration ? servicesColumns.stateDuration.width : 160,
				template: '#=Renderer.duration(stateDuration)#',
				attributes: {
					'class': 'cw_state_duration tooltip ellipsis to_expand'
				},
				//groupable: false
			}, {
				field: 'tags',
				title: lang.TAGS,
				sortable: true,
				filterable: {
					ui: function (element) {
						let multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'tags',
							grid: scope.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: scope.filterOptions['tags']
						});
					},
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					}
				},
				template: item => item.tags.join(','),
				hidden: servicesColumns.tags.hidden,
				width: servicesColumns.tags.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'shared',
				title: lang.SHARED,
				template: '#=shared?lang.SHARED:""#',
				valueRenderer: function (value) {
					return value ? lang.YES : lang.NO;
				},
				hidden: servicesColumns.shared.hidden,
				width: servicesColumns.shared.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				//groupable: false
			}, {
				field: 'information',
				title: lang.INFORMATION,
				sortable: true,
				filterable: true,
				lockable: false,
				template: (item) => {
					return item.information || ''
				},
				hidden: servicesColumns.information.hidden,
				width: servicesColumns.information.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
			}, {
				field: 'description',
				title: lang.DESCRIPTION,
				sortable: false,
				filterable: true,
				template: '#=description ? description : "" #',
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				hidden: servicesColumns.description.hidden,
				width: servicesColumns.description.width,
				//groupable: false
			}
			], servicesColumns),
			columnMenu: true,
			change: $.proxy(this.onRowExpand, this),
			dataBound: $.proxy(this.onGridDataBound, this)
		}).data('kendoCustomGrid')

		/*$('.services_grid').find('.k-grid-content').scroll($.proxy(function () {
			var gridContainer = $('.services_grid').find('.k-grid-content');
			if (gridContainer.scrollTop() + gridContainer.innerHeight() >= $(gridContainer)[0].scrollHeight) {
				if (!this.allDataLoaded) {
					this.gridScroll = true;
					var gridDatasource = $('.services_grid').data('kendoCustomGrid').dataSource;
					this.totalPages = parseInt(gridDatasource.data().length / this.pageSize) + 1;
					gridDatasource.read();
				}
			}
		}, this));*/

		$('.k-virtual-scrollable-wrap').scroll(function () {
			State.dynamicGridPos = $('.services_grid').data('kendoCustomGrid').wrapper.find(".k-scrollbar").scrollTop();
		});

		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.adjustSectionHeight();

		this.columnTooltip = $("#cw_service_qualifiers_states").kendoTooltip({
			filter: ".cw_tooltip",
			content: function (e) {
				return $(e.target).text();
			}
		}).data("kendoTooltip");


		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
		var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('servicesSearchPhrase') || '');

		if (searchValue) {
			//this.indicatorUpdate(true);
		}

		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_service_filters',
			dontLoadDefault: this.accountName ? true : this.preferences ? true : false,
			grid: this.grid,
			modulePrefName: 'Service',
			defaultPrefViewKey: 'defaultFsView',
			prefListKey: 'servicesFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['name', 'responsibleTeamName', 'description', 'accountName', 'information'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns
		});
	},
	onGridReorderResize: function(e){
		const grid = e.sender,
			gridHeaderTable = grid.thead.parent(),
			gridBodyTable = grid.tbody.parent();
		if (gridBodyTable.width() < grid.wrapper.width() - kendo.support.scrollbar()) {
			gridHeaderTable.find("> colgroup > col").last().width("");
			gridBodyTable.find("> colgroup > col").last().width("");
			grid.columns[grid.columns.length - 1].width = "";

			gridHeaderTable.width("");
			gridBodyTable.width("");
		}
	},
	/**
	 * Handler for the grid Data Bound event
	 * @param {Object} e The dataBoundEvent
	 */
	onGridDataBound: function (e) {
		$('.cw_service_state').off();
		if (State.mainApp.session.hasRole('SERVICE_MODEL_READ')) {
			$('.cw_service_state').on('click', '.cw_status_widget_color', $.proxy(this.onServiceStateClick, this));
		} else {
			$('.services_grid').find('.cw_status_widget_color').removeClass('pointer');
		}
		if (State.mainApp.session.hasRole('SLA_READ')) {
			$('.cw_service_sla').on('click', '.status_icon', $.proxy(this.onSlaIndicatorClick, this));
		} else {
			$('.services_grid').find('.status_icon').removeClass('pointer');
		}
		if (State.mainApp.session.hasRole('ASSET_READ')) {
			$('.cw_service_health').on('click', '.cw_status_widget_color', $.proxy(this.onHealthIndicatorClick, this));
		} else {
			$('.services_grid').find('.cw_status_widget_color').removeClass('pointer');
			$('.services_grid').find('.cw_service_indicator').removeClass('pointer');
			$('.services_grid').find('.cw_status_indicator').removeClass('pointer');
		}
		if (State.mainApp.session.hasRole('INCIDENT_READ')) {
			$('.cw_incidents_link').on('click', $.proxy(this.onIncidentsLink, this));
		} else {
			$('.services_grid').find('.cw_incidents_link').removeClass('pointer');
		}
		var tooltip = $('.services_grid').find('.k-grid-content').data('kendoTooltip');
		if (tooltip) {
			tooltip.destroy();
		}
		$('.services_grid').find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (event) {
				return event.target.text().split("\n").join("<br />");
			}
		});
		Utils.applyTooltip();

		setTimeout(() => {
			if (this.checkAll) {
				$('.cw_grid_check_all').prop('checked', true);
				const checkboxes = $('.cw_service_grid').find('.cw_grid_check');
				let length = checkboxes.length;
				for (let i = 0; i < length; i++) {
					$(checkboxes[i]).prop('checked', true);
				}
			}
		});
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function (e) {
		let selectedRow = this.grid.select();
		let myRow = selectedRow[0];
		let messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('.services_grid').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},

	/**
	 * Handler for the service checkbox click
	 * @param {Object} e The click event
	 */
	onServiceCheck: function (e) {
		var checkboxes = $('.cw_grid_check');
		var count = 0;
		var selectAllIndex;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			var checkboxId = $(checkboxes[i]).attr('data-id');
			var index = State.markedCheckboxes.indexOf(checkboxId);
			if (this.selectedIds) {
				selectAllIndex = this.selectedIds.indexOf(checkboxId);
			}
			if ($(checkboxes[i]).is(':checked')) {
				count++;
				if (index === -1) {
					State.markedCheckboxes.push(checkboxId);
				}
				if (selectAllIndex === -1) {
					this.selectedIds.push(checkboxId);
				}
			} else {
				if (index !== -1) {
					State.markedCheckboxes.splice(index, 1);
				}
				if (selectAllIndex !== undefined && selectAllIndex !== -1) {
					this.selectedIds.splice(selectAllIndex, 1);
				}
			}
		}
		if (count) {
			this.gridMenu.enableItem('cw_delete');
			this.gridMenu.enableItem('cw_set_in_maintenance');
			this.gridMenu.enableItem('cw_end_maintenance');
			this.gridMenu.enableItem('cw_set_tags');
			this.gridMenu.enableItem('cw_recalculate');
			this.gridMenu.enableItem('cw_set_information');
		} else {
			this.gridMenu.disableItem('cw_delete');
			this.gridMenu.disableItem('cw_set_in_maintenance');
			this.gridMenu.disableItem('cw_end_maintenance');
			this.gridMenu.disableItem('cw_set_tags');
			this.gridMenu.disableItem('cw_recalculate');
			this.gridMenu.disableItem('cw_set_information');
		}
		if (count === 1) {
			this.gridMenu.enableItem('cw_incidents_create');
			this.gridMenu.enableItem('cw_details');
		} else {
			this.gridMenu.disableItem('cw_incidents_create');
			this.gridMenu.disableItem('cw_details');
		}
		this.checkboxStates = Utils.getCheckboxStates(this.grid);
	},
	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onServiceCheckAll: function (e) {
		this.checkAll = $('.cw_grid_check_all').is(':checked');
		var checkboxes = $('.cw_grid_check');
		var i, length = checkboxes.length;
		this.filteringChanged = false;
		for (let i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', this.checkAll);
			var checkboxId = $(checkboxes[i]).attr('data-id');
			var index = State.markedCheckboxes.indexOf(checkboxId);
			if (this.checkAll) {
				if (index === -1) {
					State.markedCheckboxes.push(checkboxId);
				}
			}
		}
		if (this.checkAll && checkboxes.length) {
			this.gridMenu.enableItem('cw_delete');
			this.gridMenu.enableItem('cw_recalculate');
			this.gridMenu.enableItem('cw_set_in_maintenance');
			this.gridMenu.enableItem('cw_end_maintenance');
			this.gridMenu.enableItem('cw_set_information');
			if (checkboxes.length === 1) {
				this.gridMenu.enableItem('cw_incidents_create');
				this.gridMenu.enableItem('cw_details');
			} else {
				this.gridMenu.disableItem('cw_incidents_create');
				this.gridMenu.disableItem('cw_details');
			}
			this.gridMenu.enableItem('cw_set_tags');
			this.getAllIds();
		} else {
			State.markedCheckboxes = [];
			this.gridMenu.disableItem('cw_delete');
			this.gridMenu.disableItem('cw_recalculate');
			this.gridMenu.disableItem('cw_set_in_maintenance');
			this.gridMenu.disableItem('cw_end_maintenance');
			this.gridMenu.disableItem('cw_incidents_create');
			this.gridMenu.disableItem('cw_details');
			this.gridMenu.disableItem('cw_set_tags');
			this.gridMenu.disableItem('cw_set_information');
			this.selectedIds = null;
		}
		this.checkboxStates = Utils.getCheckboxStates(this.grid);

	},
	getAllIds: function () {
		let url = this.gridUrl + '/selectAll/' + this.urlSubaccounts;
		Utils.ajax(url, 'POST', JSON.stringify(this.gridPayload), $.proxy(function (result) {
			if (result.success) {
				this.selectedIds = result.data;
			}
		}, this));
	},
	/**
	 * Handler for the delete button click
	 * @param {Object} e The click event
	 */
	onServiceDelete: function (e) {
		var url = Settings.serverPath + 'services/delete';
		var checkboxes = $('.cw_grid_check');
		var i, length = checkboxes.length, haveServices = false, checked = 0;
		var ids = [];
		for (let i = 0; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				var id = $(checkboxes[i]).attr('data-id');
				ids.push(id);
				haveServices = true;
				checked++;
			}
		}
		if (haveServices) {
			var dialog = new Dialog({
				title: lang.INFO,
				msg: checked > 1 ? lang.service.messages.SERVICES_REMOVE_CONFIRMATION : lang.service.messages.SERVICE_REMOVE_CONFIRMATION,
				icon: 'ERROR',
				actionText: 'DELETE',
				position: topLeftCornerPosition,
				fn: $.proxy(function (value, button) {
					if (button === 'ok') {
						this.gridMenu.disableItem('cw_delete');
						kendo.ui.progress($('.services_grid'), true);
						this.deleteInProgress = true;
						Utils.ajax(url, 'POST', JSON.stringify(this.selectedIds || ids), $.proxy(function (result) {
							this.deleteInProgress = false;
							kendo.ui.progress($('.services_grid'), false);
							if (result.success) {
								this.actionNotification.setOptions({
									message: lang.service.messages.SERVICE_SUCCESS_DELETED,
									status: 'success'
								}).show();
								// reload data source
								this.dataSource.read();
								$('#cw_details').addClass('disabled');
								$('#delete_service').addClass('disabled');
								$('#cw_incidents_create').addClass('disabled');
							} else {
								Utils.showInfo(lang.ALERT, result.message, result.details, this.grid);
							}
						}, this));
					}
				}, this),
				deleteFromGrid: this.grid
			});
			dialog.show();
		}
	},
	/**
	 * Handler function for set in maintenance button
	 */
	onSetInMaintenance: function () {
		var checkboxes = this.grid.element.find('.cw_grid_check:checked');
		var services = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			services.push($(checkboxes[i]).attr('data-id'));
		}
		var maintenancePeriod = new MaintenancePeriod({
			layout: 'window',
			mode: 'create',
			type: 'service',
			entities: this.selectedIds || services,
			entityId: this.serviceId,
			accountId: this.accountId
		});
	},
	/**
	 * Handler function for end maintenance button
	 */
	onEndMaintenance: function () {
		var checkboxes = this.grid.element.find('.cw_grid_check:checked');
		var services = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			services.push($(checkboxes[i]).attr('data-id'));
		}
		var url = Settings.serverPath + 'maintenance/endMultipleServices';
		Utils.ajax(url, 'POST', JSON.stringify(this.selectedIds || services), $.proxy(function (result) {
			if (result.success) {
				this.clearCheckboxes();
				this.actionNotification.setOptions({
					message: lang.management.messages.END_MAINTENANCE_SUCCEEDED,
					status: 'success'
				}).show();
				this.grid.dataSource.read();
			} else {
				this.actionNotification.setOptions({
					message: result.message,
					status: 'error'
				}).show();
			}
		}, this));
	},
	/**
	 * Clears all checkboxes
	 */
	clearCheckboxes: function () {
		var checkAll = this.grid.element.find('.cw_grid_check_all'),
			checkboxes = this.grid.element.find('.cw_grid_check'),
			i, length = checkboxes.length;
		checkAll.prop('checked', false);
		this.checkAll = false;
		for (let i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', false);
		}
		State.markedCheckboxes = [];
	},
	/**
	 * Handler function for create incident button
	 */
	onCreateIncident: function (e) {
		var serviceCheckbox = $('.cw_grid_check:checked');
		var record = this.dataSource.get(serviceCheckbox.data('id'));
		var services = [record];
		var accountList = [{
			id: record.accId,
			name: record.accountName
		}];
		if (!Utils.getFromListById(accountList, Cookies.CeesoftCurrentAccountId)) {
			accountList.push({
				id: Cookies.CeesoftCurrentAccountId,
				name: Cookies.CeesoftCurrentAccountName
			});
		}
		if (services.length) {
			State.mainApp.navigate(IncidentsRouter.createNew('SERVICE'), {
				extra: {
					serviceId: services[0].id,
					serviceName: services[0].name,
					accountList,
				}
			});
		} else {
			var status = $('.status');
			status.find('p').addClass('error');
			status.find('p').text(lang.service.messages.PLEASE_SELECT_SERVICE);
			status.slideDown().delay(2000).slideUp();
		}
	},
	/**
	 * Handler function for clicking export to csv option in grid menu
	 */
	onExportCsv: function () {
		var url = this.gridUrl + '/exportCSV' + '?includeSubaccounts=' + State.includeSubaccounts + '&_dc=' + Utils.timestamp();
		Utils.exportGridCsv(this.grid, url);
	},
	onServiceRecalculate: function () {
		if (State.mainApp.session.hasRole('SERVICE_UPDATE')) {
			let html = '<div class="cw_recalculate_container">';
			html += '<p>' + lang.service.messages.SERVICE_MODEL_RECALCULATE_CONFIRMATION_DETAIL + '</p>';
			html += '<div class="cw_field">';
			html += '<label class="cw_inline">' + lang.reports.DATE_FROM + '</label>';
			html += '<div class="cw_dropdown_container"><input id="cw_recalculate_from" />';
			html += '</div></div>';
			html += '<div class="cw_field">';
			html += '<input type="checkbox" class="k-checkbox" id="cw_recalculate_service" checked="checked"/>';
			html += '<label for="cw_recalculate_service" class="k-checkbox-label">' + lang.RECALCULATE_SERVICE_SLA + '</label>';
			html += '</div>';
			html += '</div>';
			html += '<div id="cw_dialog_preloader" class="cw_preloader">';
			html += '<div class="spinner">';
			html += '</div></div>';
			html += '<div class="actions">';
			html += '<button id="cw_button_close" class="k-button right">' + lang.CANCEL + '</button>';
			html += '<button id="cw_button_update" class="k-button k-primary right">' + lang.UPDATE + '</button>';
			html += '</div>';

			this.recalculateWindow = $('#cw_recalculate_window').kendoWindow({
				animation: false,
				actions: false,
				draggable: true,
				resizable: false,
				title: lang.RECALCULATE,
				modal: true,
				width: 500,
				height: 200,
				close: $.proxy(function () {
					this.recalculateWindow.destroy();
				}, this)
			}).data('kendoWindow');
			this.recalculateWindow.content(html);
			this.recalculateFrom = $('#cw_recalculate_from').kendoDateTimePicker({
				format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
				timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat),
				value: new Date()
			}).data('kendoDateTimePicker');
			this.recalculateWindow.center();

			$('#cw_button_close').off();
			$('#cw_button_update').off();

			$('#cw_button_close').on('click', $.proxy(this.onRecalculateCloseButton, this));
			$('#cw_button_update').on('click', $.proxy(this.onRecalculateUpdateButton, this));

			let checkboxes;
			this.serviceIds = [];
			if ($('.cw_grid_check_all').is(':checked')) {
				let url = `${this.gridUrl}/selectAll?includeSubaccounts=${State.includeSubaccounts}&_dc=${Utils.timestamp()}`;
				Utils.ajax(url, 'POST', JSON.stringify(this.gridPayload), $.proxy(function (result) {
					if (result.success) {
						this.serviceIds = result.data;
						checkboxes = this.grid.element.find('.cw_grid_check:not(:checked)');
						if (checkboxes.length) {
							let idsToRemove = [];
							for (let checkbox of checkboxes) {
								idsToRemove.push($(checkbox).attr('data-id'));
							}
							const filteredArray = this.serviceIds.filter(function(x) {
								return idsToRemove.indexOf(x) < 0;
							});
							this.serviceIds = filteredArray;
						}
					}
				}, this));
			} else {
				checkboxes = this.grid.element.find('.cw_grid_check:checked');
				for (let i = 0, length = checkboxes.length; i < length; i++) {
					this.serviceIds.push($(checkboxes[i]).attr('data-id'));
				}
			}
		} else {
			this.showStatusMessage(lang.service.messages.SERVICE_MODEL_UPDATE_PERMISIONS, 'error');
		}
	},
	onRecalculateCloseButton: function () {
		this.stop = true;
		this.recalculateWindow.close();
		this.clearCheckboxes();
		$('#service_content').append('<div id="cw_recalculate_window"></div>');
		this.showRecalculationStatus();
	},
	onRecalculateUpdateButton: function () {
		$('#cw_button_update').addClass('hide');
		this.recalculateWindow.title(lang.RECALCULATION);
		this.recalculateSla = $('#cw_recalculate_service').is(':checked');
		this.stop = false;
		this.successNo = 0;
		this.recalculateServices(0);
	},
	recalculateServices: function (index) {
		if (index >= this.serviceIds.length) {
			this.onRecalculateCloseButton();
			return;
		} else {
			let template = kendo.template(index === 1 ? lang.service.RECALCULATE_SERVICE : lang.service.RECALCULATE_SERVICES)({
				i: index + 1,
				length: this.serviceIds.length
			});
			$('.cw_recalculate_container').addClass('text_center').html(template);
			$('#cw_dialog_preloader').css('display', 'block');
			let url = Settings.serverPath + 'services/' + this.serviceIds[index] + '/recalculate?fromTime=' + this.recalculateFrom.value().getTime() + '&recalculateSla=' + this.recalculateSla;
			Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
				index++;
				if (result.success) {
					this.successNo++;
				}
				if (!this.stop) {
					this.recalculateServices(index);
				}
			}, this));
		}

	},
	showRecalculationStatus: function () {
		let template = kendo.template(lang.service.RECALCULATE_DONE)({
			i: this.successNo,
			length: this.serviceIds.length
		});
		this.actionNotification.setOptions({
			message: template,
			status: 'success',
		}).show();
	},

	onSetInformation() {
		$('.window_area').append('<div id="set-information-window-container"></div>');
		ReactDOM.render(<SetInformationWindow
			onClose={this.onSetInformationClose}
			onUpdate={(value) => this.onSetInformationUpdate(value)}
		></SetInformationWindow>, $('#set-information-window-container').get(0))
	},
	onSetInformationClose() {
		ReactDOM.unmountComponentAtNode($('#set-information-window-container').get(0));
	},
	onSetInformationUpdate(informationValue) {
		let currentSelectedIds;
		if (this.selectedIds) {
			currentSelectedIds = this.selectedIds;
		} else {
			let selectedCheckboxes = $('.services_grid').find('.cw_grid_check:checked');
			currentSelectedIds = [];
			for (let i = 0; i < selectedCheckboxes.length; i++) {
				currentSelectedIds.push($(selectedCheckboxes[i]).attr('data-id'));
			}
		}

		let url = Settings.serverPath + 'services/information';
		let postObj = {
			serviceIds: currentSelectedIds,
			information: informationValue
		};
		Utils.ajax(url, 'POST', JSON.stringify(postObj), $.proxy(function (result) {
			if (result.success) {
				this.actionNotification.setOptions({
					message: lang.messages.INFO_SUCCESSFULLY_UPDATE,
					status: 'success'
				}).show();
				this.clearCheckboxes();
				this.grid.dataSource.read();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
			this.onSetInformationClose();
		}, this));
	},

	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		if (!this.isView) {
			var preferences = [{
				key: 'showGridView',
				value: this.showGridView
			}];
			var keys = {
				searchPhrase: 'servicesSearchPhrase',
				FsViews: 'servicesFSViews'
			};

			if (this.showGridView) {
				keys.columns = 'servicesColumns';
				keys.sort = 'servicesSort';
				keys.filter = 'servicesFilter';
				//keys.group = 'servicesGroup';
			}

			keys.servicesShowGridView = this.showGridView;
			this.saveUserPrefs({
				category: 'Service',
				filterPanel: this.gridFilterPanel,
				grid: this.grid,
				eventsToolbar: this.eventsToolbar,
				preferences: preferences,
				keys: keys
			});
		}
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		'use strict';

		UserPrefs.load('Service', $.proxy(function(result) {
			if (!result.success) {
				return Utils.showInfo(lang.ALERT, result.message, result.details);
			}

			this.userPref = result.data;

			let defaultFsView = UserPrefs.get('defaultFsView');

			if (Utils.isGuid(defaultFsView)) {
				let pref = JSON.parse(UserPrefs.get(defaultFsView));
				pref = pref || {
					sort: [{
						field: 'serviceState',
						dir: 'asc',
						compare: null
					}],
					filter: []
				};
				this.servicesSort = pref.sort;
				this.servicesFilter = pref.filter;
				this.servicesColumns = pref.columns;
				//this.servicesGroup = pref.group;
			} else {
				if (UserPrefs.get('servicesSort')) {
					this.servicesSort = JSON.parse(UserPrefs.get('servicesSort'));
				} else {
					this.servicesSort = [{
						field: 'serviceState',
						dir: 'asc',
						compare: null
					}];
				}
				if (UserPrefs.get('servicesFilter')) {
					this.servicesFilter = JSON.parse(UserPrefs.get('servicesFilter'));
				} else {
					this.servicesFilter = [];
				}
				/*if (UserPrefs.get('servicesGroup')) {
					this.servicesGroup = JSON.parse(UserPrefs.get('servicesGroup'));
				} else {
					this.servicesGroup = [];
				}*/
			}

			let servicesShowGridView = UserPrefs.get('servicesShowGridView');

			if (servicesShowGridView) {
				this.showGridView = (servicesShowGridView === 'true');
			} else {
				this.showGridView = true;
			}

			this.createDataSource();

			let eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
				startFrom: 0,
				playing: true
			};

			this.eventsToolbar = new EventsToolbar({
				renderTo: $('#cw_services_events_toolbar'),
				callBackFn: $.proxy(this.onEventsTimeout, this),
				subscriberId: this.subscriberId,
				startFrom: eventStatus.startFrom,
				playing: eventStatus.playing
			});

			this.initCardView();

		}, this))
	},
	/**
	 * Creates the common datasource
	 */
	createDataSource: function () {
		var url, accountId, includeSubaccounts;
		if (this.accountId) {
			accountId = this.accountId;
			includeSubaccounts = false;
		} else {
			accountId = Cookies.CeesoftCurrentAccountId;
			includeSubaccounts = State.includeSubaccounts.toString();
		}
		var externalDefaultPreferences = {
			filter: [],
			sort: [{
				field: 'name',
				order: 'asc'
			}]
		};

		this.urlSubaccounts = '?includeSubaccounts=' + includeSubaccounts;
		if (this.serviceState) {
			var stateFilters = this.convertStateToFilter(this.serviceState);
			this.preferences = externalDefaultPreferences;
		}
		if(this.linkServiceId) {
			this.preferences = externalDefaultPreferences;
		}
		if (this.assetId) {
			this.gridUrl = Settings.serverPath + 'accounts/' + accountId + '/assets/' + this.assetId + '/services';
			this.preferences = externalDefaultPreferences;
		}
		else {
			this.gridUrl = Settings.serverPath + 'accounts/' + accountId + '/services/serviceDetails';
		}
		url = this.gridUrl + this.urlSubaccounts;

		var servicesSort = this.servicesSort || [{
			field: 'serviceState',
			dir: 'asc',
			compare: null
		}];
		var servicesFilter = this.servicesFilter || [];
		if (this.preferences) {
			servicesSort = this.preferences.sort || servicesSort;
			servicesFilter = this.preferences.filter || servicesFilter;
		}
		if (stateFilters) {
			servicesFilter.push(stateFilters.state);
			if (stateFilters.coverage) {
				servicesFilter.push(stateFilters.coverage);
			}
		}

		this.dataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: url,
					contentType: "application/json; charset=utf-8",
					type: "POST",
					dataType: "json",
					cache: false
				},
				parameterMap: $.proxy(function (data, type) {
					if (data.filter?.filters) {
						data.filter.filters = Utils.changeDateFilterToString(data.filter.filters);
					}
					this.gridPayload = data;
					this.gridPayload.tags = this.preferences?.tags || this.tags || [];
					this.gridPayload.showUntagged = this.preferences?.showUntagged || this.showUntagged;
					if(this.linkServiceId)
						data.linkServiceId = this.linkServiceId;

					return kendo.stringify(data);
				}, this),
			},
			pageSize: 100,
			schema: {
				model: {
					id: 'id',
					fields: {
						incidentCount: {
							type: 'number'
						},
						stateDuration: {
							type: 'number'
						},
						hasDocuments: {
							type: 'boolean'
						},
						timestamp: {
							type: 'number'
						},
						coverage: {
							type: 'number'
						},
						shared: {
							type: 'boolean'
						},
						srvHealthIndex: {
							type: 'number'
						},
						srvSlaIndex: {
							type: 'number'
						}
					}
				},
				parse: $.proxy(function(result) {
					this.filterOptions = result.filterOptions;
					return result;
				}, this),
				total: function (response) {
					this.visibleItems = response.visible;
					this.totalItems = response.total;
					return response.visible;
				},
				data: function (result) {
					const response = result.items.map(x => x.data);
					for (var i = 0, length = response.length; i < length; i++) {
						response[i].timestamp = Utils.timestamp();
						var states = ['OPERATIONAL', 'NON_OPERATIONAL', 'IN_MAINTENANCE'];
						var labels = [lang.ACTIVE, lang.service.OUTSIDE_OPERATION_PERIOD, lang.service.IN_MAINTENANCE];
						var index = states.indexOf(response[i].operatingState);
						if (index > -1) {
							response[i].Operational = labels[index];
						} else {
							response[i].Operational = '';
						}
					}
					return response;
				}
			},
			serverSorting: true,
			serverPaging: true,
			serverFiltering: true,
			sort: servicesSort,
			filter: servicesFilter,
			error: ErrorHandler.kendoServerError,
			change: $.proxy(function (e) {
				this.allDataLoaded = false;

				if (JSON.stringify(this.currentGridFilter) !== JSON.stringify(this.dataSource.filter())) {
					this.filteringChanged = true;
				} else {
					this.filteringChanged = false;
				}
				let restoreCheckboxes = true;
				if (this.filteringChanged && this.selectedIds) {
					this.selectedIds = null;
					State.markedCheckboxes = [];
					$('.cw_grid_check_all').prop('checked', false);
					$('.cw_grid_check').prop('checked', false);
					this.onServiceCheck();
					restoreCheckboxes = false;
				}
				this.currentGridFilter = this.dataSource.filter();

				if (this.showGridView && restoreCheckboxes) {
					if (State.markedCheckboxes.length) {
						var restoreCheckboxStates = function () {
							Utils.setCheckboxStates(this.grid, this.checkboxStates);
						};
						setTimeout($.proxy(restoreCheckboxStates, this), 200);
					}
				}
			}, this)
		});
	},
	/**
	 * Handler function for converting the state from bucket to filter
	 * @param {String} state The current state
	 */
	convertStateToFilter: function (state) {
		let stateObj;

		function getPlainFilters(serviceState) {
			return JSON.parse(JSON.stringify({
				filters: [{
					field: 'serviceState',
					operator: 'eq',
					value: serviceState
				}],
				logic: 'or'
			}));
		}

		function getAgentDownFilters(serviceState) {
			return JSON.parse(JSON.stringify({
				filters: [{
					field: 'serviceState',
					operator: 'eq',
					value: serviceState
				}, {
					filters: [{
						field: 'agentStatus',
						operator: 'eq',
						value: 'AGENT_DOWN'
					}, {
						field: 'agentStatus',
						operator: 'eq',
						value: 'AGENT_MAINTENANCE'
					}, {
						field: 'qualifierError',
						operator: 'eq',
						value: true
					}, {
						field: 'assetMaintenance',
						operator: 'eq',
						value: true
					}],
					logic: 'or'
				}],
				logic: 'and'
			}));
		}

		switch (state) {
			case 'UP':
				stateObj = getPlainFilters('UP');
				break;
			case 'DOWN':
				stateObj = getPlainFilters('DOWN');
				break;
			case 'WARNING':
				stateObj = getPlainFilters('WARNING');
				break;
			case 'INACTIVE':
				stateObj = getPlainFilters('UNAVAILABLE');
				break;
			case 'UP_AGENT_DOWN':
				stateObj = getAgentDownFilters('ACTIVE');
				break;
			case 'WARNING_AGENT_DOWN':
				stateObj = getAgentDownFilters('WARNING');
				break;
			case 'DOWN_AGENT_DOWN':
				stateObj = getAgentDownFilters('INACTIVE');
				break;
			default:
				stateObj = {
					filters: [],
					logic: 'or'
				};
				break;
		}

		return {
			state: stateObj
		};
	},
	/**
	 * Method by Andy
	 *
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_section_full');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 40);
	},
	onEventsTimeout: function (events) {
		var reloadGrid, length, serviceImgSrc, services = {};
		if (this.showGridView) {
			if (!this.deleteInProgress) {
				this.dataSource.read();
			} else {
				let checkIfDeleteInProgress = setInterval(() => {
					if (!this.deleteInProgress) {
						this.dataSource.read();
						clearInterval(checkIfDeleteInProgress);
					}
				}, 100)
			}
		} else {
			this.innerView.listView.refresh();
			length = events.length;
			if (length) {
				for (var i = 0; i < length; i++) {
					/*if (events[i].eventType === 'ServiceModelPreview') {
						services[events[i].serviceId] = events[i].serviceId;
					}*/

					if (events[i].eventType === 'ServiceAdmin' || events[i].eventType === 'ServiceSummary' || events[i].eventType === 'Sla') {
						reloadGrid = true;
					}
				}
			} else {
				/*if (events.eventType === 'ServiceModelPreview') {
					services[events.serviceId] = events.serviceId;
				}*/

				if (events.eventType === 'ServiceAdmin' || events.eventType === 'ServiceSummary' || events.eventType === 'Sla') {
					reloadGrid = true;
				}
			}

			if (reloadGrid) {
				//this.reloadGrid = true;
				this.dataSource.read();
				this.innerView.listView.refresh();
			} else {
				for (let serviceId in services) {
					serviceImgSrc = $('#model_' + serviceId).attr('src');
					if ($('#model_' + serviceId).length) {
						$('#model_' + serviceId).attr('src', serviceImgSrc.replace(/_dc=(.*)/, '_dc=' + Utils.timestamp()));
					}
				}
			}
		}
	},
	/**
	 * Handler function for the click event on the asset health from buckets/grid
	 * @param {Object} e The click event object
	 */
	onServiceStateClick: function (e) {
		// todo: we need remove old services list and use new one(in another task),
		//  so I deleted this method, because it used removed page
	},
	/**
	 * Handler function for the click event on the sla indicator
	 * @param {Object} e The click event
	 */
	onSlaIndicatorClick: function (e) {
		var target = $(e.currentTarget);
		var serviceId = target.closest('tr').find('.cw_grid_check').data('id');

		const options = {
			isView: true,
			serviceId: serviceId,
			preferences: {
				sort: [{
					field: 'statusIndex',
					dir: 'desc'
				}],
				filter: []
			}
		};
		NavigationStore.go(SlaRouter.list(options))
	},
	/**
	 * Handler function for the click event on the health indicator
	 * @param {Object} e The click event
	 */

	onHealthIndicatorClick: function (e) {
		var target = $(e.currentTarget);
		var serviceId = target.closest('tr').find('.cw_grid_check').data('id');

		var rowUid = $(target).closest('tr').attr('data-uid');
		var accounts = [this.dataSource.getByUid(rowUid).accountId];

		const url = AssetsRouter.assetHealthSummary({
			serviceId: serviceId,
			source: 'service_assets',
			accountList: accounts,
			includeSubaccounts: State.includeSubaccounts,
			isView: true,
			preferences: {
				sort: [{
					field: 'statusIndex',
					dir: 'desc'
				}],
				filter: []
			}
		})
		NavigationStore.go(url)
	},
	/**
	 * Handler function for the click event on the incidents link
	 * @param {Object} e The click event
	 */
	onIncidentsLink: function (e) {
		var target = $(e.currentTarget),
			serviceId = target.closest('tr').find('.cw_grid_check').data('id'),
			rowUid = $(target).closest('tr').attr('data-uid'),
			accountId = this.dataSource.getByUid(rowUid).accountId;

		if (target.text() !== '0') {
			State.mainApp.navigate(IncidentsRouter.list(), {
				extra: {
					filter: 'SERVICE',
					serviceAccountId: accountId,
					serviceId: serviceId
				}
			});
		}
	},
	/**
	 * Called after one or multiple are set in maintenance
	 */
	onMaintenanceSaved: function () {
		this.actionNotification.setOptions({
			message: lang.service.messages.MAINTENANCE_SUCCESS_SAVED,
			status: 'success',
		}).show();
		this.grid.dataSource.read();
	},
	/*
	 * Handler function for reloading sections which are using tags
	 * */
	reloadByTags: function () {
		this.grid.dataSource.read();
	},
	/**
	 * Subscribes to the events
	 */
	subscribe: function () {
		this.isDataSourceSubscribed = true;
		var subscriptionObj = [{
			eventType: 'ServiceAdmin',
			actionTypes: ['SERVICE_CREATE', 'SERVICE_UPDATE', 'SERVICE_DELETE'],
			accountId: Cookies.CeesoftCurrentAccountId,
			includeSubaccounts: State.includeSubaccounts
		},{
			eventType: 'ServiceSummary',
			accountId: Cookies.CeesoftCurrentAccountId,
			includeSubaccounts: State.includeSubaccounts
		},{
			eventType: 'Sla',
			accountId: Cookies.CeesoftCurrentAccountId,
			includeSubaccounts: State.includeSubaccounts
		}];
		// if (this.showGridView) {
		// 	subscriptionObj.push();
		// } else {
		// 	subscriptionObj.push(/*{
		// 		eventType: 'ServiceModelPreview',
		// 		accountId: Cookies.CeesoftCurrentAccountId,
		// 		includeSubaccounts: State.includeSubaccounts
		// 	}, */{
		// 		eventType: 'Sla',
		// 		accountId: Cookies.CeesoftCurrentAccountId,
		// 		includeSubaccounts: State.includeSubaccounts
		// 	});
		// }
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		if (this.destroyInProgress) {
			return;
		}

		this.destroyInProgress = true;

		this.saveUserPreferences();
		if (this.eventsToolbar) {
			this.eventsToolbar.destroy();
		}
		Application.prototype.destroy.call(this);
	}
});
