import {makeAutoObservable} from "mobx";
import moment, {Moment} from "moment";
import {ModelType} from "areas/cost/budget/budgetTypes";
import {createModelSchemaWrapper, withParent} from "../../../framework/serializr-integration";
import {custom, deserialize, list, object, primitive, serialize} from "serializr";
import {BudgetItemYearValues, CostBudgetItem} from "./costBudgetItem";
import {ModelValidator, ValidatableModel} from "../../../framework/mobx-integration";
import {CostModel} from "./costModel";
import {newGuid} from "tools/guid";

export class CostBudgetData {
	profileId: string;

	modelId: string;
	_id: string; // it's modelId actually

	accountId: string;
	fetchType: string;
	items: CostBudgetItem[];
	modelType: ModelType;

	// we don't calculate costRate from items as other fields
	costRate: BudgetItemYearValues;
	currentEstimateRate: number;
	periodEstimateRate: number;

	dirty: boolean;

	constructor() {
		makeAutoObservable(this);
	}

	get id(){
		return this._id
	}

	set id(id: string){
		this._id = id
		this.modelId = id
	}

	addItem(item: CostBudgetItem) {
		item.parent = this;
		this.items.push(item);
	}

	removeItem(item: CostBudgetItem) {
		const index = this.items.findIndex(i => i.id === item.id);
		this.items.splice(index, 1);
	}

	get isBudgetData() {
		return true;
	}

	get linkedIds() : string[] {
		return this.items?.map(i => i.linkedIds).flat() ?? [];
	}
}

createModelSchemaWrapper(CostBudgetData, {
	profileId: primitive(),
	accountId: primitive(),
	fetchType: primitive(),
	items: list(withParent(object(CostBudgetItem))),
	modelType: primitive(),
	costRate: withParent(object(BudgetItemYearValues)),
	currentEstimateRate: primitive(),
	periodEstimateRate: primitive(),
	dirty: primitive()
})

export class CostBudget implements ValidatableModel<CostBudget> {
	budgetId: string;
	currency: string;
	startDate: Moment;
	costBudget?: CostBudgetData;
	parent: CostModel;

	validator = new ModelValidator(this);

	constructor() {
		makeAutoObservable(this);
	}

	get endDate() {
		return moment(this.startDate).add(1, 'year').add(-1, 'day');
	}

	get formattedStartDate() {
		return this.startDate.format('DD-MM-YYYY');
	}

	get formattedEndDate() {
		return this.endDate.format('DD-MM-YYYY');
	}

	clone = (budgetStart: moment.Moment, useLastCost?: boolean, useLastBudget?: boolean) => {
		const newBudget = deserialize(CostBudget, serialize(this));
		newBudget.budgetId = newGuid();
		newBudget.startDate = budgetStart;
		newBudget.costBudget.items.forEach(x => {
			if (!useLastCost) {
				x.cost.clear();
				x.clearDescendantsValues(x.cost);
			}
			if (!useLastBudget) {
				x.budget.clear();
				x.clearDescendantsValues(x.budget);
			}
		});
		newBudget.costBudget.items.forEach(this.resetMetricsAndEvent);
		return newBudget;
	}

	private resetMetricsAndEvent = (item: CostBudgetItem) => {
		item.hasEvents = false;
		item.items.forEach(this.resetMetricsAndEvent);
	}
}

createModelSchemaWrapper(CostBudget, {
	budgetId: primitive(),
	currency: primitive(),
	startDate: custom((value: Moment) => {
		return value.format('YYYY-MM-DD');
	}, (jsonValue, context, oldValue) => {
		return moment(jsonValue);
	}),
	costBudget: object(CostBudgetData)
})
