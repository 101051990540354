import './barsStatesWidget.less'

import React, {useEffect, useRef, useState} from "react";
import {observer} from 'mobx-react';

import {Severity, severityToText} from "framework/entities/healthData";
import {Geometry, WidgetConfig} from "../designer/features/widgets/widgetConfig";
import {SeveritiesSelect, SeverityOptions} from "./severitiesSelect";
import FormEntry from "./form/formEntry";
import {sharedLocalization} from "../designer/features/widgets/localization";
import {firstToUpper} from "../../tools/utils";
import {DefaultWidgetWrapper} from "../designer/features/widgets/defaultWidgetWrapper";
import {ApiResponse} from "../../framework/api";
import {AntAlert} from "./ant/antAlert";
import {throttle} from "lodash";
import {WidgetProps} from "../designer/features/widgets/widgetProps";
import {EventType} from "../../framework/entities/events";
import {RemoteEventsManager} from "../../core/remoteEventsManager";
import _ from 'lodash';

let b = require('b_').with('bars-states-widget');
const i = require('core/localization/localization').translator(sharedLocalization, {

});

export type StateWidgetDisplayOptions = {
	showBucket?: boolean,
	showBar?: boolean,
	displayLabels?: boolean
}

export type BarsStatesWidgetProps = {
	dataList: BarsStateWidgetEntry[],
	warningIconMessage?: string,
	onClick?: (severity: Severity, isIcon?: boolean) => void,
	geometry?: Geometry,
	type?: string,
	displayOptions?: StateWidgetDisplayOptions
}

export type BarsStateWidgetEntry = {
	value: number
	label?: string
	title: string
	severity: Severity
	warning?: boolean
}

export type WidgetPropsBase<TSummary, TSeverity> = {
	summary?: TSummary;
	severities?: TSeverity[];
} & Omit<BarsStatesWidgetProps, 'dataList'>;

export function getTotal(bars: BarsStateWidgetEntry[]) {
	return bars.reduce((total, entry) => total + entry.value, 0)
}

export const BarsStatesWidget = observer((props: BarsStatesWidgetProps) => {
	const contRef = useRef();
	const total = getTotal(props.dataList);
	const [labelClass, setLabelClass] = useState<string>();
	const [showLabel, setShowLabel] = useState<boolean>();

	useEffect(() => {
		init();
		window.addEventListener('resize', onResize);
		return () => {
			window.removeEventListener('resize', onResize);
		}
	}, [])

	useEffect(() => {
		init();
	}, [contRef.current, props.geometry, props.displayOptions])

	const onResize = () => {
		if(!props.geometry){
			initDebounced();
		}
	}

	const init = () => {
		const geometry: Geometry = props.geometry ?? {
			width: contRef?.current?.offsetWidth ?? 0,
			top: 0,
			left: 0,
			height: 0
		}
		let showLabel = props.displayOptions?.displayLabels ?? true;
		let labelClass = b('label')
		if (props.dataList.length === 5 && geometry?.width < 300) {
			if (geometry.width < 250) {
				showLabel = false
			} else {
				labelClass = b('label-small')
			}
		}
		if (props.dataList.length === 4 && props.geometry?.width < 250) {
			labelClass = b('label-small')
		}

		if (props.dataList.length === 3 && props.type === 'sla-state' && props.geometry?.width < 255) {
			labelClass = b('label-small');
			if (geometry?.width < 225) {
				showLabel = false;
			}
		}

		if (!props.geometry && geometry?.width < 240) {
			showLabel = false;
		}
		setShowLabel(showLabel);
		setLabelClass(labelClass);
	}
	const initDebounced = _.debounce(init, 500);

	return (
		<div className={b()} ref={contRef}>
			{(props.displayOptions?.showBucket ?? true) && <div className={b('bars')}>
				{props.dataList.map((entry) =>
					<div key={entry.severity}
						 className={b('bar', {empty: !entry.value})}
						 title={entry.title}
						 onClick={() => entry.value != 0 && props.onClick?.(entry.severity)}
					>
						<div className={b('value', {severity: entry.severity.toLowerCase()})}>{entry.value}</div>
						{showLabel && <div className={labelClass}>{entry.label ?? severityToText(entry.severity)}</div>}
						{entry.warning && <div className={b('warning-icon')}
											   title={props.warningIconMessage}
											   onClick={(e) => {
												   props.onClick?.(entry.severity, true);
												   e.stopPropagation();
											   }}
						>
						</div>}
					</div>)
				}
			</div>}
			{(props.displayOptions?.showBar ?? true) && <div className={b('progress-container')}>
				{props.dataList.filter(x => x.value != 0).map((entry) =>
					<div key={entry.severity}
						 onClick={() => props.onClick && props.onClick(entry.severity)}
						 className={b('progress-bar', {severity: entry.severity.toLowerCase()})}
						 style={{width: entry.value / total * 100 + '%'}}
						 title={entry.title}
					>
					</div>)
				}
			</div>}
		</div>)
})

export class SeverityStateConfiguratorProps {
	displayOptions: StateWidgetDisplayOptions;
	severitiesList: (Severity | string)[];
	dataSource: string[][];
	onChange: (value: {displayOptions: StateWidgetDisplayOptions, severities: (Severity | string)[]}) => void;
}

export const SeverityStateConfigurator = (props: SeverityStateConfiguratorProps) => {
	const [displayOptions, setDisplayOptions] = useState(props.displayOptions ?? {showBucket: true, showBar: true, displayLabels: true});
	const [severities, setSeverities] = useState(props.severitiesList ?? []);

	useEffect(() => {
		props.onChange?.({
			displayOptions: displayOptions,
			severities: severities
		});
	}, [displayOptions, severities])

	return <>
		<FormEntry label={i('Severities')}
				   vertical={true}>
			<SeveritiesSelect
				onChange={setSeverities}
				value={severities}
				datasource={props.dataSource}
				required
			/>
		</FormEntry>
		<SeverityOptions {...displayOptions} onChange={setDisplayOptions} />
	</>
}

export function summaryToDataListConverter<TSummary, TSeverity>(availableList: TSeverity[], severityMap: Map<TSeverity, Severity>, valueGetter: Map<TSeverity, (summary: TSummary, isWarning?: boolean) => number | boolean>, translates: any) {
	return (summary: TSummary, severities?: TSeverity[]) => {
		if (!summary)
			return []

		if (!severities) {
			severities = [...availableList]
		}

		return availableList
			.filter(x => severities.indexOf(x) >= 0)
			.map(x => {
				const firstUpper = firstToUpper(x);
				const value= valueGetter.get(x)(summary);
				return {
					severity: severityMap.get(x),
					label: translates(firstUpper),
					title: translates('BarTitle', i(firstUpper), value),
					value: value,
					warning: valueGetter.get(x)(summary, true)
				} as BarsStateWidgetEntry;
			});
	}
}

export function withStateDataSource<TSummary, TSeverity>(WrappedComponent: typeof BarsStatesWidget, listMapper: (summary: TSummary, severities?: TSeverity[]) => BarsStateWidgetEntry[]) {
	return class extends React.Component<WidgetPropsBase<TSummary, TSeverity>, {dataList: BarsStateWidgetEntry[]}> {
		constructor(props: WidgetPropsBase<TSummary, TSeverity>) {
			super(props);
			this.state = {
				dataList: []
			}
		}

		componentDidMount() {
			this.setState({
				dataList: listMapper(this.props.summary, this.props.severities)
			});
		}

		componentDidUpdate(prevProps: Readonly<WidgetPropsBase<TSummary, TSeverity>>, prevState: Readonly<{dataList: BarsStateWidgetEntry[]}>, snapshot?: any) {
			if (JSON.stringify(prevProps.summary) != JSON.stringify(this.props.summary)) {
				this.setState({
					dataList: listMapper(this.props.summary, this.props.severities)
				});
			}
		}

		render() {
			return <WrappedComponent {...this.props} dataList={this.state.dataList} />;
		}
	};
}

export type WidgetState<TSummary> = {
	summary?: TSummary;
	error: boolean;
}

export type StateWidgetProps<C extends WidgetConfig, P extends object> = WidgetProps<C, P> & {
	subscriptions: Subscription[],
	type?: string,
	onClick: (severity: Severity) => void,
	warningIconMessage?: string
};
export type Subscription = {eventType: EventType, accountId: string, includeSubaccounts: boolean};

export function withDashboardData<C extends WidgetConfig & {severities: Severity[], displayOptions: StateWidgetDisplayOptions}, P extends object>(WrappedComponent: any, warningIconMessage?: string) {
	return class extends React.Component<StateWidgetProps<C, P>, WidgetState<P>> {
		constructor(props: StateWidgetProps<C, P>) {
			super(props);
		}

		componentDidMount() {
		}

		render() {
			const passThruProps = {...this.props};
			const warningMessage = this.props.warningIconMessage ?? warningIconMessage;
			return (
				<DefaultWidgetWrapper {...passThruProps}>
					<WrappedComponent {...this.props}
									  warningIconMessage={warningMessage}
									  severities={this.props.config.severities}
									  displayOptions={this.props.config.displayOptions}
					/>
				</DefaultWidgetWrapper>
			)
		}
	};
}

export function withDataLoad<C extends WidgetConfig, P extends object>(WrappedComponent: any, dataLoader: (config: any) => Promise<ApiResponse<P>>) {
	return class extends React.Component<StateWidgetProps<C, P>, WidgetState<P>> {
		destroyed: boolean = false;

		subscriber: {
			unsubscribe: () => void
		}
		constructor(props: StateWidgetProps<C, P>) {
			super(props);
			this.state = { error: false	};
		}

		render() {
			return <>
				{this.state.summary && !this.state.error && <WrappedComponent {...this.props} summary={this.state.summary} />}
				{this.state.error && <AntAlert type={"error"} message={i('There was an error while loading data')}/>}
			</>
		}

		async componentDidMount() {
			await this.loadData();
			this.subscriber = RemoteEventsManager.subscribeCallback(this.props.subscriptions, throttle(this.loadData, 500));
		}

		componentWillUnmount() {
			this.destroyed = true;
			this.subscriber?.unsubscribe()
		}

		loadData = async () => {
			let result = await dataLoader(this.props.config);
			if (this.destroyed) {
				return;
			}
			if (result.success) {
				this.setState({ summary: result.data });
			} else {
				this.setState({ error: true });
			}
		}
	};
}
