import React from 'react';
import ReactDOM from "react-dom";
import {Utils, applyRequired} from 'tools/utils';
import Settings from 'settings';
import MultiToggle from 'controls/multiToggle';
import ErrorHandler from 'core/errorHandler';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import {AntSelect} from "controls/react/ant/antSelect";
import FormEntry from "controls/react/form/formEntry";

export function GaugeWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export {GaugeWidgetConfiguration as default};

jQuery.extend(GaugeWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {Object} widgetObj
	 */

	/**
	 * Main init function
	 */
	initComponent: function () {
		var minValue;
		this.widgetObj.configuration.type = this.widgetObj.configuration.type || 'SERVICE';

		if (this.wasUpdate) {
			minValue = this.widgetObj.configuration.minValue || 0;
		} else {
			minValue = this.widgetObj.configuration.minValue || '';
		}
		this.widgetObj.configuration.minValue = minValue;
		this.widgetObj.configuration.maxValue = this.widgetObj.configuration.maxValue === undefined ? '' : this.widgetObj.configuration.maxValue;
		this.widgetObj.configuration.breachValue = this.widgetObj.configuration.breachValue === undefined ? '' : this.widgetObj.configuration.breachValue;

		var widgetObj = this.widgetObj;
		var inverted = widgetObj.configuration.inverted;
		var html = '';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TITLE + ': </label>';
		html += '<input type="text" class="cw_widget_title cw_inline" value="' + (widgetObj.title || '') + '" />';
		html += '</div>';
		html += '<div class="cw_field" id="cw_gauge_account">';
		html += '<label class="cw_inline">' + lang.ACCOUNT + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_gauge_accountid" type="text" value="' + (widgetObj.configuration.accountId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_gauge_profile">';
		html += '<label class="cw_inline">' + lang.PROFILE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_gauge_profileid" type="text" value="' + (widgetObj.configuration.profileId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_gauge_category">';
		html += '<label class="cw_inline">' + lang.CATEGORY + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_gauge_categoryid" type="text" value="' + (widgetObj.configuration.categoryId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_gauge_rule_type">';
		html += '<label class="cw_inline">' + lang.kpi.RULE_TYPE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_gauge_ruletypeid" type="text" value="' + (widgetObj.configuration.ruleTypeId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_gauge_sample_rate">';
		html += '<label class="cw_inline">' + lang.kpi.SAMPLE_RATE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_gauge_sample_rateid" type="text" value="' + (widgetObj.configuration.sampleRateId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_gauge_kpi_accounts">';
		html += '<label class="cw_inline">' + lang.kpi.KPI_ACCOUNTS + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_gauge_kpi_accountsids" type="text" value="' + (widgetObj.configuration.kpiAccountId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field"><label class="cw_inline">' + lang.TYPE + ': </label><ul id="cw_gauge_type"></ul></div>';
		html += '<ul id="cw_gauge_type"></ul>';
		html += '</div>';
		html += '<div class="cw_field cw_tooltip" data-title="' + lang.messages.GAUGE_MIN_VALUE + '" id="cw_gauge_min_field" class="' + (inverted ? "" : "hide") + '"><label class="cw_inline">' + lang.widget.MIN_VALUE + ': </label><div class="cw_numeric_container"><input id="cw_gauge_min" type="text" value="' + widgetObj.configuration.minValue + '" /></div></div>';
		html += '<div class="cw_field cw_tooltip" data-title="' + lang.messages.GAUGE_MAX_VALUE + '"><label class="cw_inline">' + lang.widget.MAX_VALUE + ': </label><div class="cw_numeric_container"><input id="cw_gauge_max" type="text" value="' + widgetObj.configuration.maxValue + '" /></div></div>';
		html += '<div class="cw_field cw_tooltip" data-title="' + lang.messages.GAUGE_BREACH_VALUE + '"><label class="cw_inline">' + lang.widget.BREACH_VALUE + ': </label><div class="cw_numeric_container"><input id="cw_gauge_breach" type="text" value="' + widgetObj.configuration.breachValue + '" /></div></div>';
		html += '<div class="cw_field cw_tooltip" data-title="' + lang.messages.GAUGE_DATA_REPRESENTATION + '">';
		html += '<input type="checkbox" id="cw_gauge_invert" class="k-checkbox" ' + (inverted ? 'checked' : '') + ' />';
		html += '<label for="cw_gauge_invert" class="k-checkbox-label">' + lang.widget.INVERT + '</label>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<span class="cw_inline_full">' + lang.LEGEND + ': </span>';
		html += '<input id="cw_gauge_legend" type="text" />';
		html += '</div>';

		this.widgetConfigurationFormEl.empty().append(html);
		if (this.mode === 'update' || this.wasUpdate) {
			this.selectedProfileId = this.widgetObj.configuration.profileId;
			this.selectedCategoryId = this.widgetObj.configuration.categoryId;
			this.selectedRuleTypeId = this.widgetObj.configuration.ruleTypeId;
			this.selectedSampleRateId = this.widgetObj.configuration.sampleRateId;
			this.selectedKpiAccountId = this.widgetObj.configuration.kpiAccountId;
			this.widgetAccountId = this.widgetObj.configuration.accountId;
		}
		this.gaugeType = new MultiToggle({
			id: 'cw_gauge_type',
			type: 'label',
			cssClass: 'cw_multi_toggle true',
			value: 'gauge',
			items: [{
				id: 'cw_toggle_gauge',
				title: lang.GAUGE,
				label: lang.GAUGE,
				selected: this.mode === 'update' || this.wasUpdate ? (widgetObj.configuration.gaugeType === 'gauge' ? true : false) : true,
				fn: this.onGaugeTypeToggle,
				scope: this,
				value: 'gauge'
			}, {
				id: 'cw_toggle_solidgauge',
				title: lang.SOLID_GAUGE,
				label: lang.SOLID_GAUGE,
				selected: this.mode === 'update' || this.wasUpdate ? (widgetObj.configuration.gaugeType === 'solidgauge' ? true : false) : false,
				fn: this.onGaugeTypeToggle,
				scope: this,
				value: 'solidgauge'
			}]
		});
		this.legendDataSource = [{
			name: '<Account>',
			id: '<Account>'
		}, {
			name: '<KPI account>',
			id: '<KPI account>'
		}, {
			name: '<Profile>',
			id: '<Profile>'
		}, {
			name: '<RuleType>',
			id: '<RuleType>'
		}, {
			name: '<SampleRate>',
			id: '<SampleRate>'
		}];
		if (this.mode === 'update' || this.wasUpdate) {
			this.legendMultiList = widgetObj.configuration.labelTemplate || [];
		}
		this.renderLegendMultiSelect();
		this.initKendoComponents();
		const requiredFields = ['#cw_gauge_accountid', '#cw_gauge_profileid', '#cw_gauge_categoryid', '#cw_gauge_ruletypeid', '#cw_gauge_sample_rateid', '#cw_gauge_kpi_accountsids', '#cw_gauge_min', '#cw_gauge_max', '#cw_gauge_breach'];
		applyRequired(requiredFields);
	},
	renderLegendMultiSelect: function() {
		ReactDOM.render(<FormEntry label={lang.LEGEND} vertical={true}>
			<AntSelect
				dataList={this.legendDataSource}
				mode={"multiple"}
				onChange={value => this.onLegendChange(value)}
				defaultValue={this.legendMultiList || []}
				placeholder={lang.SELECT} />
		</FormEntry>,  $('#cw_gauge_legend').parent().get()[0]);
	},
	onLegendChange: function(value) {
		this.legendMultiList = value || [];
	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		var widgetObj = this.widgetObj;

		this.createAccountComponent({
			element: $('#cw_gauge_accountid'),
			container: $('#cw_gauge_account'),
			nextElement: $('#cw_gauge_profileid'),
			otherElements: [$('#cw_gauge_categoryid'), $('#cw_gauge_ruletypeid'), $('#cw_gauge_sample_rateid'), $('#cw_gauge_kpi_accountsids')],
			events: {
				change: (e) => {
					this.isInputChanged = true;
					this.currentAccountId = e.sender.value();
					this.profileSelector.dataSource.read();
					this.emptyComponent({
						containers: [{
							container: $('#cw_gauge_profile'),
							label: lang.PROFILE
						}, {
							container: $('#cw_gauge_category'),
							label: lang.CATEGORY
						}, {
							container: $('#cw_gauge_rule_type'),
							label: lang.TYPE
						}, {
							container: $('#cw_gauge_sample_rate'),
							label: lang.kpi.SAMPLE_RATE
						}, {
							container: $('#cw_gauge_kpi_accounts'),
							label: lang.kpi.KPI_ACCOUNTS
						}],
						length: e.sender.dataSource.data().length
					});
				},
				dataSourceChange: $.proxy(function (e, length) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_gauge_profile'),
							label: lang.PROFILE
						}, {
							container: $('#cw_gauge_category'),
							label: lang.CATEGORY
						}, {
							container: $('#cw_gauge_rule_type'),
							label: lang.TYPE
						}, {
							container: $('#cw_gauge_sample_rate'),
							label: lang.kpi.SAMPLE_RATE
						}, {
							container: $('#cw_gauge_kpi_accounts'),
							label: lang.kpi.KPI_ACCOUNTS
						}],
						length: length
					});
				}, this)
			}
		});

		this.profileSelector = $('#cw_gauge_profileid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.currentAccountId || this.widgetAccountId || widgetObj.configuration.accountId) + '/kpi/lite?ignoreTags=true';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_gauge_category'),
							label: lang.CATEGORY
						}, {
							container: $('#cw_gauge_rule_type'),
							label: lang.TYPE
						}, {
							container: $('#cw_gauge_sample_rate'),
							label: lang.kpi.SAMPLE_RATE
						}, {
							container: $('#cw_gauge_kpi_accounts'),
							label: lang.kpi.KPI_ACCOUNTS
						}],
						length: e.items.length
					});
				}, this),
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'name',
			clearButton: false,
			dataValueField: 'id',
			select: $.proxy(function (e) {
				if (e.item && e.item.hasClass('k-hover')) {
					this.isProfileChanged = true;
					this.isInputChanged = true;
					this.selectedProfileId = e.dataItem.id;
					var categoryHandler = $('#cw_gauge_categoryid').data('kendoComboBox');
					var ruleTypeHandler = $('#cw_gauge_ruletypeid').data('kendoComboBox');
					var sampleRateHandler = $('#cw_gauge_sample_rateid').data('kendoComboBox');
					var kpiAccountsHandler = $('#cw_gauge_kpi_accountsids').data('kendoComboBox');

					categoryHandler.text('');
					if (this.selectedProfileId) {
						categoryHandler.enable(true);
						categoryHandler.dataSource.read();

					} else {
						categoryHandler.enable(false);
					}

					ruleTypeHandler.text('');
					ruleTypeHandler.enable(false);
					sampleRateHandler.text('');
					sampleRateHandler.enable(false);
					kpiAccountsHandler.text('');
					kpiAccountsHandler.enable(false);
				}
			}, this),
			template: '<span data-id="${ data.id }">${ data.name }</span>',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.mode === 'update' || this.wasUpdate) {
					if (!this.isAccountChanged) {
						this.errorFields = Utils.comboBoxValueCheck(e);
					}
				}
				if (this.isAccountChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this),
			placeholder: lang.SELECT
		}).data('kendoComboBox');

		$('#cw_gauge_categoryid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.currentAccountId || this.widgetAccountId || widgetObj.configuration.accountId) + '/kpi/' + this.selectedProfileId + '/categories';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_gauge_rule_type'),
							label: lang.TYPE
						}, {
							container: $('#cw_gauge_sample_rate'),
							label: lang.kpi.SAMPLE_RATE
						}, {
							container: $('#cw_gauge_kpi_accounts'),
							label: lang.kpi.KPI_ACCOUNTS
						}],
						length: e.items.length
					});
				}, this),
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'text',
			dataValueField: 'value',
			clearButton: false,
			select: $.proxy(function (e) {
				if (e.item && e.item.hasClass('k-hover')) {
					this.isCategoryChanged = true;
					this.isInputChanged = true;
					this.selectedCategoryId = e.dataItem.value;
					var ruleTypeHandler = $('#cw_gauge_ruletypeid').data('kendoComboBox');
					var sampleRateHandler = $('#cw_gauge_sample_rateid').data('kendoComboBox');
					var kpiAccountsHandler = $('#cw_gauge_kpi_accountsids').data('kendoComboBox');
					if (this.selectedCategoryId) {
						ruleTypeHandler.enable(true);
						ruleTypeHandler.dataSource.read();
						ruleTypeHandler.text('');
					} else {
						ruleTypeHandler.enable(false);
					}
					sampleRateHandler.text('');
					sampleRateHandler.enable(false);
					kpiAccountsHandler.text('');
					kpiAccountsHandler.enable(false);
				}
			}, this),
			template: '<span data-value="${ data.value }">${ data.text }</span>',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.mode === 'update' || this.wasUpdate) {
					if (!this.isProfileChanged) {
						this.errorFields = Utils.comboBoxValueCheck(e);
					}
				}
				if (this.isProfileChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this),
			placeholder: lang.SELECT
		}).data('kendoComboBox');

		$('#cw_gauge_ruletypeid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.currentAccountId || this.widgetAccountId || widgetObj.configuration.accountId) + '/kpi/' + this.selectedProfileId + '/categories/' + this.selectedCategoryId + '/types';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				schema: {
					model: {
						id: "value"
					}
				},
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_gauge_sample_rate'),
							label: lang.kpi.SAMPLE_RATE
						}, {
							container: $('#cw_gauge_kpi_accounts'),
							label: lang.kpi.KPI_ACCOUNTS
						}],
						length: e.items.length
					});
				}, this),
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'text',
			dataValueField: 'value',
			clearButton: false,
			select: $.proxy(function (e) {
				if (e.item && e.item.hasClass('k-hover')) {
					this.isRuleTypeChanged = true;
					this.isInputChanged = true;
					this.selectedRuleTypeId = e.dataItem.value;
					var sampleRateHandler = $('#cw_gauge_sample_rateid').data('kendoComboBox');
					var kpiAccountsHandler = $('#cw_gauge_kpi_accountsids').data('kendoComboBox');
					if (this.selectedRuleTypeId) {
						sampleRateHandler.enable(true);
						sampleRateHandler.dataSource.read();
						sampleRateHandler.text('');
					} else {
						sampleRateHandler.enable(false);
					}
					kpiAccountsHandler.text('');
					kpiAccountsHandler.enable(false);
				}
			}, this),
			template: '<span data-value="${ data.value }">${ data.text }</span>',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.mode === 'update' || this.wasUpdate) {
					if (!this.isCategoryChanged) {
						this.errorFields = Utils.comboBoxValueCheck(e);
					}
				}
				if (this.isCategoryChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this),
			placeholder: lang.SELECT
		}).data('kendoComboBox');

		$('#cw_gauge_sample_rateid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.currentAccountId || this.widgetAccountId || widgetObj.configuration.accountId) + '/kpi/' + this.selectedProfileId + '/types/' + this.selectedRuleTypeId + '/sampleRates';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_gauge_kpi_accounts'),
							label: lang.kpi.KPI_ACCOUNTS
						}],
						length: e.items.length
					});
				}, this),
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'text',
			dataValueField: 'value',
			clearButton: false,
			select: $.proxy(function (e) {
				if (e.item && e.item.hasClass('k-hover')) {
					this.isSampleRateChanged = true;
					this.isInputChanged = true;
					this.selectedSampleRateId = e.dataItem.value;
					var kpiAccountsHandler = $('#cw_gauge_kpi_accountsids').data('kendoComboBox');
					if (this.selectedSampleRateId) {
						kpiAccountsHandler.enable(true);
						kpiAccountsHandler.dataSource.read();
						kpiAccountsHandler.text('');
					} else {
						kpiAccountsHandler.enable(false);
					}
				}
			}, this),
			template: '<span data-value="${ data.value }">${ data.text }</span>',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.mode === 'update' || this.wasUpdate) {
					if (!this.isRuleTypeChanged) {
						this.errorFields = Utils.comboBoxValueCheck(e);
					}
				}
				if (this.isRuleTypeChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this),
			placeholder: lang.SELECT
		}).data('kendoComboBox');

		$('#cw_gauge_kpi_accountsids').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.currentAccountId || this.widgetAccountId || widgetObj.configuration.accountId) + '/kpi/' + this.selectedProfileId + '/accounts';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				schema: {
					parse: function (data) {
						data.unshift({
							value: 'aggregated',
							text: lang.AGGREGATED
						});

						return data;
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'text',
			dataValueField: 'value',
			select: $.proxy(function (e) {
				this.selectedKpiAccountId = e.dataItem.value;
			}, this),
			template: '<span data-value="${value}">${text}</span>',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			clearButton: false,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (!this.isSampleRateChanged) {
					this.errorFields = Utils.comboBoxValueCheck(e);
				} else {
					Utils.focusNextComboBox(e);
				}
			}, this),
			placeholder: lang.SELECT
		}).data('kendoComboBox');

		this.minValue = $('#cw_gauge_min').kendoNumericTextBox({
			format: 'n0',
			spinners: false
		}).data('kendoNumericTextBox');

		this.maxValue = $('#cw_gauge_max').kendoNumericTextBox({
			format: 'n0',
			spinners: false
		}).data('kendoNumericTextBox');

		this.breachValue = $('#cw_gauge_breach').kendoNumericTextBox({
			format: 'n0',
			spinners: false
		}).data('kendoNumericTextBox');
	},
	/**
	 * Handler function for the click event on qualifier type toggle
	 * @param {String} value The associated value of the clicked item
	 */
	onPeriodToggle: function (value) {
		if (value === 'CUSTOM') {
			$('#cw_gauge_dates').removeClass('hide');
		} else {
			$('#cw_gauge_dates').addClass('hide');
		}
	},
	/**
	 * Handler function for the click event on the ignore missing data chckbox
	 * @param {type} e
	 */
	onIgnoreCheckbox: function (e) {
		var checkbox = $(e.currentTarget);
		checkbox.toggleClass('check unchecked');
	},
	/*
	* Handler function used to get widget values to provide it to widgetform.js
	* */
	getValues: function () {
		var title = $('.cw_widget_title').val();
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'kpi_gauge',
			title: title,
			configuration: {
				accountId: $('#cw_gauge_accountid').data('kendoComboBox').value(),
				gaugeType: this.gaugeType.getValue(),
				inverted: $('#cw_gauge_invert').is(':checked'),
				minValue: parseFloat($('#cw_gauge_min').val() || 0),
				breachValue: parseFloat($('#cw_gauge_breach').val()),
				maxValue: parseFloat($('#cw_gauge_max').val()),
				labelTemplate: this.legendMultiList
			}
		};

		var config = {
			profileId: this.selectedProfileId,
			profileText: $('#cw_gauge_profileid').data('kendoComboBox').text(),
			categoryId: this.selectedCategoryId,
			categoryText: $('#cw_gauge_categoryid').data('kendoComboBox').text(),
			ruleTypeId: this.selectedRuleTypeId,
			ruleTypeText: $('#cw_gauge_ruletypeid').data('kendoComboBox').text(),
			sampleRateId: this.selectedSampleRateId,
			sampleRateText: $('#cw_gauge_sample_rateid').data('kendoComboBox').text(),
			kpiAccountId: this.selectedKpiAccountId
		};

		var metrics = [];
		metrics.push({
			profileId: config.profileId,
			type: config.ruleTypeId,
			sampleRate: config.sampleRateId
		});
		if (config.kpiAccountId === 'aggregated') {
			//metrics[0].accountId =  null;
			metrics[0].aggregated = true;

		} else {
			//metrics[0].accountId = config.kpiAccountId;
			metrics[0].aggregated = false;
		}
		widgetObj.configuration.metrics = metrics;
		jQuery.extend(widgetObj.configuration, config);
		return widgetObj;
	},
	isValid: function () {
		var valid = true;
		var validationMessage = '';
		var min = $('#cw_gauge_min').val();
		var breach = $('#cw_gauge_breach').val();
		var max = $('#cw_gauge_max').val();
		var inverted = $('#cw_gauge_invert').is(':checked');
		var accountCombo = $('#cw_gauge_accountid').data('kendoComboBox');
		var profileCombo = $('#cw_gauge_profileid').data('kendoComboBox');
		var categoryCombo = $('#cw_gauge_categoryid').data('kendoComboBox');
		var ruleTypeCombo = $('#cw_gauge_ruletypeid').data('kendoComboBox');
		var sampleRateCombo = $('#cw_gauge_sample_rateid').data('kendoComboBox');
		var kpiAccountsCombo = $('#cw_gauge_kpi_accountsids').data('kendoComboBox');

		if (!Utils.isGuid(accountCombo.value())) {
			valid = false;
			validationMessage = lang.widget.SELECT_ACCOUNT;
		}
		if (!Utils.isGuid(this.widgetAccountId)) {
			valid = false;
			validationMessage = lang.widget.SELECT_ACCOUNT;
		}
		if (!Utils.isGuid(profileCombo.value())) {
			valid = false;
			validationMessage = lang.widget.SELECT_PROFILE;
		}
		if (!categoryCombo.value()) {
			valid = false;
			validationMessage = lang.widget.SELECT_CATEGORY;
		}
		if (!ruleTypeCombo.value()) {
			valid = false;
			validationMessage = lang.widget.SELECT_RULE_TYPE;
		}
		if (!sampleRateCombo.value()) {
			valid = false;
			validationMessage = lang.widget.SELECT_SAMPLE_RATE;
		}
		if (!kpiAccountsCombo.value()) {
			valid = false;
			validationMessage = lang.widget.SELECT_KPI_ACCOUNTS;
		}
		if (inverted && (isNaN(min) || min.trim() === '')) {
			valid = false;
			validationMessage = lang.widget.messages.FILL_IN_NUMERIC_MIN;
		}
		if (isNaN(breach) || breach.trim() === '') {
			valid = false;
			validationMessage = lang.widget.messages.FILL_IN_NUMERIC_BREACH;
		}
		if (isNaN(max) || max.trim() === '') {
			valid = false;
			validationMessage = lang.widget.messages.FILL_IN_NUMERIC_MAX;
		}
		if (!isNaN(max) && !isNaN(breach)) {
			max = parseFloat(max);
			breach = parseFloat(breach);
			if (max < breach) {
				valid = false;
				validationMessage = lang.widget.messages.BREACH_VALUE_MAX;
			}
			if (inverted && !isNaN(min)) {
				if (max <= min) {
					valid = false;
					validationMessage = lang.widget.messages.MIN_VALUE_MAX;
				}
			}
		}
		if (this.errorFields && this.errorFields.length) {
			valid = false;
			if (this.errorFields.length > 1) {
				validationMessage = lang.widget.SELECT_MISSING_FIELDS;
			} else {
				validationMessage = lang.widget.SELECT_MISSING_FIELD;
			}
		}

		return {
			valid: valid,
			validationMessage: validationMessage
		};
	}
});
