import {BudgetSplit, budgetValuesKeys, BudgetYearValues, CostType} from "areas/cost/budget/budgetTypes";
import {createModelSchemaWrapper, optionalExt, withParent} from "../../../framework/serializr-integration";
import {list, object, primitive} from "serializr";
import {action, computed, makeAutoObservable, makeObservable, observable} from "mobx";
import Utils from "../../../tools/utils";
import {CostBudgetData} from "./costBudget";
import {Month, months} from "areas/cost/budget/month";
import {KeysMatching} from "../../../tools/types";
import Context from "serializr/lib/core/Context";
import {CostAlarm} from "./costAlarm";
import {flatTree} from "../../../tools/tree";

const IGNORED_TYPES = [CostType.METRIC, CostType.EXPRESSION];
export enum FetchType {
	Eager = 'EAGER',
	Lazy = 'LAZY',
	Default = null
}

export enum LinkSelectionType {
	// Group = 'GROUP', // for future
	GroupAndChild = 'GROUP_AND_CHILD',
	Single = 'SINGLE'
}

const toPrecision = (value: number, precision: number) => {
	const decimalMultiplier = Math.pow(10, precision);
	return Math.round(value * decimalMultiplier) / decimalMultiplier;
}

export class BudgetItemYearValues {
	editable: boolean;
	modified: boolean = false;
	jan?: number;
	feb?: number;
	mar?: number;
	apr?: number;
	may?: number;
	jun?: number;
	jul?: number;
	aug?: number;
	sep?: number;
	oct?: number;
	nov?: number;
	dec?: number;
	total?: number;

	parent?: CostBudgetItem;

	constructor(props?: Partial<BudgetItemYearValues>) {
		makeAutoObservable(this);
		if (props) {
			Object.assign(this, props);
		}
	}

	clearMonths() {
		months.forEach(m => this[m] = null);
	}

	monthsSum() {
		return months.reduce((sum, month) => { return sum + (this[month] || 0) }, 0);
	}

	setTotal(newTotal: number | null, {startMonth, displayDecimals, reset}: {startMonth: Month, displayDecimals: number, reset: boolean}) {
		this.modified = true;
		if(!newTotal && newTotal != 0) {
			this.clearMonths();
			this.total = null;
			return;
		}

		if (reset || !this.total || this.parent?.split === BudgetSplit.EVEN) {
			this.clearMonths();
			this.distributeThroughMonths(newTotal, displayDecimals, startMonth);
		} else {
			const threshold = Math.round(Math.abs(newTotal - (this.total || 0)) * Math.pow(10, displayDecimals));
			if (threshold >= 1) {
				this.distributeThroughMonths(newTotal - (this.total || 0), displayDecimals, startMonth);
			}
		}
		this.total = toPrecision(this.monthsSum(), displayDecimals);
		this.parent.clearDescendantsValues(this);
	}

	distributeThroughMonths(distributeValue: number, precision: number, startMonth: Month) {
		const decimalMultiplier = Math.pow(10, precision);
		const monthIndex = months.findIndex(x => x == startMonth);
		const sortedMonths = months.slice(monthIndex).concat(months.slice(0, monthIndex));
		const intMonths = sortedMonths.map(m => Math.floor((this[m] ?? 0) * decimalMultiplier));
		const intDiff = Math.round(distributeValue * decimalMultiplier);
		const newIntMonths = this.distributeValue(intDiff, intMonths);
		sortedMonths.forEach((x, i) => {
			this[x] = newIntMonths[i] / decimalMultiplier;
		})
	}

	distributeValue(value: number, data: number[]) {
		let array = data;
		const n = array.length;

		const splitValue = Math.floor(value / n) + (value < 0 ? 1 : 0);
		const remainderOne = value < 0 ? -1 : 1;
		const remainder = Math.abs(value - splitValue * n);
		return array.map(
			(x, i) => x + (i < remainder ? splitValue + remainderOne : splitValue)
		);
	}

	setMonth(month: Month, value: number, {displayDecimals}: {displayDecimals: number}) {
		this[month] = value;

		this.total = toPrecision(this.monthsSum(), displayDecimals);
		this.parent.split = BudgetSplit.NONE;
		this.parent.clearDescendantsValues(this);
		this.modified = true;
	}

	splitEqual({displayDecimals, startMonth}: {displayDecimals: number, startMonth: Month}) {
		this.setTotal(this.total, {startMonth, displayDecimals, reset: true});
	}

	setPercentage(percentage: number) {
		BudgetItemYearValues.numberKeys.forEach(key => {
			if(this[key]) {
				this[key] = percentage * this[key] / 100;
			}
		})
	}

	clear() {
		BudgetItemYearValues.numberKeys.forEach(key => {
			this[key] = null;
		})
	}

	static monthsKeys = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'] as KeysMatching<BudgetItemYearValues, number>[];
	static numberKeys = [...BudgetItemYearValues.monthsKeys, 'total'] as KeysMatching<BudgetItemYearValues, number>[];
}

createModelSchemaWrapper(BudgetItemYearValues, {
	editable: primitive(),
	modified: primitive(),
	jan: optionalExt(primitive()),
	feb: optionalExt(primitive()),
	mar: optionalExt(primitive()),
	apr: optionalExt(primitive()),
	may: optionalExt(primitive()),
	jun: optionalExt(primitive()),
	jul: optionalExt(primitive()),
	aug: optionalExt(primitive()),
	sep: optionalExt(primitive()),
	oct: optionalExt(primitive()),
	nov: optionalExt(primitive()),
	dec: optionalExt(primitive()),
	total: optionalExt(primitive())
});

export interface CostBudgetLink {
	costTargetId?: string;
	costTargetType?: CostType;
	costModelId?: string;
	selectionType: LinkSelectionType;
	targetAccountId: string;
	targetProfileId: string;
	percentage?: number;
}

export class CostBudgetItem implements CostBudgetLink {
	id: string;
	uiId: string;
	name: string;
	split: BudgetSplit = BudgetSplit.NONE;
	costType: CostType;

	cost: BudgetItemYearValues;
	costRate: BudgetItemYearValues;
	budget: BudgetItemYearValues;
	listingPrice: BudgetItemYearValues;

	periodEstimate?: number;
	currentEstimate?: number;
	periodEstimateRate?: number;
	currentEstimateRate?: number;

	costTargetId?: string;
	costTargetType?: CostType;
	costModelId?: string;
	percentage?: number;
	targetAccountId: string;
	targetProfileId: string;
	information: {[x: string]: string};
	selectionType: LinkSelectionType;
	linkTargetReadable?: boolean;

	costAlarm: CostAlarm;
	costAlarmId: string;

	fetchType?: FetchType;
	items: CostBudgetItem[] = [];
	hasChildren: boolean;

	parent: CostBudgetItem | CostBudgetData;

	hasMetrics: boolean;
	hasEvents: boolean;
	hasExternalLink: boolean;

	anythingChanged: boolean = false;

	constructor(readonly: boolean = false) {
		this.uiId = Utils.guid();
		!readonly && this.initCalculations();
	}

	initCalculations() {
		makeObservable(this, {
			uiId: observable,
			id: observable,
			name: observable,
			split: observable,
			costType: observable,
			cost: observable,
			budget: observable,
			listingPrice: observable,
			currentEstimate: observable,
			periodEstimate: observable,
			currentEstimateRate: observable,
			periodEstimateRate: observable,
			costTargetId: observable,
			costTargetType: observable,
			costModelId: observable,
			targetAccountId: observable,
			targetProfileId: observable,
			selectionType: observable,
			information: observable,
			items: observable,
			hasChildren: observable,
			costAlarm: observable,
			costAlarmId: observable,
			splitEnabled: computed,
			isCostLink: computed,
			level: computed,
			addItem: action,
			setPercentage: action,
			linkTargetReadable: observable,
			anythingChanged: observable,
			modified: computed
		});
	}

	get hasThresholds() {
		return !!(this.costAlarm || this.costAlarmId)
	}

	get statusSortString() {
		return `${this.hasEvents ? 'a' : 'b'}${this.hasThresholds ? 'a' : 'b'}${this.isCostLink ? 'a' : 'b'}${this.costTargetType || ''}`
	}

	get splitEnabled() {
		if (this.isCostLink) return false;
		return this.budget.editable || this.cost.editable;
	}

	get isCostLink() {
		return this.costTargetType && this.costTargetType !== 'NONE';
	}

	get isDeletedCostLink() {
		return this.costTargetType && this.costTargetType === 'NONE' && this.targetAccountId && this.targetProfileId;
	}

	get isAssetLink() {
		return this.costTargetType && this.costTargetType === 'ASSET' && !this.costModelId && this.costTargetId;
	}

	get level() : number {
		if(this.parent && 'level' in this.parent) {
			return this.parent.level + 1;
		}
		return 0;
	}

	get inLink() : boolean {
		if(!this.parent || this.parent.isBudgetData) return false;

		const parent = this.parent as CostBudgetItem;
		return parent.isCostLink || parent.inLink;
	}

	get addChildrenEnabled() {
		return !(this.isCostLink || this.inLink);
	}

	get isBudgetData() {
		return false;
	}

	addItem(item: CostBudgetItem) {
		if (this.costType === CostType.COST_RESOURCE) {
			this.convertToGroup();
		}
		item.parent = this;
		this.items.push(item);
		this.anythingChanged = true;
	}

	private convertToGroup() {
		this.id = Utils.guid();
		this.costType = CostType.COST_GROUP;
	}

	removeItem(item: CostBudgetItem) {
		const index = this.items.findIndex(i => i.id === item.id);
		this.items.splice(index, 1);
		if (this.costType === CostType.COST_GROUP && this.items.length === 0) {
			this.convertToResource();
		}
		this.anythingChanged = true;
	}

	replaceItems(items: CostBudgetItem[]) {
		this.items = items;
		items.forEach(item => item.parent = this);
		(['cost', 'budget'] as KeysMatching<CostBudgetItem, BudgetYearValues>[]).forEach(key => {
			if (this[key].modified || this.inModified(key)) {
				this.internalClearDescendantsValues(key)
			}
		})
	}

	inModified(key: KeysMatching<CostBudgetItem, BudgetYearValues>) {
		for(let parent = this.parent as CostBudgetItem; !parent?.isBudgetData; parent = parent.parent as CostBudgetItem) {
			if (parent[key].modified)
				return true
		}
		return false
	}

	private convertToResource() {
		this.id = Utils.guid();
		this.costType = CostType.COST_RESOURCE;
	}

	remove() {
		this.parent.removeItem(this);
	}

	update(props: Partial<CostBudgetItem>) {
		Object.assign(this, props);
	}

	editDisabled(subAccounts: string[]) {
		return this.isCostLink && !subAccounts?.includes(this.targetAccountId);
	}

	setPercentage = (percentage: number) => {
		this.cost.setPercentage(percentage);
		this.listingPrice.setPercentage(percentage);
		this.currentEstimate = percentage * this.currentEstimate / 100;
		this.periodEstimate = percentage * this.periodEstimate / 100;
		this.items.forEach(item => item.setPercentage(percentage));
	}

	static newEmptyResource(name: string) {
		const item = new CostBudgetItem();
		Object.assign(item, {
			name,
			id: Utils.guid(),
			split: BudgetSplit.NONE,
			cost: new BudgetItemYearValues({editable: true, parent: item}),
			budget: new BudgetItemYearValues({editable: true, parent: item}),
			listingPrice: new BudgetItemYearValues({editable: false, parent: item}),
			costType: CostType.COST_RESOURCE
		});
		return item;
	}

	setSplit(value: BudgetSplit, {displayDecimals, startMonth}: {displayDecimals: number, startMonth: Month}) {
		this.split = value;
		if (value != BudgetSplit.EVEN) {
			return;
		}
		budgetValuesKeys.forEach(prefix => {
			if (!this[prefix].editable) {
				return;
			}
			this[prefix].splitEqual({displayDecimals, startMonth});
		})
	}

	clearDescendantsValues(value: BudgetYearValues) {
		(['cost', 'budget'] as KeysMatching<CostBudgetItem, BudgetYearValues>[]).forEach(key => {
			if (this[key] == value) {
				this.internalClearDescendantsValues(key);
			}
		})
	}

	private internalClearDescendantsValues(key: KeysMatching<CostBudgetItem, BudgetYearValues>) {
		(this.items || []).forEach(item => {
			item[key].clear();
			item.internalClearDescendantsValues(key);
		})
	}

	sumFromItems(accessor: (item: CostBudgetItem) => number | null) {
		return this.items
			.filter(x => !(IGNORED_TYPES.includes(x.costType) || IGNORED_TYPES.includes(x.costTargetType)))
			.map(accessor)
			.filter(x => x != null)
			.reduce((sum, item) => sum + item, null);
	}

	get linkedIds() : string[] {
		if(!this.isCostLink) {
			return this.items.map(item => item.linkedIds).flat();
		} else {
			const result = [this.costTargetId];
			if (this.selectionType != LinkSelectionType.Single) {
				const childrenIds = flatTree(this.items, x => x.items).map(x => x.id);
				return childrenIds.concat(result);
			}
			return result;
		}
	}

	get informationString() {
		return Object.values(this.information || {}).join(', ');
	}

	get modified() : boolean {
		if(this.anythingChanged) return true;

		const modified = (['cost', 'budget'] as KeysMatching<CostBudgetItem, BudgetYearValues>[]).some(key => this[key].modified)
		return modified || this.items?.some(item => item.modified)
	}

	set modified(value: boolean) {
		if(value) {
			this.anythingChanged = true;
		}
	}
}

export interface CostItemDetails {
	name: string
	id: string
	items: CostItemDetails[]
	costType: CostType
}

// we need this to deserialize information with any fields
class Information {}
createModelSchemaWrapper(Information, {
	"*": true
})

createModelSchemaWrapper(CostBudgetItem, {
	id: primitive(),
	name: primitive(),
	split: primitive(),
	costType: primitive(),
	cost: withParent(object(BudgetItemYearValues)),
	costRate: withParent(object(BudgetItemYearValues)),
	budget: withParent(object(BudgetItemYearValues)),
	listingPrice: withParent(object(BudgetItemYearValues)),
	fetchType: primitive(),
	items: list(withParent(object(CostBudgetItem))),
	hasChildren: primitive(),
	costTargetId: primitive(),
	costTargetType: primitive(),
	costModelId: primitive(),
	selectionType: primitive(),
	percentage: primitive(),
	currentEstimate: primitive(),
	periodEstimate: primitive(),
	currentEstimateRate: primitive(),
	periodEstimateRate: primitive(),
	targetAccountId: primitive(),
	targetProfileId: primitive(),
	hasMetrics: primitive(),
	hasEvents: primitive(),
	hasExternalLink: primitive(),
	linkTargetReadable: primitive(),
	information: object(Information),
	costAlarm: object(CostAlarm),
	costAlarmId: primitive()
}, (context: Context) =>  {
	const readonly = context.args?.readonly || false;
	return new CostBudgetItem(readonly);
})
