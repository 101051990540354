import './formEntryNew.less'

import React, {CSSProperties} from 'react'
import classnames from 'classnames'
import {observer} from 'mobx-react'

import {ModelValidator, ValidatableModel, ValidationState} from "framework/mobx-integration"
import {QuestionCircleOutlined} from "@ant-design/icons";
import {makeAutoObservable, observable, trace} from "mobx";
import {useStore} from "core/react/useStore";

let b = require('b_').with('form-entry-new');

const i = require('core/localization/').translator();

export class  FormEntryProps<TModel extends object, TModelField extends keyof TModel> {
	label?: React.ReactNode
	title?: 'string'
	children: React.ReactNode
	width?: number

	className?: string

	id?: string
	style?: CSSProperties

	model?: TModel
	modelField?: TModelField
	onChange?: (value: TModel[TModelField]) => void

	headerAdditionalContent?: React.ReactNode

	disabled?: boolean

	validator?: ModelValidator<TModel>

	changeOnBlur?: boolean


	//if you do not want Form entry to bind the first children to a provided model but only want to show validation result.
	// This might be useful when there are several inputs inside form entry
	validationOnly?: boolean

	constructor() {
		makeAutoObservable(this, {
			children: observable.ref,
			headerAdditionalContent: observable.ref,
			label: observable.ref
		})
	}
}

class FormEntryNewStore<TModel extends ValidatableModel<TModel>|object, TModelField extends keyof TModel> {
	focused: boolean
	props: FormEntryProps<TModel, TModelField> = new FormEntryProps<TModel, TModelField>()
	tempValue: TModel[TModelField] = null
	initialValueChanged: boolean = false

	constructor() {
		makeAutoObservable(this)
	}

	get validator(){
		if(this.props.validator)
			return this.props.validator

		if(this.props.model == null)
			return null

		return "validator" in this.props.model
			? this.props.model.validator
			: null
	}

	get fieldValidator(){
		return this.validator?.getFieldValidator(this.props.modelField)
	}

	get hasValidation() {
		return this.fieldValidator != null
	}

	get required(){
		if(this.fieldValidator == null)
			return false

		return this.fieldValidator.requiredValidationState != ValidationState.Valid
	}

	get validationState(){
		if(this.fieldValidator == null)
			return ValidationState.Valid

		return this.fieldValidator.revalidated && this.initialValueChanged
			? this.fieldValidator.validationState
			: ValidationState.Valid
	}

	get validationMessage(){
		return this.validationState != ValidationState.Valid
			? (this.validator?.getErrors(this.props.modelField).join() ?? "")
			: ""
	}

	get value() {
		if (this.props.model == null)
			return null

		if(this.focused && this.props.changeOnBlur){
			return this.tempValue
		}

		return this.props.model[this.props.modelField]
	}

	get hasValue(){
		return Array.isArray(this.value)
			? this.value.length > 0
			: this.value as any === 0 || this.value as any === false
				? true
				: !!this.value
	}

	get onChange() {
		if (this.props.validationOnly)
			return null

		if (this.props.changeOnBlur) {
			return (value: TModel[TModelField]) => this.tempValue = value
		}

		if (this.props.onChange)
			return this.props.onChange

		return (value: TModel[TModelField]) => this.props.model[this.props.modelField] = value
	}

	get style(){
		if(this.props.width == null)
			return this.props.style

		const result: CSSProperties = {
			...(this.props.style ?? {} )
		}

		if(Number.isInteger(this.props.width)){
			result.width = this.props.width + 'px'
		}

		return result
	}

	getProcessedChildren(){
		const children = React.Children.toArray(this.props.children);
		if (!React.isValidElement(children[0]))
			return children;

		const {onFocus, onBlur, readOnly} = (children[0].props ?? {});
		let propsOverrides: { [index: string]: any } = {
			onFocus: (ev: React.FocusEvent) => {
				if (readOnly)
					return;

				this.tempValue = this.value

				this.focused = true
				onFocus?.(ev);
			},
			onBlur: (ev: React.FocusEvent) => {
				this.focused = false

				if (this.props.model[this.props.modelField] != this.tempValue) {
					this.initialValueChanged = true
				}
				if (this.props.changeOnBlur) {
					this.props.model[this.props.modelField] = this.tempValue
					this.tempValue = null
				}
				onBlur?.(ev);
			}
		}

		if (this.props.validationOnly !== true) {
			propsOverrides = { value: this.value, onChange: this.onChange, ...propsOverrides};
			if (this.props.disabled !== undefined) {
				propsOverrides.disabled = this.props.disabled
			}
		}

		if (!this.focused || this.hasValue) {
			propsOverrides.placeholder = ""
		}

		//We are assuming there that the first child element is an input so we can pass value and onChange
		children[0] = React.cloneElement(children[0], propsOverrides);

		return children
	}
}

export const FormEntryNew = observer(<TModel extends ValidatableModel<TModel>|object, TModelField extends keyof TModel>(props: FormEntryProps<TModel, TModelField>) => {
	const store = useStore(() => new FormEntryNewStore<TModel, TModelField>(), {
		props: props
	})

	const blockClasses: { [index: string]: any } = {
		focused: store.focused,
		disabled: props.disabled,
		'has-validation': store.hasValidation,
		required: store.required,
		'validation-state': store.validationState,
		'has-value': store.hasValue
	}

	const rootClasses = classnames(
		b(blockClasses),
		props.className
	);

	return (
		<div id={props.id} className={rootClasses} style={store.style} title={props.title}>
			{props.label &&
				// <FormEntryLabel label={props.label}>{props.labelAdditionalContent}</FormEntryLabel>
				<div className={b('label')}>
					{/*<span className={b('label-text')}>*/}
						{props.label}
					{store.required && ' *'}
					{/*</span>*/}
				</div>
			}
			{props.headerAdditionalContent &&
				<div className={b('additional-content')}>{props.headerAdditionalContent}</div>
			}

			{/*<AntTooltip className={b('field')}*/}
			{/*            title={validationMessage}*/}
			{/*            placement={"rightTop"}*/}
			{/*            color={"red"}*/}
			{/*            trigger={options.errors?.length ? 'hover' : null}*/}
			{/*            zIndex={11000}*/}
			{/*>*/}
				<div className={b('field')}>
					{store.getProcessedChildren()}
				</div>
			{store.validationMessage && <p className={b('validation-message')}>{store.validationMessage}</p>}

			{/*</AntTooltip>*/}
		</div>
	);
})

export type HelpIconProps = {
	tooltip: string
}

export const HelpIcon = observer((props: HelpIconProps) => {
	return <QuestionCircleOutlined title={props.tooltip} />
})
