import React from "react";
import './BudgetTable.less'
import {EditableCell} from "./EditableCell";
import {AntTable} from "../../../../controls/react/ant/antTable";
import {ExpandedState, ExpandedStateJson} from "./ExpandedState";
import {observer} from "mobx-react";
import {i} from "../../translations";
import {CostBudget} from "../../models/costBudget";
import {CostBudgetItem} from "../../models/costBudgetItem";
import {NewBudgetEntryWindow} from "./popups/NewBudgetEntryWindow";
import {LinkCostProfileRowWindow} from "areas/cost/budget/linkCostProfileRowWindow";
import {BudgetTableStore, Total} from "./BudgetTableStore";
import {SortDirection} from "areas/cost/models/sortDirection";
import {VariableSizeGrid as Grid} from 'react-window';
import ResizeObserver from 'rc-resize-observer';
import {BudgetTableContext} from "./BudgetTableContext";
import {CostTableViewType} from "./CostTableViewType";
import classnames from "classnames";
import {BudgetEditableColumnType} from "./BudgetTableColumns";
import {GridWithStickyColumns} from "./gridWithStickyColumns";
import {FilterValue, SorterResult, TableCurrentDataSource, TablePaginationConfig} from "antd/lib/table/interface";
import {Key, SortOrder} from "antd/es/table/interface";
import {MobxManager} from "framework/mobx-integration";
import {ThresholdWindow} from "../thresholdWindow";
import {setElementVariables} from "../../../../tools/styles";
import {Resizable, ResizeCallbackData} from 'react-resizable';
import {getValueOnPath} from "tools/helpers/math";

const b = require('b_').with('budget-table');

interface BudgetSubTableProps {
	level?: number,
	items: CostBudgetItem[],
	expandedState: ExpandedState,
	onSortChanged?: (sorter: BudgetTableSorter) => void,
	onRowClick?: (row: CostBudgetItem) => void,
	viewScale?: number
}

interface BudgetTableVirtualCellProps {
	columnIndex: number;
	rowIndex: number;
	style: React.CSSProperties;
	data: any;
	real: boolean; // we render empty cells for virtual grid, then rerender it manually in GridWithStickyColumn
}

export type BudgetTableSorter = SorterResult<CostBudgetItem>;

const BudgetTableVirtualCell = observer(class BudgetTableVirtualCell extends React.PureComponent<BudgetTableVirtualCellProps> {
	static contextType = BudgetTableContext;
	declare context: React.ContextType<typeof BudgetTableContext>;

	render() {
		if(!this.props.real) {
			return <></>
		}
		const {columns, showedRows} = this.context;
		const {rowIndex, columnIndex, style} = this.props;
		const column = columns[columnIndex];
		const record = showedRows[rowIndex];
		const {dataIndex, input, setValue} = column;
		const value = BudgetTableVirtualCell.getValue(record, dataIndex);
		let title = (column.ellipsis && (column.ellipsis as {showTitle: boolean}).showTitle != false) ? value?.toString() : null;
		title = [title, column.cellTitle].filter(x => x).join('\n')
		function content() {
			if (column.render) {
				return column.render(value, record, rowIndex);
			} else {
				return value;
			}
		}

		return <div
			className={classnames(b('vcell', {
				column: column.key,
				level: record.level,
				'last': columnIndex === columns.length - 1,
			}), column.className)}
			style={style}
			title={title}
		>
			{
				BudgetTableVirtualCell.isEditable(column, record)
				? <EditableCell
					editable={true}
					dataIndex={dataIndex as any}
					record={record}
					input={input}
					setValue={setValue}
				>
					{content()}
				</EditableCell>
				: <span className={b('cell-content', {align: column.align})}>{content()}</span>
			}
		</div>
	}

	static isEditable(column: BudgetEditableColumnType, record: CostBudgetItem) {
		if (!column.editable) {
			return false;
		} else {
			if (column.editable instanceof Function) {
				return column.editable(record);
			} else {
				return column.editable;
			}
		}
	}

	static getValue(record: CostBudgetItem, dataIndex: any): string | number | null {
		if (!dataIndex) {
			return null;
		}
		const path = typeof dataIndex === 'string' ? dataIndex : (dataIndex as string[]).join('.');
		return getValueOnPath(record, path);
	}
});

const ResizableHeader = (
	props: React.HTMLAttributes<any> & {
		onResize: (e: React.SyntheticEvent<Element>, data: ResizeCallbackData) => void;
		width: number;
	},
) => {
	const { onResize, width, ...restProps } = props;
	if (!width) {
		return <th {...restProps} />;
	}

	return (
		<Resizable
			width={width}
			height={0}
			handle={
				<span
					className="react-resizable-handle"
					onClick={(e) => e.stopPropagation()}
				/>
			}
			onResize={onResize}
			draggableOpts={{ enableUserSelectHack: false }}
		>
			<th {...restProps} />
		</Resizable>
	);
};

const BudgetSubTable = observer(class BudgetSubTableUnobservable extends React.PureComponent<BudgetSubTableProps> {
	static contextType = BudgetTableContext;
	declare context: React.ContextType<typeof BudgetTableContext>;

	gridRef: React.RefObject<Grid> = React.createRef<Grid>();
	connectObject: any;
	mobx = new MobxManager()

	constructor(props: BudgetSubTableProps) {
		super(props);
		const self = this;
		const obj = document.createElement('div');
		Object.defineProperty(obj, 'scrollLeft', {
			get: () => {
				// HACK for correct header position after rerender
				return self.gridRef?.current?.state?.scrollLeft;
			},
			set: (scrollLeft: number) => {
				self.gridRef?.current?.scrollTo({scrollLeft});
			},
		});

		this.connectObject = obj;
	}

	componentDidMount() {
		//TODO move to store
		this.mobx.reaction(() => [this.context.columns.length, this.context.tableSize.width, this.context.nameColumnWidth], () => {
			this.gridRef?.current?.resetAfterIndices({columnIndex: 0, rowIndex: 0, shouldForceUpdate: false});
		})

		this.mobx.reaction(() => this.context.sortOrder, () => {
			if(!this.context.selectedRecordId) {
				return;
			}
			this.scrollToRow(this.context.selectedRecordId);
		})

		const {highlightedRecordId} = this.context;
		if (highlightedRecordId) {
			// hack, because we need to wait until kendo splitter initialized & table resized
			setTimeout(() => {
				this.scrollToRow(highlightedRecordId);
			}, 500);
		}
	}

	componentWillUnmount() {
		this.mobx.destroy()
	}

	private scrollToRow(id: string) {
		const {showedRows} = this.context;

		const i = showedRows.findIndex(x => x.id === id);
		this.gridRef?.current?.scrollToItem({
			align: 'center',
			rowIndex: i,
			columnIndex: 0
		});
	}

	render() {
		const {showedRows, columns} = this.context;
		return (
			<ResizeObserver onResize={({width, height}) => {
					if (this.props.viewScale) {
						width /= this.props.viewScale;
						height /= this.props.viewScale;
					}
					this.context.setTableSize({width, height});
				}}
			>
				<AntTable
					rowKey={'uiId'}
					className={b('table')}
					dataSource={showedRows}
					columns={columns}
					bordered={true}
					components={{body: this.renderVirtualList, header: {cell: ResizableHeader}}}
					pagination={false}
					scroll={{y: this.context.tableSize.height - 40, x: this.context.tableSize.width}}
					tableLayout={'fixed'}
					onChange={this.onChange}
				/>
			</ResizeObserver>
		);
	}

	renderVirtualList = (rawData: readonly CostBudgetItem[], {scrollbarSize, ref, onScroll}: any) => {
		const {columns, tableSize, showedRows, selectRecord, rowHeight} = this.context;
		ref.current = this.connectObject;
		const totalHeight = showedRows.length * rowHeight;
		const {width, height} = tableSize;
		const innerHeight = height - 40;
		const hasScrollbar = totalHeight > innerHeight;
		return (
			<GridWithStickyColumns
				ref={this.gridRef}
				className="virtual-grid"
				overscanRowCount={50}
				overscanColumnCount={2}
				columns={columns}
				columnWidth={(index: number) => {
					// TODO find reason why index < 0 after columns.length changing
					if (index < 0) return 0;
					const { width } = columns[index];
					return hasScrollbar && index === columns.length - 1
						? (width as number) - scrollbarSize
						: (width as number);
				}}
				height={innerHeight}
				rowCount={showedRows.length}
				rowHeight={() => {
					return rowHeight;
				}}
				width={width}
				itemData={showedRows}
				onScroll={({scrollLeft}: { scrollLeft: number }) => {
					onScroll({scrollLeft});
				}}
				itemKey={({columnIndex, rowIndex}: {columnIndex: number, rowIndex: number}) => {
					const item = showedRows[rowIndex];
					return `${item.uiId}-${columns[columnIndex].key}`;
				}}
				rowKey={(rowIndex: number) => showedRows[rowIndex].uiId}
				rowClass={this.rowClass}
				onRowClick={
					(event:  React.MouseEvent<HTMLElement, MouseEvent>, rowIndex: number) => {
						selectRecord(showedRows[rowIndex]);
						const isActionCell = event.currentTarget.querySelector('.budget-table__action-cell-content')?.contains(event.target as Node)
							|| !event.currentTarget.contains(event.target as Node);
						if (isActionCell) return;
						this.props.onRowClick?.(showedRows[rowIndex]);
					}
				}
			>
				{BudgetTableVirtualCell}
			</GridWithStickyColumns>
		);
	};

	rowClass = (rowIndex: number) => {
		const { showedRows, highlightedRecordId, selectedRecordId } = this.context;
		const id = showedRows[rowIndex].id;
		if (id === 'total') {
			return b('total-row')
		}

		return b('row', {highlight: id == highlightedRecordId, selected: id == selectedRecordId, level: showedRows[rowIndex].level});
	}

	onExpandedRowsChange = (value: Readonly<React.Key[]>) => {
		this.props.expandedState.setExpandedRowKeys([...value]);
	}

	onChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<CostBudgetItem> | SorterResult<CostBudgetItem>[], extra: TableCurrentDataSource<CostBudgetItem>) => {
		if (extra.action != 'sort') {
			return
		}

		this.context.updateSortOptions(sorter as BudgetTableSorter); // we not support multiple sorting
		this.props.onSortChanged?.(sorter as BudgetTableSorter);
	}
});

interface BudgetTableProps {
	budget: CostBudget,
	displayDecimals: number,
	displayAsThousands: boolean,
	showEstimate?: boolean,
	showYearEstimate?: boolean,
	showCurrentMonthEstimate?: boolean,
	showListingPrice: boolean,
	monthOrder: SortDirection,
	currentMonthFirst: boolean,
	lockValues?: boolean,
	lockStructure?: boolean,
	expandToRecordId?: string,
	expandedState?: ExpandedStateJson,
	onExpandedStateChanged?: (state: ExpandedState) => void,
	showCost: boolean,
	showCostRate: boolean,
	showBudget: boolean,
	showSplit?: boolean,
	showName?: boolean,
	showInformation?: boolean,
	showAction?: boolean,
	showStatus?: boolean,
	viewType?: CostTableViewType,
	sortSettings?: { order: SortOrder, columnKey: Key },
	onSortChanged?: (sorter: BudgetTableSorter) => void,
	onRowClick?: (row: CostBudgetItem) => void,
	viewScale?: number,
	compact?: boolean,
	onColumnResize?: (columnKey: string, width: number) => void,
	columnWidths?: {[x: string]: number}, //used only for name column now
	styles?: {
		fontSize?: string,
		fontColor?: string,
		line1Color?: string,
		line2Color?: string,
		bgColor?: string
	},
	linkRedirectDisabled?: boolean;
}

export const BudgetTable = observer(class BudgetTable extends React.Component<BudgetTableProps> {
	static defaultProps = {
		monthOrder: SortDirection.Asc,
		lockValues: false,
		lockStructure: false
	};
	store = new BudgetTableStore();
	state = {
		showNewItemWindow: false,
		showImportExternalWindow: false
	}
	total: Total;
	private rootRef: React.RefObject<HTMLDivElement> = React.createRef();

	constructor(props: BudgetTableProps & BudgetSubTableProps) {
		super(props);
		this.store.loadSubAccounts();
		this.store.setLinkCellRedirectDisabled(this.props.linkRedirectDisabled);
		this.updateStore();
		if (this.props.expandToRecordId) {
			this.store.expandedState.expandToRecord(this.props.expandToRecordId);
			this.store.highlightRecord(this.props.expandToRecordId);
		}
		if (this.props.expandedState) {
			this.store.expandedState.fillExpandStateFromJson(this.props.expandedState);
		}
		if (this.props.onExpandedStateChanged) {
			this.store.expandedState.subscribeOnExpandedStateChange(this.props.onExpandedStateChanged);
		}
		if (this.props.viewType) {
			this.store.setViewType(this.props.viewType);
		}
	}

	componentDidUpdate(prevProps: Readonly<BudgetTableProps>, prevState: Readonly<{}>, snapshot?: any) {
		this.updateStore();
	}

	componentDidMount() {
		this.store.rootRef = this.rootRef;
		if(this.props.styles) {
			setElementVariables(this.rootRef.current, 'budget-table', this.props.styles);
		}
		([['name', 170], ['information', 150]] as [string, number][]).forEach(([name, width]) => this.initResizable(name, width));
	}

	render() {
		const {budget, showColumns} = this.store;
		return <div className={b({...showColumns, compact: this.props.compact})} ref={this.rootRef}>
			<BudgetTableContext.Provider value={this.store}>
				<BudgetSubTable items={this.store.rows}
								expandedState={this.store.expandedState}
								onSortChanged={this.props.onSortChanged}
								onRowClick={this.props.onRowClick}
								viewScale={this.props.viewScale} />
			</BudgetTableContext.Provider>
			{
				this.store.showNewItemWindow &&
				<NewBudgetEntryWindow title={i('Create')} onCancel={this.store.hideNewEntryWindow}
															onSave={this.store.addNewItem}/>
			}
			{
				this.store.showImportExternalWindow &&
				<LinkCostProfileRowWindow
					onCancel={this.store.hideImportExternalWindow}
					onSave={this.store.importExternalProfile}
					budget={budget}
					parent={this.store.newEntryParent}
				/>
			}
			{
				this.store.showThresholdWindow &&
					<ThresholdWindow
						record={this.store.thresholdWindowRecord}
						onClose={this.store.closeThresholdWindow}
						currency={this.store.budget.currency}
						readonly={this.store.viewType == CostTableViewType.Widget}
					/>
			}
		</div>;
	}

	componentWillUnmount() {
		this.store.destroy()
	}

	private initResizable = (columnKey: string, defaultWidth: number) => {
		if(this.props.columnWidths?.[columnKey]) {
			this.store.handleColResize(this.props.columnWidths[columnKey], columnKey);
		} else {
			if (this.props.compact) {
				this.store.handleColResize(defaultWidth, columnKey); // default for compact mode
			}
		}
	}

	private updateStore() {
		const {
			displayDecimals,
			displayAsThousands,
			budget,
			lockValues,
			lockStructure,
			showCost,
			showCostRate,
			showBudget,
			showSplit,
			showEstimate,
			showListingPrice,
			showInformation,
			monthOrder,
			currentMonthFirst,
			showYearEstimate,
			showCurrentMonthEstimate,
			showName,
			showStatus,
			showAction,
			compact,
			onColumnResize
		} = this.props;
		this.store.setShowSettings({
			displayDecimals,
			displayAsThousands,
			showCost,
			showCostRate,
			showBudget,
			showSplit,
			showEstimate,
			showListingPrice,
			showInformation,
			monthOrder,
			currentMonthFirst,
			showYearEstimate,
			showCurrentMonthEstimate,
			showName,
			showStatus,
			showAction,
		});
		this.store.setLocking({lockValues, lockStructure});
		this.store.setBudget(budget);
		this.store.setCompact(compact);
		this.store.updateSortOptions(this.props.sortSettings);
		this.store.setOnColumnResize(onColumnResize);
	}
});
