import React from 'react'
import {observer} from "mobx-react";
import {CaretRightOutlined} from "@ant-design/icons";
import {makeAutoObservable} from "mobx";

import {Section} from "controls/react/layout/section";
import {Toolbar} from "controls/react/layout/toolbar";
import {FormEntryNew, HelpIcon} from "controls/react/form/formEntryNew";
import {AntDefaultOptionType, AntSelect} from "controls/react/ant/antSelect";
import {MobxManager} from "framework/mobx-integration";
import {SingleMetricSelector} from "controls/react/metricsSelector";
import {AntInput, AntTextAreaAutoSize} from "controls/react/ant/antInput";
import {apiFetch} from "framework/api";
import {getMetricUnits, validateConvertion} from "areas/service-boards/widgets/common/dataSources/MetricSettingsApi";
import {getDataRegistry} from "areas/management/data-registry/api";
import {GridSelectionMode} from "controls/grid/gridSelection";
import {DataRegistryEntry} from "areas/management/data-registry/dataRegistryEntry";
import {AntSwitch} from "controls/react/ant/antSwitch";
import {AntInputNumber} from "controls/react/ant/antInputNumber";
import {AntButton} from "controls/react/ant/antButton";
import {formatNumber} from "tools/helpers/math";
import {AntPopover} from "controls/react/ant/antPopover";
import {useStore} from "core/react/useStore";

const i18n = require('core/localization').translator({
	'{0} (default)': {},
	"Formula placeholder": {
		"en": "Expressions must use 'metric' to represent metric value. Example: metric*100.",
		"no": "Uttrykket må bruke 'metric' for å representere metrikkverdi. Eksempel: metric*100."
	},
	"Formula tooltip": {
		"en": "Formula editor support normal operators (+-/*%^) and functions (cos, sin, log). Check https://www.objecthunter.net/exp4j/#Built-in_functions for more examples",
		"no": "Formel editor støtter normale operatorer (+ - / *% ^) og funksjoner (cos, sin, log). Se https://www.objecthunter.net/exp4j/#Built-in_functions for flere eksempler"
	},
	"Formula is valid": {
		"no": "Formelen er gyldig"
	},
	"Formula is valid. Output is: {0}.": {
		"no": "Formelen er gyldig. resultatet er: {0}."
	},
	"Formula is not valid": {
		"no": "Formelen er ikke gyldig"
	},
	'Autoscaling': {}
})

export class MetricConfiguration {
	metricId?: string
	unit?: string
	unitLabel?: string
	decimals?: number
	formula?: string

	constructor() {
		makeAutoObservable(this)
	}
}

export enum MetricConfigurationUnitType{
	Autoscaling= 'AUTOSCALING',
	Custom = 'CUSTOM'
}

type MetricSelectorAdvancedProps = {
	model: MetricConfiguration
	accountId?: string
	label?: string
}

export class MetricSelectorAdvancedStore {
	mobx = new MobxManager()
	units: AntDefaultOptionType[]
	dataRegistryEntry: DataRegistryEntry
	loaded = false
	loading = false

	validating = false
	validationResult: string
	validationResultVisible = false

	props: MetricSelectorAdvancedProps

	constructor() {
		makeAutoObservable(this)
	}

	init(){
		this.mobx.reaction(() => this.model?.metricId, () => {
			this.model.unit = MetricConfigurationUnitType.Autoscaling
			this.model.unitLabel = ''

			this.loadData()
		})

		this.mobx.reaction(x => this.model?.formula, () => {
			this.validateFormula()
		})

		this.loadData()
	}

	get model() {
		return this.props?.model
	}

	async loadData(){
		this.loading = true
		this.loaded = true
		this.units = []
		this.dataRegistryEntry = null

		await this.loadMetric()
		await this.loadAvailableUnitsForMetric()

		this.loading = false
	}

	async loadMetric() {
		if (this.model?.metricId == null)
			return

		const response = await apiFetch(getDataRegistry({
			accountId: this.props.accountId,
			selection: {
				ids: [this.model.metricId],
				mode: GridSelectionMode.Include
			}
		}))

		if (!response.success || !response.data?.items?.length) {
			return
		}

		this.dataRegistryEntry = response.data.items[0].data
	}

	async loadAvailableUnitsForMetric() {
		if (!this.dataRegistryEntry)
			return

		const unitType = this.dataRegistryEntry.unitType
		const response = await apiFetch(getMetricUnits(unitType));

		if (response.success) {
			let units = response.data
			const defaultIndex = units.findIndex(x => x.value == this.dataRegistryEntry.unitType);
			if (defaultIndex >= 0) {
				units[defaultIndex].description = i18n('{0} (default)', units[defaultIndex].description)
			}

			this.units = [{
				label: i18n('Autoscaling'),
				value: MetricConfigurationUnitType.Autoscaling
			}, {
				label: i18n('Custom'),
				value: MetricConfigurationUnitType.Custom
			}, ...units.map(x => ({label: x.description, value: x.value}))]

			this.loaded = true
		}
	}

	validateFormula = async () => {
		this.validating = true
		const result = await apiFetch(validateConvertion(this.model.formula))

		if (!result.success) {
			this.validationResult = i18n('Formula is not valid');
		} else if (result.data) {
			this.validationResult = i18n('Formula is valid. Output is: {0}.', formatNumber(result.data, this.model.decimals))
		} else {
			this.validationResult = i18n('Formula is valid');
		}

		this.validationResultVisible = true
		setTimeout(() => this.validationResultVisible = false, 3000)

		this.validating = false
	}

	destroy() {
		this.mobx.destroy()
	}
}

export const MetricSelectorAdvanced = observer((props: MetricSelectorAdvancedProps) => {
	const store = useStore(() => new MetricSelectorAdvancedStore(), {props})

	const [showAdvanced, setShowAdvanced] = React.useState(false)

	return <Section title={props.label ?? i18n('Metric')} appearance={'frame'}>
		<FormEntryNew label={i18n('Metric')} model={store.model} modelField={"metricId"}>
			<SingleMetricSelector accountId={props.accountId}/>
		</FormEntryNew>
		<Section contentHidden={!showAdvanced}
		         appearance={"frame-top-only"}
		         contentPadding={true}
		         childrenPadding={true}>
			<Toolbar title={i18n('Advanced')}>
				<AntSwitch size={"small"} onClick={() => setShowAdvanced(!showAdvanced)}/>
			</Toolbar>
			<FormEntryNew label={i18n('Unit')} disabled={!store.loaded} model={store.model} modelField={"unit"}>
				<AntSelect options={store.units}  />
			</FormEntryNew>

			{store.model?.unit == MetricConfigurationUnitType.Custom &&
				<FormEntryNew label={i18n('Unit Label')}
				              disabled={!store.loaded}
				              changeOnBlur={true}
				              model={store.model} modelField={"unitLabel"}>
					<AntInput  />
				</FormEntryNew>
			}

			{store.model?.unit == MetricConfigurationUnitType.Custom &&
				<FormEntryNew label={i18n('Formula')}
				              disabled={!store.loaded}
				              model={store.model}
				              changeOnBlur={true}
				              headerAdditionalContent={
								<>
									<HelpIcon tooltip={i18n('Formula tooltip')} />
									<AntPopover trigger={"contextMenu"}
									            open={store.validationResultVisible}
									            overlayPadding={true}
									            content={store.validationResult}>
										<AntButton icon={<CaretRightOutlined />}
										           onClick={store.validateFormula}
										           loading={store.validating}
										           disabled={!store.loaded}
										           title={i18n('Validate')}
										           size={"small"}
										           type={"primary"}/>
									</AntPopover>
								</>}
				              modelField={"formula"}>
					<AntTextAreaAutoSize placeholder={i18n('Formula placeholder')} />
				</FormEntryNew>
			}
			<FormEntryNew label={i18n('Decimals')}
			              changeOnBlur={true}
			              model={props.model}
			              modelField={"decimals"}>
				<AntInputNumber disabled={!store.loaded} />
			</FormEntryNew>
		</Section>
	</Section>
})
