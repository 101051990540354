import {makeAutoObservable} from "mobx";
import {TimePeriod} from "controls/react/form/timePeriodSelector";
import {MxCell} from "controls/designer/mxGraphInterfaces";
import {getService, getServiceModel} from "areas/services/api";
import {Service} from "framework/entities/service";
import {apiFetch} from "framework/api";
import {getCurrentTimeStringInTimeZone, isTimeZoneDifferentFromCurrent} from "../../tools/dateTimeUtils";
import {TimePeriodType} from "controls/react/form/timePeriodType";

export type ServiceViewerProps = {
	id: string
}

export class ViewerStore {
	timePeriod: TimePeriod = {
		period: TimePeriodType.Last24Hours
	}

	timePeriodZoomed: TimePeriod

	selectedElement: MxCell
	selectedQualifierId: number

	service: Service
	serviceModel: any

	loading: boolean
	loaded: boolean = false

	constructor(public props: ServiceViewerProps) {
		makeAutoObservable(this)
	}

	get timePeriodEffective() {
		return this.timePeriodZoomed ?? this.timePeriod
	}

	get isTimezoneDifferent(){
		return isTimeZoneDifferentFromCurrent(this.service.operatingSchedule.timeZone);
	}

	get serviceTime() {
		return getCurrentTimeStringInTimeZone(this.service.operatingSchedule.timeZone);
	}

	updateTimePeriod = (timePeriod: TimePeriod) => {
		this.timePeriod = timePeriod
		this.timePeriodZoomed = null
	}

	async init() {
		this.loading = true

		let serviceResult = await apiFetch(getService({
			id: this.props.id
		}))

		if (serviceResult.success) {
			this.service = serviceResult.data

			const modelResult = await apiFetch(getServiceModel({
				id: this.props.id
			}));

			if(modelResult.success){
				this.serviceModel = modelResult.data
				this.loaded = true
			}
		}

		this.loading = false
	}
}
