import {Localization, translator} from '../../core';

export const costTranslates = new Localization({
  "cost": {
    "no": "kostnad"
  },
  "costSchedule": {
    "en": "Cost",
    "no": "kostnad"
  },
  "advisorSchedule": {
    "en": "Advisor",
    "no": "Advisor"
  },
  "resourcesSchedule": {
    "en": "Resources",
    "no": "Ressurser"
  },
  "budget": {
    "no": "budsjett"
  },
  "Collapse": {
    "no": "Kollaps"
  },
  "Cost Profile": {
    "no": "Kostnadsprofil",
    "en": "Costprofile"
  },
  "Cost Gateway": {
    "no": "Kost gateway",
    "en": "Cost gateway"
  },
  "Cost gateway": {
    "no": "Kost gateway"
  },
  "all": {
    "no": "alle"
  },
  "Split": {
    "no": "Splitt"
  },
  "Total year": {
    "no": "Total år"
  },
  "Import external cost data": {
    "no": "Importer kostdata"
  },
  "No Cost Profile is created for this link. It might be from an Cost collector without a Cost Profile.": {
    "no": "Det er ingen kostprofil opprettet for denne linken. Den kan komme fra en kost gateway uten Kostbase.",
    "en": "No costprofile is created for this link. It might be from an Cost gateway without Coststore."
  },
  "Single delete confirmation": {
    "en": "Are you sure you want to remove this cost?",
    "no": "Vil du fjerne denne kost?"
  },
  "Multiple delete confirmation": {
    "en": "Are you sure you want to remove these costs?",
    "no": "Vil du fjerne disse kostnadene"
  },
  "Hierarchy cost link": {
    "no": "Hierarki kost link"
  },
  "Configuration": {
    "no": "Konfigurasjon"
  },
  "Show cost": {
    "no": "Vis kostnad"
  },
  "Show budget": {
    "no": "Vis budsjett"
  },
  "Creates a link to a row in another Cost Profile. Note that only the cost and name is linked. Budget is set per Cost Profile.": {
    "no": "Oppretter en link til en rad i en kostmodell. Merk at bare kost og navn er lenket. Budsjett styres per kostmodell.",
    "en": "Creates a link to a row in costmodel. Note that only the cost and name is linked. Budget is set per costmodel."
  },
  "Start date": {
    "no": "Start dato"
  },
  "Currency": {
    "no": "Valuta"
  },
  "Show estimates": {
    "no": "Vis estimater"
  },
  "Year": {
    "no": "År"
  },
  "Year Estimate": {
    "no": "Årsestimat",
    "en": "Year estimate"
  },
  "Current month": {
    "no": "Nåværende måned"
  },
  "Current month estimate": {
    "no": "Estimat nåværende måned"
  },
  "Month order": {
    "no": "Måned rekkefølge"
  },
  "Current month first": {
    "no": "Nåværende måned først"
  },
  "Asc": {
    "no": "Asc"
  },
  "Desc": {
    "no": "Desc"
  },
  "Cost Model": {
    "no": "Kostmodell",
    "en": "Costmodel"
  },
  "Cost Store": {
    "no": "Kostbase",
    "en": "Coststore"
  },
  "Cost Store/Model": {
    "no": "Kostbase/modell",
    "en": "Coststore/model"
  },
  "Cost Sheet": {
    "no": "Kostfane",
    "en": "Costsheet"
  },
  "Dirty confirmation": {
    "en": "You have unsaved content in cost. You will lose the changes if you click Close. Click Cancel and save the changes if you want to keep them.",
    "no": "Du har ulagret innhold i kost. Du vil miste endringene hvis du klikker Lukk. Klikk på Avbryt og lagre endringene hvis du vil beholde dem."
  },
  "Costprofile": {
    "no": "Kostprofil"
  },
  "Display Decimals": {
    "no": "Vis desimaler",
    "en": "Display decimals"
  },
  "Display as 1000": {
    "no": "Vises som 1000"
  },
  "Costmodel settings": {
    "no": "Kostmodell innstillinger"
  },
  "Costprofile settings": {
    "no": "Kostprofil innstillinger"
  },
  "Please select": {
    "no": "Velg"
  },
  "Costmodel": {
    "no": "Kostmodell"
  },
  "Budget period": {
    "no": "Budsjettperiode"
  },
  "Link name": {
    "no": "Lenkenavn"
  },
  "Only Costp Pofiles with same currency can be selected due to issues with currency conversion.": {
    "no": "Bare Kostprofiler med samme valuta kan velges grunnet problemer med valutakonvertering.",
    "en": "Only Costprofiles with same currency can be selected due to issues with currency conversion."
  },
  "Missing conversion rate for {0} to {1}. Please add conversion in Account/Currency tab.": {
  "no": "Mangler konverteringsrate for {0} to {1}. Legg til manglende konverteringsrate i konto/valuta fanen."
  },
  "Cost report metric": {
    "no": "Kost rapport metrikk"
  },
  "Metric registry": {
    "no": "Metrikk register"
  },
  "The link's cost profile is deleted. Please remove this link.": {
    "no": "Lenken's kostprofil er slettet. Fjern denne lenken."
  },
  "DELETED": {
    "no": "SLETTET"
  },
  "Numerics": {
    "no": "Tall"
  },
  "Columns": {
    "no": "Kolonner"
  },
  "Metric": {
    "no": "Metrikk"
  },
  "Expression": {
    "no": "Expression"
  },
  "Link": {
    "no": "Link"
  },
  "Name exists": {
    "en": "An identical name exist on this account, please use another name.",
    "no": "Et identisk navn finnes på denne kontoen, bruk et annet navn."
  },
  "listing price": {
    "no": "listepris"
  },
  "ListingPrice": {
    "en": "Listing price",
    "no": "Listepris"
  },
  "listingPrice": {
    "en": "Listing price",
    "no": "Listepris"
  },
  "Start month": {
    "no": "Start måned"
  },
  "Duplicate last year values": {
    "no": "Dupliser siste års verdier"
  },
  "Use previous year Cost": {
    "no": "Bruk forrige år kost",
    "en": "Use previous year cost"
  },
  "Use previous year Budget": {
    "no": "Bruk forrige år budsjett",
    "en": "Use previous year budget"
  },
  "Use previous year Listing Price": {
    "no": "Bruk forrige år listepris",
    "en": "Use previous year listing price"
  },
  "Create new year": {
    "no": "Opprett nytt år"
  },
  "Do you really want to delete budget year": {
    "no": "Vil du virkelig slette budsjettår"
  },
  "Delete confirmation": {
    "no": "Slett bekreftelse"
  },
  "Budget year": {
    "no": "Budsjettår"
  },
  "Linked Cost profile item is not reachable. It might be that it belong on a a higher account level.": {
    "no": "Linket kostprofil kan ikke nås. Det kan være fordi den tilhører konto på et høyere nivå.",
    "en": "Linked cost item is not reachable. It might be that it belong on a higher account level."
  },
  "Month Order": {
    "no": "Måned rekkefølge",
    "en": "Month order"
  },
  "Threshold": {
    "no": "Terskelverdi"
  },
  "The Warning threshold cant be equal or larger than the Critical threshold": {
    "no": "Advarselsterskel kan ikke være lik eller større enn kritisk terskel",
    "en": "Warning threshold can't be equal or larger than critical threshold"
  },
  "Breach of a threshold give an Event.": {
    "no": "Brudd av en terskelverdi gir en hendelse.",
    "en": "Breach of a threshold give an event."
  },
  "Percentage change": {
    "no": "Prosentvis endring"
  },
  "%": {
    "no": "%"
  },
  "Percentage change from previous month": {
    "no": "Prosentvis endring fra forrige måned"
  },
  "Percentage change from previous period": {
    "no": "Prosentvis endring fra forrige periode"
  },
  "decrease from previous month": {
    "no": "nedgang fra forrige måned"
  },
  "increase from previous month": {
    "no": "økning fra forrige måned"
  },
  "No change from previous month": {
    "no": "Ingen endring fra forrige måned"
  },
  "decrease from previous year": {
    "no": "nedgang fra forrige år"
  },
  "increase from previous year": {
    "no": "økning fra forrige år"
  },
  "No change from previous year": {
    "no": "Ingen endring fra forrige år"
  },
  "costRate": {
    "en": "%",
    "no": "%"
  },
  "Read only, Listing price is only received from Cost store link": {
    "no": "Kun les, listepris hentes kun fra kost lenke",
    "en": "Read only, listing price is only received from cost link"
  },
  "Link type": {
    no: "Lenketype"
  }
});

export const i = translator(costTranslates);
