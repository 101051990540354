import React from "react";
import {Popover} from "antd";
import {DownloadOutlined, EditOutlined, LoadingOutlined} from "@ant-design/icons";
import {BudgetLink, CostType} from "areas/cost/budget/budgetTypes";
import {getCostLinkInfo, getCostProfile} from "../../api";
import {CostsRouter} from "../../bundleDescription";
import {State} from "../../../../tools";
import "./LinkCell.less"
import {i} from "../../translations"
import b_ from "b_";
import {LinkCostProfileRowWindow} from "areas/cost/budget/linkCostProfileRowWindow";
import {CostBudgetItem, CostBudgetLink} from "areas/cost/models/costBudgetItem";
import {flatTree, getPath} from "../../../../tools/tree";
import {BudgetTableContext} from "./BudgetTableContext";
import classnames from "classnames";
import {isOneOf} from "../../../../tools/utils";
import {CostProfile} from "../../models/costProfile";
import {apiFetch} from "../../../../framework/api";
import {makeAutoObservable} from "mobx";
import {observer} from "mobx-react";
import {AntPopover} from "../../../../controls/react/ant/antPopover";
import {MobxManager} from "framework/mobx-integration";
import {BudgetTableStore} from "./BudgetTableStore";
import {NavigationStore} from "../../../../framework/navigationStore";

const b = b_.with('link-cell');

const METRIC_TYPES = [CostType.METRIC, CostType.EXPRESSION];

class LinkCellStore {
	record: CostBudgetItem
	loading: boolean = false
	popoverShowed: boolean = false
	editWindowShowed: boolean = false

	account: {id: string, name: string}
	profile: CostProfile
	path: CostBudgetItem[]
	tableStore: BudgetTableStore;

	mobx = new MobxManager()

	constructor(record: CostBudgetItem) {
		this.record = record;
		makeAutoObservable(this);
	}

	showEditWindow = () => this.editWindowShowed = true;
	hideEditWindow = () => this.editWindowShowed = false;

	showPopover = async () => {
		this.popoverShowed = true;
		this.loadLinkData();
	}

	hidePopover = () => this.popoverShowed = false;

	get hasProfile() {
		return !!this.record.targetProfileId;
	}

	get isLinkTargetReadable() {
		return this.record.linkTargetReadable;
	}

	get isMetric() {
		return isOneOf(this.record.costTargetType, METRIC_TYPES);
	}

	get loaded(): boolean {
		return !!this.description;
	}

	loadLinkData = async () => {
		if (this.loading || this.loaded) {
			return;
		}
		this.loading = true;
		await this.tableStore?.loadLinkDescription(this.record);
		this.loading = false;
	}

	get description() {
		return this.tableStore?.linksDescriptions?.get(this.record.costTargetId);
	}

	get type() {
		switch (this.record.costTargetType) {
			case CostType.METRIC:
				return i('Metric');
			case CostType.EXPRESSION:
				return i('Expression');
			default:
				return i('Link');
		}
	}

	get editable() {
		return this.tableStore?.lockStructure == false && this.tableStore?.lockValues == false;
	}

	destroy(){
		this.mobx.destroy()
	}
}

interface LinkCellProps {
	record: CostBudgetItem
}

class LinkCellClass extends React.PureComponent<LinkCellProps> {
	static contextType = BudgetTableContext;
	declare context: React.ContextType<typeof BudgetTableContext>;
	store: LinkCellStore;
	constructor(props: LinkCellProps) {
		super(props);
		this.store = new LinkCellStore(props.record);
	}

	componentDidMount() {
		this.store.tableStore = this.context;
	}

	render() {
		const {popoverShowed, record, editable} = this.store;
		const {budget} = this.context;

		// TODO: move LinkCostProfileRowWindow opening to BudgetTableContext
		return (
			<div className={b()}>
				<AntPopover placement="topLeft" content={this.popoverContent()} open={popoverShowed} overlayPadding={true} />
				<div className={b('content')}>
					{this.icon()}
					{this.text()}
					{editable && this.editIcon()}
				</div>
				{
					this.store.editWindowShowed &&
					<LinkCostProfileRowWindow
						onCancel={this.store.hideEditWindow}
						onSave={this.updateLink}
						initLink={record}
						budget={budget}
						parent={record.parent as CostBudgetItem}
					/>
				}
			</div>
		)
	}

	componentWillUnmount() {
		this.store.destroy()
	}

	text = () => {
		const { record, isMetric } = this.store;
		if(isMetric) {
			return <span className={b('text')} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>{record.name}</span>;
		}
		return <a className={b('link')} href="#" onClick={this.onClick} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>{record.name}</a>;
	}

	editIcon = () => {
		const {record} = this.props;
		if (isOneOf(record.costTargetType, METRIC_TYPES) || record.editDisabled(this.context.subAccounts)) return;

		return <a href="#" onClick={this.onEditClick}><EditOutlined  className={b('edit-icon')}/></a>
	}

	icon = () => {
		const {loading} = this.store;
		return loading ? <LoadingOutlined/> : null;
	}

	popoverContent = () => {
		const {record} = this.props;

		if (isOneOf(record.costTargetType, METRIC_TYPES)) return <div>
			<div className={b('popover-content')}>
				<div>{i('Type')}: {i(record.costTargetType)}</div>
			</div>
		</div>;

		const {loading} = this.store;
		if (loading) {
			return <div>{i('Loading...')}</div>;
		}

		const {type, description} = this.store;

		return <div>
			<div className={b('popover-content')}>
				<div className={b('description')}>{description}</div>
				<div>{i('Type')}: {type}</div>
				{record.hasHierarchy && <div>{i('Hierarchy cost link')}</div>}
				<div>{i('Percentage')}: {record.percentage}%</div>
			</div>
		</div>;
	}

	updateLink = async (newLink: CostBudgetItem) => {
		const { record } = this.props;
		record.update({
			name: newLink.name,
			costTargetType: newLink.costTargetType,
			costTargetId: newLink.costTargetId,
			costModelId: newLink.costModelId,
			targetAccountId: newLink.targetAccountId,
			targetProfileId: newLink.targetProfileId,
			hasHierarchy: newLink.hasHierarchy,
			hierarchyIds: newLink.hierarchyIds,
			percentage: newLink.percentage,
			cost: newLink.cost,
			listingPrice: newLink.listingPrice,
			periodEstimate: newLink.periodEstimate,
			currentEstimate: newLink.currentEstimate,
			items: newLink.items,
			linkTargetReadable: newLink.linkTargetReadable,
			information: newLink.information
		});

		this.store.hideEditWindow();
	}

	onEditClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		this.store.showEditWindow();
	}

	onClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		if(this.context.linkRedirectDisabled)
			return;
		if (this.store.hasProfile && this.store.isLinkTargetReadable) {
			const url = CostsRouter.details(this.props.record.targetProfileId, this.props.record.costTargetId, this.props.record.costModelId);
			NavigationStore.go(url);
		}
	}

	onMouseEnter = (e: React.MouseEvent<HTMLAnchorElement>) => {
		this.store.showPopover();
	}

	onMouseLeave = async (e: React.MouseEvent<HTMLAnchorElement>) => {
		this.store.hidePopover();
	}
}

export const LinkCell = observer(LinkCellClass);
