import React, {useCallback} from "react"
import {sharedLocalization as widgetsSharedLocalization} from "controls/designer/features/widgets/localization";
import {Section} from "controls/react/layout/section"
import {FormEntry} from "controls/react/form/formEntry"
import {AntInput} from "controls/react/ant/antInput"
import {AccountDropDown} from "controls/react/dropdowns/accountDropDown"
import {AntCheckbox} from "controls/react/ant/antCheckbox"
import {TagsSelect} from "controls/react/tagsSelect"
import {EventTypeSelect} from "controls/react/eventTypeSelect"
import {ApplicationState} from "framework/applicationState"
import {apiFetch} from "framework/api"
import {Severity} from "framework/entities/healthData"
import {getEventsState} from "areas/summary/events/api"
import {EventStateDashboardWidgetConfig} from "areas/summary/events/eventStateDashboardWidgetConfig"
import {WidgetConfigurationProps} from "controls/designer/features/widgets/widgetConfig"
import {EventStateDashboardWidgetPersistedProps} from "areas/summary/events/eventStateDashboardWidgetPersistedProps"
import {EventStateGenericWidget} from "areas/summary/events/eventStateGenericWidget";
import {SummaryRouter} from "areas/summary/bundleDescription";
import {EventsSummaryPopupProps, showEventsSummaryPopup} from "areas/summary/events/eventsSummaryPopup"
import {EventSummaryEvent} from "framework/entities/events";
import {
	SeverityStateConfigurator,
	StateWidgetProps, Subscription, WidgetState,
	withDashboardData,
	withDataLoad
} from "controls/react/barsStatesWidget";
import {TimePeriodType} from "controls/react/form/timePeriodType";

const i = require('core/localization').translator(widgetsSharedLocalization, {
	'Event type': {},
	'Open events in popup': {no: 'Åpne hendelser i popup'},
	'Show menu': {no: 'Vis meny'},
	'Display settings': {no: 'Skjerminnstillinger'},
});

export function getWidgetDescription(){
	return {
		form: WidgetConfiguration,
		defaultConfig: {
			type: 'event-state',
			title: i('Event State'),
			accountId: ApplicationState.accountId,
			includeSubaccounts: false,
			showUntagged: false
		},
		widget: Widget,
		fullTitle: i('Event State')
	}
}

export const WidgetConfiguration = (props: WidgetConfigurationProps<EventStateDashboardWidgetConfig>) => {
	const { configLink } = props;
	let severities = configLink.get('severities').check(i('Select Severity'), (x: any) => !x || x?.length > 0).value;
	const displayOptions = configLink.get('displayOptions').value;
	const dataSource = [[Severity.Critical, i('CRITICAL')], [Severity.Major,  i('MAJOR')], [Severity.Minor,  i('MINOR')]];
	severities ??= [Severity.Critical, Severity.Major, Severity.Minor];

	const onSeveritiesChanged = useCallback((value: {displayOptions: {showBucket: boolean, showBar: boolean}, severities: (Severity | string)[]}) => {
		configLink.update((current: any) => {
			current.displayOptions = value.displayOptions;
			current.severities = value.severities;
		});
	}, []);
	return (
		<Section appearance={'none'} contentPadding={false} childrenPadding={true}>
			<FormEntry label={i('Title')}>
				<AntInput {...configLink.get('title').props}/>
			</FormEntry>
			<FormEntry label={i('Account')}>
				<AccountDropDown {...configLink.get('accountId').props}/>
			</FormEntry>
			<FormEntry>
				<AntCheckbox valueLink={configLink.get('includeSubaccounts')}>{i('Include subaccounts')}</AntCheckbox>
			</FormEntry>
			<Section appearance={'frame'} title={i('Tags')} childrenPadding={true}>
				<FormEntry label={i('Tags')} vertical={true} width={'fit'}>
					<TagsSelect accountId={configLink.value.accountId}
								{...configLink.get('tags').props} />
				</FormEntry>
				<AntCheckbox valueLink={configLink.get('showUntagged')}>{i('Show Untagged')}</AntCheckbox>
			</Section>
			<Section appearance={'frame'} title={i('Event type')} childrenPadding={true}>
				<FormEntry label={i('Event type')} vertical={true} width={"fit"}>
					<EventTypeSelect
						accountId={configLink.value.accountId}
						{...configLink.get('eventTypes').props}
					/>
				</FormEntry>
			</Section>
			<Section title={i('Severities')} appearance={"frame"} childrenPadding={true}>
				<SeverityStateConfigurator displayOptions={displayOptions}
										   severitiesList={severities}
										   dataSource={dataSource}
										   onChange={onSeveritiesChanged}/>
			</Section>
			<Section appearance={'frame'} title={i('Display settings')} childrenPadding={true}>
				<FormEntry>
					<AntCheckbox valueLink={configLink.get('showEventsInPopup')}>{i('Open events in popup')}</AntCheckbox>
					<AntCheckbox valueLink={configLink.get('showMenu')} disabled={!configLink.get('showEventsInPopup').value}>{i('Show menu')}</AntCheckbox>
				</FormEntry>
			</Section>
		</Section>
	)
}

const WidgetInner = withDashboardData(withDataLoad(EventStateGenericWidget, config => apiFetch(getEventsState(config))));

class Widget extends React.Component<StateWidgetProps<EventStateDashboardWidgetConfig, EventStateDashboardWidgetPersistedProps>, WidgetState<EventStateDashboardWidgetPersistedProps>> {
	subscriptions: Subscription[];
	window: { destroy: () => void }

	constructor(props: StateWidgetProps<EventStateDashboardWidgetConfig, EventStateDashboardWidgetPersistedProps>) {
		super(props);
		this.subscriptions = [
			EventSummaryEvent.subscription(this.props.config)
		];
	}

	render() {
		return <WidgetInner {...this.props} type={this.props.config.type} subscriptions={this.subscriptions}
		                    onClick={this.onClick}/>
	}

	onClick = (severity: Severity, isWarning?: boolean) => {
		const config = this.props.config;

		let sentSeverity: string[] = [severity];
		if (isWarning) {
			sentSeverity.push('WARNING');
		}

		let additionalData = {
			includeSubaccounts: config.includeSubaccounts,
			accountId: config.accountId,
			showUntagged: config.showUntagged,
			tags: config.tags,
			type: config.eventTypes,
			severity: sentSeverity,
			sorting: [{field: 'time', direction: 'desc'} as const],
			filters: [{'field': 'severity', operator: 'multiselect_equals', value: [severity]}],
			timePeriod: TimePeriodType.All,
			eventTypes: config.eventTypes,
		} as EventsSummaryPopupProps

		if (config.showEventsInPopup) {
			this.window?.destroy()
			this.props.navigator.go({action: () => {
				this.window = showEventsSummaryPopup(additionalData)
			}, url: SummaryRouter.events(additionalData)})
		} else {
			this.props.navigator.go({url: SummaryRouter.events(additionalData)})
		}
	}
}

