import './aiQueryWidgetChat.less'

import {observer} from "mobx-react"
import React from "react"
import {DatabaseOutlined, SendOutlined} from '@ant-design/icons'

import {AiQueryWidgetStore} from "controls/designer/features/widgets/ai-query-widget/aiQueryWidgetStore";
import {AntModal, ModalPosition} from "controls/react/ant/antModal";
import {Section, Toolbar} from "controls/react/layout";
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {AntButton} from "controls/react/ant/antButton";
import {ChatHistory} from "controls/chat/chatHistory";
import {AntAlert} from "controls/react/ant/antAlert";

const i18n = require('core/localization').translator({
	'Select a datasource': {
		no: 'Velge en datakilde'
	},
	'Generate': {},
	'AI-query entered here will be persisted when updating dashboard.': {
		no: 'AI-spørring her vil bli lagret når dashboard oppdateres.'
	}
}, sharedLocalization);

type AiQueryWidgetChatProps = {
	onClose: () => void
	height: number
	width: number
	top: number
	left: number
	store: AiQueryWidgetStore
}

const b = require('b_').with('ai-query-widget-chat');

export const AiQueryWidgetChat = observer((props: AiQueryWidgetChatProps) => {
	const store = props.store
	const style = React.useMemo(() => {
		return {
			top: props.top,
			left: props.left
		}
	}, [props.top, props.left])

	return <AntModal footer={null}
	                 width={props.width}
	                 height={props.height}
	                 open={true}
	                 resizable={true}
	                 style={style}
	                 positionType={ModalPosition.Custom}
	                 onCancel={props.onClose}>
		<Section appearance={"none"} height={"fit"}>
			<Toolbar title={store.props.config.title}>
				<AntButton icon={<DatabaseOutlined />}
				           title={i18n('Select a datasource')}
				           onClick={store.showCustomDataList}
				           />
			</Toolbar>
			<ChatHistory chatEngine={store.chatEngine}/>

			{!store.props.designer.config.chromeless &&
				<AntAlert message={i18n('AI-query entered here will be persisted when updating dashboard.')}
			          className={b('alert-message')}
			          closable={true}/>
			}

			<Section appearance={"none"}
			         contentPadding={true}
			         className={b('message-section')}
			         childrenPadding={true}>

				<AntButton icon={<SendOutlined />}
				           className={b('send-button')}
				           title={i18n('Generate')}
				           disabled={store.chatEngine.sending || !props.store.query}
				           onClick={store.sendRequestToAi}
				           type={"primary"} />
				<div ref={store.setDesignerRef}></div>
			</Section>
		</Section>
	</AntModal>
})
